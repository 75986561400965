import React from "react";
import "./Legalese.css";
import { useHistory } from "react-router-dom";

export default function Terms() {
  const history = useHistory();
  return (
    <main className="legalese terms-and-conditions">
      <div className="legalese-container">
        <p
          onClick={() => {
            history.goBack();
          }}
          className="body-2 pointer"
        >
          <span className="back-arrow" /> BACK
        </p>
        <section>
          <h1>Handl Health, INC Website Terms and Conditions of Use</h1>
          <br />
          <p>
            <b>
              {" "}
              Effective and last modified: 10.19.22 <br /> <br /> IMPORTANT:
              PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS OF USE. THEY
              CONTAIN A CLASS ACTION WAIVER, LIMITATIONS ON HANDL’S LIABILITY
              AND OTHER PROVISIONS THAT AFFECT YOUR LEGAL RIGHTS. BY ACCEPTING
              THESE TERMS AND CONDITIONS OF USE, AS DETAILED BELOW, YOU AGREE TO
              WAIVE YOUR RIGHTS TO PARTICIPATE IN A CLASS ACTION SUIT. IF YOU
              THINK YOU MAY HAVE A MEDICAL EMERGENCY, CALL YOUR DOCTOR OR 911
              IMMEDIATELY.
            </b>
          </p>
          <br />
          <p>
            These Website Terms and Conditions of Use (these “<b>Terms</b>”), as
            well as the Privacy Policy (available here:
            www.handlhealth.com/privacy (collectively, the “<b>Agreement</b>”),
            describe the terms and conditions upon which Handl Health, INC. and
            its affiliates, subsidiaries, and brands (collectively, “
            <b>Handl</b>
            ,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”), grant you access to
            the website located at www.gohandlhealth.com and www.handlhealth.com
            and any other website or service in or to which the Agreement is
            displayed or linked (collectively, the “<b>Services</b>”). The
            Agreement does not apply to any application, website, or service
            that does not display or link to the Agreement. You may download a
            copy of these Terms here. THE AGREEMENT IS A BINDING LEGAL CONTRACT
            BETWEEN US AND YOU. PLEASE READ THE AGREEMENT CAREFULLY BEFORE
            ACCESSING AND USING THE SERVICES. IF YOU DO NOT AGREE TO ANY TERM OF
            THE AGREEMENT, YOU MUST NOT USE THE SERVICES.
          </p>
          BY ACCESSING AND USING THE SERVICES, YOU AFFIRM THAT:
          <ul>
            <li>YOU HAVE READ AND UNDERSTAND THE AGREEMENT;</li>
            <li>YOU WILL COMPLY WITH THE AGREEMENT; AND</li>
            <li>
              YOU ARE AT LEAST THE AGE OF LEGAL MAJORITY IN YOUR PLACE OF
              RESIDENCE.
            </li>
          </ul>
        </section>
        <article>
          <h2>ADDITIONAL TERMS </h2>
          <p>
            Certain features of the Services may be subject to additional terms
            (“Additional Terms”) presented in conjunction with the features.
            Regardless of how they are presented to you, you must agree to the
            Additional Terms before using the features of the Services to which
            they apply. If you do not agree to any Additional Terms, then you
            must not use the Services to which they relate. Unless otherwise
            specified in the Additional Terms, all Additional Terms are hereby
            incorporated into and made a part of the Agreement. The Agreement
            and all Additional Terms apply equally, but if any term within a
            particular set of Additional Terms is inconsistent with any
            provision of these Terms, the inconsistent term within the
            Additional Terms will prevail solely for the Services to which such
            Additional Terms apply.
          </p>
        </article>

        <article>
          <h2>CHANGES TO the TERMS</h2>
          <p>
            The effective date of these Terms is set forth at the top of this
            webpage. The effective date of the Privacy Policy is set forth at
            the top of the Privacy Policy. From time to time in our sole
            discretion, we may revise or supplement the Agreement. We will
            provide you with advance notice of material revisions to the
            Agreement. We will not make revisions that have an immediate effect
            unless we are legally required to do so or to protect other users of
            the Services. The advance notice period will commence when the
            revised Agreement is uploaded to the Services and made accessible to
            our users. You acknowledge and agree that posting the updated
            version of the Agreement on the Services will constitute adequate
            and sufficient notice, and you further agree to check for updates
            any time you use the Services. Your continued use of the Services
            following the posted effective date of an update to the Agreement
            constitutes your affirmation that you have read, understand, and
            accept the revised Agreement.
          </p>
        </article>
        <article>
          <h2>USE of THE SERVICES</h2> <br />
          <u className="subheading-poppins">Age of Eligibility</u>
          <br />
          <br />
          <p>
            You must be the age of legal majority or older in your place of
            residence to use the Services.
          </p>{" "}
          <u className="subheading-poppins">Your Account</u>
          <br />
          <br />
          <p>
            You may be required to create an account (“Account”) to use certain
            features of the Services. The Account allows you to:
          </p>
          <ul>
            <li>view health-related information</li>
            <li>communicate with our staff</li>
            <li>arrange for health-related services</li>
            <li>access additional services</li>
          </ul>
          <p>
            You agree that you will maintain and update your Account information
            to ensure that it is always current, complete, and accurate. If you
            provide untrue, incomplete, misleading, or inaccurate information,
            you understand that we have the right to terminate your Account and
            your use of the Services. You agree that all information that you
            provide to us in connection with creating an Account, or that we
            otherwise collect through the Services, is governed by our Privacy
            Policy, and you consent to all actions we take with respect to such
            information consistent with our Privacy Policy. You agree to protect
            the security of your Account. You are responsible for all use of
            your Account, including your login credentials (i.e., username and
            password) and activation codes. We treat access to the Services
            through your Account credentials as authorized by you. Unauthorized
            access to password-protected or secure areas is prohibited and may
            lead to criminal prosecution. We may suspend your use of all or part
            of the Services without notice if we suspect or detect any breach of
            security relating to your Account or use of the Services. Please
            immediately notify us using the contact information provided below
            (at the bottom of these Terms) in the event of any unauthorized
            access to or use of your user name or password or any other breach
            of security involving your Account, or if you believe that
            information you provided to us is no longer secure or you need to
            deactivate your Account or password.
          </p>
          <u className="subheading-poppins">Accessing the Services</u>
          <br />
          <br />
          <p>
            You are responsible for the software, hardware, and Internet service
            needed to access and use the Services. If you access and use the
            Services on your smartphone, tablet, or mobile device, you are
            solely responsible for any and all data and other fees related to
            use of the Services through such device. Certain Services may offer
            text messaging (SMS or MMS) services. Message and data rates may
            apply. Once you opt-in to receive text messages from us, the
            frequency of text messages that we send to you depends on your
            transactions with us. All charges are billed by and payable to your
            wireless service provider. Please contact your wireless service
            provider for pricing plans and details. Text message services are
            provided on an “as is” basis and may not be available in all areas
            at all times. BY AGREEING TO RECEIVE TEXT MESSAGES, YOU UNDERSTAND
            AND AGREE THAT WE MAY USE AN AUTOMATIC DIALING SYSTEM TO DELIVER
            TEXT MESSAGES TO YOU AND THAT YOUR CONSENT TO RECEIVE TEXT MESSAGES
            IS NOT REQUIRED AS A CONDITION TO USE THE SERVICES. We do not
            guarantee availability of the Services at all times of the day. We
            may from time to time perform upgrades, updates, or otherwise make
            the Services unavailable. To the maximum extent authorized under
            applicable law, we reserve the right to change, remove, delete,
            restrict, block access to, or stop providing any or all of the
            Services at any time and without notice. Except as may be provided
            in a separate customer agreement, we have no obligation to provide
            access to or support for the Services.
          </p>
          <u className="subheading-poppins">
            Restrictions on Your Use of Services{" "}
          </u>
          <br />
          <br />
          You may access and use the Services only in accordance with the
          Agreement and all laws, rules, and regulations applicable to your use
          of the Services. In addition, you agree that you will not (and you
          agree not to encourage or allow any third party to):
          <ul>
            <li>
              Breach, test, circumvent (or attempt to breach, test, or
              circumvent) any security, copy protection, or rights management
              feature in the Services, or otherwise attempt to gain unauthorized
              access to the Services, other users’ Accounts, or our computer
              systems or networks;
            </li>
            <li>
              Copy (except as permitted under the section labeled “Our Content”,
              below), modify, translate, adapt, or otherwise create derivative
              works or improvements (whether or not patentable) of any part of
              the Services;
            </li>
            <li>
              Decompile, reverse engineer, disassemble, or decode the Services,
              or otherwise attempt to derive or gain access to the source code
              of any part of the Services (unless applicable laws specifically
              prohibit such restriction);
            </li>
            <li>
              Remove, alter, or obscure any copyright, trademark, or other
              intellectual property or proprietary notice contained in the
              Services;
            </li>
            <li>
              Distribute, rent, sublicense, lease, lend, sell, resell, assign,
              transfer, transmit, stream, broadcast, or otherwise make available
              or exploit any features or functionality of the Services;
            </li>
            <li>
              Reformat or frame any portion of the Services; Cause or launch any
              programs or scripts for the purpose of scraping, indexing,
              surveying, or otherwise data mining any portion of the Services or
              unduly burdening or hindering the operation and/or functionality
              of any aspect of the Services;
            </li>
            <li>Create an Account under fraudulent pretenses; or</li>
            <li>
              Engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Services or interferes with the proper
              working of the Services, or which, as determined by us, may harm
              us or users of the Services, or expose us or them to liability.
            </li>
          </ul>
          <u className="subheading-poppins">International Use</u>
          <br />
          <p>
            If you are not a United States resident and you are accessing our
            Services from outside the United States, you agree to transfer
            certain information outside your home country to us and that you
            will follow all the laws that apply to you. Our servers and
            operations are located in the United States and our policies and
            procedures are based on United States law. Because of this, the
            following provisions apply specifically to users located outside the
            United States: (i) you consent to the transfer, storage, and
            processing of your information (including personal data) to and in
            the United States and/or other countries; (ii) if you are using the
            Services from a country embargoed by the United States, or are on
            the United States Treasury Department’s list of Specially Designated
            Nationals, you are not authorized to access or use the Services; and
            (iii) you agree to comply with all laws, rules, and regulations in
            effect in the country in which you reside and the country from which
            you access the Services. The Services are not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation, or that would subject us to any registration
            requirement within such jurisdiction or country.
          </p>
        </article>

        <article>
          <h2>our CONTENT</h2>
          <p>
            We and our licensors retain full and complete title to, and to all
            information and materials provided on or through, the Services,
            including all Software (as defined below) and all artwork, graphics,
            text, video and audio clips, trademarks, logos and other content
            (collectively, “Our Content”). Our logos and other trademarks used
            in the Services, are our trademarks and may not be used in any
            manner without our prior written consent. All other trademarks not
            owned by us that appear on or in the Services are the property of
            their respective owners. If you comply with the Agreement (including
            all applicable Additional Terms), then you may download, print
            and/or copy Our Content other than Software, solely for your own
            personal use or for limited business purposes upon our approval.
            Your rights with respect to the Software are described in the
            section labeled “Use of Software” below. In addition, except for any
            of Our Content that is in the public domain, you may not:
            Incorporate any of Our Content into any other work (such as your own
            website) or use Our Content in any public or commercial manner;
            Modify, adapt, reverse engineer, distribute, frame, republish,
            upload, display, post, transmit, transfer, license, or sell Our
            Content in any form or by any means; Change any of the notices about
            copyright, trademarks, or other intellectual property rights that
            may be part of Our Content; or ‘Deep link’ to any of the Services
            (i.e., link to any page other than the home page of one of the
            Services). You may link to our homepage, provided you do so in a way
            that is fair and legal and does not damage our reputation or take
            advantage of it, but you must not establish a link in such a way as
            to suggest any form of association, approval, or endorsement on our
            part without our express written consent. No right, title, or
            interest in or to the Services or any content on the Services is
            transferred to you, and all rights not expressly granted are
            reserved by us.
          </p>
        </article>

        <article>
          <h2>Use of Software</h2>
          <p>
            Any software that is available on or through the Services
            (collectively, “Software”), is the copyrighted work of us and/or our
            licensors. Copying or reproducing the Software to any other server
            or location for further reproduction or redistribution is strictly
            prohibited, unless such reproduction or redistribution is permitted
            by a license agreement accompanying such Software. You may not
            create derivative works of the Software, or attempt to decompile or
            reverse-engineer the Software unless otherwise permitted by law. Use
            of the Software is subject to the license terms of any license
            agreement that may accompany or is provided with the Software. You
            may not download any Software until you have read and accepted the
            terms of the accompanying Software license. Portions of the Software
            may utilize or include third party software and other copyrighted
            material. Acknowledgements, license terms, and disclaimers for such
            material are contained in the documentation for the Software, and
            your use of such material is governed by their respective terms. THE
            SOFTWARE IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF A
            SEPARATE LICENSE AGREEMENT ACCOMPANYING THE SOFTWARE. EXCEPT AS
            WARRANTED IN SUCH LICENSE AGREEMENT, WE AND OUR LICENSORS DISCLAIM
            ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SOFTWARE, INCLUDING
            ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
          </p>
        </article>

        <article>
          <h2>content on the services</h2>
          <p>
            We may offer cost estimates regarding health-related services or
            procedures (“Quote”) at healthcare facilities located near you. Any
            use or reliance on any Quote or related information available via
            the Services or obtained by you through the Services is at your own
            risk and SUCH INFORMATION WILL NOT CONSTITUTE A REPRESENTATION,
            WARRANTY, OR OTHER COMMITMENT BY US WITH RESPECT TO ANY QUOTE. WE
            HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO
            QUOTES OBTAINED THROUGH THE SERVICES, INCLUDING AS TO THE ACCURACY,
            SUITABILITY FOR ANY PURPOSE, OR COMPLETENESS THEREOF. We do not
            endorse, support, represent or guarantee the completeness,
            truthfulness, accuracy, or reliability of any Quote available via
            the Services. Nor do we endorse or support any healthcare facility
            referenced via the Services. You acknowledge that we simply act as a
            passive conduit and an interactive computer service provider for the
            publication and distribution of Quotes. You understand that we do
            not control and are not responsible for Quotes made available
            through the Services, and that by using the Services, you may be
            exposed to Quotes and other information about healthcare facilities
            that may be inaccurate or misleading. You agree that you must
            evaluate and make your own decision, and bear all risks associated
            with the use of any Quotes and information related to nearby
            healthcare facilities referenced through the Services. You further
            acknowledge that we have no obligation to screen, preview, monitor,
            or approve any healthcare facilities that are referenced on the
            Services. However, we reserve the right to review or stop
            referencing certain healthcare facilities at our sole discretion. By
            using the Services, you agree that it is solely your responsibility
            to evaluate your risks to bear associated with the use, accuracy,
            usefulness, completeness or appropriateness of any Quote that you
            may receive or access, through the Services. Under no circumstances
            will Handl be liable in any way for any Quote or services provided
            at any healthcare facility referenced on a Quote, including, but not
            limited to, confidential or private information (including, but not
            limited to, health information) or for any loss or damage of any
            kind incurred as a result of the use of any Quote accessed,
            transmitted, or otherwise conveyed via the Services. You waive the
            right to bring or assert any claim against Handl relating to Quotes,
            and release Handl from any and all liability for or relating to any
            Quote.
          </p>
        </article>
        <article>
          <h2>HEALTHCARE FACILITIES</h2>
          <p>
            THE SERVICES AND ANY QUOTE OR RELATED CONTENT IS PROVIDED FOR
            INFORMATIONAL PURPOSES ONLY. THE SERVICES ARE NOT INTENDED TO BE A
            SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT.
            ALWAYS SEEK THE ADVICE OF YOUR PHYSICIAN OR OTHER QUALIFIED HEALTH
            PROVIDER WITH ANY QUESTIONS YOU MAY HAVE REGARDING A MEDICAL
            CONDITION. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN
            SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ ON THE SERVICES. IF
            YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY, CALL YOUR DOCTOR OR 911
            IMMEDIATELY. HANDL DOES NOT RECOMMEND OR ENDORSE ANY SPECIFIC TESTS,
            PHYSICIANS, PRODUCTS, PROCEDURES, OPINIONS, OR OTHER INFORMATION
            THAT MAY BE MENTIONED ON THE SERVICES. HANDL IS NOT A HEALTH OR
            WELLNESS PROVIDER AND CANNOT RECOMMEND OR REFER YOU TO ANY HEALTH OR
            WELLNESS PROVIDER. RELIANCE ON ANY INFORMATION PROVIDED BY HANDL IS
            SOLELY AT YOUR OWN RISK. The Services may contain health-related
            information that is sexually explicit. If you find these materials
            offensive, you should not use the Services. You should be aware that
            if you post any health-related information about yourself or anyone
            else on the Services, you do so at your own risk. If you post health
            information about services rendered to another individual, you
            represent that you have the legal authority to receive health
            information about that individual from that individual’s health care
            providers and that you have the legal authority to further disclose
            such health information. If you post health-related information, you
            will be placing it into the public domain which may violate federal
            or state laws that protect the privacy of health information. You
            also acknowledge that the health care or wellness provider about
            whom you submit content may submit content that contain your private
            or confidential health information in response to content you
            submit. Handl is not liable for any such content. Handl cannot be
            expected to keep your health information confidential if you post it
            to the Services or otherwise make it available to others.
          </p>
        </article>

        <article>
          <h2>SUBMISSIONS AND COMMUNICATIONS</h2>
          <p>
            In these Terms, “User Content” means material (including without
            limitation text, images, audio material, video material, and
            audio-visual material) that you submit to or through the Services,
            for whatever purpose. Subject to the Privacy Policy, you hereby
            grant to Handl a worldwide, irrevocable, non-exclusive, royalty-free
            license to use, reproduce, adapt, publish, translate, and distribute
            your User Content in any existing or future media. You also grant to
            Handl the right to sublicense these rights. Your User Content must
            not be illegal or unlawful, must not infringe any third party’s
            legal rights, and must not be capable of giving rise to legal
            action, whether against you, Handl, or a third party (in each case
            under any applicable law). You must not submit any User Content to
            the Services that is or has ever been the subject of any threatened
            or actual legal proceedings or other similar complaint. Internet
            transmissions are never completely private or secure. You understand
            that any communication that you send to us through the Services may
            be read or intercepted by others (for example, email communications)
            unless there is a special notice that a particular message is
            encrypted (for example, e-commerce orders and credit card
            information). Sending a communication to us does not cause us to
            have any special responsibility to you.
          </p>
        </article>

        <article>
          <h2>LINKS TO OTHER WEBSITES AND SERVICES</h2>
          <p>
            The Services may contain links to other websites and services that
            we think may interest you (“Linked Services”). Linked Services are
            not under our control, and we are not responsible for any
            information or materials on, or any form of transmission received
            from, and accept no responsibility for any loss or damage that may
            arise from your use of, any Linked Service. The inclusion of a link
            does not imply our endorsement of the Linked Service or any
            association with the operators of the Linked Service. We do not
            investigate, verify or monitor the Linked Services. We provide links
            to Linked Services for your convenience only. You access Linked
            Services at your own risk.
          </p>
        </article>

        <article>
          <h2>TERMINATION</h2>
          <p>
            You may stop using the Services at any time. You may terminate your
            Account at any time upon written notice to us by sending an email
            using the contact information provided below (at the bottom of these
            Terms). We reserve the right immediately to suspend or terminate
            your Account and access to all or part of the Services without
            notice if we believe you violated the Agreement. The rights and
            obligations that by their nature should survive or extend beyond the
            termination or expiration of the Agreement will survive any
            termination or expiration of the Agreement.
          </p>
        </article>

        <section>
          <b>IMPORTANT NOTE</b>
          <br />
          Please read carefully the following sections as they describe each
          party’s potential liability to the other and affect how disputes are
          resolved.
          <article>
            <h2>warrantY disclaimer</h2>
            <p>
              EXCEPT AS OTHERWISE PROVIDED IN THESE TERMS, THE SERVICES ARE
              PROVIDED "AS IS" AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND,
              EXPRESS OR IMPLIED. WE SPECIFICALLY DISCLAIM ALL WARRANTIES AND
              CONDITIONS OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES AND
              CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, NON-INFRINGEMENT, UNINTERRUPTED USE, AND ALL WARRANTIES
              IMPLIED FROM ANY COURSE OF DEALING OR USAGE OF TRADE. WITHOUT
              LIMITING THE GENERALITY OF THE FOREGOING, WE DO NOT WARRANT THAT
              (I) THE SERVICES WILL MEET YOUR REQUIREMENTS, (II) OPERATION OF
              THE SERVICES WILL BE UNINTERRUPTED OR VIRUS- OR ERROR-FREE, OR
              (III) ERRORS WILL BE CORRECTED. ANY ORAL OR WRITTEN ADVICE
              PROVIDED BY US OR OUR AUTHORIZED AGENTS DOES NOT AND WILL NOT
              CREATE ANY WARRANTY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
              OF IMPLIED WARRANTIES, WHICH MEANS THAT SOME OR ALL OF THE ABOVE
              EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
          </article>
          <article>
            <h2>INDEMNIFICATION</h2>
            <p>
              You agree to indemnify, defend, and hold harmless us and all of
              our directors, officers, employees, agents, information providers,
              licensors, and licensees (collectively, the “Indemnified Parties”)
              from and against any and all claims, losses, liability and costs
              (including, without limitation attorneys’ fees and costs),
              incurred by the Indemnified Parties in connection with any claim
              arising out of any breach by you of the Agreement or your use of
              the Services. You agree to cooperate as fully as reasonably
              required on our defense of any such claim. We reserve the right,
              at our own expense, to assume the exclusive defense and control of
              any matter otherwise subject to indemnification by you and you
              will not in any event settle any matter without our prior written
              consent, which will not be unreasonably withheld.
            </p>
          </article>
          <article>
            <h2>LIMITATION OF LIABILITY</h2>
            <p>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, NONE OF THE
              INDEMNIFIED PARTIES WILL BE LIABLE TO YOU OR ANY THIRD PARTY FOR
              ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR INDIRECT
              DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OR
              CORRUPTION OF DATA, SERVICE INTERRUPTIONS, PROCUREMENT OF
              SUBSTITUTE SERVICES, LOSS OF PROFITS, LOSS OF GOODWILL, OR ANY
              OTHER INTANGIBLE LOSSES, IN EACH CASE ARISING OUT OF OR RELATING
              TO (I) THE USE OR INABILITY TO USE THE SERVICES OR ANY PRODUCTS
              PURCHASED THROUGH THE SERVICES OR (II) THE CONDUCT OF YOU OR ANY
              OTHER USER IN CONNECTION WITH THE USE OF THE SERVICES OR ANY
              PRODUCT PURCHASED THROUGH THE SERVICES, EVEN IF ANY OF THE
              INDEMNIFIED PARTIES KNEW OR HAD BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. EXCEPT AS EXPRESSLY REQUIRED BY APPLICABLE LAW, IN
              NO EVENT WILL THE INDEMNIFIED PARTIES’ TOTAL LIABILITY TO YOU FOR
              ALL CLAIMS ARISING OUT OF OR IN RELATION TO THE SERVICES OR THE
              AGREEMENT, WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED, IN THE
              AGGREGATE, FIVE UNITED STATES DOLLARS ($5.00). The foregoing
              disclaimers of liability will not apply to the extent prohibited
              by applicable law in the jurisdiction of your place of residence.
              You acknowledge and agree that the above limitations of liability,
              together with the other provisions in the Agreement that limit
              liability, are essential terms and that we would not be willing to
              grant you the rights set forth in the Agreement but for your
              agreement to the above limitations of liability.
            </p>
          </article>
          <article>
            <h2>DISPUTE RESOLUTION</h2>
            <p>
              If you have a complaint about the Services, please contact us via
              email at support@handlhealth.com. To the maximum extent permitted
              by applicable law, you and we agree that any dispute resolution
              proceedings will be conducted only on an individual basis and not
              in a class, consolidated, or representative action. YOU AGREE THAT
              ANY CAUSE OF ACTION BROUGHT BY YOU ARISING OUT OF OR RELATED TO
              THE SERVICES OR THE AGREEMENT MUST COMMENCE WITHIN ONE (1) YEAR
              AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, THE CAUSE OF ACTION
              IS PERMANENTLY BARRED. The Agreement will be governed by the laws
              of the State of DE, without reference to rules governing choice of
              law. You and we each hereby irrevocably consent to the exclusive
              jurisdiction and venue of the federal and state courts located DE
              with respect to any claims, suits, or proceedings arising out of
              or in connection with the Agreement, and agree not to commence or
              prosecute any such claim, suit, or proceeding other than in the
              aforementioned courts. You waive any and all objections to the
              exercise of jurisdiction over you by such courts and to venue in
              such courts.
            </p>
          </article>
          <article>
            <h2>GENERAL TERMS</h2>
            <p>
              Entire Agreement The Agreement constitutes the sole and entire
              agreement of the parties with respect to the subject matter
              contained herein, and supersedes all prior and contemporaneous
              understandings and agreements, both written and oral, with respect
              to such subject matter. If any term or provision of the Agreement
              is determined to be invalid, illegal, or unenforceable in any
              jurisdiction, such provision shall be eliminated or limited to the
              minimum extent in such jurisdiction such that the remaining
              provisions of the Agreement will continue in full force and effect
              and such invalidity, illegality, or unenforceability will not
              affect any other term or provision of the Agreement or invalidate
              or render unenforceable such term or provision in any other
              jurisdiction. Assignment You will not assign or otherwise transfer
              the Agreement or any of your rights and obligations under the
              Agreement without our prior written consent. Any assignment or
              transfer in violation of this paragraph is null and void. We may
              assign the Agreement without your consent (i) in connection with a
              merger, acquisition or sale of all or substantially all of our
              assets, or (ii) to any affiliate or as part of a corporate
              reorganization; and effective upon such assignment, the assignee
              is deemed substituted for Handl as a party to the Agreement and
              Handl is fully released from all of its obligations and duties to
              perform under the Agreement. Subject to the foregoing, the
              Agreement will be binding upon, and inure to the benefit of the
              parties and their respective permitted successors and assigns.
            </p>
          </article>
          <article>
            <h2>
              QUESTIONS ABOUT THE SERVICES; termination; or security concerns
            </h2>
            <p>
              If you have a question about the Services, want to terminate your
              Account, or if you believe information you have provided us is no
              longer secure, please contact us via email at
              support@handlhealth.com.
            </p>
          </article>
        </section>
      </div>
    </main>
  );
}
