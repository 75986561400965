import './Input.css';
import Form from 'react-bootstrap/Form';

function TextInput(props) {
  const classes = 'position-relative ' + props.formGroupClasses;
  return (
    <Form.Group controlId={props.name} className={classes} >
      <Form.Label className={props.formLabelClasses} >{props.label}</Form.Label>
      <Form.Control 
        name={props.name}  
        type={props.type}
        className={props.inputClasses} 
        placeholder={props.placeholder} 
        value={props.value} 
        onInput={props.onInput}
        onKeyDown={props.onKeyDown}
        onChange={props.onChange} 
        onClick={props.onClick} 
        pattern={props.pattern} 
        autoComplete="off"
        required
        autoFocus={props.autoFocus} />
      <Form.Control.Feedback type="invalid">
        This field is required.
      </Form.Control.Feedback>
      <Form.Text>
        {props.descripttion} 
      </Form.Text>
    </Form.Group>
  );
}

export default TextInput;
