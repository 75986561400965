import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "../elements/Tab";
import "./Tabs.css";
import Loading from "./Loading";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props?.children?.[0]?.props?.label,
      screenSize: 0,
    };
    this.checkDevice = this.checkDevice.bind(this);
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  checkDevice() {
    this.setState({ screenSize: window.innerWidth });
  }

  componentDidMount() {
    this.checkDevice();

    window.addEventListener("resize", this.checkDevice.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkDevice.bind(this));

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    let padding;
    const { screenSize } = this.state;

    if (this.props.addPadding == true) {
      if (screenSize >= 1200) {
        padding = (screenSize - 1140) / 2 + 15;
      } else if (screenSize >= 992) {
        padding = (screenSize - 960) / 2 + 15;
      } else if (screenSize >= 768) {
        padding = 0;
      } else {
        padding = 40;
      }
    } else {
      padding = 0;
    }

    if (this.props?.children?.length === 0) {
      return <Loading />;
    } else {
      return (
        <div className="tabs">
          <ol className="tab-list" style={{ paddingLeft: padding + "px" }}>
            {children.map((child) => {
              const { label } = child.props;

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              );
            })}
          </ol>
          <div className="tab-content">
            {children.map((child) => {
              if (child.props.label !== activeTab) return undefined;
              return child.props.children;
            })}
          </div>
        </div>
      );
    }
  }
}

export default Tabs;
