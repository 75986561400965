import React from "react";
import { Link } from "react-router-dom";
import comprehensiveMetabolicPanel from "../../assets/icons/EstimatesCatalog/comprehensiveMetabolicPanel.svg";
import EKGHeartScan from "../../assets/icons/EstimatesCatalog/EKGHeartScan.svg";
import annualPhysical from "../../assets/icons/EstimatesCatalog/annualPhysical.svg";
import physicalTherapy from "../../assets/icons/EstimatesCatalog/physicalTherapy.svg";
import vaginalBirth from "../../assets/icons/EstimatesCatalog/vaginalBirth.svg";
import breastGrowthRemoval from "../../assets/icons/EstimatesCatalog/breastGrowthRemoval.svg";
import colonoscopy from "../../assets/icons/EstimatesCatalog/colonoscopy.svg";
import sleepStudy from "../../assets/icons/EstimatesCatalog/sleepStudy.svg";
import pelvisCTScan from "../../assets/icons/EstimatesCatalog/pelvisCTScan.svg";
import mammogram from "../../assets/icons/EstimatesCatalog/mammogram.svg";
import vaginalUltrasound from "../../assets/icons/EstimatesCatalog/vaginalUltrasound.svg";
import upperEndoscopy from "../../assets/icons/EstimatesCatalog/upperEndoscopy.svg";
import "./EstimatesCatalog.css";

export default function EstimatesCatalog({
  onClick = () => void 0,
  currentLocation,
  heading = "Estimates you can search",
}) {
  const servicesToShow = [
    ["Comprehensive Metabolic Panel", comprehensiveMetabolicPanel],
    ["EKG Heart Scan", EKGHeartScan],
    ["Annual Physical", annualPhysical],
    ["Physical Therapy", physicalTherapy],
    ["Pregnancy care, vaginal birth", vaginalBirth],
    ["Breast growth removal, 1 or more", breastGrowthRemoval],
    ["Colonoscopy", colonoscopy],
    ["Sleep Study", sleepStudy],
    ["CT Scan of Pelvis", pelvisCTScan],
    ["Mammogram, One Breast", mammogram],
    ["Vaginal Ultrasound", vaginalUltrasound],
    ["Upper Endoscopy", upperEndoscopy],
  ];
  return (
    <>
      <p className="estimates-catalog-heading margin-bottom-24">{heading}</p>
      <div className="flex-row estimates-catalog">
        {servicesToShow.map((service) => (
          <Link
            key={service[0]}
            to={{
              pathname: "/estimates",
              query: { serviceName: service[0] },
              from: currentLocation,
            }}
          >
            <div
              className="estimate-container"
              onClick={() => onClick(service[0])}
            >
              <img src={service[1]} />
              <p>{service[0]}</p>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}
