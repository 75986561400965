import React, { useState, useEffect } from "react";
import "./SubmissionDetails.css";

export default function SubmissionDetails({ billingRecord }) {
  const [smallScreen, setSmallScreen] = useState(false);
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const submissionDetails = [
    ...(billingRecord.status === "incomplete"
      ? [
          {
            title: "Facility",
            value: billingRecord?.where_was_visit,
          },
        ]
      : []),
    {
      title: "Insurance plan",
      value:
        billingRecord?.insurance_provider?.issuer_legal_name ??
        "I don't have insurance",
    },
    {
      title: "Bill amount",
      value: "$" + billingRecord?.bill_amount,
    },
    {
      title: "Date of service",
      value: new Date(billingRecord?.service_start_date).toLocaleDateString(
        undefined,
        {
          timeZone: "UTC",
          weekday: "long",
          month: "long",
          day: "2-digit",
          year: "numeric",
        }
      ),
    },
    {
      title: "HIPAA form",
      value: "Signed on: " + billingRecord?.signed_hipaa_date,
    },

    {
      title: "Photo/PDF of bill",
      value:
        "File name: " + billingRecord?.copy_of_bill_path?.split(".com/")?.[1],
    },

    {
      title: "Visit details",
      value: billingRecord?.visit_details,
    },
  ];

  return (
    <main className="submission-details">
      <p className="fw-400 text-large margin-bottom-8">
        {billingRecord?.status === "incomplete"
          ? "Bill details"
          : "Submission details"}
      </p>
      {billingRecord?.submitted_on && (
        <p
          className={
            smallScreen ? "body-3 margin-bottom-32" : "body-2 margin-bottom-24"
          }
        >
          Submitted:{" "}
          {billingRecord?.submitted_on &&
            new Date(billingRecord?.submitted_on).toLocaleDateString(
              undefined,
              {
                month: "long",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }
            )}
        </p>
      )}
      <article className="flex-col" style={{ gap: "24px" }}>
        {submissionDetails?.map(
          (element) =>
            element.value !== null &&
            (element.title !== "Photo/PDF of bill" ||
              (billingRecord?.copy_of_bill_path !== null &&
                billingRecord?.copy_of_bill_path !== "")) && (
              <div key={element.title}>
                <p className="text-medium margin-bottom-4">{element.title}</p>
                <p
                  className={`${
                    element.title !== "Photo/PDF of bill" ? "fw-600" : "fw-400"
                  } text-description margin-bottom-0`}
                >
                  {element.title === "Photo/PDF of bill" ? (
                    <>
                      {element.value.split(":")[0]}:{" "}
                      <a href={billingRecord?.copy_of_bill_path}>
                        {element.value.split(": ")[1]}
                      </a>
                    </>
                  ) : (
                    element.value
                  )}
                </p>
              </div>
            )
        )}
      </article>
    </main>
  );
}
