import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Paragraph from "../elements/Paragraph";
import { Service } from '../modules/ListItem';
import { Button } from '../elements/Button';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ServiceGroupDetails.css'

class ServiceGroupDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        categoryName: this.props.group,
        handlCategory: '',
        categoryDescription: 'Mental health includes our emotional, psychological, and social well-being. It affects how we think, feel, and act. It also helps determine how we handle stress, relate to others, and make choices.',
        subServiceGroups: [],
        services: []
    }

    this.buildServiceGroup = this.buildServiceGroup.bind(this);
    this.buildServices = this.buildServices.bind(this);
    this.getServiceGroup = this.getServiceGroup.bind(this);
  }

  getServiceGroup() {
    const URL = process.env.REACT_APP_API_URL + 'service_groups/service_group_search';
    axios.get(URL, {
      params: {
        name: this.state.categoryName
      }
    })
    .then(res => {
      const service_group = res.data;
      this.setState({ subServiceGroups: service_group.subgroups });
      this.setState({ handlCategory: service_group.category });
    })
  }

  componentDidMount() {
    this.getServiceGroup();
  }

  buildServiceGroup(s) {
    let displayServices = [];
    const subgroup = s[0]
    displayServices = s[1].map(this.buildServices);

    return (<Card key={subgroup} className='border-top-0 border-right-0 border-left-0 border-bottom border-dark'>
              <Card.Header className='darken-background border-bottom border-dark' >
                <Accordion.Toggle as={Card.Text} eventKey={subgroup} className='d-flex justify-content-between align-items-center py-2'>
                  <div>
                    <p className='bold m-0'>{subgroup}</p>
                  </div>
                  <div className='expand-arrow'></div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={subgroup}>
                <Card.Body className='p-0 border-bottom border-dark'>
                  {displayServices}
                </Card.Body>
              </Accordion.Collapse>
            </Card>);
  }

  buildServices(s) {
    const name = s[0];
    const overline = s[1];
    const costline = s[2];
    const cptCode = s[3];

    return (<Link className='service-container border-light' to={
            {     
              pathname: '/service_search',
              state: {service: cptCode}
             }
            }>
              <Service 
                key={cptCode}
                icon="service-icon" 
                overline={overline} 
                name={name} 
                details={costline} />
              </Link>
               );
  }

  render(){
    let displayServiceGroups = []
    displayServiceGroups = this.state.subServiceGroups.map(this.buildServiceGroup);

    return (
      <div className='service-category-container h-100'>
        <div className='serivce-category-details'>
          <p className='text-xsmall'>{this.state.handlCategory.toUpperCase()}</p>
          <h3 className='bold service-group-name'>{this.state.categoryName}</h3>
          <p className='m-0'>{this.state.categoryDescription} </p>
        </div>
        <div className='service-groups'>
          <h4>Service Groups</h4>
          <p className='py-2'>Every medical or healthcare service is likely to have a variation, or related service. See similar services below: </p>
        </div>
        <Accordion className='service-subgroups-list'>
          {displayServiceGroups}
        </Accordion>
      </div>
    );
  }
}

export default ServiceGroupDetails;
