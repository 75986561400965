import React, { useState, useEffect } from "react";
import "./BillTracker.css";
import lockedMobile from "../../assets/icons/Billing/lockedMobile.svg";
import checkMark from "../../assets/icons/Billing/checkMark.svg";
import Button from "../../components/elements/Button";
import left from "../../assets/icons/left.svg";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import axios from "axios";
import HipaaForm from "../Billing/HipaaForm";
import BillDetails from "./BillDetails";
import SubmitBill from "./SubmitBill";

export default function BillTracker() {
  const [smallScreen, setSmallScreen] = useState(false);
  const [billingRecord, setBillingRecord] = useState({});
  const [currentScreen, setCurrentScreen] = useState("bill tracker");
  const [completionStatus, setCompletionStatus] = useState({
    "HIPAA agreement": false,
    "Bill details": false,
  });
  const history = useHistory();
  const { id } = useParams();

  function fetchBillingRecord() {
    const URL = process.env.REACT_APP_API_URL + "billing_records/" + id;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBillingRecord(res.data);
          setCompletionStatus({
            "HIPAA agreement":
              res.data?.remaining_tasks?.hippa_consent?.length === 0,
            "Bill details":
              res.data?.remaining_tasks?.billing_details?.length === 0,
          });
        }
      });
  }
  function updateBillingRecord(data) {
    const URL =
      process.env.REACT_APP_API_URL + "billing_records/" + billingRecord?.id;
    axios
      .patch(
        URL,
        {
          billing_record: data,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.status === "submitted") {
          history.push(`/bill_details/${billingRecord.id}`);
        } else {
          setCurrentScreen("bill tracker");
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  useEffect(() => {
    fetchBillingRecord();
  }, [currentScreen]);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const billTrackerContent = [
    {
      title: "HIPAA agreement",
      tasks: `${
        2 - billingRecord?.remaining_tasks?.hippa_consent?.length
      }/2 complete`,
    },
    {
      title: "Bill details",
      tasks: `${
        3 - billingRecord?.remaining_tasks?.billing_details?.length
      }/3 complete`,
    },
    {
      title: "Submit bill",
    },
  ];

  return currentScreen === "bill tracker" ? (
    <div className="bill-tracker">
      <div className="bill-tracker__top-container">
        <Button
          iconFirst
          icon={<img style={{ marginRight: "11px" }} src={left} />}
          class="text-btn"
          className="mobile-search__close"
          label=" back"
          onClick={() =>
            history.push({
              pathname: "/billing",
            })
          }
          active={true}
        />
        <h5 style={{ marginBottom: "16px" }} className="fw-200">
          Complete your bill review submission
        </h5>
        <p className="text-medium">
          Your <span className="fw-600">{billingRecord?.where_was_visit}</span>{" "}
          visit{" "}
          {billingRecord?.service_start_date && (
            <span className="fw-500">
              on{" "}
              {new Date(billingRecord?.service_start_date).toLocaleDateString(
                undefined,
                {
                  timeZone: "utc",
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                }
              )}
            </span>
          )}
        </p>
      </div>
      <section className="bill-tracker__tracker-section">
        {billTrackerContent?.map((item) => (
          <div
            key={item.title}
            className={`flex-row bill-tracker__container ${
              item.title === "Submit bill" &&
              !Object.values(completionStatus)?.every((item) => item === true)
                ? "inactive-container"
                : ""
            }`}
          >
            <div
              className={`bill-tracker__circle ${
                completionStatus[item.title] === true ? "green-circle" : ""
              }`}
            >
              {item.title === "Submit bill" ? (
                Object.values(completionStatus)?.every(
                  (item) => item === true
                ) ? null : (
                  <img
                    className="bill-tracker__lockedIcon"
                    src={lockedMobile}
                    alt="Locked icon"
                  />
                )
              ) : (
                completionStatus[item.title] === true && (
                  <img
                    style={smallScreen ? { width: "18px" } : {}}
                    src={checkMark}
                  />
                )
              )}
            </div>
            <div
              onClick={() =>
                setCurrentScreen(
                  item.title === "HIPAA agreement"
                    ? "hipaa form"
                    : item.title === "Submit bill"
                    ? Object.values(completionStatus)?.every(
                        (item) => item === true
                      )
                      ? "submit bill"
                      : "bill tracker"
                    : "bill details"
                )
              }
              className={`bill-tracker__box ${
                completionStatus[item.title] === true ? "border-completed" : ""
              }`}
            >
              <p
                className={
                  smallScreen ? "text-medium fw-500" : "text-large fw-400"
                }
              >
                {item?.title}
              </p>
              {item?.tasks && (
                <p
                  className={
                    smallScreen
                      ? "text-description fw-400"
                      : "text-medium fw-400"
                  }
                >
                  {item?.tasks}
                </p>
              )}
            </div>
          </div>
        ))}
      </section>
    </div>
  ) : currentScreen === "hipaa form" ? (
    <HipaaForm
      billingRecord={billingRecord}
      setCurrentScreen={setCurrentScreen}
      updateBillingRecord={updateBillingRecord}
    />
  ) : currentScreen === "bill details" ? (
    <BillDetails
      billingRecord={billingRecord}
      setCurrentScreen={setCurrentScreen}
      updateBillingRecord={updateBillingRecord}
    />
  ) : (
    currentScreen === "submit bill" && (
      <SubmitBill
        billingRecord={billingRecord}
        setCurrentScreen={setCurrentScreen}
        updateBillingRecord={updateBillingRecord}
      />
    )
  );
}
