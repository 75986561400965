import "./SidebarLayout.css";
import Footer from "../sections/Footer";
import {
  MobileNavigation,
  UserNavigation,
  MainNavigation,
} from "../../components/sections//Navigation";
import axios from "axios";
import React, { useState, useEffect } from "react";

function SidebarLayout(props) {
  const [smallScreen, setSmallScreen] = useState(false);
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sidebarClasses = "sidebar-layout__sidebar " + props.sidebarClasses;
  const contentClasses = "sidebar-layout__content " + props.contentClasses;

  return (
    <div className="sidebar-layout">
      <div
        className={props.noSidebar === true ? "flex-wrapper-space-between" : ""}
      >
        <div
          className={
            props.noSidebar === true
              ? null
              : props.signIn
              ? "sidebar-layout__body-sign-in"
              : "sidebar-layout__body-default"
          }
        >
          {props.noSidebar !== true && (
            <div className={sidebarClasses}>{props.sidebar}</div>
          )}

          <div className="sidebar-layout__content-container">
            <div className={contentClasses}>{props.content}</div>
          </div>
        </div>
        {props.customFooter}
        {props.showFooter == true ? <Footer /> : null}
      </div>
    </div>
  );
}

export default SidebarLayout;
