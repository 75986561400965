import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Directory from "./pages/Directory.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Toast from "./components/modules/Toast";
import hh_logo_color from "./assets/hh_logo_color.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import store from "./app/store";
import { Provider } from "react-redux";

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <Helmet>
          <meta property="og:url" content="http://www.handlhealth.com" />
          <meta property="og:type" content="home" />
          <meta property="og:title" content="Handl Health" />
          <meta
            property="og:description"
            content="See personalized cost estimates of healthcare services in your area."
          />
          <meta property="og:image" content={hh_logo_color} />
          <meta
            name="description"
            content="See personalized cost estimates of healthcare services in your area."
          />
          <title>Handl Health</title>
        </Helmet>
        <Directory />
      </Provider>
      <Toast
        toastStyle=""
        toastMessage="Sorry, the service you were looking for could not be found. Please select from our list of services."
      />
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);
