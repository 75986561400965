import "./Estimate.css";
import React, { Component } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useState, useEffect, useReducer } from "react";
import axios from "axios";
import Button from "../../components/elements/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import ShareFeedback from "../../components/modules/ShareFeedback";
import PopupModal from "../../components/templates/PopupModal";
import SendEstimate from "../../components/modules/SendEstimate";
import Form from "react-bootstrap/Form";
import EstimatePdf from "../../components/modules/EstimatePdf";
import Loading from "../../components/modules/Loading";
import up from "../../assets/icons/up.svg";
import down from "../../assets/icons/down.svg";
import right from "../../assets/icons/right.svg";
import {
  MainNavigation,
  MobileNavigation,
  UserNavigation,
} from "../../components/sections/Navigation";
import MobileSelect from "../../components/elements/inputs/MobileSelect";
import Footer from "../../components/sections/Footer";
function Estimate() {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState(location.state.data);
  const [smallScreen, setSmallScreen] = useState(false);
  const [quote, setQuote] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updatedCoverage, setUpdatedCoverage] = useState(false);
  const [showCoverageForm, setShowCoverageForm] = useState(false);
  const [paymentBreakdownToggle, setPaymentBreakdownToggle] = useState(
    "View Payment Details"
  );
  const [hasInsurance, setHasInsurance] = useState(
    data.insuranceProvider != "I do not have insurance" &&
      data.insuranceProvider != ""
  );
  const [lowestOopValue, setLowestOopValue] = useState(0);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    getQuote();
    getFacilitiesList();
    setLowestOopValue(calculateOopMaxPercentage(data.oopMax));
  }, []);

  function getQuote() {
    const url = process.env.REACT_APP_API_URL + "service_costs/cost_estimate";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          deductible: data.deductible,
          is_deductible_met: data.isDeductibleMet,
          deductible_percentage: data.deductiblePercentage,
          max_oop: data.oopMax,
          is_max_oop_met: data.isMaxOopMet,
          max_oop_percentage: data.oopMaxPercentage,
          service: data.service,
          zip_code: data.zipCode,
          insurance_provider: data.insuranceProvider,
          facility: data.facility,
        },
      })
      .then((res) => {
        setQuote(res.data);
        setLoaded(true);
      });
  }

  function getFacilitiesList() {
    const URL = process.env.REACT_APP_API_URL + "facilities/search";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          deductible: data.deductible,
          is_deductible_met: data.isDeductibleMet,
          deductible_percentage: data.deductiblePercentage,
          max_oop: data.oopMax,
          is_max_oop_met: data.isMaxOopMet,
          max_oop_percentage: data.oopMaxPercentage,
          service: data.service,
          zip_code: data.zipCode,
          insurance_provider: data.insuranceProvider,
        },
      })
      .then((res) => {
        const facilities = res.data;
        data.facilitiesList = facilities;
        setData(data);
      });
  }

  function reloadQuoteAndClose() {
    setUpdatedCoverage(true);
    setShowCoverageForm(false);
    reloadQuote();
  }

  function reloadQuote() {
    window.scrollTo(0, 0);

    const url = process.env.REACT_APP_API_URL + "service_costs/cost_estimate";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          deductible: data.deductible,
          is_deductible_met: data.isDeductibleMet,
          deductible_percentage: data.deductiblePercentage,
          max_oop: data.oopMax,
          is_max_oop_met: data.isMaxOopMet,
          max_oop_percentage: data.oopMaxPercentage,
          service: data.service,
          zip_code: data.zipCode,
          insurance_provider: data.insuranceProvider,
          facility: data.facility,
        },
      })
      .then((res) => {
        setQuote(res.data);
      });
  }

  const onClick = () => {
    history.push({
      pathname: "/facilities",
      state: {
        service: data.service,
        serviceName: data.serviceName,
        zipCode: data.zipCode,
        insuranceProvider: data.insuranceProvider,
        deductible: data.deductible,
        isDeductibleMet: data.isDeductibleMet,
        deductiblePercentage: data.deductiblePercentage,
        oopMax: data.oopMax,
        isMaxOopMet: data.isMaxOopMet,
        oopMaxPercentage: data.oopMaxPercentage,
        currentStep: data.currentStep,
      },
    });
  };

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleChange = (event) => {
    const { name, value } = event.target;
    data[name] = value;

    if (name == "deductiblePercentage") {
      if (value == 1) {
        data.isDeductibleMet = true;
      } else {
        data.isDeductibleMet = false;
      }

      var newLowestValue = calculateOopMaxPercentage(data.oopMax);

      setLowestOopValue(newLowestValue);

      if (data.oopMaxPercentage < newLowestValue) {
        data.oopMaxPercentage = newLowestValue;
      }
    } else if (name == "oopMaxPercentage") {
      if (value == 1) {
        data.isMaxOopMet = true;
      } else {
        data.isMaxOopMet = false;
      }
    }

    setData(data);
    getFacilitiesList();
    forceUpdate();
  };

  const changeFacility = (event) => {
    const facility_name = event.currentTarget.id;
    data.facility = facility_name;
    setData(data);
    forceUpdate();
    reloadQuote();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const changeUpdatedCoverage = () => {
    setUpdatedCoverage(false);
  };

  const toggleCoverageDetails = () => {
    if (showCoverageForm) {
      setShowCoverageForm(false);
    } else {
      setShowCoverageForm(true);
    }
  };

  function getDetail(name) {
    if (data.facilitiesList != null) {
      const facility = data.facilitiesList.find(
        (element) => element.name == name
      );
      return facility.detail;
    }
  }

  function calculateOopMaxPercentage(value) {
    const deductible =
      data.deductible == "I don't know" ? 2000 : data.deductible;
    const deductiblePercentage =
      data.isDeductibleMet == "true" ? 1 : data.deductiblePercentage;

    const deductiblePaid =
      deductiblePercentage == 0 ? 0 : deductible * deductiblePercentage;
    const percentageOfOopPaid =
      deductiblePaid == 0 ? deductiblePaid : deductiblePaid / value;
    const roundedPercentage = Math.round(percentageOfOopPaid * 10) / 10;

    return roundedPercentage;
  }

  var EstimateCallout = () => {
    if (loaded) {
      var costForService =
        quote.user_cost + " for " + data.serviceName + " at " + data.facility;

      const detail = getDetail(data.facility);

      let detailElement;
      if (detail) {
        detailElement = (
          <p className="estimate__estimate-callout-facility-detail">{detail}</p>
        );
      } else {
        detailElement = null;
      }

      return (
        <div className="estimate__estimate-callout" id="estimate-callout">
          <div className="estimate__estimate-width">
            <p
              onClick={() => {
                onClick();
              }}
              className="body-2 pointer"
            >
              <span className="back-arrow" /> BACK
            </p>
            <div className="estimate__estimate-callout-overline">
              <p className="text-alt-heading estimate__estimate-callout-overline-text">
                Your Payment Estimate
              </p>
              {detailElement}
            </div>
            <p className="estimate__callout-main-text" id="callout-main-text">
              {costForService}
            </p>
            <Button
              active={true}
              onClick={openModal}
              class="long-btn with-shadow estimate__save-estimate-button"
              id="save-estimate-btn"
              label="SAVE MY ESTIMATE"
            />
          </div>
        </div>
      );
    }
  };

  var UnderstandingYourEstimate = () => {
    if (loaded) {
      const youPayText = "You'll pay " + quote.user_cost;
      const youPayDescription =
        "This is an estimated cost for the service you are seeking at " +
        data.facility +
        ". This is not a guarantee " +
        (hasInsurance ? "of insurance coverage or" : "") +
        "the total bill from the healthcare provider. Please check with " +
        (hasInsurance ? "your health insurance company and " : "") +
        "the healthcare provider you selected to confirm the cost.";
      let updatedCoverageText_d;
      let updatedCoverageText_m;

      if (updatedCoverage) {
        if (smallScreen) {
          updatedCoverageText_m = (
            <p className="estimate__coverage-updated-text">Coverage updated!</p>
          );
          updatedCoverageText_d = null;
        } else {
          updatedCoverageText_d = (
            <p className="estimate__coverage-updated-text">Coverage updated!</p>
          );
          updatedCoverageText_m = null;
        }
      } else {
        updatedCoverageText_m = null;
        updatedCoverageText_d = null;
      }

      let insuranceSection;
      if (hasInsurance) {
        let coverageSection;

        if (showCoverageForm) {
          coverageSection = CoverageForm();
        } else {
          coverageSection = CoverageDetails();
        }

        insuranceSection = (
          <div className="estimate__estimate-section-container container-with-top-padding">
            <div>
              <p className="estimate__estimate-section-emphasized">
                {data.insuranceProvider}
              </p>
              {updatedCoverageText_d}
            </div>

            <div className="estimate__estimate-section-description">
              {coverageSection}
              {updatedCoverageText_m}
            </div>
          </div>
        );
      } else {
        insuranceSection = null;
      }

      return (
        <div
          className="estimate__estimate-section cost-and-coverage"
          id="what-youll-pay"
        >
          <div className="estimate__estimate-width">
            <p className="estimate__estimate-section-title">
              Understanding your estimate
            </p>
            <div className="estimate__estimate-section-container">
              <div>
                <p className="estimate__estimate-section-emphasized">
                  {youPayText}
                </p>
              </div>
              <div className="estimate__estimate-section-description">
                <p
                  className={`margin-bottom-0 ${
                    smallScreen ? "body-2" : "body-1"
                  }`}
                >
                  {youPayDescription}
                </p>
                {PaymentBreakdown()}
              </div>
            </div>
            {insuranceSection}
          </div>
        </div>
      );
    }
  };

  var PaymentBreakdown = () => {
    const insuranceCoverage = "- (" + quote.insurance_coverage + ")";
    return (
      <Accordion className="estimate__payment-breakdown">
        <Card className="border-0">
          <Card.Header className="bg-white p-0">
            <Accordion.Toggle
              as={Card.Text}
              eventKey="payment-breakdown"
              className={`${
                smallScreen ? "text-alt-heading" : "text-medium"
              } estimate__payment-accordion-toggle border-0`}
              onClick={() =>
                setPaymentBreakdownToggle(
                  paymentBreakdownToggle == "View Payment Details"
                    ? "Hide Payment Details"
                    : "View Payment Details"
                )
              }
            >
              <span>{paymentBreakdownToggle}</span>
              {paymentBreakdownToggle == "View Payment Details" ? (
                <img src={down} alt="down chevron arrow" />
              ) : (
                <img src={up} alt="up chevron arrow" />
              )}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey="payment-breakdown"
            id="payment-breakdown"
            className="border-0"
          >
            <Card.Body className="estimate__payment-breakdown-details">
              <div className="estimate__payment-breakdown-section">
                <div className="estimate__payment-breakdown-overline">
                  <p>Service Price</p>
                  <p>{quote.gross_price}</p>
                </div>
                <p className="estimate__payment-breakdown-description">
                  {hasInsurance
                    ? "The price negotiated by your health insurer with the healthcare provider."
                    : "The cash price offered by the healthcare provider."}
                </p>
              </div>
              <div className="estimate__payment-breakdown-section">
                <div className="estimate__payment-breakdown-overline">
                  <p>Insurance Coverage</p>
                  <p>{insuranceCoverage}</p>
                </div>
                <p className="estimate__payment-breakdown-description">
                  The amount your insurance will pay for this service based on
                  your plan details.
                </p>
              </div>
              <div className="estimate__payment-breakdown-section estimate__payment-breakdown-you-pay">
                <div className="estimate__payment-breakdown-overline">
                  <p>You Pay</p>
                  <p>{quote.user_cost}</p>
                </div>
                <p className="estimate__payment-breakdown-description">
                  Our estimate of how much you’ll owe for this service.
                </p>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  var CoverageDetails = () => {
    let paidOop;

    if (data.deductiblePercentage == 1) {
      paidOop = (
        <div className="estimate__coverage-detail-line">
          <p className={smallScreen ? "body-2" : "body-1"}>
            Out-of-pocket maximum paid to date
          </p>
          <p className={smallScreen ? "body-2" : "body-1 fw-600"}>
            {"$" + data.oopMax * data.oopMaxPercentage}
          </p>
        </div>
      );
    } else {
      paidOop = null;
    }

    let deductibleAmount;
    if (Number.isInteger(data.deductible)) {
      deductibleAmount = data.deductible;
    } else {
      deductibleAmount = data.deductible.replace("$", "");
    }

    let deductibleDisplay = "$" + deductibleAmount;

    const calculatedInformation = [
      {
        title: "My plan deductible",
        details: deductibleDisplay,
      },
      {
        title: "Deductible paid to date",
        details: "$" + deductibleAmount * data.deductiblePercentage,
      },
      {
        title: "My out-of-pocket maximum",
        details: "$" + data.oopMax,
      },
    ];
    return (
      <div className="estimate__coverage-details">
        {calculatedInformation.map((info) => (
          <div key={info.title} className="estimate__coverage-detail-line">
            <p className={`${smallScreen ? "body-2" : "body-1"}`}>
              {info.title}
            </p>
            <p className={`${smallScreen ? "body-2" : "body-1 fw-600"} `}>
              {info.details}
            </p>
          </div>
        ))}

        {paidOop}

        <p
          className={`${
            smallScreen ? "text-alt-heading" : "text-medium"
          } estimate__edit-details pointer`}
          onClick={(e) => toggleCoverageDetails()}
        >
          EDIT MY COVERAGE DETAILS
        </p>
      </div>
    );
  };

  const [validatedCoverage, setValidatedCoverage] = useState(false);

  var CoverageForm = () => {
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();

      if (form.checkValidity() === false) {
        event.stopPropagation();
      }

      setValidatedCoverage(true);
      reloadQuoteAndClose();
    };

    let deductibleOptions = [];
    for (let i = 0; i <= 6000; i = i + 250) {
      const item = { option: "$" + i, value: i };
      deductibleOptions.push(item);
    }

    let deductibleAmount;
    if (Number.isInteger(data.deductible)) {
      deductibleAmount = data.deductible;
    } else {
      deductibleAmount = data.deductible.replace("$", "");
    }

    var oopMaxPercentageOptions = [];
    for (let i = 0; i <= 10; i = i + 1) {
      const item = {
        option: i == 0 ? "0% ($0)" : i + "0% ($" + data.oopMax * (i / 10) + ")",
        value: i == 10 ? "1" : "0." + i,
        disabled: lowestOopValue * 10 > i ? true : false,
      };
      oopMaxPercentageOptions.push(item);
    }

    const deductiblePercentageOptions = [
      { option: "0% ($0)", value: 0.0 },
      { option: "10% ($" + deductibleAmount * 0.1 + ")", value: 0.1 },
      { option: "20% ($" + deductibleAmount * 0.2 + ")", value: 0.2 },
      { option: "30% ($" + deductibleAmount * 0.3 + ")", value: 0.3 },
      { option: "40% ($" + deductibleAmount * 0.4 + ")", value: 0.4 },
      { option: "50% ($" + deductibleAmount * 0.5 + ")", value: 0.5 },
      { option: "60% ($" + deductibleAmount * 0.6 + ")", value: 0.6 },
      { option: "70% ($" + deductibleAmount * 0.7 + ")", value: 0.7 },
      { option: "80% ($" + deductibleAmount * 0.8 + ")", value: 0.8 },
      { option: "90% ($" + deductibleAmount * 0.9 + ")", value: 0.9 },
      { option: "100% ($" + deductibleAmount * 1 + ")", value: 1.0 },
    ];

    return (
      <Form
        noValidate
        validated={validatedCoverage}
        onSubmit={handleSubmit}
        className="estimate__coverage-form"
      >
        <div className="estimate__coverage-form-container">
          <MobileSelect
            id="1"
            renderAltMobileSelect
            label="My plan deductible"
            description="The amount you pay for healthcare services before your health insurer starts paying its share."
            formLabelClasses="estimate__coverage-form-label"
            selectClasses="quote-options-select w-100"
            name="deductible"
            options={deductibleOptions}
            placeholder="Deductible amount"
            onChange={handleChange}
            hide={false}
            value={deductibleAmount}
            smallScreen={true}
            showArrow={true}
          />

          <MobileSelect
            id="2"
            renderAltMobileSelect
            label="How much of your deductible have you paid?"
            formLabelClasses="estimate__coverage-form-label"
            selectClasses="quote-options-select w-100"
            name="deductiblePercentage"
            options={deductiblePercentageOptions}
            placeholder="Percentage paid"
            onChange={handleChange}
            hide={false}
            value={data.deductiblePercentage}
            smallScreen={true}
            showArrow={true}
          />

          <MobileSelect
            id="3"
            renderAltMobileSelect
            label="My out-of-pocket maximum"
            description="The most you can pay out of pocket for healthcare services in one year according to your plan."
            formLabelClasses="estimate__coverage-form-label"
            selectClasses="quote-options-select w-100"
            name="oopMax"
            options={deductibleOptions}
            placeholder="Out-of-pocket max"
            onChange={handleChange}
            hide={false}
            value={data.oopMax}
            smallScreen={true}
            showArrow={true}
          />
          <MobileSelect
            id="4"
            renderAltMobileSelect
            label="How much of your out-of-pocket maximum have you paid?"
            formLabelClasses="estimate__coverage-form-label"
            selectClasses="quote-options-select w-100"
            name="oopMaxPercentage"
            options={oopMaxPercentageOptions}
            placeholder="Out-of-pocket max paid"
            onChange={handleChange}
            hide={data.deductiblePercentage != 1}
            value={data.oopMaxPercentage}
            smallScreen={true}
            showArrow={true}
          />
        </div>
        <Button
          active={true}
          class="text-btn estimate__update-coverage-btn"
          onClick={handleSubmit}
          id="submit-deductible-btn"
          label="SAVE CHANGES"
        />
      </Form>
    );
  };

  var WhereYoullGo = () => {
    if (loaded) {
      var facilityAddress = quote.facility_address;
      var map_url =
        "https://www.google.com/maps/embed/v1/place?key=" +
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
        "&q=" +
        facilityAddress;
      var distance = quote.facility_distance + " Miles Away";

      return (
        <div className="estimate__estimate-section where-youll-be">
          <div className="estimate__estimate-width">
            <p className="estimate__estimate-section-title">Where you'll go</p>
            <div className="estimate__estimate-section-container">
              <p className="estimate__estimate-section-emphasized">
                {data.facility}
              </p>
              <div className="estimate__facility-details">
                <div className="estimate__facility-details-text">
                  <p className="estimate__facility-distance">{distance}</p>
                  <div className="estimate__facility-name-and-cost">
                    <p className="estimate__facility-name">{data.facility}</p>
                    <p className="estimate__facility-cost">{quote.user_cost}</p>
                  </div>
                  <p className="body-2 margin-bottom-0">{quote.address1}</p>
                  <p className="body-2 margin-bottom-8">{quote.address2}</p>
                </div>
                <iframe
                  width="100%"
                  height="250"
                  loading="lazy"
                  allowFullScreen
                  src={map_url}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  var MoreFacilities = () => {
    if (
      loaded &&
      data.facilitiesList != null &&
      data.facilitiesList.length > 1
    ) {
      const facilities = data.facilitiesList
        .filter((facility) => facility.name != data.facility)
        .slice(0, 4)
        .map((item) => Facility(item));

      return (
        <div className="estimate__more-facilities estimate__estimate-section ">
          <div
            className="estimate__estimate-width "
            style={{ justifyContent: "space-between" }}
          >
            <div className="estimate__more-facilities-text">
              <p className="estimate__estimate-section-title">
                Want more options?
              </p>
              <p className="estimate__more-facilities-description ">
                {hasInsurance
                  ? " These facilities are other options for the service you searched based on your location and insurance information. "
                  : " These facilities are other options for the service you searched based on your location. "}
              </p>
            </div>
            <div className="estimate__facilities-list-container">
              <ul className="estimate__facilities-list">{facilities}</ul>
            </div>
          </div>
        </div>
      );
    }
  };

  var Facility = (facility) => {
    return (
      <li
        key={facility.name}
        id={facility.name}
        onClick={(e) => {
          changeFacility(e);
        }}
      >
        <div
          className="flex-row"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="flex-col">
            <p
              style={
                smallScreen
                  ? {
                      fontSize: "10px",
                      lineHeight: "15px",
                      marginBottom: "3px",
                    }
                  : { marginBottom: "3px" }
              }
              className="body-1"
            >
              {facility.distance.toFixed(1)} miles away
            </p>
            <p className="estimate__facility-list-item-name">{facility.name}</p>
            <p
              className={`${
                smallScreen ? "body-3" : "body-1"
              } margin-bottom-0 color-262525`}
            >
              {facility.user_cost}
            </p>
          </div>

          <div className="flex-row" style={{ gap: "20px" }}>
            {facility.detail !== "" && (
              <p className="service-group-icon">{facility.detail}</p>
            )}
            <img src={right} alt="chevron right" />
          </div>
        </div>
      </li>
    );
  };

  var SaveEstimatePopup = () => {
    return (
      <PopupModal
        size="lg"
        position="centered"
        show={showModal}
        close={closeModal}
        dialogClassName="update-zipcode-modal"
        body={
          <div className="update-zipcode-modal__body">
            <div id="save-estimate-modal-content">
              <h3 className="fw-700">Save your estimate</h3>
              <p className={smallScreen ? "body-2" : "body-1"}>
                Want to save this estimate for your records? Enter your first
                name and email address and we’ll send you a copy of the cost
                estimate. Don’t worry, we won’t share or save your information,
                and we won’t spam you!
              </p>
              <SendEstimate
                closeModal={closeModal}
                smallScreen={smallScreen}
                hasInsurance={hasInsurance}
              />
            </div>
            <div id="share-feedback-container">
              <ShareFeedback closeModal={closeModal} />
            </div>
          </div>
        }
      />
    );
  };

  if (!loaded) {
    return <Loading />;
  } else {
    return (
      <div
        style={{ background: "#F7F7F7" }}
        className="flex-wrapper-space-between"
      >
        <div>
          <MobileNavigation color="black" />
          <EstimatePdf data={data} quote={quote} />
          <div className="estimate" id="estimate-page">
            {SaveEstimatePopup()}
            {EstimateCallout()}
            <div>
              {UnderstandingYourEstimate()}
              {WhereYoullGo()}
              {MoreFacilities()}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Estimate;
