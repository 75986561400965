import React, { useRef } from 'react';
import Likert from 'react-likert-scale';
import Button from "../elements/Button";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useState } from 'react';
import TextAreaInput from '../elements/inputs/TextAreaInput';
import './ShareFeedback.css';


function ShareFeedback(props){
  const likertOptions = {
    question: "How would you rate your experience?",
    responses: [
      { value: 1, text: "Unsatisfied" },
      { value: 2, text: "Neutral" },
      { value: 3, text: "Satisfied" }
    ]
  };

  const likertRef = useRef();
  const [chosen, setChosen] = useState(null);
  const [textFeedback, setTextFeedback] = useState(null);

  

  const onChange = (chosen) => {
    setChosen(chosen?.value);
  };

  const textChange = (text) => {
    setTextFeedback(text.target.value);
  };

  const onClick = () => {
    generateRow();
    props.closeModal();
  };

  const generateRow = () => {
    const today = new Date();
    const row = { Rating: chosen, 'Submitted On': today.toLocaleDateString("en-US"), 'Text Response': textFeedback };
    appendSpreadsheet(row);
  }

  const appendSpreadsheet = async (row) => {
    const SPREADSHEET_ID = process.env.REACT_APP_RATING_SPREADSHEET_ID;
    const SHEET_ID = process.env.REACT_APP_RATING_SHEET_ID;
    const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
    const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, '\n');

    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  return (
    <div className='share-feedback'>
      <Likert {...likertOptions} 
        ref={likertRef} 
        onChange={onChange} />

      <TextAreaInput
        type='text'
        name='message'
        value={textFeedback}
        label='Feedback'
        inputClasses= 'contact-input'
        formLabelClasses="contact-input-label text-small"
        placeholder='Let us know if you have any feedback'
        formGroupClasses= 'w-100'
        onChange={textChange} />

      <Button 
        active={true}
        class="submit-btn" 
        label="SEND FEEDBACK" 
        onClick={onClick} />
    </div>
  );
  
}

export default ShareFeedback;
