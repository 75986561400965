import "./AboutUs.css";
import Desktop from "../../components/templates/Desktop";
import Mobile from "../../components/templates/Mobile";
import React, { useEffect } from "react";
import Button from "../../components/elements/Button";

function AboutUs() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const aboutUsBody = (
    <div className="about-us">
      <div className="about-us--1">
        <div className="about-us-heading-container">
          <p className="about-us-heading">We’re Handl Health</p>
        </div>
        <div className="about-us-text body-1">
          <p className="">
            For the first time in American history, hospitals and health plans
            are required to publish their prices. It’s awesome, but what happens
            with all that pricing info?
          </p>
          <p className="">
            At Handl Health, we help hospitals and health plans comply with the
            legislation while helping patients find the best price for the care
            they need.
          </p>
        </div>
      </div>
      <div className="about-us--2">
        <section>
          <p className="about-us-heading">
            It’s time for price transparency in healthcare.
          </p>
          <p className="body-1">
            Thanks to CMS legislation, the time has finally come. We’ve got the
            prices, you do the quick search. Are you a hospital or health plan?
            Learn more about how we can help you comply while attracting and
            retaining patients and members.
          </p>
        </section>
      </div>
      <div className="about-us--3">
        <section>
          <p className="about-us-heading fw-500">
            Humanizing healthcare through experience-led digital solutions that
            combine cost, quality and convenience.
          </p>
          <a
            href="https://www.gohandlhealth.com/"
            rel="noreferrer"
            target="_blank"
            style={{ display: "contents" }}
          >
            <Button
              active={true}
              label="visit gohandlhealth.com"
              class="long-btn filled"
            />
          </a>
        </section>
      </div>
    </div>
  );

  if (window.innerWidth <= 768) {
    return (
      <Mobile
        body={aboutUsBody}
        showQuoteNavigation={false}
        showMobileNav={true}
        mobileNavColor="white"
        genericContainer={true}
        showFooter={true}
      />
    );
  } else {
    return <Desktop body={aboutUsBody} genericContainer={true} />;
  }
}

export default AboutUs;
