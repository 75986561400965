import Autocomplete from "react-autocomplete";
import { useState } from "react";
import ServiceItem from "../modules/ServiceItem";
import "./Input.css";
import success from "../../assets/icons/success-icon.svg";
import error from "../../assets/icons/error-icon.svg";
import visible from "../../assets/icons/visible-icon.svg";
import invisible from "../../assets/icons/invisible-icon.svg";
import down from "../../assets/icons/down.svg";

function Input(props) {
  let readOnly = props.readOnly == true;

  var DesktopSelect = () => {
    var itemStyle = (isHighlighted) => {
      return {
        background: isHighlighted ? "#EFEFEF" : "white",
        padding: "20px 16px",
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.4px",
        fontFamily: "Poppins",
        color: "#363636",
      };
    };

    var renderItem = (item, isHighlighted) => {
      if (props.optionType == "service") {
        return (
          <div key={item.cpt_code}>
            <ServiceItem
              key={item.cpt_code}
              service={item}
              showArrow={false}
              showUnderline={false}
              isHighlighted={isHighlighted}
              classes="service-serch-list-item"
            />
          </div>
        );
      } else {
        return (
          <div
            style={itemStyle(isHighlighted)}
            key={item.label}
            id={props.name + "-" + item.value}
            tabIndex="0"
          >
            {item.label}
          </div>
        );
      }
    };

    var shouldItemRender = (item, value) => {
      if (props.showAllOptions == true) {
        return true;
      } else {
        return item?.label?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
      }
    };

    function handleChange(e) {
      if (props.showAllOptions == true) {
        const value = e.target.value;
        const options = props.options;
        const firstMatch = options.find(
          (item) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
        );

        if (firstMatch != null) {
          const firstMatchId = props.name + "-" + firstMatch.value;

          var myElement = document.getElementById(firstMatchId);
          var topPos = myElement.offsetTop;

          document.querySelector(
            ".input-container div div:not(.input-and-label)"
          ).scrollTop = topPos;
        }
      }

      props.onChange(e);
    }

    function handleMenuVisibilityChange(isOpen) {
      toggleArrow(isOpen);

      if (isOpen == false && props.showAllOptions == true) {
        const value = props.value == null ? "" : props.value;
        const options = props.options;
        const firstMatch = options.find(
          (item) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
        );

        if (firstMatch != null) {
          props.onSelect(firstMatch.label, props.name);
        } else {
          props.onSelect(options[0].label, props.name);
        }
      }
    }

    function determineClassName() {
      if (readOnly) {
        return "read-only";
      } else if (props.selectOnly === true) {
        return "writeable select-only";
      } else {
        return "writeable";
      }
    }
    return (
      <Autocomplete
        onMenuVisibilityChange={(isOpen) => handleMenuVisibilityChange(isOpen)}
        getItemValue={(item) => item.label}
        items={props.options}
        shouldItemRender={(item, value) => shouldItemRender(item, value)}
        renderItem={(item, isHighlighted) => renderItem(item, isHighlighted)}
        renderInput={function (props) {
          return (
            <div className="input-and-label" key={props.name}>
              <input {...props} className={determineClassName()} />
              <label htmlFor={props.name}> {props.label} </label>
            </div>
          );
        }}
        inputProps={{
          placeholder: props.placeholder,
          id: props.name,
          name: props.name,
          label: props.label,
          required: true,
        }}
        value={props.value}
        onChange={(e) => handleChange(e)}
        onSelect={(val, item) => props.onSelect(val, props.name, item.value)}
        selectOnBlur={
          props.options.find((item) => item.label == props.value) == null
        }
        menuStyle={{
          borderRadius: "0px 0px 4px 4px",
          boxShadow: "0 0px 6px rgba(0, 0, 0, 0.1)",
          background: "#FFFFFF",
          position: "absolute",
          top: "unset",
          right: "0",
          left: "0",
          overflow: "auto",
          maxHeight: "256px",
          zIndex: "1",
          cursor: "pointer",
        }}
      />
    );
  };

  var MobileSelect = () => {
    var options = props.options;
    let optionsList =
      options.length > 0 &&
      options.map((item, i) => {
        let key = props.name + "-" + i;
        if (item.disabled) {
          return (
            <option key={key} value={item.value} disabled>
              {item.label}
            </option>
          );
        } else {
          return (
            <option key={key} value={item.value}>
              {item.label}
            </option>
          );
        }
      }, this);

    return (
      <select
        name={props.name}
        id={props.name}
        onChange={(e) => props.onSelect(e.target.value, props.name)}
      >
        <option value="" disabled selected>
          {props.placeholder}
        </option>
        {optionsList}
      </select>
    );
  };

  var TextInput = () => {
    const type = props.type ? props.type : "text";
    const ref = props.innerref;
    const [showCurrencySymbol, setShowCurrencySymbol] = useState(false);
    return (
      <div
        onClick={() =>
          props.showCurrencyPrefix === true ? setShowCurrencySymbol(true) : null
        }
        className={`input-and-label ${props.showLabel ? "show-label" : ""}`}
        key={props.name}
      >
        <input
          ref={ref}
          type={type}
          value={props.value}
          name={props.name}
          id={props.name}
          placeholder={
            props.type === "date" && props.specialDatePlaceholder !== true
              ? "mm/dd/yyyy"
              : props.placeholder
          }
          required={true}
          readOnly={readOnly}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          className={readOnly ? "read-only" : "writeable"}
        />
        <label htmlFor={props.name}> {props.label} </label>
        {props.showCurrencyPrefix === true && showCurrencySymbol && (
          <span className="currency-symbol">$</span>
        )}
      </div>
    );
  };

  function determineInput() {
    let display = null;
    if (props.options && !readOnly) {
      if (props.forceAutocomplete == true) {
        display = DesktopSelect();
      } else {
        display = window.innerWidth <= 768 ? MobileSelect() : DesktopSelect();
      }
    } else {
      display = TextInput();
    }

    return display;
  }

  const [arrowDirection, setArrowDirection] = useState("down");
  var inputContainerClasses = "input-container " + props.inputContainerClasses;
  var toggleArrow = (isOpen) => {
    setArrowDirection(isOpen ? "up" : "down");
  };

  function displayIcon() {
    if (props.showErrorMessage == true && props.showErrorIcon == true) {
      return <img src={error} className="input-icon" />;
    } else if (props.showValidMessage == true) {
      return <img src={success} className="input-icon" />;
    } else if (props.showArrow == true) {
      let arrowClasses = "input-arrow input-icon" + arrowDirection;
      return <span className={arrowClasses}></span>;
    } else if (props.showDropdownArrow == true) {
      return <img src={down} className="input-icon" />;
    } else if (props.showVisibilityToggle == true) {
      let visibility = props.type == "password" ? invisible : visible;
      return (
        <img
          src={visibility}
          className="input-icon"
          onClick={props.iconOnClick}
        />
      );
    } else if (props.showInputActions == true) {
      if (props.readOnly == false) {
        return (
          <span className="input-icon input-action" onClick={props.iconOnClick}>
            SAVE
          </span>
        );
      } else if (props.readOnly == true && props.value == "") {
        return (
          <span className="input-icon input-action" onClick={props.iconOnClick}>
            ADD
          </span>
        );
      } else {
        return (
          <span className="input-icon input-action" onClick={props.iconOnClick}>
            EDIT
          </span>
        );
      }
    } else {
      return null;
    }
  }

  function displayHelpText() {
    if (props.showErrorMessage == true) {
      return <div className="input-message error">{props.errorMessage}</div>;
    } else if (props.showValidMessage == true) {
      return <div className="input-message valid">{props.validMessage}</div>;
    } else if (props.helpText) {
      return <div className="input-message">{props.helpText}</div>;
    } else {
      return null;
    }
  }

  if (props.hide == true) {
    return null;
  } else {
    return (
      <div className={inputContainerClasses}>
        {determineInput()}
        {displayIcon()}
        {displayHelpText()}
      </div>
    );
  }
}

export default Input;
