import React, { useState, useEffect } from "react";
import "./Home.css";
import videoCall from "../../assets/Home/images/videoCall.jpg";
import ladyWorking from "../../assets/Home/images/ladyWorking.jpg";
import Button from "../../components/elements/Button";
import GoHandlHealthCards from "../../components/sections/Home/GoHandlHealthCarousel";
import axios from "axios";
import ArticleCardsCarousel from "../../components/sections/Home/ArticleCardsCarousel";
import EstimatesCatalog from "../../components/sections/EstimatesCatalog";
import mobileBillReviewSxn from "../../assets/Home/icons/mobileBillReviewSxn.svg";
import OurTools from "../../components/sections/Home/OurToolsCarousel";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../../components/sections/Footer";

export default function Home() {
  const [articles, setArticles] = useState([]);
  const [smallScreen, setSmallScreen] = useState([]);
  const history = useHistory();
  const location = useLocation();
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchArticleData();
  }, []);

  async function fetchArticleData() {
    let env;
    process.env.REACT_APP_API_URL.includes("localhost")
      ? (env = "local")
      : process.env.REACT_APP_API_URL.includes("staging")
      ? (env = "staging")
      : (env = "production");
    const url = process.env.REACT_APP_API_URL + "articles";
    const request = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
      },
      params: {
        env: env,
      },
    });
    setArticles(request.data.data);
  }

  const gridContent = [
    {
      title: "Always know the cost of care before your appointment.",
      description:
        "Handl Health’s cost estimator shows you prices of healthcare services in your area so you can choose the best option for you.",
      buttonText: "get an estimate",
      img: videoCall,
    },
    {
      title: "Never miss a medical bill error again.",
      description:
        "Handl Health’s bill reviewer checks your medical bills for errors or issues to save you time and money.",
      buttonText: "START A BILL REVIEW",
      img: ladyWorking,
    },
  ];

  const MobileOnlySection = () => {
    const CostEstimatorService = () => {
      return (
        <div className="home__mobile-only-cost-estimator">
          <p className="width-343 subheading-crimson-pro fw-500 color-dark-blue center-text">
            {gridContent[0].title}
          </p>
          <p className="width-343 center-text body-2 margin-bottom-32">
            {gridContent[0].description}
          </p>
          <EstimatesCatalog currentLocation={"/"} />
          <div style={{ marginTop: "40px" }}>
            <Button
              class="long-btn filled"
              active={true}
              onClick={() =>
                history.push({
                  pathname: "/estimates",
                  from: location.pathname,
                })
              }
              label={gridContent[0].buttonText}
            />
          </div>
        </div>
      );
    };

    const BillReviewService = () => {
      return (
        <div className="home__mobile-only-bill-review">
          <p className="width-343 subheading-crimson-pro fw-500 color-dark-blue center-text">
            {gridContent[1].title}
          </p>
          <p className="width-343 center-text body-2">
            {gridContent[1].description}
          </p>
          <img style={{ width: "100%" }} src={mobileBillReviewSxn} />
          <div style={{ marginTop: "24px" }}>
            <Button
              class="long-btn filled"
              active={true}
              label="Help me with my bill"
              onClick={() =>
                history.push({
                  pathname: "/onboarding",
                  from: location.pathname,
                })
              }
            />
          </div>
        </div>
      );
    };
    return (
      <div>
        {CostEstimatorService()}
        {BillReviewService()}
        <OurTools />
      </div>
    );
  };
  return (
    <main>
      <div>
        <div className="home-top-container flex-col">
          <div className="width-845">
            <p className="fw-700 color-default-blue home-title">Handl Health</p>
            <p className="home-subtitle">
              Take care of yourself, health and wealth in one place.
            </p>
            <p className="body-2 fw-400 color-default-blue desktop-only-text">
              Our resources help you shop for care, review healthcare bills and
              stay informed about what’s happening in healthcare to save you
              time and money. We’re giving you a hand so you can focus on your
              physical and financial well being.
            </p>
          </div>
        </div>
        {smallScreen && MobileOnlySection()}
        <div className="home-grid-section">
          {gridContent.map((item) => (
            <div key={item.title} className="home-grid-container">
              <img
                className="desktop-grid-image"
                src={item.img}
                alt="Medical images"
              />
              <div className="desktop-grid-textbox">
                <div style={{ width: "433px" }}>
                  <h5
                    className="fw-500 margin-bottom-16"
                    style={{ color: "#2A4B9F" }}
                  >
                    {item.title}
                  </h5>
                  <p className="body-1" style={{ color: "#2A4B9F" }}>
                    {item.description}
                  </p>
                </div>
                <Button
                  active={true}
                  onClick={() =>
                    history.push({
                      pathname:
                        item.buttonText === "get an estimate"
                          ? "/estimates"
                          : "/onboarding",
                    })
                  }
                  label={item.buttonText}
                  class="long-btn filled"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="home-switch-container">
          <GoHandlHealthCards />
          <ArticleCardsCarousel articles={articles} />
        </div>
      </div>
      <Footer />
    </main>
  );
}
