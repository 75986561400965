import React, { useState, useEffect } from "react";
import noBills from "../../../assets/icons/Billing/noBills.svg";
import Button from "../../elements/Button";
import "./NoBills.css";
import { useHistory } from "react-router-dom";

export default function NoBills({ smallScreen }) {
  const history = useHistory();
  return (
    <div className="no-bills flex">
      <img src={noBills} alt="No bills yet minion" />
      <p className="secondary-bold">No bills yet</p>
      <p className="body-2 color-gray">
        Submit your medical bills to check for errors, issues or potential
        savings!
      </p>
      {smallScreen === false && (
        <Button
          label="Start a new bill review"
          active={true}
          onClick={() => history.push("/onboarding")}
          class="outline-btn-blue long-btn"
        />
      )}
    </div>
  );
}
