import { Link } from 'react-router-dom';
import './ServiceItem.css'

function ServiceItem(props) {
  var classes = "service__list-item " + props.classes;
  if (props.isHighlighted) {
    classes = classes + ' highlighted'
  }

  var underline;
  if (props.underline == 'cost') {
    underline = props.service.price_range
  } else {
    underline = props.service.service_categories
  }

  var overline;
  if (props.overline == 'service group') {
    overline = props.service.service_group
  } else {
    overline = "CPT " + props.service.cpt_code
  }

  return (
    <li className={classes} key={props.service.name} id={props.service.name} onClick={props.onClick}>
      <div className='service__service-information'>
        <p className='service__list-item-overline'>{overline}</p>
        <p className='service__list-item-centerline'>{props.service.name}</p>
        {props.showUnderline? (<p className='service__list-item-underline'>{underline}</p>) : null}
      </div>
      {props.showArrow? (<div className='service__arrow' />) : null}
    </li>
  );
}

export default ServiceItem
