import React, {useState} from 'react'
import SidebarLayout from '../../components/templates/SidebarLayout.js';
import SettingsSideBar from "./SettingsSideBar";
import useToken from '../../hooks/useToken';
import { Link, useLocation } from "react-router-dom";

function InsurancePlan(props) {
  const { token } = useToken();
  const location = useLocation()

const InsurancePlanPage = () => {
    return (
        <div className='settings__privacy-and-security settings-page'>
            <Link to= {{ pathname: '/settings'}} className='settings__back-button'>
              <p className='body-2 pointer'><span className='back-arrow' /> SETTINGS</p>
            </Link>
            <p className='settings__page-title'>Plan Details</p>
            <p className='plan-details-main'>Policy holder: <b>{location.state.fullName}</b></p>
            <p className='plan-details-main'>Your Insurance Plan: <b>{location.state.insurancePlan}</b></p>
            <p className='plan-details-main'>Your Insurance ID: <b>{location.state.insuranceIDNumber}</b></p>
            <p className='plan-details-main' style={{marginBottom:'46px'}}>Plan type: <b>{location.state.planType}</b></p>

            <p className='settings__page-subtitle'>Individual Coverage</p>
            <p className='plan-details-title'>In-Network</p>
            <p className='plan-details-items'>Deductible: <b>$2000</b></p>
            <p className='plan-details-items'>Out-of-Pocket Max: <b>$5000</b></p>
            <p className='plan-details-items'style={{marginBottom:'24px'}}>PCP Co-Pay: <b>$30</b></p>

            <p className='plan-details-title'>Out-of-Network</p>
            <p className='plan-details-items'>Deductible: <b>$5000</b></p>
            <p className='plan-details-items'>Out-of-Pocket Max: <b>$10,000</b></p>

            <div className='plan-details-useful-info'>
            <p className='settings__page-subtitle'  style={{padding:'0'}}>Useful Information</p>
            <ul className='settings__bullet-list'>
                <li><a href='#'>Your plan's 2022 insurance formulary {'>'}</a></li>
                <li><a href='#'>Aetna HMO contact information {'>'}</a></li>
                <li><a href='/contact'>Something wrong? Contact us {'>'}</a></li>
            </ul>

            </div>
            
        </div>
    )
}


return (
    <SidebarLayout 
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses='settings__side-bar'
      content={InsurancePlanPage()}
      contentClasses='settings__content'
      userStatus='logged-in'
      showFooter={true}
    />
  );
}

export default InsurancePlan