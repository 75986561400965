import React, { useState, useEffect } from "react";
import Button from "../../elements/Button";
import PopupModal from "../../templates/PopupModal";
import popupCancel from "../../../assets/icons/popup-cancel.svg";

import "./MobileSortPanel.css";
export default function MobileFilterPanel({
  showSortModal,
  setShowSortModal,
  filterLabel,
  setFilterLabel,
  sortOptions,
}) {
  function capitalize(valueToCapitalize) {
    if (Array.isArray(valueToCapitalize)) {
      return valueToCapitalize.map((element) => {
        return element.charAt(0).toUpperCase() + element.slice(1);
      });
    } else {
      return (
        valueToCapitalize.charAt(0).toUpperCase() + valueToCapitalize.slice(1)
      );
    }
  }
  function closeModal() {
    setShowSortModal(false);
  }

  return (
    <PopupModal
      noPadding
      noClose
      dialogClassName="sort-modal"
      position="centered"
      show={showSortModal}
      close={closeModal}
      body={
        <div className="mobile-sort-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="mobile-sort-title">Status</p>
            <img
              style={{ marginRight: "16px", width: "18px", height: "18px" }}
              onClick={() => setShowSortModal(false)}
              src={popupCancel}
            />
          </div>

          {sortOptions.sort().map((option) => (
            <div
              className="mobile-sort-options billing-dashboard-checkbox"
              key={option}
            >
              <input
                className="mobile-sort-radio"
                type="checkbox"
                value={option}
                checked={filterLabel.includes(option)}
                name="sort"
                id={option}
                onChange={(e) => {
                  if (option && e.target.checked === false) {
                    setFilterLabel((prevState) => [
                      ...prevState.filter((filter) => filter !== option),
                    ]);
                  } else {
                    setFilterLabel((prevState) => [
                      ...prevState,
                      option == e.target.value && option,
                    ]);
                  }
                }}
              />
              <label className="mobile-sort-label" htmlFor={option}>
                {capitalize(option)}
              </label>
            </div>
          ))}
          <Button
            label="filter bills"
            className="sort-btn"
            class="long-btn filled"
            active={true}
            onClick={() => {
              setShowSortModal(false);
            }}
          />
        </div>
      }
    ></PopupModal>
  );
}
