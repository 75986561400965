import cancel from '../../assets/icons/cancel.svg';
import popupCancel from '../../assets/icons/popup-cancel.svg'
import Modal from 'react-bootstrap/Modal';
import './PopupModal.css';

function PopupModal(props) {
  var position = props.position
  var size = props.size

  var closeButton = props.noClose == true ? null : (<img src={popupCancel} width="24" height="24" className='close-modal' onClick={props.close} />)

  var modal;
  if (position == 'centered') {
    return ( <Modal 
      dialogClassName={props.dialogClassName}
      onHide={props.close} 
      show={props.show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      className='px-4'
      centered>
      <Modal.Header id="contained-modal-title-vcenter" className='border-0' >
        {closeButton}
      </Modal.Header>
      <Modal.Body className={props.noPadding ? null : 'px-4 mx-2'}  >
        {props.body}
      </Modal.Body>
    </Modal>
    );
  } else {
    return ( <Modal  
      onHide={props.close} 
      show={props.show}
      dialogClassName=""
      aria-labelledby="contained-modal-title-vcenter"
      className='px-4 first-visit-modal'>
      <Modal.Header id="contained-modal-title-vcenter" className='border-0' >
        <img src={cancel} width="24" height="24" className='close-modal' onClick={props.close} />
      </Modal.Header>
      <Modal.Body className='px-4' >
        {props.body}
      </Modal.Body>
    </Modal>);
  }
}

export default PopupModal;
