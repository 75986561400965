import React from 'react';
import ProfileCompletion
 from './ProfileCompletion';
function SettingsSideBar(props) {
  
  return(
    <React.Fragment>
      <ProfileCompletion token={props.token} linked={true} />
        
      <div className='settings__privacy-notice'>
        <p className='settings__privacy-notice-title'>Handl Health values your data and privacy</p>
        <p>Making sure your information is safe and private is a top priority. We value your security.</p>
        <a href='#'>Read more about our HIPPA compliance and how we use your data.</a>
      </div>
    </React.Fragment>
  )
}

export default SettingsSideBar;
