import React, { useState, useEffect } from "react";
import Button from "../../elements/Button";
import PopupModal from "../../templates/PopupModal";
import "./MobileSortPanel.css";
import popupCancel from "../../../assets/icons/popup-cancel.svg";

export default function MobileSortFilter({
  showSortModal,
  setShowSortModal,
  filterLabel,
  setFilterLabel,
  sortOptions,
}) {
  function closeModal() {
    setShowSortModal(false);
  }

  return (
    <PopupModal
      noClose
      noPadding
      dialogClassName={"sort-modal"}
      position="centered"
      show={showSortModal}
      close={closeModal}
      body={
        <div className="mobile-sort-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="mobile-sort-title">Sort by</p>
            <img
              style={{ marginRight: "16px", width: "18px", height: "18px" }}
              onClick={() => setShowSortModal(false)}
              src={popupCancel}
            />
          </div>

          {sortOptions.map((option) => (
            <div className="mobile-sort-options" key={option}>
              <input
                className="mobile-sort-radio"
                type="radio"
                value={option}
                checked={filterLabel === option}
                name="sort"
                id={option}
                onChange={(e) => {
                  setFilterLabel(option == e.target.value && option);
                }}
              />
              <label className="mobile-sort-label" htmlFor={option}>
                {option}
              </label>
            </div>
          ))}
          <Button
            label="sort bills"
            className="sort-btn"
            class="long-btn filled"
            active={true}
            onClick={() => {
              setShowSortModal(false);
            }}
          />
        </div>
      }
    ></PopupModal>
  );
}
