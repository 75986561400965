import React from "react";
import "./OurToolsCarousel.css";
import search from "../../../assets/Home/icons/search.svg";
import compare from "../../../assets/Home/icons/compare.svg";
import choose from "../../../assets/Home/icons/choose.svg";
import submit from "../../../assets/Home/icons/submit.svg";
import hardWork from "../../../assets/Home/icons/hardWork.svg";
import results from "../../../assets/Home/icons/results.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import leftCarouselArrow from "../../../assets/icons/leftCarouselArrow.svg";
import rightCarouselArrow from "../../../assets/icons/rightCarouselArrow.svg";
import right from "../../../assets/icons/right.svg";
import left from "../../../assets/icons/left.svg";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

export default function OurTools() {
  const ourTools = [
    {
      heading: "Compare care on your terms.",
      content: [
        {
          img: search,
          subheading: "Search for the care you need",
          description:
            "Find the service or procedure you’re looking for with our intuitive search platform.",
        },
        {
          img: compare,
          subheading: "Compare & contrast by price",
          description:
            "Clearly see the prices offered by healthcare providers in your area.",
        },
        {
          img: choose,
          subheading: "Choose the best option for you",
          description:
            "Decide on the winning provider and create an account to save the info all in one place.",
        },
      ],
    },
    {
      heading: "We review the bill so you don’t have to.",
      content: [
        {
          img: submit,
          subheading: "Submit bill details",
          description:
            "We’ll need a few quick details about your appointment and bill.",
        },
        {
          img: hardWork,
          subheading: "Handl does the hard work",
          description:
            "Once you’ve submitted your bill for review, we’ll work on identifying any errors.",
        },
        {
          img: results,
          subheading: "Get the results",
          description:
            "After a few days, you’ll receive the results and suggested next steps in your Handl account.",
        },
      ],
    },
    {
      heading: "Want more Handl Health?",
      content: [
        {
          subheading: "Read articles for healthcare news and tips ‘n tricks",
          description:
            "We’ll keep you up-to-date and in-the-know with the latest healthcare trends and hacks.",
        },
        {
          subheading:
            "Browse the glossary for all those confusing healthcare terms",
          description:
            "Our glossary has all the health insurance and healthcare term definitions you’ll need.",
        },
        {
          subheading: "Keep an eye out for more features",
          description:
            "We’re always rolling out new features to help you manage your health and wealth, so stay tuned!",
        },
      ],
    },
  ];
  return (
    <main className="our-tools">
      <img src={left} className="custom-otc-swiper-button-prev" />
      <Swiper
        slidesPerView={1}
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: ".custom-otc-swiper-button-next",
          prevEl: ".custom-otc-swiper-button-prev",
        }}
        pagination={{ clickable: true }}
      >
        {ourTools.map((tool, idx) => (
          <SwiperSlide key={tool.heading}>
            <div
              className={`our-tools-container ${
                idx === ourTools.length - 1 ? "last-tools-container" : ""
              }`}
            >
              <p className="subheading-crimson-pro">{tool.heading}</p>
              <div className="flex-col">
                {tool.content.map((actionItem) => (
                  <div className="flex-col" key={actionItem.subheading}>
                    {actionItem.hasOwnProperty("img") && (
                      <img src={actionItem.img} />
                    )}
                    <p className="color-default-blue fw-600 text-subheading">
                      {actionItem.subheading}
                    </p>
                    <p className="body-2">{actionItem.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <img src={right} className="custom-otc-swiper-button-next" />
    </main>
  );
}
