import React, { useState } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router-dom";
import ForgotPassword from "../../pages/SignIn/ForgotPassword";
import PopupModal from "../../components/templates/PopupModal";
import FAQAccordian from "../../pages/SignIn/FAQAccordian";
import "../../pages/SignIn/SignIn.css";

export default function SignInForm({
  setToken,
  onSubmitAction,
  isMainSignIn = false,
  setCurrentPage,
}) {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [remember, setRemember] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const history = useHistory();

  function signInUser() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "login";
    axios
      .post(URL, {
        user: {
          email: email,
          password: password,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setToken(res.headers.authorization, remember);
          onSubmitAction(res.data.data.id);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.error === "Invalid Email or password.") {
          setErrorMessage("Email or password are incorrect.");
        } else if (
          error.response.data.error ===
          "You have one more attempt before your account is locked."
        ) {
          setErrorMessage(error.response.data.error);
        } else if (error.response.data.error === "Your account is locked.") {
          setErrorMessage(
            "Your account is locked. Please check your email for instructions."
          );
        }
      });
  }
  function onSubmit(e) {
    e.preventDefault();
    signInUser();
  }
  return (
    <div>
      <p className="sign-in__subtitle">{errorMessage}</p>

      <form className="sign-in-form" onSubmit={(e) => onSubmit(e)} noValidate>
        <Input
          placeholder="Email"
          name="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          inputContainerClasses="sign-in-form__input"
          showArrow={false}
          readOnly={false}
        />
        <Input
          placeholder="Password"
          type={passwordType}
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          inputContainerClasses="sign-in-form__input"
          showArrow={false}
          readOnly={false}
          showVisibilityToggle={true}
          iconOnClick={() =>
            passwordType === "text"
              ? setPasswordType("password")
              : setPasswordType("text")
          }
        />
        <div
          style={{
            width: "575px",
            maxWidth: "100%",
            margin: "54px auto auto",
          }}
        >
          <div className="sign-in-form__remember-me">
            <input
              type="checkbox"
              onChange={(e) => setRemember(!remember)}
              checked={remember}
            />
            <p className="remember-me">Remember Me</p>
          </div>
          <span className="sign-in-form__remember-me-text">
            By checking this box, you will stay logged in even if you close the
            tab and you won’t have to re-enter your login credentials.
          </span>
        </div>
        <div className="sign-in-btn">
          <Button
            label="Log In"
            active={email != "" && password != ""}
            class="long-btn filled"
          />
        </div>
        <div className="sign-in__other-actions ">
          <Button
            label="Forgot Password?"
            active={true}
            class="long-btn text-btn"
            onClick={() => history.push("/forgot_password")}
          />

          <div className="sign-in__or-element">
            <div className="sign-in__or-bar" />
            <span> OR </span>
            <div className="sign-in__or-bar" />
          </div>

          <div
            style={smallScreen ? {} : { marginBottom: "80px" }}
            className="sign-in__outline-btn"
          >
            <Button
              label="Create New Account"
              active={true}
              class="outline-btn"
              onClick={() =>
                isMainSignIn === true
                  ? history.push("/sign_up")
                  : setCurrentPage("form")
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
}
