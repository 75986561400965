import React from "react";
import { useState, useEffect, useRef } from "react";
import Article from "./Article.js";
import ArticlesIndex from "./ArticlesIndex.js";
import Desktop from "../../components/templates/Desktop";
import Mobile from "../../components/templates/Mobile";
import "./Articles.css";
import { Switch, Route } from "react-router-dom";

function Articles(props) {
  const [smallScreen, setSmallScreen] = useState([]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  var ArticleSwitch = () => {
    return (
      <div style={{ display: "contents" }}>
        <Switch>
          <Route key="1" path={`${props.match.url}/:url`} component={Article} />
          <Route
            key="2"
            path={props.match.url}
            render={() => <ArticlesIndex />}
          />
        </Switch>
      </div>
    );
  };

  if (smallScreen) {
    return (
      <Mobile
        body={ArticleSwitch()}
        showQuoteNavigation={false}
        showMobileNav={true}
        showFooter={true}
        mobileNavColor="white"
      />
    );
  } else {
    return <Desktop body={ArticleSwitch()} />;
  }
}

export default Articles;
