import React, { useState, useEffect } from "react";
import Loading from "../../components/modules/Loading";
import Button from "../../components/elements/Button";
import left from "../../assets/icons/left.svg";
import { useHistory, useLocation, Link } from "react-router-dom";
import axios from "axios";
import useToken from "../../hooks/useToken";
import PopupModal from "../../components/templates/PopupModal";
import cancel from "../../assets/icons/popup-cancel.svg";
import down from "../../assets/icons/down.svg";
import MobileFilterPanel from "../../components/sections/Billing/MobileFilterPanel";
import "./BillingDashboard.css";
import MobileSortFilter from "../../components/sections/Billing/MobileSortFilter";
import NoBills from "../../components/sections/Billing/NoBills";
import disabledDown from "../../assets/icons/disabledDown.svg";
import { useSelector } from "react-redux";
import BillSidebar from "../../components/sections/Billing/BillSidebar";
import ShowMore from "../../components/modules/ShowMore";
import SortFilter from "../../components/sections/Billing/SortFilter";

export default function BillingDashboard() {
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const [filteredBillingRecords, setFilteredBillingRecords] = useState([]);
  const [filterLabel, setFilterLabel] = useState("");
  const [sortLabel, setSortLabel] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [filterOptions, setFilterOptions] = useState([
    "incomplete",
    "submitted",
    "reviewed",
  ]);
  const [sortOptions, setSortOptions] = useState([
    "Default",
    "Date created: oldest to newest",
    "Date created: newest to oldest",
    "Visit date: newest to oldest",
    "Visit date: oldest to newest",
  ]);
  const remainingTasks = {
    signed_hipaa_path: "Sign HIPAA agreement",
    service_start_date: "Date of service",
    insurance_provider_id: "Insurance provider",
    bill_amount: "Bill amount",
    street_address: "Home address",
    city: "City",
    state: "State",
    zip_code: "Zip code",
    submit_bill: "Submit bill",
  };
  const [billCount, setBillCount] = useState(0);
  const [billsToShow, setBillsToShow] = useState(5);
  const [originalContent, setOriginalContent] = useState([]);
  const [billingRecords, setBillingRecords] = useState([]);
  const [showAllItems, setShowAllItems] = useState([]);
  const userID = useSelector((state) => state?.currentUser?.currentUser?.id);

  const bolded = (type) => {
    if (type === "filter") {
      return (
        <span>
          {filterLabel !== "" && filterLabel.length !== 0 && ": "}
          <b>
            {filterLabel !== ""
              ? capitalize(filterLabel).join(", ")
              : filterLabel}
          </b>
        </span>
      );
    } else {
      return <span>{sortLabel !== ""}</span>;
    }
  };
  const history = useHistory();
  const filled = (Math.min(billCount, billsToShow) / billCount) * 100;

  function capitalize(valueToCapitalize) {
    if (Array.isArray(valueToCapitalize)) {
      return valueToCapitalize.map((element) => {
        return element.charAt(0).toUpperCase() + element.slice(1);
      });
    } else {
      return (
        valueToCapitalize.charAt(0).toUpperCase() + valueToCapitalize.slice(1)
      );
    }
  }
  const contentManipulationOptions = [
    {
      type: "filter",
      showSortModal: showFilterModal,
      setShowSortModal: setShowFilterModal,
      filteredContent: filteredBillingRecords,
      setFilteredContent: setFilteredBillingRecords,
      filterLabel: filterLabel,
      setFilterLabel: setFilterLabel,
      content: billingRecords,
      sortOptions: filterOptions,
    },
    {
      type: "sort",
      showSortModal: showSortModal,
      setShowSortModal: setShowSortModal,
      filteredContent: filteredBillingRecords,
      setFilteredContent: setFilteredBillingRecords,
      filterLabel: sortLabel,
      setFilterLabel: setSortLabel,
      content: billingRecords,
      sortOptions: sortOptions,
    },
  ];

  function getBillingRecords() {
    const URL = process.env.REACT_APP_API_URL + "billing_records/";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },

        params: {
          user_id: userID,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBillCount(res.data.length);
          setBillingRecords(res.data);
          const copy = res.data.map((item) => ({ ...item }));
          setOriginalContent(copy);
        }
      });
  }

  useEffect(() => {
    if (!userID) return;
    getBillingRecords();
  }, [userID]);

  useEffect(() => {
    if (filterLabel.length > 0) {
      setBillCount(filteredBillingRecords.length);
    } else {
      setBillCount(billingRecords.length);
    }
  }, [billingRecords, filteredBillingRecords]);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function showMoreBiils() {
    if (billsToShow < billCount) {
      setBillsToShow(billsToShow + 5);
    }
  }

  function updateBillingRecordViewedStatus(billingRecord) {
    if (
      billingRecord.status !== "reviewed" ||
      billingRecord.is_reviewed === true
    )
      return;
    const URL =
      process.env.REACT_APP_API_URL + "billing_records/" + billingRecord.id;
    axios
      .patch(
        URL,
        {
          billing_record: {
            is_reviewed: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {})
      .catch(function (error) {
        console.log(error.response);
      });
  }
  const SortFilterSection = () => {
    return (
      <div
        style={
          smallScreen
            ? { marginTop: "32px", flexWrap: "wrap", gap: "8px" }
            : { flexWrap: "wrap" }
        }
        className="flex-row"
      >
        {contentManipulationOptions.map((option) => (
          <div
            key={option.type}
            className={
              billingRecords?.length < 1
                ? "filter-option filter-option-disabled"
                : option.type === "sort"
                ? sortLabel !== ""
                  ? "filter-option-active"
                  : "filter-option"
                : filterLabel.length !== 0
                ? "filter-option-active"
                : "filter-option"
            }
            onClick={() =>
              billingRecords?.length > 0
                ? option.type === "filter"
                  ? setShowFilterModal(!showFilterModal)
                  : setShowSortModal(!showSortModal)
                : null
            }
          >
            <img
              style={{ marginRight: "9px" }}
              src={billingRecords?.length > 0 ? down : disabledDown}
            />
            {option.type === "filter" ? "Status" : "Sort by"}
            {option.type === "filter" ? bolded("filter") : bolded("sort")}
          </div>
        ))}
      </div>
    );
  };
  return loaded === true ? (
    <div
      className={
        smallScreen ? "flex-wrapper billing-dashboard" : "billing-dashboard"
      }
    >
      <div>
        <SortFilter
          contentManipulationOptions={contentManipulationOptions}
          filterLabel={filterLabel}
          sortLabel={sortLabel}
          setFilterLabel={setFilterLabel}
          setSortLabel={setSortLabel}
          originalContent={originalContent}
          content={billingRecords}
          setFilteredContent={setFilteredBillingRecords}
          setContent={setBillingRecords}
        />
      </div>
      <section className={`${!smallScreen ? "grid-container homefeed" : ""}`}>
        {!smallScreen && (
          <div>
            <BillSidebar />
          </div>
        )}

        <>
          <div className={smallScreen ? "gray-section" : ""}>
            <div className="text-container">
              <Button
                iconFirst
                icon={<img style={{ marginRight: "11px" }} src={left} />}
                class="text-btn"
                className="mobile-search__close"
                label=" back"
                onClick={() =>
                  history.push({
                    pathname: "/",
                  })
                }
                active={true}
              />
              <div className={smallScreen ? "" : "callout-container"}>
                {smallScreen ? (
                  <p style={{ marginBottom: "4px" }} className="page-headings">
                    Bill review
                  </p>
                ) : (
                  <h5>Billing review</h5>
                )}
                {!smallScreen && (
                  <div className="billing-dashboard__new-bill-btn">
                    <Button
                      active={true}
                      label="Start new review"
                      class="short-btn filled"
                      onClick={() => history.push("/onboarding")}
                    />
                  </div>
                )}
                {smallScreen && SortFilterSection()}
              </div>
            </div>
            {billingRecords?.length < 1 ? (
              <NoBills smallScreen={smallScreen} />
            ) : (
              <div className="billing-dashboard__results-container">
                {!smallScreen && SortFilterSection()}
                {((filteredBillingRecords &&
                  filteredBillingRecords?.length !== 0) ||
                filterLabel.length !== 0
                  ? filteredBillingRecords
                  : billingRecords
                )
                  ?.slice(0, Math.min(billCount, billsToShow))
                  .map((billingRecord) => (
                    <div
                      key={billingRecord?.id}
                      className="billing-dashboard__results-card"
                      onClick={() => {
                        updateBillingRecordViewedStatus(billingRecord);
                        history.push(
                          billingRecord.status === "incomplete"
                            ? `/billing_tracker/${billingRecord.id}`
                            : `/bill_details/${billingRecord.id}`
                        );
                      }}
                    >
                      {billingRecord?.status === "reviewed" &&
                        billingRecord?.is_reviewed === false && (
                          <span className="billing-dashboard__new-tag">
                            NEW
                          </span>
                        )}
                      <p
                        className={`text-small billing-dashboard__results-card-status ${billingRecord?.status}`}
                      >
                        {billingRecord?.status.charAt(0).toUpperCase() +
                          billingRecord?.status.slice(1)}{" "}
                        · Created on{" "}
                        {new Date(billingRecord?.created_at).toLocaleDateString(
                          undefined,
                          { month: "numeric", day: "2-digit", year: "2-digit" }
                        )}
                      </p>
                      <p className="billing-dashboard__results-card-main-text text-medium">
                        <span className="billing-dashboard__results-card-bolded">
                          {billingRecord?.where_was_visit}
                        </span>{" "}
                        visit{" "}
                        {billingRecord?.status !== "incomplete" &&
                          `on ${new Date(
                            billingRecord?.service_start_date
                          ).toLocaleDateString(undefined, {
                            timeZone: "utc",
                            month: "numeric",
                            day: "2-digit",
                            year: "2-digit",
                          })}`}
                      </p>
                      <p className="billing-dashboard__results-card-underline-text text-small">
                        {billingRecord?.status === "incomplete" ? (
                          <>
                            <span style={{ fontWeight: "700" }}>
                              {
                                [
                                  ...billingRecord?.remaining_tasks
                                    .billing_details,
                                  ...billingRecord?.remaining_tasks
                                    .hippa_consent,
                                  ...billingRecord?.remaining_tasks
                                    .bill_submitted,
                                ].flat().length
                              }{" "}
                              {smallScreen ? "" : "required "}
                              {[
                                ...billingRecord?.remaining_tasks
                                  .billing_details,
                                ...billingRecord?.remaining_tasks.hippa_consent,
                                ...billingRecord?.remaining_tasks
                                  .bill_submitted,
                              ].length === 1
                                ? "task"
                                : "tasks"}
                            </span>
                            {[
                              ...billingRecord?.remaining_tasks.billing_details,
                              ...billingRecord?.remaining_tasks.hippa_consent,
                              ...billingRecord?.remaining_tasks.bill_submitted,
                            ].length > 0 && <span> left to complete</span>}
                          </>
                        ) : billingRecord.status === "reviewed" ? (
                          "Bill outcome ready for review!"
                        ) : (
                          "Pending response from Handl Health"
                        )}
                      </p>
                      {!smallScreen &&
                        billingRecord?.status === "incomplete" && (
                          <>
                            <ul>
                              {[
                                ...billingRecord?.remaining_tasks
                                  .billing_details,
                                ...billingRecord?.remaining_tasks.hippa_consent,
                                ...billingRecord?.remaining_tasks
                                  .bill_submitted,
                              ]
                                .flat()
                                .map((item, idx) =>
                                  idx > 1 &&
                                  !showAllItems?.includes(
                                    billingRecord?.id
                                  ) ? null : (
                                    <li
                                      key={item}
                                      className="remaining-tasks__li body-3 poppins"
                                    >
                                      {remainingTasks[item]}
                                    </li>
                                  )
                                )}
                            </ul>
                            {!showAllItems?.includes(billingRecord?.id) &&
                              [
                                ...billingRecord?.remaining_tasks
                                  .billing_details,
                                ...billingRecord?.remaining_tasks.hippa_consent,
                                ...billingRecord?.remaining_tasks
                                  .bill_submitted,
                              ].length > 2 && (
                                <span
                                  className="label-text"
                                  style={{
                                    marginLeft: "0.85rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowAllItems((prevState) => [
                                      ...prevState,
                                      billingRecord?.id,
                                    ]);
                                  }}
                                >
                                  +{" "}
                                  {[
                                    ...billingRecord?.remaining_tasks
                                      .billing_details,
                                    ...billingRecord?.remaining_tasks
                                      .hippa_consent,
                                    ...billingRecord?.remaining_tasks
                                      .bill_submitted,
                                  ].flat().length - 2}{" "}
                                  more
                                </span>
                              )}
                          </>
                        )}
                    </div>
                  ))}

                <ShowMore
                  type="bills"
                  toShow={billsToShow}
                  totalCount={billCount}
                  filled={filled}
                  showMoreHandler={showMoreBiils}
                />
              </div>
            )}
          </div>
        </>
      </section>
      {smallScreen && (
        <div
          style={{ marginTop: "40px", marginBottom: "80px" }}
          className="billing-dashboard__new-bill-btn"
        >
          <Button
            active={true}
            label="Start a new bill"
            class="long-btn filled"
            onClick={() => history.push("/onboarding")}
          />
        </div>
      )}
    </div>
  ) : (
    <Loading />
  );
}
