import "./Navigation.css";
import { React, useState, useEffect, memo } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "../elements/Button";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useSelector } from "react-redux";
import largeRight from "../../assets/Navigation/largeRight.svg";
import navHomeLogo from "../../assets/Navigation/navHomeLogo.svg";
import HHLogo from "../../assets/Navigation/HHLogo.svg";
import home from "../../assets/Navigation/home.svg";
import blueDownArrow from "../../assets/Navigation/blueDownArrow.svg";
import downArrow from "../../assets/Navigation/downArrow.svg";
import UserIcon from "../elements/UserIcon";

function MainNavigation(props) {
  const location = useLocation();
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showWhoWeServeDropdown, setShowWhoWeServeDropdown] = useState(false);
  const GOHANDLURL = "https://www.gohandlhealth.com/";
  const whoWeServeKeys = ["brokers", "health-plans", "providers", "employers"];

  const goToWhatWeDo = () => {
    if (location.pathname == "/") {
      document.getElementById("what-we-do").scrollIntoView();
    } else {
      history.push({
        pathname: "/",
        state: { goToWhatWeDo: "what-we-do" },
      });
    }
  };

  function capitalize(str) {
    const titleCase = str
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return titleCase;
  }

  return (
    <Navbar bg="white" sticky="top" expand="true" className="header">
      <Navbar.Brand href="/">
        <p className="text-large handl-logo">Handl Health</p>
      </Navbar.Brand>
      <Navbar.Text>
        <div className="icons">
          <Dropdown
            show={showWhoWeServeDropdown}
            onMouseLeave={() => setShowWhoWeServeDropdown(false)}
            onMouseOver={() => setShowWhoWeServeDropdown(true)}
          >
            <Dropdown.Toggle
              className="white nav-link"
              id="resources-dropdown-toggle"
              key="who-we-serve"
            >
              Who We Serve
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {whoWeServeKeys.map((key) => (
                <Dropdown.Item
                  rel="noopener noreferrer"
                  href={GOHANDLURL + key}
                  target="_blank"
                  eventKey="4"
                  key={key}
                >
                  {capitalize(key.split("-").join(" "))}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
          >
            <Dropdown.Toggle
              className="white nav-link"
              id="resources-dropdown-toggle"
              key="resources"
            >
              Resources
            </Dropdown.Toggle>
            <Dropdown.Menu show={showDropdown}>
              <Dropdown.Item href="/articles" eventKey="2" key="articles">
                Articles
              </Dropdown.Item>
              <Dropdown.Item href="/glossary" eventKey="1" key="glossary">
                Glossary
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Link className="nav-link" key="press" to={{ pathname: "/press" }}>
            Press
          </Link>
          <Link
            className="nav-link"
            key="contact"
            to={{ pathname: "/contact" }}
          >
            Contact
          </Link>
        </div>
      </Navbar.Text>
    </Navbar>
  );
}

function MobileNavigation(props) {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [showResources, setShowResources] = useState(false);
  const loggedIn = useSelector((state) => state?.currentUser?.loggedIn); //need to update this
  const history = useHistory();
  const location = useLocation();
  const navSignedOutOptions = [
    {
      title: "Resources",
      subCategories: ["press", "articles", "glossary"],
    },
    {
      title: "Who We Serve",
      subCategories: [
        "brokers",
        "health plans & tpas",
        "providers",
        "employers",
      ],
    },
  ];
  const GOHANDLURL = "https://www.gohandlhealth.com/";

  const navSignedInOptions = [
    "billing",
    "estimates",
    "articles",
    "glossary",
    "press",
  ];

  const helpSectionOptions = [
    ...(loggedIn
      ? [
          {
            id: 1,
            text: "Need help?",
            action: () => history.push("/settings/help-center"),
          },
          {
            id: 2,
            text: "Settings",
            action: () => history.push("/settings"),
          },
          {
            id: 3,
            text: "Logout",
            action: () => props?.LogOut(),
          },
        ]
      : [
          {
            id: 4,
            text: "Sign In",
            action: () => history.push("/sign_in"),
          },
          {
            id: 5,
            text: "Create account",
            action: () => history.push("/sign_up"),
          },
          {
            id: 6,
            text: "Need help?",
            action: () => history.push("/contact"),
          },
        ]),
  ];
  const navbarClasses = " w-100 mobile-header bg-light-blue";

  function closeNavigationMenu() {
    setOpenNavigation(false);
  }
  return (
    <>
      <Navbar fixed="top" expand="true" className={navbarClasses}>
        <a
          onClick={() => setOpenNavigation(!openNavigation)}
          className="nav-toggle"
        >
          {Array.from(Array(3)).map((el, idx) => (
            <span
              key={idx}
              className={`bar ${openNavigation ? "x" : ""}`}
            ></span>
          ))}
        </a>
        {
          <p
            style={{ marginBottom: "0" }}
            className="text-description fw-600 margin-bottom-0"
            onClick={() => history.push("/onboarding")}
          >
            Get help with a bill {`>`}
          </p>
        }
      </Navbar>
      <div
        className={`flex-col updated-mobile-nav-container ${
          openNavigation ? "updated-mobile-nav-container__active" : ""
        }`}
      >
        <div className="flex-col" style={{ position: "relative" }}>
          <div className="flex-col">
            <div
              className="flex-row"
              style={{ gap: "12px", marginBottom: "20px" }}
              onClick={() => {
                history.push("/");
                closeNavigationMenu();
              }}
            >
              <img src={home} alt="Home icon" />
              <p className="fw-700 color-default-blue updated-mobile-nav-container__home-title">
                Handl Health
              </p>
            </div>

            {loggedIn === false ? (
              navSignedOutOptions.map((opt) => (
                <div key={opt.title}>
                  <p className="body-3 fw-700">{opt.title}</p>
                  {opt.subCategories.map((item) =>
                    opt.title === "Resources" ? (
                      <Link
                        key={item}
                        to={"/" + item}
                        onClick={() => closeNavigationMenu()}
                      >
                        <p
                          className={`label-text margin-bottom-24 ${
                            window.location.href.includes(item)
                              ? "userActions-navigation__dropdown-open"
                              : ""
                          }`}
                        >
                          {item}
                        </p>
                      </Link>
                    ) : (
                      <a
                        key={item}
                        href={
                          GOHANDLURL +
                          (item.includes("health") ? "health-plans" : item)
                        }
                        onClick={() => closeNavigationMenu()}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <p className="label-text margin-bottom-24">{item}</p>
                      </a>
                    )
                  )}
                </div>
              ))
            ) : (
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                {navSignedInOptions.map((opt) => (
                  <Link
                    to={"/" + opt}
                    key={opt}
                    onClick={() => closeNavigationMenu()}
                  >
                    <p
                      className={`updated-mobile-nav-container__menu-options  ${
                        (opt === "bill review" &&
                          window.location.href.includes("billing")) ||
                        window.location.href.includes(opt)
                          ? "userActions-navigation__dropdown-open"
                          : ""
                      }`}
                    >
                      {opt}
                    </p>
                  </Link>
                ))}
              </div>
            )}
          </div>
          <div className="flex-col" style={{ gap: "32px" }}></div>
          <div className="line" />
          <div
            className=" find-an-estimate-container flex-row"
            onClick={() => {
              history.push("/onboarding");
              closeNavigationMenu();
            }}
          >
            <p
              style={{ marginBottom: "0" }}
              className="updated-mobile-nav-container__menu-options"
            >
              Start a new bill
            </p>
            <img src={largeRight} alt="Large right icon" />
          </div>
          <div className="line" />
          <div className="updated-mobile-nav-container__help-section">
            {helpSectionOptions.map((option) => (
              <p
                key={option.id}
                className="updated-mobile-nav__li-options"
                onClick={() => {
                  option.action();
                  closeNavigationMenu();
                }}
              >
                {option.text}
              </p>
            ))}
          </div>

          <img
            className="updated-mobile-nav-container__handl-logo"
            src={navHomeLogo}
            alt="Handl home logo"
          />
        </div>
      </div>
    </>
  );
}

function QuoteNavigation(props) {
  let classes = "back sticky-top " + props.classes;
  let textClasses = "small back-text " + props.textClasses;
  let arrowClasses = "back-arrow quote-navigation-arrow " + props.arrowClasses;

  return (
    <div className={classes} onClick={props.back}>
      <div className={arrowClasses}></div>
      <p className={textClasses}>{props.lastPage}</p>
    </div>
  );
}

function UserNavigation(props) {
  const loggedIn = useSelector((state) => state.currentUser.loggedIn); //need to update this
  const { token, removeToken } = useToken();
  const userID = useSelector((state) => state?.currentUser?.currentUser?.id);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showUserInfoDropdown, setShowUserInfoDropdown] = useState(false);
  const [userInformation, setUserInformation] = useState({
    fullName: "",
  });
  const [smallScreen, setSmallScreen] = useState(false);
  const [fullName, setFullName] = useState("");

  const [userZipcode, setUserZipcode] = useState("");
  const history = useHistory();
  const location = useLocation();

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
      return () => window.removeEventListener("resize", handleResize);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  function goToSignUp() {
    window.location.href = "/sign_up";
  }

  function goToSignIn() {
    window.location.href = "/sign_in";
  }

  function LogOut() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "logout";
    axios
      .delete(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        window.location.href = "/sign_in";
        removeToken();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function capitalize(str) {
    const titleCase = str
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return titleCase;
  }

  const UserActions = () => {
    const UserInformation = () => {
      return (
        <div className="userActions-navigation__dropdown-user-information">
          <p className="text-medium fw-500 center-text">{fullName}</p>
          <Dropdown.Item>
            <Button
              label="My Profile"
              active={true}
              class="outline-btn-blue"
              onClick={() => {
                history.push("/settings/my-profile");
              }}
            />
          </Dropdown.Item>

          <Dropdown.Item>
            <Button
              label="Sign out"
              active={true}
              class="text-btn"
              onClick={(e) => LogOut()}
            />
          </Dropdown.Item>
        </div>
      );
    };
    if (loggedIn === true) {
      return (
        <div className="navigation-bar__user-actions">
          <div className="navigation-bar__user-actions-search"></div>

          <Dropdown
            onToggle={(isOpen, event, metadata) => {
              setShowUserInfoDropdown(isOpen);
            }}
          >
            <Dropdown.Toggle
              className="navigation-bar__dropdown"
              variant="link"
              key="user-information"
            >
              <UserIcon
                style="small"
                active={showUserInfoDropdown}
                userID={userID}
                setFullName={setFullName}
                fullName={fullName}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="userActions-navigation__dropdown-user-information-menu"
              show={showUserInfoDropdown}
            >
              {UserInformation()}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    } else {
      return (
        <div className="navigation-bar__user-actions">
          <Button
            label="Create an account"
            active={true}
            class="text-btn"
            className="navigation-bar__user-actions-btn"
            onClick={(e) => goToSignUp()}
          />

          <Button
            label="Sign in"
            active={true}
            class="outline-btn-blue"
            className="navigation-bar__user-actions-btn"
            onClick={(e) => goToSignIn()}
          />
        </div>
      );
    }
  };

  const dropDownItems = ["articles", "glossary", "press"];
  const leftMenuItems = ["billing", "estimates"];
  return !smallScreen ? (
    <div className="navigation-bar">
      <div className="flex-row nav-left-container">
        <Link
          className="navigation-bar__link"
          key="press"
          to={{ pathname: "/" }}
        >
          <img src={HHLogo} alt="Handl Health logo" />
        </Link>

        <div className="navigation-bar__page-links">
          {leftMenuItems.map((item) => (
            <Link
              className={`navigation-bar__link userActions-navigation-bar__items `}
              key={item}
              to={
                item === "billing" && !loggedIn
                  ? { pathname: "/onboarding" }
                  : { pathname: "/" + item }
              }
            >
              <p
                className={`margin-bottom-0
              ${
                window.location.href.includes(item) ||
                (window.location.href.includes("onboarding") &&
                  item === "billing" &&
                  loggedIn === false)
                  ? "userActions-navigation__dropdown-open"
                  : ""
              }
              `}
              >
                {capitalize(item)}
              </p>
            </Link>
          ))}

          <Dropdown
            onToggle={(isOpen, event, metadata) => {
              setShowDropdown(isOpen);
            }}
          >
            <Dropdown.Toggle
              className="navigation-bar__dropdown"
              variant="link"
              key="resources"
            >
              <div
                className={`userActions-navigation__dropdown-toggle flex-row 
                ${
                  showDropdown === true ||
                  ["articles", "press", "glossary"].some((item) =>
                    window.location.href.includes(item)
                  )
                    ? "userActions-navigation__dropdown-open"
                    : ""
                }

              `}
              >
                <p
                  className={`userActions-navigation-bar__items`}
                  style={{ marginBottom: "0" }}
                >
                  Resources
                </p>
                <img
                  style={{ marginLeft: "6px" }}
                  src={
                    showDropdown === true ||
                    ["articles", "press", "glossary"].some((item) =>
                      window.location.href.includes(item)
                    )
                      ? blueDownArrow
                      : downArrow
                  }
                  alt="down chevron"
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="userActions-navigation__dropdown-menu"
              show={showDropdown}
            >
              {dropDownItems.map((item, idx) => (
                <Dropdown.Item
                  as={Link}
                  to={"/" + item}
                  eventKey={idx + 1}
                  key={item}
                  className={
                    window.location.href.includes(item)
                      ? "color-default-blue"
                      : ""
                  }
                >
                  {capitalize(item)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Link
            className={`navigation-bar__link userActions-navigation-bar__items`}
            key="help"
            to={
              loggedIn
                ? { pathname: "/settings/help-center" }
                : { pathname: "/contact" }
            }
          >
            <p
              className={`margin-bottom-0
              ${
                (loggedIn === true &&
                  window.location.href.includes("help-center")) ||
                (loggedIn === false && window.location.href.includes("contact"))
                  ? "userActions-navigation__dropdown-open"
                  : ""
              }
              `}
            >
              Help
            </p>
          </Link>
        </div>
      </div>
      {UserActions()}
    </div>
  ) : (
    <MobileNavigation
      userID={userID}
      userZipcode={userZipcode}
      userInformation={userInformation}
      LogOut={() => LogOut()}
    />
  );
}
const MemoizedUserNavigation = memo(UserNavigation, () => true);

export {
  MainNavigation,
  QuoteNavigation,
  MobileNavigation,
  UserNavigation,
  MemoizedUserNavigation,
};
