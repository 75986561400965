import './EstimatePdf.css';
import React, { Component } from "react";
import { useState, useEffect, useReducer } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function EstimatePdf(props) {
  const [data, setData] = useState(props.data);
  const [quote, setQuote] = useState(props.quote);
  const [loaded, setLoaded] = useState(false);
  const [paymentBreakdownToggle, setPaymentBreakdownToggle] = useState('View Payment Details');
  const [hasInsurance, setHasInsurance] = useState(data.insuranceProvider != "I do not have insurance" && data.insuranceProvider != "")

  function getDetail(name) {
    if (data.facilitiesList != null ) {
      const facility = data.facilitiesList.find(element => element.name == name) ;
      return facility.detail;
    } 
  }

  var EstimateCallout = () => {
    var costForService = quote.user_cost + " for " + data.serviceName + " at " + data.facility;

    const detail = getDetail(data.facility);
    let detailElement;

    if (detail) {
      detailElement = (<p className='estimate__estimate-callout-facility-detail'>{detail}</p>);
    } else {
      detailElement = null;
    }

    return (
      <div className='estimate__estimate-callout' id='estimate-callout'>
        <div className='estimate__estimate-width'>
          <div className='estimate__estimate-callout-overline'>
            <p className='estimate__estimate-callout-overline-text'>Your Price Estimate</p>
            {detailElement}
          </div>
          <p className='estimate__callout-main-text' id='callout-main-text'>{costForService}</p>
        </div>
      </div>
    );      
  }

  var UnderstandingYourEstimate = () => {
      const youPayText = "You'll pay " + quote.user_cost;
      const youPayDescription = "This is an estimated cost for the service you are seeking at " + data.facility + ". This is not a guarantee " + (hasInsurance? "of insurance coverage or":"")  + "the total bill from the healthcare provider. Please check with " + (hasInsurance? "your health insurance company and ":"")  + "the healthcare provider you selected to confirm the cost."

      let insuranceSection;
      if (hasInsurance) {
        let coverageSection = CoverageDetails();

        insuranceSection = ( <div className='estimate__estimate-section-container container-with-top-padding'>
            <div className='estimate__estimate-section-emphasized-container'>
              <p className='estimate__estimate-section-emphasized'>{data.insuranceProvider}</p>
            </div>
            
            <div className='estimate__estimate-section-description'>
              {coverageSection}
            </div>
          </div>
        );
      } else {
        insuranceSection = null;
      }

      if (!hasInsurance) {
        return (
          <div className='estimate__estimate-section cost-and-coverage' id='what-youll-pay'>
            <div className='estimate__estimate-width'>
              <p className='estimate__estimate-section-title'>Understanding your estimate</p>
              <div className='estimate__estimate-section-container'>
                <div className='estimate__estimate-section-emphasized-container'>
                  <p className='estimate__estimate-section-emphasized'>{youPayText}</p>
                </div>
                <div className='estimate__estimate-section-description'>
                  <p className='estimate__estimate-section-text'>{youPayDescription}</p>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className='estimate__estimate-section cost-and-coverage' id='what-youll-pay'>
            <div className='estimate__estimate-width  estimate__extra-pdf-padding'>
              <p className='estimate__estimate-section-title'>Understanding your estimate</p>
              <div className='estimate__estimate-section-container'>
                <div className='estimate__estimate-section-emphasized-container'> 
                  <p className='estimate__estimate-section-emphasized'>{youPayText}</p>
                </div>
                <div className='estimate__estimate-section-description'>
                  <p className='estimate__estimate-section-text'>{youPayDescription}</p>
                  {PaymentBreakdown()}
                </div>
              </div>
              {insuranceSection}
            </div>
          </div>
        );
      }

      
  }

  var PaymentBreakdown = () => {
    const insuranceCoverage = "- (" + quote.insurance_coverage + ")";
    return(
      <Card.Body className='estimate__payment-breakdown-details'>
        <div className='estimate__payment-breakdown-section'>
          <div className='estimate__payment-breakdown-overline'> 
            <p>Service Price</p>
            <p>{quote.gross_price}</p>
          </div>
          <p className='estimate__payment-breakdown-description'>
            {hasInsurance? "The price negotiated by your health insurer with the healthcare provider.":"The cash price offered by the healthcare provider."}
          </p>
        </div>
        <div className='estimate__payment-breakdown-section'>
          <div className='estimate__payment-breakdown-overline'> 
            <p>Insurance Coverage</p>
            <p>{insuranceCoverage}</p>
          </div>
          <p className='estimate__payment-breakdown-description'>The amount your insurance will pay for this service based on your plan details.</p>
        </div>
        <div className='estimate__payment-breakdown-section estimate__payment-breakdown-you-pay'>
          <div className='estimate__payment-breakdown-overline'> 
            <p>You Pay</p>
            <p>{quote.user_cost}</p>
          </div>
          <p className='estimate__payment-breakdown-description'>Our estimate of how much you’ll owe for this service.</p>
        </div>
      </Card.Body>

    )
  }

  var CoverageDetails = () => {
    let paidOop;

    if (data.deductiblePercentage == 1) {
      paidOop = (<div className='estimate__coverage-detail-line'>
                    <p>Out-Of-Pocket Maximum paid to date</p>
                    <p>{'$' + (data.oopMax * data.oopMaxPercentage)}</p>
                  </div>)
    } else {
      paidOop = null;
    }

    let deductibleAmount;
    if (Number.isInteger(data.deductible)) {
      deductibleAmount = data.deductible
    } else {
      deductibleAmount = data.deductible.replace('$', '');
    }

    let deductibleDisplay = '$' + deductibleAmount;


    return(
      <div className='estimate__coverage-details'>
        <div className='estimate__coverage-detail-line'>
          <p>My plan deductible</p>
          <p>{deductibleDisplay}</p>
        </div>

        <div className='estimate__coverage-detail-line'>
          <p>Deductible paid to date</p>
          <p>{'$' + (deductibleAmount * data.deductiblePercentage)}</p>
        </div>

        <div className='estimate__coverage-detail-line'>
          <p>My Out-Of-Pocket Maximum</p>
          <p>{'$' + (data.oopMax)}</p>
        </div>

        {paidOop}

      </div>
    )
  }

  var WhereYoullGo = () => {
      var facilityAddress = quote.facility_address;
      var map_url = "https://www.google.com/maps/embed/v1/place?key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY + "&q=" + facilityAddress;
      var distance = quote.facility_distance + ' Miles Away'

      return (
        <div className='estimate__estimate-section where-youll-be'>
          <div className='estimate__estimate-width'>
            <p className='estimate__estimate-section-title'>Where you'll go</p>
            <div className='estimate__estimate-section-container'>
              <div className='estimate__estimate-section-emphasized-container'>
                <p className='estimate__estimate-section-emphasized'>{data.facility}</p>
              </div>
              <div className='estimate__facility-details'>
                <div className='estimate__facility-details-text'>
                  <p className='estimate__facility-distance'>{distance}</p>
                  <div className='estimate__facility-name-and-cost'>
                    <p className='estimate__facility-name'>{data.facility}</p>
                    <p className='estimate__facility-cost'>{quote.user_cost}</p>
                  </div>
                  <p className='estimate__facility-address'>{quote.address1}</p>
                  <p className='estimate__facility-address'>{quote.address2}</p>
                </div>
                {/* <iframe
                  width="100%"
                  height="250"
                  // style="border:0"
                  loading="lazy"
                  allowFullScreen
                  src={map_url}>
                </iframe> */}
              </div>
            </div>
          </div>
        </div>
      );
  }

  var MoreFacilities = () => {
    if (data.facilitiesList != null && data.facilitiesList.length > 1) {
      var amount = 3;
      if (!hasInsurance) {
        amount = 2;
      }
      const facilities = data.facilitiesList.filter(facility => facility.name != data.facility).slice(0, amount).map(item=> Facility(item));

        return (
          <div className='estimate__estimate-section estimate__more-facilities'>
            <p className='estimate__estimate-section-emphasized-container estimate__more-facilities-text'>
              <span className='bold'>Want more options?</span>
              {hasInsurance? " These facilities are other options for the service you searched based on your location and insurance information. ":" These facilities are other options for the service you searched based on your location. "}
               
            </p>
            <ul className='estimate__facilities-list'>
              {facilities}
            </ul>
          </div>
        );
      
    } 
  }

  var Facility = (facility) => {
    return (
      <li key={facility.name} id={facility.name} >
        <p className='estimate__facility-list-item-cost'>{facility.user_cost}</p>
        <p className='estimate__facility-list-item-name'>{facility.name}</p>
        <p className='estimate__facility-list-item-address'>{facility.address1}</p>
        <p className='estimate__facility-list-item-address'>{facility.address2}</p>
      </li>
    );
  }

  if (hasInsurance) {
    return (
      <div id='estimate-pdf-container'>
        <div className='estimate-pdf' id='estimate-pdf-1'>
          {EstimateCallout()}
          {UnderstandingYourEstimate()}
        </div>
  
        <div className='estimate-pdf' id='estimate-pdf-2'>
          {WhereYoullGo()}
          {MoreFacilities()}
        </div>
      </div>
    );
  } else {
    return (
      <div id='estimate-pdf-container'>
        <div className='estimate-pdf' id='estimate-pdf-1'>
          {EstimateCallout()}
          {UnderstandingYourEstimate()}
          {WhereYoullGo()}
          {MoreFacilities()}
        </div>
      </div>
    );
  }

  
  
}

export default EstimatePdf;
