import SettingsSideBar from "./SettingsSideBar";
import SidebarLayout from '../../components/templates/SidebarLayout.js';
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import useToken from '../../hooks/useToken';
import EditPassword from "./EditPassword";
import DeletionActions from "./DeletionActions";
import axios from 'axios';

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className='settings__accordian-toggle border-0' type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}

function Privacy(props) {
  const [activeKey, setActiveKey] = useState(0);
  const { token } = useToken();
  const location = useLocation();
  const history = useHistory();
  const [userId, setUserId] = useState(location.state ? location.state.userId : null);

  useEffect(() => {
    getUserId();
  }, []);

  function getUserId() {
    if(userId == null) {
      const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/users';
      axios.get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(res => {
        if (res.status == 200) {
          setUserId(res.data.data.id);
        } else {
          history.push({
            pathname: "/sign_in" });
        }
      }).catch(function(error) {
        history.push({
          pathname: "/sign_in" });
      });
    } 
  }
  
  var PrivacyCard = (title, body, index) => {
    let cardClass = activeKey === index ? "active settings__privacy-card-title" : "settings__privacy-card-title"
    return (
      <Card className={cardClass} key={index}>
        <CustomToggle
          as={Card.Header}
          eventKey={index}
          handleClick={() => {
            if (activeKey === index) {
              setActiveKey(null);
            } else {
              setActiveKey(index);
            }
          }}
        >
          <span>{title}</span>
          <span>{activeKey === index ? " -" : " +"}</span>
        </CustomToggle>
        <Accordion.Collapse eventKey={index} className='border-0'>
          <Card.Body className='settings__privacy-card-body'>
            {body}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }


  const PrivacyAndSecurity = () => {
    const privacyInformation = [{title: 'Terms and Conditions', body: 'Terms and Conditions'},
                                {title: 'Our Privacy Policy', body: 'Our Privacy Policy'},
                                {title: 'HIPPA Compliance Policy', body: 'HIPPA Compliance Policy'}]

    let privacyInformationCards = privacyInformation.map((item, index) => PrivacyCard(item.title, item.body, "privacy-" + index));


    return (
      <div className='settings__privacy-and-security settings-page'>
        <Link to= {{ pathname: '/settings'}} className='settings__back-button'>
          <p className='body-2 pointer'><span className='back-arrow' /> SETTINGS</p>
        </Link>
        <p className='settings__page-title'>Privacy and security settings</p>

        <EditPassword token={token} userId={userId} />

        <DeletionActions token={token} userId={userId} />

        <div className='settings__privact-information'>
          <Accordion defaultActiveKey={0} activeKey={activeKey}>
            {privacyInformationCards}
          </Accordion>
        </div>
      </div>
    );
  }

  return (
    <SidebarLayout 
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses='settings__side-bar'
      content={PrivacyAndSecurity()}
      contentClasses='settings__content'
      userStatus='logged-in'
      showFooter={true}
    />
  );
}

export default Privacy;
