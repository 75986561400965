import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextInput from "../../components/elements/inputs/TextInput";
import TextAreaInput from "../../components/elements/inputs/TextAreaInput";
import { useState } from "react";
import { send } from "emailjs-com";
import Desktop from "../../components/templates/Desktop";
import Mobile from "../../components/templates/Mobile";
import Button from "../../components/elements/Button";
import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import React, { Component, useEffect } from "react";
import axios from "axios";
import { GoogleSpreadsheet } from "google-spreadsheet";
import ContactForm from "../../components/modules/ContactForm";

function Contact(props) {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY =
    process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, "\n");
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + "email_lists";
    axios
      .post(
        URL,
        {
          contact_first_name: name,
          contact_last_name: "",
          contact_email: email_address,
          query_type: "contact us",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {});
  };

  const [toSend, setToSend] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);

  const recaptchaRef = React.createRef();

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue.length) {
      console.log("no captcha");
      document.getElementById("captcha-text").style.display = "block";
      return;
    }

    const SERVICE_ID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_EMAIL_JS_USER_ID;

    send(SERVICE_ID, TEMPLATE_ID, toSend, USER_ID)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        document.getElementById("captcha-text").style.display = "none";
        document.getElementById("contact-form-container").style.display =
          "none";
        document.getElementById("thank-you-container").style.display = "block";
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });

    const email_address = toSend.from_email;
    const name = toSend.from_name;
    const today = new Date();
    const newRow = {
      Email: email_address,
      Name: name,
      "Submitted On": today.toLocaleDateString("en-US"),
      "Query Type": "contact us",
    };

    appendSpreadsheet(newRow);
    createContact(email_address, name);
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const contactForm = (
    <div className="contact-page width-896">
      <h1 className="generic-heading">Contact Us</h1>
      <div id="contact-form-container">
        <ContactForm contactDescription="Have questions? We’re here to help!" />
      </div>
      <div id="thank-you-container">
        <p className="thank-you-text">Thanks! We’ll be in touch.</p>
      </div>
    </div>
  );

  if (window.innerWidth <= 768) {
    return (
      <Mobile
        body={contactForm}
        showQuoteNavigation={false}
        showMobileNav={true}
        mobileNavColor="white"
        showFooter={true}
        genericContainer={true}
      />
    );
  } else {
    return <Desktop genericContainer={true} body={contactForm} />;
  }
}

export default Contact;
