import React, { useState, useEffect, Fragment } from "react";
import Input from "../../components/elements/Input";
import axios from "axios";
import Button from "../../components/elements/Button";

function EditPassword(props) {
  const [password, setPassword] = useState("123password123");
  const [passwordConfirmation, setPasswordConfirmation] =
    useState("123password123");
  const [readOnlyPassword, setReadOnlyPassword] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(null);
  const [passwordConfirmationIsValid, setPasswordConfirmationIsValid] =
    useState(null);

  function updatePassword() {
    var updateRequest = {
      password: password,
      password_confirmation: passwordConfirmation,
    };

    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/users/" + props.userId;
    axios
      .patch(
        URL,
        { user: updateRequest },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: props.token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setPassword("123password123");
          setPasswordConfirmation("123password123");
        } else {
          // Error
          console.log(res);
        }
      })
      .catch(function (error) {
        // Error
        console.log(error.response);
      });
  }

  function validatePassword() {
    if (password == "") {
      return null;
    } else {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{12,}/;
      const passwordLength = password.length;

      const uppercasePassword = uppercaseRegExp.test(password);
      const lowercasePassword = lowercaseRegExp.test(password);
      const digitsPassword = digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword = minLengthRegExp.test(password);

      return uppercasePassword && specialCharPassword && minLengthPassword;
    }
  }

  function validatePasswordConfirmation() {
    return (
      password != "" &&
      passwordConfirmation != "" &&
      password == passwordConfirmation
    );
  }

  function handleClick() {
    if (readOnlyPassword == true) {
      setPassword("");
      setPasswordConfirmation("");
      setReadOnlyPassword(!readOnlyPassword);
      setPasswordIsValid(null);
      setPasswordConfirmationIsValid(null);
    }
  }

  function handlePasswordUpdate() {
    var validPassword = validatePassword();
    var validPasswordConfirmation = validatePasswordConfirmation();
    if (validPassword && validPasswordConfirmation) {
      setPasswordIsValid(null);
      setPasswordConfirmationIsValid(null);
      updatePassword();
      setReadOnlyPassword(!readOnlyPassword);
    } else {
      if (!validPassword) {
        setPasswordIsValid(false);
        setPasswordConfirmationIsValid(null);
      } else if (!validPasswordConfirmation) {
        setPasswordConfirmationIsValid(false);
        setPasswordIsValid(null);
      }
    }
  }

  return (
    <div className="settings__edit-password">
      <p className="settings__subtitle">Edit your password</p>
      <Input
        placeholder="Password"
        type="password"
        name="password"
        label="Password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setPasswordIsValid(null);
        }}
        inputContainerClasses="settings__input"
        showArrow={false}
        showInputActions={readOnlyPassword}
        readOnly={readOnlyPassword}
        iconOnClick={() => handleClick()}
        errorMessage="Please use at least 12 characters, including one upper case letter and one symbol."
        showErrorMessage={password != "" && passwordIsValid == false}
        showErrorIcon={false}
      />

      <Input
        placeholder="Confirm new password"
        type="password"
        name="password_confirmation"
        label="Confirm new password"
        value={passwordConfirmation}
        onChange={(e) => {
          setPasswordConfirmation(e.target.value);
          setPasswordConfirmationIsValid(null);
        }}
        inputContainerClasses="settings__input password-confirmation"
        showArrow={false}
        readOnly={readOnlyPassword}
        errorMessage="Passwords do not match. Please re-enter."
        showErrorMessage={
          passwordConfirmation != "" && passwordConfirmationIsValid == false
        }
        showErrorIcon={false}
      />
      <Button
        label="Save new password"
        active={readOnlyPassword === false && validatePasswordConfirmation()}
        class="long-btn text-btn"
        onClick={() => handlePasswordUpdate()}
      />
    </div>
  );
}

export default EditPassword;
