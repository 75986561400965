import React, { useState, useEffect } from "react";
import "./UserIcon.css";
import useToken from "../../hooks/useToken";
import axios from "axios";

export default function UserIcon({
  userID,
  style,
  active,
  setFullName,
  fullName,
}) {
  const { token, removeToken } = useToken();

  useEffect(() => {
    getUserInformation();
  }, []);

  // get user info based on user id
  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const data = res.data.data.attributes;
        setFullName(data?.full_name ? data.full_name : "");
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  const userInitials =
    fullName?.split(" ")[0].charAt(0) +
    fullName?.split(" ")[fullName?.split(" ").length - 1].charAt(0);
  return (
    <div className={`user-profile-icon ${style}`}>
      <div className={active === true ? "active-open" : "hidden-selected"} />
      <p style={{ marginBottom: "0" }} className="text-large fw-600">
        {userInitials}
      </p>
    </div>
  );
}
