import React, { useState, useEffect } from "react";
import Button from "../../components/elements/Button";
import detailsAlt from "../../assets/icons/Billing/detailsAlt.svg";
import billReview from "../../assets/icons/Billing/billReview.svg";
import billUpdate from "../../assets/icons/Billing/billUpdate.svg";
import mobileBillReviewSxn from "../../assets/Home/icons/mobileBillReviewSxn.svg";
import left from "../../assets/icons/left.svg";
import "./Onboarding.css";

export default function BillReviewOnboarding({
  setCurrentStep,
  progressInterval,
  progressPercentage,
  setProgressPercentage,
}) {
  const [smallScreen, setSmallScreen] = useState(false);
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const mainElement = document.querySelector(".billing-onboarding");
    if (smallScreen === true) {
      mainElement.classList.remove("billing-onboarding");
      mainElement.classList.add("lets-handl-this");
    }

    return () => {
      mainElement?.classList?.remove("lets-handl-this");
      mainElement?.classList?.add("billing-onboarding");
    };
  }, [smallScreen]);

  const billContent = [
    {
      img: detailsAlt,
      description:
        "Give us some quick details about the bill you want us to review",
    },
    {
      img: billReview,
      description:
        "We take care of the hard part and review your bill for any errors or issues",
    },
    {
      img: billUpdate,
      description:
        "You get an update on the outcome of your bill review and next steps",
    },
  ];
  return (
    <main className="flex-wrapper-space-between lets-handl-this">
      <div>
        <section className="lets-handl-this-main-section">
          <h1 className="lets-handl-this-heading">
            {smallScreen ? "Let's Handl this." : "Bill Review"}
          </h1>
          <div>
            <p className="body-2">
              To make sure we do the most accurate bill review, please grab a
              few quick <br />
              things:{" "}
            </p>
            <ul>
              <li>Visit type, location and date of visit</li>
              <li>The insurance plan name you used</li>
              <li>Total amount of the bill</li>
            </ul>
          </div>
          <p className="lets-handl-this-additional-text body-2">
            Ready to get started? It takes only a few minutes, and Handl will
            take care of the hard part!
          </p>
          <div className="lets-handl-this-btn">
            <Button
              label="Start a bill review"
              active={true}
              class="long-btn filled"
              onClick={() => {
                setCurrentStep("Visit type");
                setProgressPercentage(progressPercentage + progressInterval);
              }}
            />
          </div>
        </section>
        {!smallScreen ? (
          <section className="width-896 lets-handl-this__desktop-only-sxn flex-row">
            {billContent.map((element) => (
              <div key={element.description} className="flex-col">
                <img style={{ width: "80px" }} src={element.img} />
                <p className="text-subheadhing fw-500 ">
                  {element.description}
                </p>
              </div>
            ))}
          </section>
        ) : (
          <img
            className="lets-handl-this__mobile-only-img"
            src={mobileBillReviewSxn}
          />
        )}
      </div>
    </main>
  );
}
