import React, { useState, useEffect } from "react";
import search from "../../../assets/icons/Billing/search.svg";
import compare from "../../../assets/icons/Billing/compare.svg";
import bestOption from "../../../assets/icons/Billing/bestOption.svg";
import "./WhatToExpect.css";

export default function WhatToExpect() {
  const [smallScreen, setSmallScreen] = useState(false);
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const whatToExpectContent = [
    {
      image: search,
      heading: "Search for the care you need",
      description:
        "Find the service or procedure you’re looking for with our intuitive search platform.",
    },
    {
      image: compare,
      heading: "Compare & contrast by price",
      description:
        "Clearly see the prices offered by healthcare providers in your area.",
    },
    {
      image: bestOption,
      heading: "Choose the best option for you",
      description:
        "Decide on the winning provider and create an account to save the info all in one place.",
    },
  ];
  return (
    <div className="what-to-expect">
      <p
        style={{ alignSelf: "flex-start" }}
        className={`text-large ${smallScreen ? "fw-300" : "fw-400"}`}
      >
        What to expect
      </p>
      <div className="what-to-expect__container">
        {whatToExpectContent.map((el) => (
          <div
            key={el.heading}
            className="flex-col"
            style={{ alignItems: "center", flex: "1 1 0px" }}
          >
            <img src={el.image} alt={el.heading} />
            <p
              style={{ margin: "10px 0 " }}
              className="text-subheading color-default-blue center-text"
            >
              {el.heading}
            </p>
            <p
              className="body-2"
              style={
                smallScreen ? { maxWidth: "359px" } : { maxWidth: "240px" }
              }
            >
              {el.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
