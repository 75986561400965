import React from "react";
import "./ToggleSwitch.css";

function ToggleSwitch(props) {
  return (
    <div className="toggle-switch-container">
      <p className='toggle-text'>{props.label}{" "}</p>
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={props.name} id={props.name} checked={props.checked} onChange={e => props.onChange(e)} />
        <label className="label" htmlFor={props.name}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
}
  
export default ToggleSwitch;
