import React from "react";
import axios from "axios";
import "./Article.css";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loading from "../../components/modules/Loading";
import ArticleCard from "../../components/modules/ArticleCard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ArticleCardsCarousel from "../../components/sections/Home/ArticleCardsCarousel";

function Article(props) {
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    async function fetchArticleData() {
      const title = props.location.search
        ? props.location.search
        : props.match.params.url;
      const url = process.env.REACT_APP_API_URL + "articles/find_by_title";
      const request = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          title: title,
        },
      });

      window.scrollTo(0, 0);

      setArticle(request.data.data.attributes);
      setLoading(false);
    }
    fetchArticleData();
  }, [params]);

  const sources = generateSources();

  function generateSources() {
    if (loading == false) {
      return (
        <div className="article__article-sources">
          <p>Sources:</p>
          <div
            className="article__article-sources-links"
            dangerouslySetInnerHTML={{
              __html: article.sources_body.replace(
                /href/g,
                "target='_blank' href"
              ),
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  }
  var ArticleData = () => {
    return (
      <div className="individual-article-page">
        <div className="article__article-width">
          <h1 className="article__article-title">{article.title}</h1>
          <p className="article__article-subtitle">{article.subtitle}</p>
        </div>
        <div className="article__article-container">
          <div className="article__article-image-container">
            <img
              className="article__article-image"
              src={article.header_image}
            />
          </div>
          <div className="article__article-width">
            <p className="article__article-date">{article.date}</p>
            <p className="article__author-name">{article.author_name}</p>

            <p
              className="article__article-content"
              dangerouslySetInnerHTML={{
                __html: article.content_body.replace(
                  /href/g,
                  "target='_blank' href"
                ),
              }}
            />
            {sources}
            <div className="article__article-author">
              <img
                className="article__author-icon"
                src={article.author_image}
              />
              <div className="article__author-details">
                <p className="article__author-title">AUTHOR</p>
                <p className="article__author-name">{article.author_name}</p>
              </div>
            </div>
            <p className="article__author-bio">{article.author_bio}</p>
          </div>
        </div>
        <ArticleCardsCarousel articles={article.recommended_articles.data} />
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <HelmetProvider>
        <div style={{ display: "contents" }}>
          <Helmet>
            <meta
              property="og:url"
              content={"http://www.handlhealth.com/articles/" + article.url}
            />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={article.title} />
            <meta
              property="og:description"
              content={article.meta_description}
            />
            <meta property="og:image" content={article.image} />
            <meta name="description" content={article.meta_description} />
            <title>{article.title}</title>
          </Helmet>
          {ArticleData()}
        </div>
      </HelmetProvider>
    );
  }
}
export default Article;
