import SettingsSideBar from "./SettingsSideBar";
import Button from "../../components/elements/Button";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useToken from "../../hooks/useToken";

function SettingsHome(props) {
  const history = useHistory();
  const { token } = useToken();

  const [firstName, setFirstName] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [completionPercent, setCompletionPercent] = useState(0);
  const [userId, setUserId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);

  // state for user insurances
  const [employer, setEmployer] = useState("");
  const [insurancePlan, setInsurancePlan] = useState("");
  const [planType, setPlanType] = useState("");
  const [insuranceIDNumber, setInsuranceIDNumber] = useState("");

  useEffect(() => {
    getUserInformation();
  }, []);

  useEffect(() => {
    getUserInsuranceInformation();
  }, [userId]);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_informations";
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        var user_information = res.data.data.attributes;
        setCompletionPercent(user_information.completion);
        setFirstName(user_information.first_name);
        setFullName(user_information.full_name);
        setUserId(user_information.user_id);
        setLoaded(true);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  // get user insurance information
  function getUserInsuranceInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/user_insurances/" + userId;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        setEmployer(
          userInformation.employer_name[0]
            ? userInformation.employer_name[0]
            : ""
        );
        setInsurancePlan(
          userInformation.issuer_legal_name[0]
            ? userInformation.issuer_legal_name[0]
            : ""
        );
        setPlanType(userInformation.plan_type ? userInformation.plan_type : "");
        setInsuranceIDNumber(
          userInformation.member_id ? userInformation.member_id : ""
        );
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  const BulletList = (listItems) => {
    return (
      <div className="settings__bullet-list-container">
        {listItems.title ? <p className="body-1">{listItems.title}</p> : null}
        <ul className="settings__bullet-list">
          {listItems.items.map((item, index) => (
            <li key={index}>
              <p className="body-2">{item}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const HomeCard = (cardData, index) => {
    function goToSettingsPage(page) {
      history.push({
        pathname: "/settings/" + page,
        state: {
          userId: userId,
          employer: employer,
          planType: planType,
          insuranceIDNumber: insuranceIDNumber,
          insurancePlan: insurancePlan,
          fullName: fullName,
        },
      });
      window.scrollTo(0, 0);
    }

    return (
      <div
        className="settings__home-card"
        key={cardData.page ? cardData.page : index}
        onClick={(e) => goToSettingsPage(cardData.page)}
      >
        <p className="settings__home-card-title">{cardData.title}</p>

        {cardData.body}

        <div className="settings__home-see-more-btn">
          <Button label="See More" active={true} class="long-btn text-btn" />
        </div>
      </div>
    );
  };

  const Home = () => {
    const myProfileListItems = [
      "View your email and DOB",
      "Delete your account",
      "Update your password",
    ];
    const helpCenterListItems = [
      "View our FAQs",
      "Terms & conditions and privacy policy",
      "Handl Health and insurance contact information",
    ];

    var homeCardData = [
      {
        title: "Your profile",
        body: BulletList({ items: myProfileListItems }),
        page: "my-profile",
      },

      {
        title: "Help & FAQs",
        body: BulletList({ items: helpCenterListItems }),
        page: "help-center",
      },
    ];

    var homeCards = homeCardData.map((item, index) => HomeCard(item, index));

    return (
      <div className="settings__home settings-page">
        <div className="settings__home-info">
          <p className="settings__page-title">Settings</p>

          <h5 className="fw-200 margin-bottom-32">
            Hey{firstName ? " " + firstName + "," : ","} {smallScreen && <br />}
            glad you're here.
          </h5>
        </div>

        {homeCards}
      </div>
    );
  };

  return (
    <SidebarLayout
      noSidebar={true}
      content={Home()}
      contentClasses="settings__content settings__home-container"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default SettingsHome;
