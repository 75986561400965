import Form from "react-bootstrap/Form";
import TextInput from "../elements/inputs/TextInput";
import { useState, useEffect } from "react";
import { send } from "emailjs-com";
import Button from "../elements/Button";
import "./SendEstimate.css";
import ReCAPTCHA from "react-google-recaptcha";
import React, { Component } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import axios from "axios";
import { GoogleSpreadsheet } from "google-spreadsheet";
import ShareFeedback from "./ShareFeedback";
import Input from "../elements/Input";

function SendEstimate(props) {
  const [toSend, setToSend] = useState({
    to_name: "",
    to_email: "",
    cost_estimate: "hello",
  });

  const [validated, setValidated] = useState(false);

  const recaptchaRef = React.createRef();

  const validateForm = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue.length) {
      document.getElementById("captcha-text").style.display = "block";
      return;
    }

    exportAsPicture(e);
  };

  const exportAsPicture = (e) => {
    window.scrollTo(0, 0);
    if (document.getElementById("estimate-pdf-container")) {
      document.getElementById("estimate-pdf-container").style.display = "block";
    }

    var estimatePdfPage1 = document.getElementById("estimate-pdf-1");

    let pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "letter",
      compressPdf: true,
    });

    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();

    html2canvas(estimatePdfPage1, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL({
        format: "png",
        quality: 0.5,
      });

      if (props.hasInsurance) {
        pdf.addImage(imgData, "PNG", 0, 0, width, height, undefined, "FAST");
      } else {
        var page1ClientHeight = estimatePdfPage1.clientHeight / 1.35;
        var page1ClientWidth = estimatePdfPage1.clientWidth / 1.35;

        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          page1ClientWidth,
          page1ClientHeight,
          undefined,
          "FAST"
        );

        let output = pdf.output("datauristring");
        let attachment = output.split(",")[1];
        sendEmail(e, attachment);
      }
    });

    if (props.hasInsurance) {
      var estimatePdfPage2 = document.getElementById("estimate-pdf-2");
      var pageClientHeight = estimatePdfPage2.clientHeight / 1.35;
      var pageClientWidth = estimatePdfPage2.clientWidth / 1.35;

      html2canvas(estimatePdfPage2, { scale: 1 }).then((canvas) => {
        const imgData = canvas.toDataURL({
          format: "png",
          quality: 0.5,
        });

        pdf.addPage("letter", "p");
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          pageClientWidth,
          pageClientHeight,
          undefined,
          "FAST"
        );

        let output = pdf.output("datauristring");
        let attachment = output.split(",")[1];
        sendEmail(e, attachment);
      });
    }

    if (document.getElementById("estimate-pdf-container")) {
      document.getElementById("estimate-pdf-container").style.display = "none";
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + "email_lists";
    axios
      .post(
        URL,
        {
          contact_first_name: name,
          contact_last_name: "",
          contact_email: email_address,
          query_type: "estimate",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {});
  };

  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY =
    process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, "\n");
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const sendEmail = (e, attachment) => {
    const SERVICE_ID = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
    const TEMPLATE_ID =
      process.env.REACT_APP_EMAIL_JS_SAVE_ESTIMATE_TEMPLATE_ID;
    const USER_ID = process.env.REACT_APP_EMAIL_JS_USER_ID;

    send(
      SERVICE_ID,
      TEMPLATE_ID,
      {
        to_name: toSend.to_name,
        to_email: toSend.to_email,
        cost_estimate: attachment,
      },
      USER_ID
    )
      .then((response) => {})
      .catch((err) => {
        console.log("FAILED...", err);
      });

    const email_address = toSend.to_email;
    const name = toSend.to_name;
    const today = new Date();
    const newRow = {
      Email: email_address,
      Name: name,
      "Submitted On": today.toLocaleDateString("en-US"),
      "Query Type": "estimate",
    };

    appendSpreadsheet(newRow);
    createContact(email_address, name);
    showShareFeedback();
    props.closeModal();
  };

  const showShareFeedback = () => {
    document.getElementById("save-estimate-modal-content").style.display =
      "none";
    document.getElementById("share-feedback-container").style.display = "block";
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={validateForm}
      className="send-estimate-form"
    >
      <Input
        type="text"
        name="to_name"
        value={toSend.to_name}
        inputContainerClasses="update-zipcode-modal__input"
        label="Full name"
        placeholder="Full name"
        formGroupClasses="w-100"
        onChange={handleChange}
        autoFocus={true}
      />
      <Input
        type="text"
        name="to_email"
        value={toSend.to_email}
        label="Email address"
        inputContainerClasses="update-zipcode-modal__input"
        placeholder="Email address"
        formGroupClasses="w-100"
        onChange={handleChange}
        autoFocus={false}
      />
      <section>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
      </section>
      <p className="captcha-text" id="captcha-text">
        You must not be a robot.
      </p>
      <Button active={true} class="long-btn filled" label="Send my estimate" />
    </Form>
  );
}

export default SendEstimate;
