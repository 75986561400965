import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import {
  Link
} from 'react-router-dom';

function ProfileCompletion(props) {
  const [completionPercent, setCompletionPercent] = useState(0);
  const [nextToComplete, setNextToComplete] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    getProfileCompletion();
  }, []);

  function getProfileCompletion() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/user_informations/profile_completion';
    axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: props.token,
      },
    }).then(res => {
      var user_information = res.data.data.attributes;
      setCompletionPercent(user_information.completion)
      setNextToComplete(user_information.next_to_complete);
      setUserId(user_information.user_id);
    }).catch(function(error) {
      console.log(error.response);
    });
  };

  const itemToComplete = (item) => {
    var url;

    if (item == "Update my insurance information") {
      url ="/"
    } else if (item == "Choose my notification preferences") {
      url ="/settings/notifications"
    } else {
      url = "/settings/my-profile/"
    }

    if (props.linked == true) {
      return <Link to={{pathname: url, state: {userId: userId}}} key={item}><li key={item}>{item + ' >'}</li></Link>
    } else {
      return <li key={item}>{item}</li>
    }
  }

  const WhatsNextSection = () => {
    if (nextToComplete.length >= 1) {
      var listItems = nextToComplete.map(item => itemToComplete(item))

      return (
        <div className='settings__whats-next'>
          <p className='body-2'>What's next: </p>
          <ul className='settings__bullet-list'>
            {listItems}
          </ul>
        </div>
      )
    } else {
      return null;
    }
    
  }

  return (
    <div className="settings__profile-completion-section">
      <div className='settings__profile-completion'>

        <div className='settings__profile-completion-descripton'>
          <p className='settings__profile-completion-text'>Profile Completion</p>
          <p className='settings__profile-completion-percentage'>{completionPercent + "%"}</p>
        </div>

        <div className='settings__profile-completion-bar'> <div className='settings__profile-completion-bar-filled' style={{width: completionPercent + '%'}} /> </div>
      </div>

      {WhatsNextSection()}
    </div>
  )

}

export default ProfileCompletion;
