import React, { Component } from "react";
import "./Select.css";
import Form from "react-bootstrap/Form";
import cancel from "../../../assets/icons/cancel.svg";
import down from "../../../assets/icons/down.svg";

function MobileSelect(props) {
  const classes = "position-relative " + props.formGroupClasses;
  const descriptionID = props.name + "-description";
  var icon;
  if (props.showArrow) {
    icon = <img src={down} className="select-input-icon" />;
  } else {
    if (props.value == "") {
      icon = (
        <img src={cancel} width="24" height="24" className="cancel-icon" />
      );
    } else {
      icon = null;
    }
  }

  console.log(props);
  var options = props.options;
  let optionsList =
    options.length > 0 &&
    options.map((item, i) => {
      let key = item.name + "-" + item.value;
      if (item.disabled) {
        return (
          <option key={key} value={item.value} disabled>
            {item.option}
          </option>
        );
      } else {
        return (
          <option key={key} value={item.value}>
            {item.option}
          </option>
        );
      }
    }, this);

  if (props.hide == true) {
    return null;
  } else {
    return (
      <Form.Group controlId={props.name} className={classes}>
        <Form.Label className={props.formLabelClasses}>
          {props.label}
        </Form.Label>
        <Form.Text id={descriptionID}>{props.description}</Form.Text>

        <div className={props.inputAndFeedback}>
          <label
            className="custom-form-label"
            htmlFor={props.name}
            style={{ paddingLeft: "8px" }}
          >
            {props.placeholder}
          </label>
          <Form.Control
            as="select"
            className={props.selectClasses}
            onChange={(e) => props.onChange(e)}
            name={props.name}
            value={props.value}
            required
            disabled={props.disabled}
          >
            <option key="blankChoice" hidden value="">
              {props.placeholder}
            </option>
            {optionsList}
          </Form.Control>
          {icon}

          <Form.Control.Feedback type="invalid">
            This field is required.
          </Form.Control.Feedback>
        </div>
      </Form.Group>
    );
  }
}

export default MobileSelect;
