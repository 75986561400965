import React, { Component, useState, useReducer } from "react";
import "./EstimateFormDesktopMobile.css";
import axios from "axios";
import Geocode from "react-geocode";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useHistory, Link } from "react-router-dom";
import Button from "../elements/Button";
import Form from "react-bootstrap/Form";
import Mobile from "../templates/Mobile";
import TextInput from "../elements/inputs/TextInput";
import MobileSelect from "../elements/inputs/MobileSelect";
import zipcode from "../../assets/icons/zipcode.png";

class EstimateFormMobile extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1

      serviceName: this.props.serviceName,
      service: this.props.service,
      serviceError: "",

      zipCode: "",
      locationLoading: true,

      rawInsuranceData: [],
      providerList: [],
      plansList: [],
      insuranceProvider: "",
      insurancePlan: "",
      insurance: "",
      insuranceError: "",

      greatestCost: "",

      deductible: "",
      isDeductibleMet: "",
      deductiblePercentage: 0,

      oopMax: "",
      isMaxOopMet: "",
      oopMaxPercentage: 0,
      lowestOopMaxPercentageValue: 0,
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.handleIsDeductibleMetChange =
      this.handleIsDeductibleMetChange.bind(this);
    this.calculateOopMaxPercentage = this.calculateOopMaxPercentage.bind(this);

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    this.getLocation = this.getLocation.bind(this);
    this.searchZipCode = this.searchZipCode.bind(this);
    this.findPlansForProvider = this.findPlansForProvider.bind(this);
    this.validateCoverage = this.validateCoverage.bind(this);
    this.validateDeductible = this.validateDeductible.bind(this);
    this.startOver = this.startOver.bind(this);

    this.quoteFormRef = React.createRef();
  }

  componentDidMount() {
    if ("geolocation" in navigator) {
      this.getLocation();
    }
  }

  // This function uses google geocode to get the user's zipcode if they allow it
  // If it is allowed, their zipcode will be pre-filled in the form
  getLocation() {
    var self = this;
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          var postalCode = response.results[0].address_components.find(
            (element) => element.types[0] == "postal_code"
          ).long_name;
          self.setState({
            zipCode: postalCode,
            locationLoading: false,
          });
        },
        (error) => {
          console.error(error);
          self.setState({
            locationLoading: false,
          });
        }
      );
    });
  }

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;

    if (name == "insurancePlan") {
      this.setState({
        [name]: value,
        insurance: value,
      });
    } else if (name == "deductible" && value == "I don't know") {
      this.setState({
        [name]: value,
        oopMax: value,
      });
    } else {
      this.setState({ [name]: value }, () => {
        if (name == "insuranceProvider") {
          this.findPlansForProvider(value);
        }

        if (name == "service") {
          this.setState({
            serviceError: "",
          });
        }

        if (name == "isDeductibleMet") {
          this.handleIsDeductibleMetChange(value);
        }
      });
    }

    if (name == "oopMax") {
      this.setState({
        oopMaxPercentage: this.calculateOopMaxPercentage(value),
        lowestOopMaxPercentageValue: this.calculateOopMaxPercentage(value) * 10,
      });
    }
  }

  handleIsDeductibleMetChange(value) {
    if (value == "false") {
      this.setState({
        isMaxOopMet: "false",
      });
    } else {
      this.setState({
        isMaxOopMet: "",
      });
    }
  }

  calculateOopMaxPercentage(value) {
    const deductible =
      this.state.deductible == "I don't know" ? 2000 : this.state.deductible;
    const deductiblePercentage =
      this.state.isDeductibleMet == "true"
        ? 1
        : this.state.deductiblePercentage;

    const deductiblePaid =
      deductiblePercentage == 0 ? 0 : deductible * deductiblePercentage;
    const percentageOfOopPaid =
      deductiblePaid == 0 ? deductiblePaid : deductiblePaid / value;
    const roundedPercentage = Math.round(percentageOfOopPaid * 10) / 10;
    return roundedPercentage;
  }

  _next() {
    let currentStep = this.state.currentStep;
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4 ? 5 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  // This function searches for the zipcode the user entered
  // If there are facilities offering the given service in the zipcode, it goes to the next step
  // If there are not, it shows an error screen
  searchZipCode(e) {
    e.stopPropagation();
    e.preventDefault();

    const zipCode = document.querySelector("input[name='zipCode']").value;
    if (zipCode == "") {
      console.log("No input");
    } else {
      let insuranceData;
      const URL =
        process.env.REACT_APP_API_URL +
        "insurance_providers/by_service_and_zip_code";
      axios
        .get(URL, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
          params: {
            zip_code: zipCode,
            service: this.state.service,
          },
        })
        .then((res) => {
          insuranceData = res.data;
          if (typeof insuranceData === "string") {
            this.setState({ currentStep: 999 });
          } else {
            this.setState({ rawInsuranceData: insuranceData });
            var greatestCost = Math.max.apply(
              Math,
              insuranceData.map(function (o) {
                return o.cost;
              })
            );
            this.setState({ greatestCost: greatestCost });

            var provider_data = insuranceData.map((i) => ({
              option: i.insurance_name,
              value: i.insurance_name,
            }));
            var unique_provider_data = [
              ...new Map(
                provider_data.map((item) => [item["option"], item])
              ).values(),
            ];
            this.setState({ providerList: unique_provider_data });
            this._next(e);
          }
        });
    }
  }

  findPlansForProvider(name) {
    var plans;
    var insurance;
    var unique_plans;

    if (name != "I do not have insurance") {
      if (name == "Medicare" || name == "Medicaid") {
        insurance = this.state.rawInsuranceData.filter(
          (i) => i.insurance_plan == name.toLowerCase()
        );
        plans = insurance.map((i) => ({
          option: i.issuer_legal_name,
          value: i.issuer_legal_name,
        }));
      } else {
        insurance = this.state.rawInsuranceData.filter(
          (i) => i.insurance_name == name
        );
        plans = insurance.map((i) => ({
          option: i.issuer_legal_name,
          value: i.issuer_legal_name,
        }));
      }

      unique_plans = [
        ...new Map(plans.map((item) => [item["option"], item])).values(),
      ];

      this.setState({
        plansList: unique_plans,
      });
    }
  }

  validateCoverage(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.insuranceProvider == "") {
      console.log("No provider input");
    } else if (this.state.insuranceProvider == "I do not have insurance") {
      this.setState({
        insurance: "None",
      });

      this.props.onSubmit(e);
    } else {
      if (this.state.insurancePlan == "") {
        console.log("No plan input");
      } else {
        this._next(e);
      }
    }
  }

  validateDeductible(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.deductible == "") {
      console.log("No deductible input");
    } else if (this.state.deductible == "I don't know") {
      this._next(e);
    } else {
      if (this.state.isDeductibleMet == "") {
        console.log("No is deductible met input");
      } else {
        if (this.state.isDeductibleMet == "true") {
        } else {
        }
        this._next(e);
      }
    }
  }

  startOver() {
    this.setState({
      currentStep: 1,
      service: "",
      zipCode: "",
    });

    this.props.updateProgress(0);
  }

  render() {
    if (!this.props.show) {
      return null;
    } else {
      return (
        <React.Fragment>
          <div className="service-quote-form">
            <Location
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              zipCode={this.state.zipCode}
              locationLoading={this.state.locationLoading}
              back={this.props.onClose}
              next={this.searchZipCode}
              serviceName={this.props.serviceName.toUpperCase()}
            />
            <Coverage
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              providerList={this.state.providerList}
              plansList={this.state.plansList}
              service={this.state.service}
              serviceName={this.state.serviceName}
              zipCode={this.state.zipCode}
              insuranceProvider={this.state.insuranceProvider}
              insurancePlan={this.state.insurancePlan}
              back={this._prev}
              next={this.validateCoverage}
            />
            <DeductibleAndOopMax
              labelClasses="bold text-medium pb-2 pr-5"
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              service={this.state.service}
              serviceName={this.state.serviceName}
              zipCode={this.state.zipCode}
              insurance={this.state.insurance}
              deductible={this.state.deductible}
              isDeductibleMet={this.state.isDeductibleMet}
              deductiblePercentage={this.state.deductiblePercentage}
              oopMax={this.state.oopMax}
              isMaxOopMet={this.state.isMaxOopMet}
              oopMaxPercentage={this.state.oopMaxPercentage}
              back={this._prev}
              next={this.props.onSubmit}
              submitButtonText="NEXT: SEE YOUR QUOTE"
              greatestCost={this.state.greatestCost}
              calculateOopMaxPercentage={this.calculateOopMaxPercentage}
              lowestOopMaxPercentageValue={
                this.state.lowestOopMaxPercentageValue
              }
              classes="quote-option deductible px-5 pb-5 mb-5 h-100"
            />
            <NotAvailable
              currentStep={this.state.currentStep}
              startOver={this.startOver}
            />
          </div>
        </React.Fragment>
      );
    }
  }
}

function Location(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    props.next(event);
    setValidated(true);
  };

  const overline = props.serviceName + " > MY COST ESTIMATE";

  if (props.currentStep !== 1) {
    return null;
  } else {
    return (
      <Mobile
        body={
          <div className="quote-option-section">
            <p className="text-xsmall">{overline}</p>
            <h3 className="bold section-name">Location</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="d-flex position-relative">
                <img
                  src={zipcode}
                  width="20"
                  height="20"
                  className="zipcode-icon location-modal-icon"
                />
                <TextInput
                  label="Where would you like to receive this service?"
                  formLabelClasses="bold text-medium"
                  type="text"
                  name="zipCode"
                  inputClasses="quote-option-input"
                  formGroupClasses="w-100"
                  value={props.zipCode}
                  onChange={props.handleChange}
                  autoFocus={true}
                />
                <div
                  className="right-arrow-input location-modal-icon"
                  id="arrow-icon"
                ></div>
              </div>
            </Form>
            <p className="text-small">Enter your zip code here.</p>
            <p>
              Location can play a big role in the cost of care. Your zip might
              have a surprising impact on the total bill.{" "}
            </p>
            <Button
              active={props.zipCode != null && props.zipCode != ""}
              class="mobile-estimate-form-btn long-btn with-shadow"
              onClick={handleSubmit}
              label="NEXT: INSURANCE DETAILS"
            />
          </div>
        }
        back={props.back}
        lastPage={props.serviceName}
        showQuoteNavigation={true}
        showMobileNav={true}
        mobileNavColor="white"
      />
    );
  }
}

function Coverage(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    if (props.insuranceProvider == "I do not have insurance") {
      getFacilities(event);
    } else {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }

      setValidated(true);
      props.next(event);
    }
  };

  const overline = props.serviceName.toUpperCase() + " > MY COST ESTIMATE";
  var noInsurance = props.insuranceProvider == "I do not have insurance";
  var providerInputted =
    props.insuranceProvider != "" && props.insuranceProvider != null;
  var planInputted = props.insurancePlan != "" && props.insurancePlan != null;
  var active = noInsurance ? true : providerInputted && planInputted;

  var hidePlan = providerInputted && !noInsurance ? false : true;
  var nextText = noInsurance ? "NEXT: SEE MY QUOTE" : "NEXT: DEDUCTIBLE";

  const history = useHistory();

  const getFacilities = (e) => {
    e.stopPropagation();
    e.preventDefault();

    history.push({
      pathname: "/facilities",
      state: {
        service: props.service,
        serviceName: props.serviceName,
        zipCode: props.zipCode,
        insuranceProvider: props.insuranceProvider,
        deductible: 0,
        isDeductibleMet: false,
        deductiblePercentage: 0,
        oopMax: 0,
        isMaxOopMet: false,
        oopMaxPercentage: 0,
        currentStep: 3,
      },
    });
  };

  if (props.currentStep !== 2) {
    return null;
  } else {
    return (
      <Mobile
        body={
          <div className="quote-option-section">
            <p className="text-xsmall">{overline}</p>
            <h3 className="bold section-name">Coverage</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <MobileSelect
                label="Choose your insurance provider below"
                formLabelClasses="bold text-medium pb-2 pr-5"
                selectClasses="quote-options-select w-100"
                name="insuranceProvider"
                options={props.providerList}
                placeholder="Select your option"
                description="This is the insurance company that you purchase health insurance from. If you don’t have insurance there’s an option for that too."
                onChange={props.handleChange}
                hide={false}
                value={props.insuranceProvider}
                smallScreen={true}
              />

              <MobileSelect
                label="Choose your insurance plan below"
                formLabelClasses="bold text-medium pb-2 pr-5 mr-5"
                selectClasses="quote-options-select w-100"
                name="insurancePlan"
                options={props.plansList}
                placeholder="Select your option"
                description="This is the specific type of health insurance plan you have."
                onChange={props.handleChange}
                hide={hidePlan}
                value={props.insurancePlan}
                smallScreen={true}
              />

              <Button
                active={active}
                class="mobile-estimate-form-btn long-btn with-shadow"
                onClick={handleSubmit}
                label={nextText}
              />
            </Form>
          </div>
        }
        back={props.back}
        lastPage="LOCATION"
        showQuoteNavigation={true}
        showMobileNav={true}
        mobileNavColor="white"
      />
    );
  }
}

function DeductibleAndOopMax(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    validateDeductibleAndOOPMax(event);
  };

  const history = useHistory();

  const getFacilities = (e) => {
    e.stopPropagation();
    e.preventDefault();

    history.push({
      pathname: "/facilities",
      state: {
        service: props.service,
        serviceName: props.serviceName,
        zipCode: props.zipCode,
        insuranceProvider: props.insurance,
        deductible:
          props.deductible == "I don't know" ? 2000 : props.deductible,
        isDeductibleMet: props.isDeductibleMet,
        deductiblePercentage:
          props.isDeductibleMet == "true" ? 1 : props.deductiblePercentage,
        oopMax: props.oopMax == "I don't know" ? 4000 : props.oopMax,
        isMaxOopMet: props.isMaxOopMet,
        oopMaxPercentage:
          props.isMaxOopMet == "true" ? 1 : props.oopMaxPercentage,
        currentStep: 5,
      },
    });
  };

  const validateDeductibleAndOOPMax = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (props.deductible == "I don't know") {
      getFacilities(e);
    } else {
      if (props.deductible == "") {
        console.log("No deductible input");
      } else {
        if (props.isDeductibleMet == "") {
          console.log("No is deductible met input");
        } else {
          if (hideOopMax) {
            getFacilities(e);
          } else {
            if (props.oopMax == "") {
              console.log("No oopMax input");
            } else {
              if (props.isMaxOopMet == "") {
                console.log("No is oop max met input");
              } else {
                getFacilities(e);
              }
            }
          }
        }
      }
    }
  };

  // Drop down options
  let deductibleOptions = [{ option: "I don't know", value: "I don't know" }];
  for (let i = 0; i <= 6000; i = i + 250) {
    const item = { option: "$" + i, value: i };
    deductibleOptions.push(item);
  }

  let oopMaxOptions = [{ option: "I don't know", value: "I don't know" }];
  var validDeductibleAmount =
    props.deductible != "" &&
    props.deductible != "none" &&
    props.deductible != "I don't know";
  var lowestOopValue = validDeductibleAmount ? parseInt(props.deductible) : 0;
  for (let i = lowestOopValue; i <= 6000; i = i + 250) {
    const item = { option: "$" + i, value: i };
    oopMaxOptions.push(item);
  }

  const percentageOptions = [];
  for (let i = 0; i <= 10; i = i + 1) {
    const item = {
      option: i == 0 ? "0%" : i + "0%",
      value: i == 10 ? "1" : "0." + i,
    };
    percentageOptions.push(item);
  }

  const isDeductibleMet = [
    { option: "Yes - I have met my deductible", value: true },
    { option: "No - I have not met my deductible", value: false },
  ];
  const isMaxOopMet = [
    { option: "Yes - I have met my out-of-pocket max", value: true },
    { option: "No - I have not met my out-of-pocket max", value: false },
  ];

  // Determine which inputs should be shown or hidden
  var hideIsDeductibleMet = false;
  var hideDeductiblePercentage = false;
  var hideOopMax = false;
  var hideIsOopMaxMet = false;
  var hideOopPercentage = false;
  var disableOPM = false;

  if (props.deductible == "") {
    // if the user has not yet entered their deductible amount
    hideIsDeductibleMet = true; // do not show any other inputs
    hideDeductiblePercentage = true;
    hideOopMax = true;
    hideIsOopMaxMet = true;
    hideOopPercentage = true;
  } else if (props.deductible == "I don't know") {
    // if the user selected idk for their deductible
    hideIsDeductibleMet = true; // hide all inputs except opm max amount
    hideDeductiblePercentage = true;
    hideIsOopMaxMet = true;
    hideOopPercentage = true;
    disableOPM = true;
  } else {
    // if the user selected anything other than idk for their deductible

    if (props.isDeductibleMet == "true") {
      // if the user has met their deductible
      hideDeductiblePercentage = true; // do not show the deductible percentage input
    }

    if (props.isDeductibleMet == "false") {
      // if the user not met their deductible
      hideOopPercentage = true; // do not show the opm percentage input
    }

    if (props.greatestCost < 2500 || props.deductible > props.greatestCost) {
      hideOopMax = true; // hide opm inputs if the above conditions are met
      hideIsOopMaxMet = true;
      hideOopPercentage = true;
    }

    if (props.oopMax == "") {
      // if the user has not yet entered their opm amount
      hideIsOopMaxMet = true; // do not show the other opm inputs
      hideOopPercentage = true;
    }
  }

  var isMaxOopMetValue;
  var disabledInput;
  if (props.isDeductibleMet == "false") {
    isMaxOopMetValue = props.isDeductibleMet;
    disabledInput = true;
  } else {
    isMaxOopMetValue = props.isMaxOopMet;
    disabledInput = false;
  }

  var oopMaxPercentageOptions = [];
  var lowestValue;
  if (!hideOopPercentage) {
    lowestValue = props.lowestOopMaxPercentageValue;
    for (let i = 0; i <= 10; i = i + 1) {
      const item = {
        option: i == 0 ? "0%" : i + "0%",
        value: i == 10 ? "1" : "0." + i,
        disabled: lowestValue > i ? true : false,
      };
      oopMaxPercentageOptions.push(item);
    }
  }

  // Heading and overline display
  const overline = props.serviceName.toUpperCase() + " > MY COST ESTIMATE";
  let classes = "";

  // IDK blurb display
  var deductibleText;
  if (props.deductible == "I don't know") {
    deductibleText = (
      <div className="text-small mx-2 mt-2 mb-4">
        See our glossary for more information about{" "}
        <Link
          className=""
          to={{
            pathname: "/glossary",
            state: { searchTerm: "deductible" },
          }}
        >
          deductibles
        </Link>
        . For now we have assumed your deductible is $2000 for{" "}
        <Link
          className=""
          to={{
            pathname: "/glossary",
            state: { searchTerm: "in-network" },
          }}
        >
          in-network
        </Link>{" "}
        care, based on the national average. You can update this for a more
        accurate search at any time.
      </div>
    );
  } else {
    deductibleText = (
      <Form.Text style={{ marginBottom: "16px" }}>
        Your deductible is the amount you pay for healthcare services before
        your health insurer starts paying its share.
      </Form.Text>
    );
  }

  var oopMaxText;
  if (hideOopMax == false) {
    if (props.oopMax == "I don't know") {
      oopMaxText = (
        <div className="text-small mx-2 mt-2 mb-4">
          See our glossary for more information about{" "}
          <Link
            className=""
            to={{
              pathname: "/glossary",
              state: { searchTerm: "out of pocket maximum" },
            }}
          >
            out of pocket maximums
          </Link>
          . For now we have assumed your out of pocket max is $4000 for{" "}
          <Link
            className=""
            to={{
              pathname: "/glossary",
              state: { searchTerm: "in-network" },
            }}
          >
            in-network
          </Link>{" "}
          care, based on the national average. You can update this for a more
          accurate search at any time.
        </div>
      );
    } else {
      oopMaxText = (
        <Form.Text>
          Out-of-pocket max is the most you can pay out of your own pocket for
          covered services in one year according to your plan.
        </Form.Text>
      );
    }
  }

  if (props.currentStep !== 3) {
    return null;
  } else {
    var deductibleFilled = props.deductible != null && props.deductible != "";
    var isDeductibleMetFilled =
      props.isDeductibleMet != null && props.isDeductibleMet != "";
    var deductibleInputsFilled =
      (deductibleFilled && isDeductibleMetFilled) ||
      props.deductible == "I don't know";

    var oopMaxFilled = props.oopMax != null && props.oopMax != "";
    var isMaxOopMetFilled =
      props.isMaxOopMet != null && props.isMaxOopMet != "";
    var oopInputsFilled =
      (oopMaxFilled && isMaxOopMetFilled) || props.oopMax == "I don't know";

    var active = deductibleInputsFilled && oopInputsFilled;

    return (
      <Mobile
        body={
          <div className="quote-option-section">
            <p className="text-xsmall">{overline}</p>
            <h3 className="bold section-name">Deductible</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <MobileSelect
                label="Select your insurance deductible"
                formGroupClasses={props.formGroupClasses}
                formLabelClasses={props.labelClasses}
                selectClasses="quote-options-select w-100"
                name="deductible"
                options={deductibleOptions}
                placeholder="Select your option"
                onChange={props.handleChange}
                hide={false}
                value={props.deductible}
                smallScreen={true}
                showArrow={props.showArrow}
              />
              {deductibleText}

              <MobileSelect
                label="Have you met your deductible?"
                formGroupClasses={props.formGroupClasses}
                formLabelClasses={props.labelClasses}
                selectClasses="quote-options-select w-100"
                name="isDeductibleMet"
                options={isDeductibleMet}
                placeholder="Select your option"
                description="Select the best option for you"
                onChange={props.handleChange}
                hide={hideIsDeductibleMet}
                value={props.isDeductibleMet}
                smallScreen={true}
                showArrow={props.showArrow}
              />

              <MobileSelect
                label="How much of your deductible have you paid off?"
                formGroupClasses={props.formGroupClasses}
                formLabelClasses={props.labelClasses}
                selectClasses="quote-options-select w-100"
                name="deductiblePercentage"
                options={percentageOptions}
                placeholder="Select your option"
                description="This is how much of your deductible you have paid so far."
                onChange={props.handleChange}
                hide={hideDeductiblePercentage}
                value={props.deductiblePercentage}
                smallScreen={true}
                showArrow={props.showArrow}
              />

              <MobileSelect
                label={
                  <span className="d-flex flex-wrap">
                    <span className="mr-1">Select your</span>{" "}
                    <span>Out-Of-Pocket Max</span>
                  </span>
                }
                formGroupClasses={props.formGroupClasses}
                formLabelClasses={props.labelClasses}
                selectClasses="quote-options-select w-100"
                name="oopMax"
                options={oopMaxOptions}
                placeholder="Select your option"
                onChange={props.handleChange}
                hide={hideOopMax}
                value={props.oopMax}
                smallScreen={true}
                showArrow={props.showArrow}
                disabled={disableOPM}
              />
              {oopMaxText}

              <MobileSelect
                label={
                  <span className="d-flex flex-wrap">
                    <span className="mr-1">Have you met your</span>{" "}
                    <span>Out-Of-Pocket Max?</span>
                  </span>
                }
                formGroupClasses={props.formGroupClasses}
                formLabelClasses={props.labelClasses}
                selectClasses="quote-options-select w-100 disabled"
                name="isMaxOopMet"
                options={isMaxOopMet}
                placeholder="Select your option"
                description="Select the best option for you."
                onChange={props.handleChange}
                hide={hideIsOopMaxMet}
                value={isMaxOopMetValue}
                smallScreen={true}
                showArrow={props.showArrow}
                disabled={disabledInput}
              />

              <MobileSelect
                label="How much of your Out-Of-Pocket Max have you paid off?"
                formGroupClasses={props.formGroupClasses}
                formLabelClasses={props.labelClasses}
                selectClasses="quote-options-select w-100"
                name="oopMaxPercentage"
                options={oopMaxPercentageOptions}
                placeholder="Select your option"
                description="This is how much of your out-of-pocket max you have paid so far."
                onChange={props.handleChange}
                hide={
                  hideOopPercentage ||
                  props.isMaxOopMet == "" ||
                  props.isMaxOopMet == "true"
                }
                value={props.oopMaxPercentage}
                smallScreen={true}
                showArrow={props.showArrow}
              />

              <Button
                active={active}
                class="mobile-estimate-form-btn long-btn with-shadow"
                onClick={handleSubmit}
                id="submit-deductible-btn"
                label={props.submitButtonText}
              />
            </Form>
          </div>
        }
        back={props.back}
        lastPage="COVERAGE"
        showQuoteNavigation={true}
        showMobileNav={true}
        mobileNavColor="white"
      />
    );
  }
}

function NotAvailable(props) {
  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY =
    process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, "\n");

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);

      document.getElementById("not-available").style.display = "none";
      document.getElementById("thank-you-container").style.display = "block";
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + "email_lists";
    axios
      .post(
        URL,
        {
          contact_first_name: name,
          contact_last_name: "",
          contact_email: email_address,
          query_type: "sign up",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {});
  };

  const handleClick = (e) => {
    const email_address = document.querySelector("input[name='email']").value;
    const name = document.querySelector("input[name='name']").value;
    const today = new Date();

    const newRow = {
      Email: email_address,
      Name: name,
      "Submitted On": today.toLocaleDateString("en-US"),
      "Query Type": "sign up",
    };

    appendSpreadsheet(newRow);
    createContact(email_address, name);
  };

  if (props.currentStep !== 999) {
    return null;
  } else {
    return (
      <div className="not-available">
        <div id="not-available">
          <img src="https://img.icons8.com/emoji/124/000000/eyes-emoji.png" />
          <p className="not-available-heading">
            Darn! We weren’t able to find that service in your area.{" "}
          </p>
          <p className="text-large m-0">
            Handl Health is still growing. Sign up to find out when we’ve
            reached your zip.
          </p>
          <section>
            <TextInput
              type="text"
              name="name"
              inputClasses="not-available-input"
              placeholder="Enter your name"
              formGroupClasses="w-100"
              onChange={props.handleChange}
              autoFocus={true}
            />
            <TextInput
              type="text"
              name="email"
              inputClasses="not-available-input"
              placeholder="Enter your email address"
              formGroupClasses="w-100"
              onChange={props.handleChange}
              autoFocus={true}
            />
          </section>

          <Button
            active={true}
            class="mobile-estimate-form-btn long-btn with-shadow"
            label="SIGN UP"
            onClick={handleClick}
          />

          <p className="start-over pointer" onClick={props.startOver}>
            Try another service.
          </p>
        </div>
        <div id="thank-you-container">
          <p className="thank-you-text">Thanks! We’ll be in touch.</p>
        </div>
      </div>
    );
  }
}

export default EstimateFormMobile;
