import './Facilities.css';
import FacilitiesDetails from '../../components/sections/FacilitiesDetails';
import { useLocation, useHistory } from "react-router-dom";

function Facilities(props) {
  const location = useLocation();
  const history = useHistory();

  const service = location.state.service;
  const serviceName = location.state.serviceName;
  const zipCode = location.state.zipCode;
  const insuranceProvider = location.state.insuranceProvider; 
  const deductible = location.state.deductible;
  const isDeductibleMet = location.state.isDeductibleMet == ''? false:location.state.isDeductibleMet;
  const deductiblePercentage = location.state.deductiblePercentage;
  const oopMax = location.state.oopMax;
  const isMaxOopMet = location.state.isMaxOopMet == ''? false:location.state.isMaxOopMet;
  const oopMaxPercentage = location.state.oopMaxPercentage;
  const currentStep = location.state.currentStep;

  const back = () => {
    if (location.state.new_service_page) {
      history.push({
        pathname: '/services/' + location.state.new_service_page,
        state: { 
          service: service, 
          serviceName: serviceName, 
          zipCode: zipCode, 
          insuranceProvider: insuranceProvider, 
          deductible: deductible, 
          isDeductibleMet: isDeductibleMet, 
          deductiblePercentage: deductiblePercentage, 
          oopMax: oopMax, 
          isMaxOopMet: isMaxOopMet, 
          oopMaxPercentage: oopMaxPercentage, 
          currentStep: currentStep }
      });

    } else {
      history.push({
        pathname: '/service_search',
        state: { 
          service: service, 
          serviceName: serviceName, 
          zipCode: zipCode, 
          insuranceProvider: insuranceProvider, 
          deductible: deductible, 
          isDeductibleMet: isDeductibleMet, 
          deductiblePercentage: deductiblePercentage, 
          oopMax: oopMax, 
          isMaxOopMet: isMaxOopMet, 
          oopMaxPercentage: oopMaxPercentage, 
          currentStep: currentStep }
      });
    }
  }
    
  // console.log("cpt code: ", service);
  // console.log("service name: ", serviceName);
  // console.log("zipCode: ", zipCode);
  // console.log("insuranceProvider: ", insuranceProvider);
  // console.log("deductible: ", deductible);
  // console.log("isDeductibleMet ", isDeductibleMet );
  // console.log("deductiblePercentage ", deductiblePercentage);
  // console.log("oopMax: ", oopMax);
  // console.log("isMaxOopMet: ", isMaxOopMet );
  // console.log("oopMaxPercentage: ", oopMaxPercentage);
  // console.log("currentStep: ", currentStep);

  return (
    <div>
      <FacilitiesDetails 
        back={back}
        service={service}
        serviceName={serviceName}
        zipCode={zipCode}
        insuranceProvider={insuranceProvider}
        deductible={deductible}
        isDeductibleMet={isDeductibleMet}
        deductiblePercentage={deductiblePercentage}
        oopMax={oopMax}
        isMaxOopMet={isMaxOopMet} 
        oopMaxPercentage={oopMaxPercentage} 
        currentStep={currentStep} />
    </div>
  );
}


export default Facilities;
