import React from "react";
import axios from "axios";
import { GoogleSpreadsheet } from "google-spreadsheet";
import Button from "../elements/Button";
import Input from "../elements/Input";
import "./NoEstimatesInYourArea.css";
import notAvailable from "../../assets/icons/notAvailable.svg";
import { useHistory } from "react-router-dom";
import EstimatesCatalog from "./EstimatesCatalog";

export default function NotAvailable(props) {
  const history = useHistory();
  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY =
    process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, "\n");

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);

      document.getElementById("not-available").style.display = "none";
      document.querySelector(".not-available").style.background = "transparent";
      document.getElementById("thank-you-container").style.display = "block";
      document.getElementById("thank-you-container").style.display = "block";
      document.getElementById("not-available-estimates-catalog").style.display =
        "block";
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + "email_lists";
    axios
      .post(
        URL,
        {
          contact_first_name: name,
          contact_last_name: "",
          contact_email: email_address,
          query_type: "sign up",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {});
  };

  const handleClick = (e) => {
    const email_address = document.querySelector("input[name='email']").value;
    const name = document.querySelector("input[name='name']").value;
    const today = new Date();

    const newRow = {
      Email: email_address,
      Name: name,
      "Submitted On": today.toLocaleDateString("en-US"),
      "Query Type": "sign up",
    };

    appendSpreadsheet(newRow);
    createContact(email_address, name);
  };

  return (
    <div className="not-available-main">
      <p
        onClick={() => {
          history.push(
            window.location.href.includes("estimates") ? "/" : "/estimates"
          );
        }}
        className="body-2 pointer"
      >
        <span className="back-arrow" /> BACK
      </p>
      <div className="not-available">
        <div id="not-available">
          <img
            className="mb-3"
            src={notAvailable}
            alt="Not available googly eyes"
          />
          <p className="text-large fw-600">
            Darn! We weren’t able to find that service in your area.{" "}
          </p>
          <p
            style={{ maxWidth: "620px", width: "100%" }}
            className="body-1 m-0"
          >
            Handl Health is still growing. Sign up to find out when we’ve
            reached your zip.
          </p>
          <section>
            <Input
              type="text"
              name="name"
              inputContainerClasses="update-zipcode-modal__input"
              placeholder="Name"
              formGroupClasses="w-100"
              onChange={props.handleChange}
              autoFocus={true}
            />

            <Input
              type="text"
              name="email"
              inputContainerClasses="update-zipcode-modal__input"
              placeholder="Email address"
              formGroupClasses="w-100"
              onChange={props.handleChange}
              autoFocus={true}
            />
          </section>

          <Button
            active={true}
            class="long-btn filled"
            label="Submit"
            onClick={handleClick}
          />
        </div>
        <div id="thank-you-container">
          <p className="text-large">Thank you, we’ll be in touch!</p>
          <div className="search-another-estimate-btn-container">
            <Button
              active={true}
              class="long-btn filled"
              label="Search Another Estimate"
              onClick={() => history.push("/estimates")}
            />
          </div>
        </div>
      </div>
      <div id="not-available-estimates-catalog">
        <EstimatesCatalog heading="need help with your search?" />
      </div>
    </div>
  );
}
