import React, { useState, useEffect } from "react";
import Button from "../../components/elements/Button";
import left from "../../assets/icons/left.svg";
import PopupModal from "../../components/templates/PopupModal";
import "./SubmitBill.css";
import SubmissionDetails from "../../components/sections/Billing/SubmissionDetails";

export default function SubmitBill({
  setCurrentScreen,
  billingRecord,
  updateBillingRecord,
}) {
  const [smallScreen, setSmallScreen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [visitDetails, setVisitDetails] = useState(
    billingRecord?.visit_details ?? ""
  );
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleChange(e) {
    setVisitDetails(e.target.value);
  }

  const SubmissionModal = () => {
    function closeModal() {
      setShowModal(false);
    }
    return (
      <PopupModal
        noPadding
        show={showModal}
        close={closeModal}
        size="lg"
        dialogClassName="submit-bill-modal"
        position="centered"
        body={
          <div className="">
            <p className="sign-up__modal-header">
              Are you ready to submit this bill for review?
            </p>
            <p className="sign-up__modal-text">
              Add any extra details that might be helpful for us to know before
              submitting this bill. After you submit, keep an eye out for
              updates from us in your account.
            </p>
            <div className="submit-bill-modal__action-buttons">
              <Button
                label="Confirm bill submission"
                active={true}
                class="long-btn filled"
                onClick={() =>
                  updateBillingRecord({
                    visit_details: visitDetails !== "" ? visitDetails : null,
                    status: "submitted",
                    submitted_on: new Date(),
                  })
                }
              />
              <Button
                label="Return to bill"
                active={true}
                class="outline-btn-blue"
                onClick={() => setShowModal(false)}
              />
            </div>
          </div>
        }
      />
    );
  };
  return (
    <div className="hipaa-form">
      {SubmissionModal()}
      <div
        className={smallScreen === true ? "bill-tracker__top-container" : ""}
      >
        <Button
          iconFirst
          icon={<img style={{ marginRight: "11px" }} src={left} />}
          class="text-btn"
          className={`mobile-search__close 
            width-904 hipaa-form__return-to-bill`}
          label="Return to bill"
          onClick={() => {
            setCurrentScreen("bill tracker");
          }}
          active={true}
        />
        <div className="hipaa-form__text-container width-904">
          <h5
            style={{ marginBottom: "24px" }}
            className={`hipaa-form__text-container-heading fw-600
          `}
          >
            Submit your bill
          </h5>
        </div>
      </div>
      <SubmissionDetails billingRecord={billingRecord} />
      <section
        style={smallScreen ? { paddingInline: "16px" } : {}}
        className="hipaa-form__section"
      >
        <>
          <p className="hipaa-form__heading body-1">Visit details (optional)</p>
          <div className="flex-row" style={{ justifyContent: "center" }}>
            <textarea
              value={visitDetails}
              spellCheck={true}
              className="submit-bill__text-area"
              placeholder="Please share any other details you’d like us to know about your bill."
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </>
      </section>
      <div style={{ paddingBottom: "40px" }}>
        <Button
          active={true}
          label="Submit bill"
          onClick={() => setShowModal(true)}
          class="long-btn filled"
        />
      </div>
    </div>
  );
}
