import React, { Component } from "react";
import "./AutocompleteInput.css";
import Form from "react-bootstrap/Form";
import { Service } from "../../modules/ListItem";
import down from "../../../assets/icons/down.svg";

class AutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      topResult: "",
      suggestions: this.props.suggestions,
    };

    this.displaySuggestions = this.displaySuggestions.bind(this);
    this.formRef = React.createRef();
    this.handleClickOutsideInput = this.handleClickOutsideInput.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutsideInput);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideInput);
  }
  componentDidUpdate() {
    let filteredSuggestions;
    const { suggestions, name } = this.props;
    const { userInput } = this.state;

    if (userInput != "" && name == "insurancePlan") {
      filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.option.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      if (!filteredSuggestions.length) {
        this.setState({ userInput: "" });
      }
    }
  }

  onChange = (e) => {
    if (this.props.resetClickedService) {
      this.props.resetClickedService();
    }

    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    let filteredSuggestions;
    let topResult;

    if (this.props.name == "service") {
      filteredSuggestions = suggestions;
    } else {
      filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.option.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
    }

    if (
      this.props.searchCoverage &&
      (this.props.name == "insuranceProvider" ||
        this.props.name == "insurancePlan")
    ) {
      this.props.searchCoverage(e.target.value);
    } else if (this.props.selectService) {
      this.props.selectService(e.target.value);
    }

    if (this.props.name == "insuranceProvider") {
      if (document.querySelector(".insurance-plan-suggestions")) {
        document.querySelector(".insurance-plan-suggestions").style.display =
          "none";
      }
    } else if (this.props.name == "insurancePlan") {
      if (document.querySelector(".insurance-provider-suggestions")) {
        document.querySelector(
          ".insurance-provider-suggestions"
        ).style.display = "none";
      }
    }

    if (filteredSuggestions[0]) {
      topResult = filteredSuggestions[0].name;
    } else {
      topResult = "";
    }

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
      topResult: topResult,
    });
  };

  onClick = (e) => {
    let clickedElement =
      this.props.name == "service" ? e.target.parentElement.id : e.target.id;

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: clickedElement,
      topResult: clickedElement,
    });
    if (
      this.props.searchCoverage &&
      (this.props.name == "insuranceProvider" ||
        this.props.name == "insurancePlan")
    ) {
      this.props.searchCoverage(clickedElement);
    } else if (this.props.selectService) {
      this.props.selectService(clickedElement);
      this.props.selectServiceName(clickedElement);
    }
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    if (e.keyCode === 13) {
      if (this.props.smallScreen) {
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
        });
      } else {
        if (this.state.showSuggestions == true) {
          e.preventDefault();
        }

        if (document.querySelector(".suggestion-active")) {
          const highlightedSuggestion =
            document.querySelector(".suggestion-active").innerText;
          if (this.props.selectService) {
            this.props.selectService(highlightedSuggestion);
            this.props.selectServiceName(highlightedSuggestion);
          }
          this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
            userInput: highlightedSuggestion,
          });
        } else if (document.querySelector("li.suggestion-active")) {
          var enteredSuggestion = document.querySelector(
            "li.suggestion-active"
          ).innerText;
          this.props.searchCoverage(enteredSuggestion);

          this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
            userInput: enteredSuggestion,
          });
        } else {
          this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
          });
        }
      }
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  displaySuggestions = () => {
    this.setState({
      showSuggestions: !this.state.showSuggestions,
    });

    if (this.props.name == "insuranceProvider") {
      if (document.querySelector(".insurance-plan-suggestions")) {
        document.querySelector(".insurance-plan-suggestions").style.display =
          "none";
      }

      if (document.querySelector(".insurance-provider-suggestions")) {
        document.querySelector(
          ".insurance-provider-suggestions"
        ).style.display = "block";
      }
    } else if (this.props.name == "insurancePlan") {
      if (document.querySelector(".insurance-provider-suggestions")) {
        document.querySelector(
          ".insurance-provider-suggestions"
        ).style.display = "none";
      }

      if (document.querySelector(".insurance-plan-suggestions")) {
        document.querySelector(".insurance-plan-suggestions").style.display =
          "block";
      }
    }
  };

  handleClickOutsideInput(e) {
    if (this.formRef && !this.formRef?.current?.contains(e.target)) {
      this.setState({
        showSuggestions: false,
      });
    }
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    var icon = null;

    if (this.props.showDropdownArrow == true) {
      icon = <img src={down} className="form-input-icon" />;
    }
    if (showSuggestions) {
      var suggestionsList = [];
      if (filteredSuggestions.length) {
        suggestionsList = filteredSuggestions;
      } else {
        suggestionsList = this.props.suggestions;
      }
      suggestionsListComponent = (
        <ul className={this.props.suggestionClasses}>
          {suggestionsList.map((suggestion, index) => {
            if (this.props.name == "service") {
              let className =
                index === activeSuggestion ? "suggestion-active" : "";
              let pathName = "/service_search";
              return (
                <Service
                  key={suggestion.cpt_code}
                  name={suggestion.name}
                  displayName={suggestion.display_name}
                  classes={className}
                  onClick={onClick}
                  path={pathName}
                  linked={this.props.smallScreen}
                />
              );
            } else {
              let className =
                index === activeSuggestion ? "suggestion-active" : "";
              return (
                <li
                  key={suggestion.option}
                  id={suggestion.value}
                  name={suggestion.value}
                  className={className}
                  onClick={onClick}
                >
                  {suggestion.option}
                </li>
              );
            }
          })}
        </ul>
      );
    }
    if (this.props.hide == true) {
      return null;
    } else {
      return (
        <div className={this.props.containerClasses} ref={this.formRef}>
          <Form.Group
            controlId={this.props.name}
            className={this.props.formGroupClasses}
            onClick={this.displaySuggestions}
          >
            <Form.Label className={this.props.formLabelClasses}>
              {this.props.label}
            </Form.Label>
            <Form.Control
              name={this.props.name}
              type="text"
              className={this.props.inputClasses}
              placeholder={this.props.placeholder}
              value={this.props.value}
              onBlur={this.props.onBlur}
              onInput={this.props.onInput}
              onChange={onChange}
              onKeyDown={onKeyDown}
              autoComplete="off"
              required
              autoFocus
            />
            {icon}

            <Form.Label className={this.props.formLabelClasses}>
              {this.props.label}
            </Form.Label>
          </Form.Group>
          {suggestionsListComponent ? null : this.props.errorMessage}
          {suggestionsListComponent}
        </div>
      );
    }
  }
}

export default AutocompleteInput;
