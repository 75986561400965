import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./Home/LandingPage.js";
// import Home from './Home.js';
// import ServiceEstimate from './ServiceEstimate.js';
import Estimate from "./Estimate/Estimate.js";
import ServiceSearch from "./MobileSearch/ServiceSearch.js";
import ServiceGroupSearch from "./MobileSearch/ServiceGroupSearch.js";
import Facilities from "./FacilityResults/Facilities.js";
import Facility from "./Facility/Facility.js";
import Contact from "./Contact/Contact.js";
import AboutUs from "./AboutUs/AboutUs.js";
import Glossary from "./Glossary/Glossary.js";
import Search from "./MobileSearch/Search.js";
import Articles from "./Articles/Articles.js";
import PressReleases from "./PressReleases/PressReleases.js";
import ServiceGroup from "./ServiceGroup/ServiceGroup.js";
import Service from "./Service/Service.js";
import ServiceIndex from "./ServiceIndex/ServiceIndex.js";
import SignUp from "./SignUp/SignUp.js";
import SignIn from "./SignIn/SignIn.js";
import ForgotPassword from "./SignIn/ForgotPassword";
import UnlockAccount from "./SignIn/UnlockAccount";
import Settings from "./Settings/Settings.js";
import useToken from "../hooks/useToken";
import PrivateRoute from "./PrivateRoute.js";
import Onboarding from "./Billing/Onboarding.js";
import BillingDashboard from "./BillingDashboard/BillingDashboard.js";
import BillTracker from "./BillingDashboard/BillTracker.js";
import CompletedBillDetails from "./BillingDashboard/CompletedBillDetails.js";
import Home from "./Home/Home.js";
import ScrollToTop from "../ScrollToTop.js";
import { MemoizedUserNavigation } from "../components/sections/Navigation.js";

import Terms from "./Legalese/Terms.js";
import Privacy from "./Legalese/Privacy.js";
function Directory() {
  const { token, setToken } = useToken();

  useEffect(() => {
    document.title = "Handl Health";
  }, []);

  return (
    <div className="h-100">
      <BrowserRouter>
        <ScrollToTop />
        <MemoizedUserNavigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/service_search" component={ServiceSearch} />
          <Route exact path="/estimates" component={LandingPage} />

          <Route
            exact
            path="/service_group_search"
            component={ServiceGroupSearch}
          />
          <Route exact path="/estimate" component={Estimate} />
          <Route exact path="/facilities" component={Facilities} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about_us" component={AboutUs} />
          <Route exact path="/glossary" component={Glossary} />
          <Route exact path="/search" component={Search} />
          <Route path="/articles" component={Articles} />
          <Route exact path="/press" component={PressReleases} />
          <Route exact path="/sign_up" component={SignUp} />
          <Route
            exact
            path="/sign_in"
            component={() => <SignIn setToken={setToken} />}
          />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route
            path="/settings"
            render={(props) => (
              <PrivateRoute path="/settings" component={Settings} />
            )}
          />
          <Route exact path="/onboarding" component={Onboarding} />
          <Route
            path="/billing"
            render={(props) => (
              <PrivateRoute path="/billing" component={BillingDashboard} />
            )}
          />
          <Route
            path="/billing_tracker/:id"
            render={(props) => (
              <PrivateRoute
                path="/billing_tracker/:id"
                component={BillTracker}
              />
            )}
          />
          <Route
            path="/bill_details/:id"
            render={(props) => (
              <PrivateRoute
                path="/bill_details/:id"
                component={CompletedBillDetails}
              />
            )}
          />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Directory;
