import React, { useState, useEffect } from "react";
import Button from "../../elements/Button";
import "./GoHandlHealth.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import leftCarouselArrow from "../../../assets/icons/leftCarouselArrow.svg";
import rightCarouselArrow from "../../../assets/icons/rightCarouselArrow.svg";
import right from "../../../assets/icons/right.svg";
import left from "../../../assets/icons/left.svg";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

export default function GoHandlHealthCards() {
  const [smallScreen, setSmallScreen] = useState(false);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const content = [
    {
      title: "Health Plans & TPAs",
      description:
        "Comply with legislation to avoid fines, but the real value is going beyong complaince and drive value for your clients and members, increasing retention and satisfaction.",
    },
    {
      title: "Providers",
      description:
        "Reduce the burden of meeting complaince with out of the box solutions that meet requirements while increasing patient trust and loyalty.",
    },
    {
      title: "Brokers",
      description:
        "Keep your current clients happy and attract new business with Handl Health’s innovative and intuitive benefits and concierge platform. No network changes, while saving 30%.",
    },
  ];

  const GOHANDLURL = "https://www.gohandlhealth.com/";
  return (
    <div className="go-handl-health">
      <p className="fw-500 go-handl-title">
        Not a patient? Handl is here to help.
      </p>
      <div className="go-handl-health-card-container">
        <img
          src={smallScreen ? left : leftCarouselArrow}
          className="custom-ghh-swiper-button-prev"
        />

        <Swiper
          slidesPerView={smallScreen ? 1 : 3}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: ".custom-ghh-swiper-button-next",
            prevEl: ".custom-ghh-swiper-button-prev",
          }}
          pagination={smallScreen && { clickable: true }}
        >
          {content.map((element) => (
            <SwiperSlide key={element.title}>
              <div className="go-handl-health-card">
                <p className="margin-bottom-0 text-large color-dark-blue">
                  {element.title}
                </p>
                <p
                  className="margin-bottom-0 body-3 center-text"
                  style={{ minHeight: "144px" }}
                >
                  {element.description}
                </p>
                <a
                  style={{ display: "contents" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    element.title === "Health Plans & TPAs"
                      ? GOHANDLURL + "health-plans"
                      : GOHANDLURL +
                        (element.title.charAt(0).toLowerCase() +
                          element.title.slice(1))
                  }
                >
                  <Button
                    active={true}
                    label="Learn More"
                    class="long-btn outline-btn-blue"
                  />
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <img
          src={smallScreen ? right : rightCarouselArrow}
          className="custom-ghh-swiper-button-next"
        />
      </div>
    </div>
  );
}
