import ServiceGroupDetails from '../../components/sections/ServiceGroupDetails'
import Mobile from '../../components/templates/Mobile'
import './ServiceSearch.css'
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';

function ServiceGroupSearch() {
  const location = useLocation();
  const history = useHistory();
  
  const onClick = () => {
    history.push("/");
  }

  const group = location.state.group;

  return (
    <Mobile 
      body={<ServiceGroupDetails group={group}/>}
      back={onClick} 
      showQuoteNavigation={true} 
      lastPage='SEARCH'
      showMobileNav={true} 
      mobileNavColor='white'
    />
  ); 
}

export default ServiceGroupSearch;
