import React, { useState, useEffect } from 'react';
import { Redirect, Route, useLocation } from "react-router-dom";
import useToken from '../hooks/useToken';
import axios from 'axios';
import Loading from '../components/modules/Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const { token } = useToken();
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    authenticateToken();
  }, []);

  function authenticateToken() {
    const URL = process.env.REACT_APP_DEVISE_API_URL + 'users/users';
    axios.get(URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    .then(res => {
      if (res.status == 200) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    }).catch(function(error) {
      setAuth(false);
    });
  };

  if(auth == null) {
    return <Loading />;
  }

  return (
    <Route {...rest}>
      {auth == true ?
        <Component />
      :
        <Redirect to={{ pathname: "/sign_in", state: { from: location } }} />
      }
    </Route>
  );
};

export default PrivateRoute;
