import './Paragraph.css'

function Paragraph(props){
  return (
     <p className={props.textStyle} onClick={props.onClick}>
      {props.text}
    </p>
  );
}

export default Paragraph;
