import React, { useEffect, useState } from "react";
import "./ArticleCardsCarousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Link, useHistory } from "react-router-dom";
import Button from "../../elements/Button";
import leftCarouselArrow from "../../../assets/icons/leftCarouselArrow.svg";
import rightCarouselArrow from "../../../assets/icons/rightCarouselArrow.svg";
import right from "../../../assets/icons/right.svg";
import left from "../../../assets/icons/left.svg";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

export default function ArticleCardsCarousel({ articles }) {
  const [smallScreen, setSmallScreen] = useState(false);
  const history = useHistory();
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  return (
    <div className="article-card">
      <div className="article-card-content">
        <div className="article-card__text-container">
          {!smallScreen ? (
            <h5>Recommended reading</h5>
          ) : (
            <p className="subheading-crimson-pro margin-bottom-8">
              Recommended reading
            </p>
          )}
          <p className={"text-medium fw-400"}>
            Stay in the loop with what’s happening in healthcare.
          </p>
        </div>
        <img
          src={smallScreen ? left : leftCarouselArrow}
          className="custom-ac-swiper-button-prev"
        />

        <Swiper
          slidesPerView={smallScreen ? 1 : 2}
          spaceBetween={16}
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: ".custom-ac-swiper-button-next",
            prevEl: ".custom-ac-swiper-button-prev",
          }}
          pagination={smallScreen && { clickable: true }}
        >
          {shuffle(articles)
            ?.slice(-3)
            .map((article) => (
              <SwiperSlide key={article?.id}>
                <div key={article?.id} className="article-card-container">
                  <Link to={`/articles/${article?.attributes?.url}`}>
                    <div>
                      <img
                        loading="lazy"
                        className="article-card-container__img"
                        src={
                          article?.attributes?.mini_header_image ??
                          article?.attributes.header_image
                        }
                        alt="Article header image"
                      />
                      <div>
                        <p
                          style={{ marginBottom: "12px" }}
                          className={"heading-poppins fw-600"}
                        >
                          {article?.attributes.title}
                        </p>
                        <p style={{ marginBottom: "8px" }} className={"body-3"}>
                          {article?.attributes.date +
                            " · " +
                            article?.attributes.article_keywords
                              .slice(0, 2)
                              .join(", ")}
                        </p>{" "}
                        <div className="article-card-container_author-section">
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                              borderRadius: "50%",
                            }}
                            src={article?.attributes.author_image}
                            loading="lazy"
                            alt="Author image"
                          />
                          <p
                            className={"body-3 margin-bottom-0"}
                            style={{ letterSpacing: "0.6px" }}
                          >
                            {article?.attributes.author_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <img
          src={smallScreen ? right : rightCarouselArrow}
          className="custom-ac-swiper-button-next"
        />

        {smallScreen && (
          <Button
            label="See all articles"
            class="text-btn"
            className="article-card__see-all-btn"
            active="active"
            onClick={() => history.push("/articles")}
          />
        )}
      </div>
    </div>
  );
}
