import "./FacilitiesDetails.css";
import Paragraph from "../elements/Paragraph";
import Button from "../elements/Button";
import Filter from "../elements/Filter";
import TextInput from "../elements/inputs/TextInput";
import { Facility, DesktopFacility } from "../modules/ListItem";
import Loading from "../modules/Loading";
import Mobile from "../templates/Mobile";
import PopupModal from "../templates/PopupModal";
import Desktop from "../templates/Desktop";
import zipcode from "../../assets/icons/zipcode.png";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import ShowMore from "../modules/ShowMore";
import Input from "../elements/Input";
import down from "../../assets/icons/down.svg";
import Footer from "../sections/Footer";
import NotAvailable from "./NoEstimatesInYourArea";
import DashboardTopContainer from "./DashboardTopContainer";
import { withRouter } from "react-router-dom";

class FacilitiesDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      service: this.props.service,
      zipCode: this.props.zipCode,
      updatedZipCode: "",
      insuranceProvider: this.props.insuranceProvider,
      deductible: this.props.deductible,
      isDeductibleMet: this.props.isDeductibleMet,
      deductiblePercentage: this.props.deductiblePercentage,
      oopMax: this.props.oopMax,
      isMaxOopMet: this.props.isMaxOopMet,
      oopMaxPercentage: this.props.oopMaxPercentage,
      facilities: [],
      show: false,
      facilitiesToShow: 5,
      outOfFacilities: false,
      serviceName: this.props.serviceName,
      filterLabel: "Recommended",
      loading: true,
      smallScreen: true,
      currentPage: 0,
      showErrorScreen: false,
    };

    this.updateZipCode = this.updateZipCode.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showMoreFacilities = this.showMoreFacilities.bind(this);
    this.getFacilitiesList = this.getFacilitiesList.bind(this);
    this.checkDevice = this.checkDevice.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  checkDevice() {
    const resolution = window.innerWidth;
    const smallScreen = resolution <= 768;
    this.setState({
      smallScreen: smallScreen,
    });
  }

  updateZipCode(e) {
    e.stopPropagation();
    e.preventDefault();
    this.showModal();

    const zipCode = e.target[0].value;

    this.setState({ zipCode: zipCode, loading: true }, () => {
      this.getFacilitiesList();
    });
  }

  getFacilitiesList() {
    const URL = process.env.REACT_APP_API_URL + "facilities/search";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          service: this.state.service,
          zip_code: this.state.zipCode,
          insurance_provider: this.state.insuranceProvider,
          deductible: this.state.deductible,
          is_deductible_met: this.state.isDeductibleMet,
          deductible_percentage: this.state.deductiblePercentage,
          max_oop: this.state.oopMax,
          is_max_oop_met: this.state.isMaxOopMet,
          max_oop_percentage: this.state.oopMaxPercentage,
        },
      })
      .then((res) => {
        if (res.data === "No facilities found") {
          this.setState({
            showErrorScreen: true,
            facilities: [],
            loading: false,
          });
        } else {
          const facilities = res.data;
          this.setState({ facilities, loading: false });
        }
      })
      .catch(function (error) {
        console.error(error.message);
      });
  }

  showModal = () => {
    this.setState({ show: !this.state.show });
  };

  showMoreFacilities() {
    if (this.state.facilitiesToShow !== this.state.facilities.length) {
      this.setState({
        facilitiesToShow:
          this.state.facilitiesToShow +
          Math.min(
            5,
            this.state.facilities.length - this.state.facilitiesToShow
          ),
      });
    }
  }

  onClick = (e) => {
    const sortBy = e.currentTarget.innerHTML;

    this.setState({ filterLabel: sortBy });

    if (sortBy == "Recommended") {
      this.setState({
        facilities: this.state.facilities.sort(
          (a, b) => +a.sort_rank - +b.sort_rank
        ),
      });
    } else if (sortBy == "Best Price") {
      this.setState({
        facilities: this.state.facilities.sort(
          (a, b) => +parseInt(a.raw_user_cost) - +parseInt(b.raw_user_cost)
        ),
      });
    } else if (sortBy == "A-Z") {
      this.setState({
        facilities: this.state.facilities.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      });
    } else if (sortBy == "Distance") {
      this.setState({
        facilities: this.state.facilities.sort(
          (a, b) => +a.distance - +b.distance
        ),
      });
    }
  };

  handlePageClick({ selected: selectedPage }) {
    this.setState({
      currentPage: selectedPage,
    });
  }

  componentDidMount() {
    this.getFacilitiesList();
    this.checkDevice();

    window.addEventListener("resize", this.checkDevice.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkDevice.bind(this));
  }

  render() {
    const overline =
      this.state.serviceName.toUpperCase() + " > MY COST ESTIMATE";
    const sortOptions = ["Recommended", "Best Price", "A-Z", "Distance"];

    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }

    let showMoreFacilitiesButton;

    if (this.state.facilitiesToShow >= this.state.facilities.length) {
      showMoreFacilitiesButton = null;
    } else {
      showMoreFacilitiesButton = (
        <div className="px-5 text-center show-more-facilities-btn">
          <Button
            active={true}
            class="mobile-estimate-form-btn long-btn with-shadow"
            onClick={this.showMoreFacilities}
            label="SHOW MORE FACILITIES"
            hide={this.state.facilitiesToShow >= this.state.facilities.length}
          />
        </div>
      );
    }

    {
      const currentPageData = this.state.facilities
        .slice(0, this.state.facilitiesToShow)
        .map((item) => (
          <Link
            key={item.name}
            to={{
              pathname: "/estimate",
              state: {
                data: {
                  service: this.state.service,
                  serviceName: this.state.serviceName,
                  zipCode: this.state.zipCode,
                  insuranceProvider: this.state.insuranceProvider,
                  deductible: this.state.deductible,
                  isDeductibleMet: this.state.isDeductibleMet,
                  deductiblePercentage: this.state.deductiblePercentage,
                  oopMax: this.state.oopMax,
                  isMaxOopMet: this.state.isMaxOopMet,
                  oopMaxPercentage: this.state.oopMaxPercentage,
                  facility: item.name,
                  facilityDetail: item.detail,
                  grossCost: item.user_cost,
                  facilitiesList: this.state.facilities,
                  currentStep: this.props.currentStep,
                },
              },
            }}
          >
            {this.state.smallScreen === true ? (
              <Facility
                user_cost={item.user_cost}
                name={item.name}
                address1={item.address1}
                address2={item.address2}
                detail={item.detail}
                classes="facilities-list-facility"
                showOverline={true}
              />
            ) : (
              <DesktopFacility
                user_cost={item.user_cost}
                name={item.name}
                address1={item.address1}
                address2={item.address2}
                detail={item.detail}
                classes="facilities-list-facility"
              />
            )}
          </Link>
        ));
      const filled =
        (Math.min(this.state.facilities.length, this.state.facilitiesToShow) /
          this.state.facilities.length) *
        100;

      return (
        <div className="flex-wrapper-space-between facilities-details">
          <div className="facilities-details-container">
            <PopupModal
              size="lg"
              position="centered"
              show={this.state.show}
              close={this.showModal}
              dialogClassName="update-zipcode-modal"
              body={
                <form
                  className="update-zipcode-modal__body"
                  onSubmit={this.updateZipCode}
                >
                  <h3 className="fw-700">Change your location</h3>
                  <div style={{ marginTop: "16px" }}>
                    <p className={"body-2"}>
                      Changing your location will show a new list of facilities
                      within a ten mile radius of the zip code of your choosing.
                    </p>
                    <p className="body-2">
                      Current zip code: <b>{this.state.zipCode}</b>
                    </p>
                    <div className="d-flex position-relative">
                      <Input
                        type="text"
                        name="updatedZipCode"
                        inputContainerClasses="update-zipcode-modal__input"
                        placeholder={"Enter your zip code"}
                        value={this.state.updatedZipCode}
                        onChange={(e) =>
                          this.setState({ updatedZipCode: e.target.value })
                        }
                      />
                    </div>

                    <Button
                      active={true}
                      type="submit"
                      class="long-btn filled"
                      label="Update location"
                    />
                  </div>
                </form>
              }
            />
            {this.state.showErrorScreen === true ? (
              <>
                <NotAvailable />
              </>
            ) : (
              <div>
                <p
                  onClick={() => {
                    this.props.history.push("/estimates");
                  }}
                  className="body-2 pointer"
                >
                  <span className="back-arrow" /> BACK
                </p>
                <h1
                  className={`bold  ${
                    this.state.smallScreen
                      ? "facilities-list-mobile-title"
                      : "pb-5"
                  }`}
                >
                  Choose your facility in{" "}
                  <u onClick={this.showModal} className="pointer">
                    {this.state.zipCode}
                  </u>
                </h1>
                <Filter
                  customClass
                  noPadding
                  className="filter-option-active"
                  icon={<img style={{ marginRight: "9px" }} src={down} />}
                  label={<b>{this.state.filterLabel}</b>}
                  onClick={this.onClick}
                  dropdownItems={sortOptions}
                />
                <div className="facilities-list">{currentPageData}</div>
                <ShowMore
                  type="estimates"
                  toShow={this.state.facilitiesToShow}
                  totalCount={this.state.facilities.length}
                  filled={filled}
                  showMoreHandler={this.showMoreFacilities}
                />

                <p className="small facilities-disclaimer">
                  This is an estimated cost for the service you are seeking.
                  This is not a guarantee of insurance coverage or the total
                  bill with the healthcare provider. Please check with your
                  health insurance company and the healthcare provider you
                  selected to confirm the cost.
                </p>
              </div>
            )}
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default withRouter(FacilitiesDetails);
