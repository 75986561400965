import React, {useState} from 'react'
import Button from '../../components/elements/Button';
import Input from '../../components/elements/Input';


function SubmitAccessCode (props) {

    return (
      <div>
        {props.header}
        <form className='forgot-password-form' onSubmit={(e) =>props.onSubmit(e)} noValidate>
          <Input
            placeholder="7-digit code"
            name="access-code"
            label="7-digit code"
            value={props.accessCode}
            onChange={(e) => props.onChange(e)}
            inputContainerClasses='sign-in-form__input'
            showArrow={false}
            readOnly={false}
            errorMessage="Hmm, that isn't the code we sent you."
            validMessage="Valid code."
            showErrorMessage={props.accessCodeIsValid == false}
            showValidMessage={props.accessCodeIsValid == true}
          /> 

          <Button 
            label="Submit"
            active={props.accessCode != ''}
            class="long-btn filled"
          />
        </form>
      </div>
    )
  }

  export default SubmitAccessCode