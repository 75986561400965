import { Link } from "react-router-dom";
import "./ArticleCard.css";

function ArticleCard({ article }) {
  return (
    <div key={article?.id} className="article-card-container">
      <Link to={`/articles/${article?.url}`}>
        <div>
          <img
            loading="lazy"
            className="article-card-container__img"
            src={article?.mini_header_image ?? article?.header_image}
            alt="Article header image"
          />
          <div>
            <p
              style={{ marginBottom: "12px" }}
              className={"heading-poppins fw-600"}
            >
              {article?.title}
            </p>
            <p style={{ marginBottom: "8px" }} className={"body-3"}>
              {article?.date +
                " · " +
                article?.article_keywords.slice(0, 2).join(", ")}
            </p>{" "}
            <div className="article-card-container_author-section">
              <img
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                }}
                src={article?.author_image}
                loading="lazy"
                alt="Author image"
              />
              <p
                className={"body-3 margin-bottom-0"}
                style={{ letterSpacing: "0.6px" }}
              >
                {article?.author_name}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ArticleCard;
