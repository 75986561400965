import React, { Fragment, useState, useEffect } from "react";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import SettingsSideBar from "./SettingsSideBar";
import useToken from "../../hooks/useToken";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap";
import down from "../../assets/icons/down.svg";
import up from "../../assets/icons/up.svg";
import externalLink from "../../assets/icons/externalLink.svg";
import { useHistory } from "react-router-dom";

function HelpCenter(props) {
  const { token } = useToken();
  const [activeKey, setActiveKey] = useState(0);

  const [showAllFAQs, setShowAllFAQs] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!showAllFAQs) {
      window.scrollTo(0, 0);
    }
  }, [showAllFAQs]);

  function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      handleClick();
    });

    return (
      <div
        className="settings__accordian-toggle border-0"
        type="button"
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  }

  const HelpCard = (title, body, index, idx) => {
    let cardClass =
      activeKey === index
        ? "active help-center-card-title"
        : "help-center-card-title";
    return (
      <Fragment key={idx}>
        {(idx < 4 || showAllFAQs) && (
          <div>
            {idx === 0 && <p className="help-subtitle">Common FAQs</p>}
            <Card className={cardClass} key={index}>
              <CustomToggle
                as={Card.Header}
                eventKey={index}
                handleClick={() => {
                  if (activeKey === index) {
                    setActiveKey(null);
                  } else {
                    setActiveKey(index);
                  }
                }}
              >
                <span className="help-accordian-span-title">{title}</span>
                <span className="help-accordian-span-icon">
                  {activeKey === index ? " -" : " +"}
                </span>
              </CustomToggle>
              <Accordion.Collapse eventKey={index} className="border-0">
                <Card.Body className="settings__help-center-card-body">
                  {body}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        )}
      </Fragment>
    );
  };

  const HelpCenterPage = () => {
    const helpInformation = [
      {
        title: "How long does the bill review take?",
        body: "The length of the review process depends on how complicated the bill is, and how many issues we find (if any!). If it’s a fairly straightforward bill, the process shouldn’t take more than a few business days. If it’s a bit more complicated and we need to contact the facility for more information, it could take anywhere from 2-3 weeks. We’ll keep you in the loop via email and in your Handl portal as we have updates, and you can always reach out if you have questions or want to know the status of your bill review. ",
      },

      {
        title: "Do you contact my insurer?",
        body: "Probably not. We’re more likely to contact the facility you went to if we have questions or see any issues with your bill. If we need to contact your insurer for any reason, we’ll reach out to get your consent first.",
      },
      {
        title: "Why should I have my medical bills reviewed?",
        body: "It’s important to review all medical bills in case there are any errors. This will save you from potentially over-paying or paying the wrong amount.",
      },
      {
        title: "What do you see about my medical records?",
        body: "When undergoing the bill review process, we will only acess the information we need about the appointment(s) from your bill specifically. This could include any factor that would influence the cost of the service like a diagnosis, the treatment you got and the amount of time you spent at the facility. We only look at the information that will help us identify the appropriateness of what’s on the bill.",
      },
      {
        title: "How much does Handl’s bill review cost?",
        body: "Handl’s bill review is totally free!",
      },
      {
        title: "What is HIPAA?",
        body: "HIPAA (the Health Insurance Portability and Accontability Act) is a federal law that protects your patient health information (PHI) from being shared without your consent. For more information about HIPAA and our privacy policy, click here.",
      },
      {
        title:
          "I don’t see my question listed, how do I contact Handl support?",
        body: "We’re here to help! You can email hello@handlhealth.com and we’ll get back to you within one business day.",
      },
    ];

    let helpInformationCards = helpInformation.map((item, index) =>
      HelpCard(item.title, item.body, "privacy-" + index, index)
    );

    const contactInfo = [
      {
        id: 1,
        title: "Contact Handl Health",
        emailSubfield: "via email",
        emailSubfieldInfo: "support@handlhealth.com",
      },
    ];

    const ExternalLinksSection = () => {
      const content = [
        { title: "Terms and Conditions", link: "/terms" },
        { title: "Our Privacy Policy", link: "/privacy" },
      ];
      return (
        <div className="external-links__container">
          {content.map((item) => (
            <Link to={item.link} key={item.title}>
              <div className="flex-row">
                <p className="text-small">{item.title}</p>
                <img src={externalLink} />
              </div>
            </Link>
          ))}
        </div>
      );
    };
    return (
      <div className="settings__privacy-and-security settings-page">
        <div className="width-704">
          <Link
            to={{ pathname: "/settings" }}
            className="settings__back-button"
          >
            <p className="body-2 pointer">
              <span className="back-arrow" /> SETTINGS
            </p>
          </Link>
        </div>
        <div className="width-576">
          <div>
            <div className="settings__privacy-information">
              <Accordion defaultActiveKey={0} activeKey={activeKey}>
                {helpInformationCards}
              </Accordion>
            </div>
          </div>

          <div className="help-center-view-all-container">
            <p
              className="text-subheading center-text help-center-view-all"
              onClick={() => setShowAllFAQs(!showAllFAQs)}
            >
              {showAllFAQs ? "Collapse" : "View all FAQs"}
            </p>
            <img src={showAllFAQs ? up : down} />
          </div>
          {contactInfo.map((item) => (
            <div className="help-contact-card" key={item.id}>
              <p className="plan-details-main">{item.title}</p>
              <p className="plan-details-items">
                {item.emailSubfield}: <b>{item.emailSubfieldInfo}</b>
              </p>
            </div>
          ))}
          {ExternalLinksSection()}
        </div>
      </div>
    );
  };
  return (
    <SidebarLayout
      noSidebar={true}
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses="settings__side-bar"
      content={HelpCenterPage()}
      contentClasses="settings__content"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default HelpCenter;
