import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionToggle } from "react-bootstrap";
import "./FAQAccordian.css";

function FAQAccordian(props) {
  const [activeKey, setActiveKey] = useState(0);
  useEffect(() => {
    setActiveKey(null);
  }, [props]);

  const FooterAccordion = () => {
    const accordianInformation = props.accordianInformation;
    let accordianInformationCards = accordianInformation.map((item, index) =>
      AccordianCard(item.title, item.body, "accordian-" + index)
    );

    return (
      <div className="FAQ__accordian-footer">
        <p>Need a hand?</p>
        <Accordion defaultActiveKey={0} activeKey={activeKey}>
          {accordianInformationCards}
        </Accordion>
      </div>
    );
  };
  function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      handleClick();
    });

    return (
      <div
        className="FAQ__accordian-toggle border-0"
        type="button"
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  }

  const AccordianCard = (title, body, index) => {
    let cardClass =
      activeKey === index
        ? "active FAQ__accordian-card-title"
        : "FAQ__accordian-card-title";
    return (
      <Card className={cardClass} key={index}>
        <CustomToggle
          as={Card.Header}
          eventKey={index}
          handleClick={() => {
            if (activeKey === index) {
              setActiveKey(null);
            } else {
              setActiveKey(index);
            }
          }}
        >
          <span>{title}</span>
          <span className="FAQ__accordian-icon">
            {activeKey === index ? " -" : " +"}
          </span>
        </CustomToggle>
        <Accordion.Collapse eventKey={index} className="border-0">
          <Card.Body className="settings__privacy-card-body">{body}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };
  return <FooterAccordion />;
}

export default FAQAccordian;
