import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import SubmissionDetails from "../../components/sections/Billing/SubmissionDetails";
import Button from "../../components/elements/Button";
import left from "../../assets/icons/left.svg";
import { useHistory } from "react-router-dom";
import WhatToExpect from "../../components/sections/Billing/WhatToExpect";

export default function CompletedBillDetails() {
  const [billingRecord, setBillingRecord] = useState({});
  const [smallScreen, setSmallScreen] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    fetchBillingRecord();
  }, []);

  function fetchBillingRecord() {
    const URL = process.env.REACT_APP_API_URL + "billing_records/" + id;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBillingRecord(res.data);
        }
      });
  }

  const TopSection = () => {
    return (
      <div className="text-container">
        <Button
          iconFirst
          icon={<img style={{ marginRight: "11px" }} src={left} />}
          class="text-btn"
          className={`mobile-search__close ${
            smallScreen ? "" : "width-904 hipaa-form__return-to-bill"
          }`}
          label=" back"
          onClick={() =>
            history.push({
              pathname: "/billing",
            })
          }
          active={true}
        />
        <div
          className={
            smallScreen
              ? ""
              : "callout-container hipaa-form__text-container width-904"
          }
        >
          <div style={{ justifyContent: "space-between" }} className="flex-row">
            <h5
              style={
                smallScreen
                  ? { maxWidth: "196px", marginBottom: "16px" }
                  : { marginBottom: "16px" }
              }
              className="fw-200"
            >
              {billingRecord?.status === "submitted"
                ? "Submitted for bill review"
                : "Reviewed bill"}
            </h5>
            {billingRecord?.status === "reviewed" && !smallScreen && (
              <div>
                <Button
                  label="View PDF"
                  class="long-btn filled"
                  active={true}
                />
              </div>
            )}
          </div>
          <p className="text-medium">
            <span className="fw-600">{billingRecord?.where_was_visit}</span>{" "}
            visit{" "}
            {billingRecord?.service_start_date && (
              <>
                on{" "}
                <span className="fw-500">
                  {new Date(
                    billingRecord?.service_start_date
                  ).toLocaleDateString(undefined, {
                    timeZone: "UTC",
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  })}
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    );
  };

  const BillOutcomeReady = () => {
    return (
      <div className="submission-details">
        <p className="text-large fw-300 margin-bottom-8">
          Bill outcome ready for review
        </p>
        <p className="margin-bottom-24">
          Completed{" "}
          {billingRecord?.bill_analysis_completed_on &&
            new Date(
              billingRecord?.bill_analysis_completed_on
            ).toLocaleDateString(undefined, {
              month: "long",
              day: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
        </p>
        <Button label="View PDF" class="long-btn filled" active={true} />
      </div>
    );
  };

  const NeedMoreAssistance = () => {
    return (
      <div
        style={{ gap: "0" }}
        className={smallScreen ? "submission-details" : "what-to-expect"}
      >
        <p
          className={`text-large fw-300 ${
            smallScreen ? "margin-bottom-32" : "margin-bottom-24"
          }`}
        >
          Need more assistance with this bill?
        </p>
        <Button
          label="Contact Handl"
          class="long-btn outline-btn-blue"
          active={true}
        />
      </div>
    );
  };
  return (
    <div className={smallScreen ? "" : "hipaa-form"}>
      <div className={smallScreen ? "flex-wrapper billing-dashboard" : ""}>
        {TopSection()}
        {smallScreen &&
          billingRecord?.status === "reviewed" &&
          BillOutcomeReady()}
        <SubmissionDetails billingRecord={billingRecord} />
        {billingRecord?.status === "submitted" && <WhatToExpect />}
        {billingRecord?.status === "reviewed" && NeedMoreAssistance()}
      </div>
    </div>
  );
}
