import React from "react";
import { useSmartcrop, SmartcropStatus } from "use-smartcrop";
import header from '../../assets/header.png';

function CroppedImage(props) {

  // const src = header;
  const src = props.src;
  
  // Width and height are required.
  const [cropped, error] = useSmartcrop({ src }, { width: props.width, height: props.height, minScale: 1.0 });

  if (error) {
    // console.error(error);
  }
  
  return (
    <div className={props.containerClassName}>
      {cropped && <img src={cropped} className={props.imageClassName} />}
    </div>
  );
}

export default CroppedImage;
