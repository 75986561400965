import './Settings.css';
import React, { useState, useEffect, Fragment } from 'react';
import PrivateRoute from "../PrivateRoute.js";
import SettingsHome from './SettingsHome';
import MyProfile from './MyProfile';
import Notifications from './Notifications';
import Privacy from './Privacy';
import HelpCenter from './HelpCenter';
import InsurancePlan from './InsurancePlan';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function Settings(props) {

  return (
    <Switch>
      <Route exact path="/settings" render={() => <SettingsHome {...props} />} />
      <Route path='/settings/my-profile' render={() => <MyProfile {...props} />} />
      <Route path='/settings/privacy' render={() => <Privacy {...props} />} />
      <Route path='/settings/notifications' render={() => <Notifications {...props} />} />
      <Route path='/settings/help-center' render={() => <HelpCenter {...props} />} />
      <Route path='/settings/plan-details' render={() => <InsurancePlan {...props} />} />
      
    </Switch>
    
  )
}

export default Settings
