import React, { Component } from "react";
import './HandlCategory.css';
import { Service } from '../modules/ListItem';
import { Link } from 'react-router-dom';
import Filter from '../elements/Filter';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class HandlCategory extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        categoryName: this.props.category,
        description: this.props.description,
        services: this.props.services,
        serviceGroups: [...new Set(this.props.services.map(service => service.service_group_name.replace("’", "'") ))],
        servicesLoading: true,
        filteredServices: this.props.services,
        filterLabel: "Filter by Group"
    }

    this.buildServices= this.buildServices.bind(this);
  }

  onClick = (e) => {
    const serviceGroup = e.currentTarget.innerHTML;

    if (serviceGroup == 'Show All') {
      this.setState({ filteredServices: this.state.services });
      this.setState({ filterLabel: "Filter by Group" });
    } else {
      let filtered = this.state.services.filter(service => service.service_group_name.replace("’", "'") == serviceGroup );
      this.setState({ filteredServices: filtered });
      this.setState({ filterLabel: serviceGroup });
    }
  }

  buildServices(service) {
    let serviceCategory = service.service_group_name
    if (service.service_subgroup_name != null){
      serviceCategory = serviceCategory + ', ' + service.service_subgroup_name
    }

    if (this.props.smallScreen == true) {
      return (<Link key={service.cpt_code} className ="service-container"to= {
              {     
                pathname: '/service_search',
                state: {service: service.cpt_code}
               }
              }>
                <Service
                  icon="service-icon" 
                  overline={"CPT " + service.cpt_code} 
                  name={service.display_name} 
                  details={serviceCategory} /> 
                <div className="arrow"></div>
                </Link>);
    } else {

      return (<div className="service-container" onClick={(e) => {this.props.fillServiceInput(service.display_name)}} key={service.cpt_code}> 
              <Service 
                icon="service-icon" 
                overline={"CPT " + service.cpt_code} 
                name={service.display_name} 
                details={serviceCategory} /> 
              <div className="arrow"></div>
              </div>);
    }
    
  }

  render() {
    let displayServices = [];
    displayServices = this.state.filteredServices.map(this.buildServices);
    let allServices = "All " + this.state.categoryName;

    return (
      <div className='handl-category'>
        <Container fluid="lg">
          <Row>
            <Col>
              <p className="handl-category-description">{this.state.description}</p>
              <div className='all-services-heading'>
                <p className="bold m-0 all-services-label">{allServices}</p>
                <Filter 
                  label={this.state.filterLabel} 
                  dropdownItems={this.state.serviceGroups} 
                  onClick={this.onClick} 
                  showAll={true}
                  useIcon={this.props.smallScreen} />
              </div>
              <ul className="all-services-list p-0">
                {displayServices}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

 export default HandlCategory;
