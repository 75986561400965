import "./ListItem.css";
import Paragraph from "../elements/Paragraph";
import { Link } from "react-router-dom";
import right from "../../assets/icons/right.svg";

function Service(props) {
  var classes =
    "d-flex w-100 justify-content-between list-item " + props.classes;
  var serviceGroup;

  if (props.serviceGroup) {
    serviceGroup = (
      <Paragraph textStyle="m-0 service-group-icon" text={props.serviceGroup} />
    );
  }

  if (props.linked == true) {
    return (
      <Link
        className="text-decoration-none pointer"
        key={props.cpt_code}
        to={{
          pathname: props.path,
          state: { service: props.cpt_code },
        }}
      >
        <li
          className={classes}
          key={props.cpt_code}
          onClick={props.onClick}
          id={props.displayName}
        >
          <div className={props.icon}></div>
          <div
            className="service-information flex-grow-1"
            id={props.displayName}
          >
            <Paragraph textStyle="small m-0" text={props.overline} />
            <Paragraph textStyle="bold m-0 wrap" text={props.name} />
            <Paragraph textStyle="small m-0" text={props.details} />
          </div>
          {serviceGroup}
        </li>
      </Link>
    );
  } else {
    return (
      <li
        tabIndex="0"
        className={classes}
        key={props.cpt_code}
        onClick={props.onClick}
        id={props.displayName}
      >
        <div className={props.icon}></div>
        <div className="service-information flex-grow-1" id={props.displayName}>
          <Paragraph textStyle="small m-0" text={props.overline} />
          <Paragraph textStyle="body-2 m-0 wrap" text={props.name} />
          <Paragraph textStyle="small m-0" text={props.details} />
        </div>
        {serviceGroup}
      </li>
    );
  }
}

function Facility(props) {
  var overlineText = (
    <span>
      <span
        style={{ fontSize: "13px", textAlign: "right" }}
        className="facility-user-cost mr-2"
      >
        {props.user_cost}
      </span>{" "}
      <span className="your-estimate-text">your estimate</span>
    </span>
  );
  var classes = "bg-white list-item " + props.classes;
  var facilityDetail;
  var overline;

  if (props.detail) {
    facilityDetail = (
      <Paragraph textStyle="m-0 service-group-icon" text={props.detail} />
    );
  } else {
    facilityDetail = <div className="placeholder"></div>;
  }

  if (props.showOverline) {
    overline = <Paragraph textStyle="text-small m-0" text={overlineText} />;
  } else {
    overline = null;
  }

  return (
    <li className={classes} key={props.name}>
      <div
        className="service-information flex-grow-1 width-204"
        id={props.name}
      >
        {overline}
        <Paragraph textStyle="body-2  fw-500 m-0" text={props.name} />
        <Paragraph textStyle="body-3 m-0" text={props.address1} />
        <Paragraph textStyle="body-3 m-0" text={props.address2} />
      </div>
      <div
        style={
          props.detail
            ? { alignItems: "center", gap: "24px" }
            : { alignItems: "center" }
        }
        className="flex-row"
      >
        {facilityDetail}
        <img src={right} alt="chevron right" />
      </div>
    </li>
  );
}

function DesktopFacility(props) {
  var facilityDetail;

  if (props.detail) {
    facilityDetail = (
      <Paragraph textStyle="ml-4 service-group-icon" text={props.detail} />
    );
  } else {
    facilityDetail = <div className="placeholder"></div>;
  }

  return (
    <li
      className={`border-bottom bg-white d-flex d-flex justify-content-end list-item ${props.classes}`}
      key={props.name}
    >
      <div className="service-information " id={props.name}>
        <Paragraph
          textStyle="fw-500 facility-name m-0 pb-2 wrap"
          text={props.name}
        />
        <Paragraph textStyle="body-2 m-0" text={props.address1} />
        <Paragraph textStyle="body-2 m-0" text={props.address2} />
      </div>
      {facilityDetail}
      <div
        className="flex-row flex-grow-1 text-right"
        style={{
          justifyContent: "flex-end",
          gap: "24px",
          alignItems: "center",
        }}
      >
        <div>
          <Paragraph
            textStyle="facility-user-cost mb-0"
            text={props.user_cost}
          />
          <Paragraph textStyle="body-3 m-0 text-nowrap" text="your estimate" />
        </div>

        <img src={right} alt="chevron right" />
      </div>
    </li>
  );
}

export { Service, Facility, DesktopFacility };
