import './Toast.css'
import Paragraph from '../elements/Paragraph'

function Toast(props){
  var toastStyle = props.toastStyle + ' toast-notification'
  if (!props.show) {
    toastStyle = toastStyle + ' hide'
  }

  return (
    <div id="toast-notification" className={toastStyle} onClose={props.onClose} >
      <Paragraph textStyle='m-0 small' text={props.toastMessage} />
    </div>
  );
  
}

export default Toast;
