import "./Footer.css";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import facebook from "../../assets/icons/facebook.svg";
import { Link } from "react-router-dom";

function Footer() {
  const GOHANDLURL = "https://www.gohandlhealth.com/";
  const whoWeServeOptions = [
    "brokers",
    "health plans & TPAs",
    "providers",
    "employers",
  ];
  function capitalize(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-row footer-links">
          <div className="flex-col" style={{ justifyContent: "space-evenly" }}>
            <p className="text-alt-heading fw-600">Who We Serve</p>
            {whoWeServeOptions.map((option) => (
              <a
                href={
                  GOHANDLURL +
                  (option === "health plans & TPAs" ? "health-plans" : option)
                }
                rel="noopener noreferrer"
                target="_blank"
                key={option}
                className="footer-link"
              >
                {capitalize(option)}
              </a>
            ))}
          </div>
          <div className="flex-col" style={{ justifyContent: "space-between" }}>
            <Link
              className="footer-link about-us-link"
              to={{ pathname: "/about_us" }}
            >
              About Us
            </Link>
            <div className="footer-row footer-icons">
              <a href="https://www.instagram.com/handlhealth/" target="_blank">
                <img src={instagram} width="40" height="40" />
              </a>
              <a
                href="https://www.linkedin.com/company/handl-health"
                target="_blank"
              >
                <img src={linkedin} width="36" height="36" />
              </a>

              <a
                href="https://www.facebook.com/Handl-Health-103600331988081"
                target="_blank"
              >
                <img src={facebook} width="40" height="40" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
