import Dropdown from "react-bootstrap/Dropdown";
import "./Filter.css";

function Filter(props) {
  const className = props.className;

  var filterLabel = (
    <div className="mr-2 fancy-search-sort">
      {props.icon}
      {props.status ? "Status: " : "Sort by: "}
      {props.label}
    </div>
  );
  var showAll =
    props.showAll == true ? (
      <Dropdown.Item
        key="show-all"
        id="show-all"
        className="filter-item"
        onClick={props.onClick}
      >
        Show All
      </Dropdown.Item>
    ) : null;

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" className={className}>
        {filterLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={
          (props.noPadding && "filter-dropdown") ?? "filter-dropdown py-3"
        }
      >
        {props.dropdownItems.map((item) => (
          <Dropdown.Item
            key={item}
            id={item}
            className={
              props.customClass &&
              (props.status
                ? item === props.label.props.children[0]
                : item === props.label.props.children)
                ? "filter-item currently-selected"
                : "filter-item"
            }
            onClick={props.onClick}
          >
            {item}
          </Dropdown.Item>
        ))}
        {showAll}
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default Filter;
