import React, { useState, useEffect } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router-dom";
import ForgotPassword from "../SignIn/ForgotPassword";
import PopupModal from "../../components/templates/PopupModal";
import FAQAccordian from "../SignIn/FAQAccordian";
import "./SignUp.css";

export default function SignUp({
  displaySignInForm,
  invoker = "sign up",
  setRenderingConfirmationPage,
}) {
  const [userInformation, setUserInformation] = useState({
    fullName: "",
    email: "",
    dateOfBirth: "",
    phoneNumber: "",
    password: "",
    passwordConfirmation: "",
  });
  const [smallScreen, setSmallScreen] = useState(false);
  const [validity, setValidity] = useState({
    email: null,
    password: null,
    passwordConfirmation: null,
    phoneNumber: null,
  });
  const [accessCode, setAccessCode] = useState("");
  const [currentPage, setCurrentPage] = useState("form");
  const [accessCodeIsValid, setAccessCodeIsValid] = useState(null);
  const [accessCodeResent, setAccessCodeResent] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(
    "Please enter a valid email address."
  );
  const [userExists, setUserExists] = useState(false);
  const [consented, setConsented] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userID, setUserID] = useState("");

  const history = useHistory();
  const { token, setToken } = useToken();

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const createUser = () => {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "signup";
    axios
      .post(URL, {
        user: {
          email: userInformation.email,
          password: userInformation.password,
          password_confirmation: userInformation.passwordConfirmation,
          hipaa_consented: consented,
        },

        user_information: {
          first_name: userInformation.fullName.split(" ")[0],
          last_name:
            userInformation.fullName.split(" ")[
              userInformation.fullName.split(" ").length - 1
            ],
          phone_number:
            validity["phoneNumber"] === true
              ? userInformation.phoneNumber
              : null,
          birthdate: userInformation.dateOfBirth,
        },
      })
      .then((res) => {
        setUserID(res.data.data.id);
        switchPage("confirmation");
      })
      .catch(function (error) {
        const errors = error.response.data.errors;
        const emailExists = errors.some(
          (e) => e.detail === "Email already exists."
        );
        if (emailExists == true) {
          const emailExistsError = (
            <span>
              There is already an account associated with that email.{" "}
              <span onClick={() => switchPage("sign in")}>
                <u style={{ cursor: "pointer" }}>Log in here.</u>
              </span>
            </span>
          );
          setEmailErrorMessage(emailExistsError);
          setUserExists(true);
        }
      });
  };

  const confirmUser = (e) => {
    e.preventDefault();
    const URL = process.env.REACT_APP_DEVISE_API_URL + "confirmation";
    axios
      .get(URL, {
        params: {
          email: userInformation.email,
          confirmation_token: accessCode,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setAccessCodeIsValid(true);
          switchPage("sign in");
        } else {
          setAccessCodeIsValid(false);
          setAccessCodeResent(false);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        setAccessCodeIsValid(false);
        setAccessCodeResent(false);
      });
  };
  function switchPage(page) {
    if (page === "sign in") {
      if (invoker === "onboarding") {
        setRenderingConfirmationPage(false);
        displaySignInForm();
        return;
      } else {
        history.push("/sign_in");
      }
    } else if (page === "confirmation" && invoker === "onboarding") {
      setRenderingConfirmationPage(true);
    }
    setCurrentPage(page);
    window.scrollTo(0, 0);
    setConsented(false);
    setValidity({
      email: null,
      phoneNumber: null,
      passwordConfirmation: null,
      password: null,
    });
    setEmailErrorMessage("");
    setUserExists(null);
    setUserInformation({
      fullName: "",
      email: "",
      dateOfBirth: "",
      phoneNumber: "",
      password: "",
      passwordConfirmation: "",
    });
  }

  function validateEmail() {
    const regExp = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i;
    if (userInformation.email === "") return null;
    else {
      return regExp.test(userInformation.email);
    }
  }
  function validatePassword() {
    const regExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{12,}$/;
    if (userInformation.password === "") return null;
    else {
      return regExp.test(userInformation.password);
    }
  }

  function validatePhoneNumber() {
    const regExp =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    if (userInformation.phoneNumber === "") return null;
    else {
      return regExp.test(userInformation.phoneNumber);
    }
  }

  function camelCaseToCapitalized(text) {
    const result = text.replace(/([A-Z])/g, (str) => " " + str.toLowerCase());
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  const resendAccessCode = () => {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "confirmation";
    axios
      .post(URL, {
        user: {
          email: userInformation.email,
        },
      })
      .then((res) => {
        setAccessCodeResent(true);
      });
  };

  function deleteAccountHandler() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/users/" +
      +userID +
      "/destroy_unconfirmed_user";
    axios
      .delete(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          window.scrollTo(0, 0);
          switchPage("form");
          setRenderingConfirmationPage(false);
        } else {
          // Error
        }
      })
      .catch(function (error) {
        // Error
        console.log(error.response);
      });
  }

  const EmailConfirmation = () => {
    function closeModal() {
      setShowModal(false);
    }
    const accessCodeResendHelper = accessCodeResent ? (
      <span className="access-code-helper sent">
        Code sent! This may take a few mins.
      </span>
    ) : (
      <a
        className="sign-up-form__link access-code-helper"
        onClick={(e) => resendAccessCode()}
      >
        I didn't get an email.
      </a>
    );

    return (
      <React.Fragment>
        <PopupModal
          noPadding
          show={showModal}
          close={closeModal}
          size="md"
          dialogClassName="my-modal"
          position="centered"
          body={
            <div className="sign-up__modal-body">
              <p className="sign-up__modal-header">
                Are you sure you want to go back?
              </p>
              <p className="sign-up__modal-text">
                This will delete any progress you’ve made in creating your
                account.
              </p>
              <Button
                label="Go Back"
                active={true}
                class="outline-btn-blue"
                onClick={() => deleteAccountHandler()}
              />
              <Button
                label="Cancel"
                active={true}
                class="long-btn text-btn"
                onClick={() => setShowModal(false)}
              />
            </div>
          }
        />
        <p className="body-2 pointer" onClick={() => setShowModal(true)}>
          <span className="back-arrow" /> BACK
        </p>
        <div
          style={{ margin: "auto" }}
          className="account-creation__access-code-text-container"
        >
          <h5 className="fw-200 account-creation-header margin-bottom-24">
            Check your email
          </h5>

          <p className="sign-up__subtitle">
            Your security is our priority. Anytime you sign in, we’ll send you
            an access code to make sure it’s you and only you! No password
            breaches over here.
          </p>
        </div>
        <form
          id="email-confirmation-form"
          onSubmit={(e) => confirmUser(e)}
          noValidate
        >
          <Input
            placeholder="Access Code"
            name="access-code"
            label="Access Code"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            inputContainerClasses="access-code__input"
            showArrow={false}
            readOnly={false}
            helpText="Psst...you can add your mobile number later to receive future codes via text."
            errorMessage="Hmm, that isn't the code we sent you."
            validMessage="Valid code."
            showErrorMessage={accessCodeIsValid == false}
            showValidMessage={accessCodeIsValid == true}
            showErrorIcon={true}
          />

          <Button
            label="Sign in"
            active={accessCode != ""}
            class="long-btn filled build-profile-btn"
          />
        </form>
        <div className="access-code-helper-container">
          {accessCodeResendHelper}
        </div>
      </React.Fragment>
    );
  };

  const ActionButtons = () => {
    return (
      <div className="account-creation__action-buttons">
        <Button
          label="Create Account"
          active={
            userInformation.fullName !== "" &&
            userInformation.dateOfBirth !== "" &&
            consented === true &&
            Object.entries(validity)
              .filter((item) => item[0] !== "phoneNumber")
              .every((item) => item[1] === true)
          }
          class="long-btn filled"
          onClick={() => {
            createUser();
          }}
        />

        <div className="sign-in__or-element">
          <div className="sign-in__or-bar" />
          <span> OR </span>
          <div className="sign-in__or-bar" />
        </div>

        <Button
          label="Sign in"
          active={true}
          class="outline-btn"
          onClick={() => switchPage("sign in")}
        />
      </div>
    );
  };

  const accountCreationFAQInfo = [
    {
      title: "Why do I need to create an account?",
      body: "We’ll communicate with you about the status and outcome of your bill review through your account. Your account will help keep everything secure in one safe place and it’ll make submitting future bills a lot quicker!",
    },
    {
      title: "Does Handl contact my insurer?",
      body: "We don't contact your insurer, but we might contact the facility you went to if we need further details about the bill or to confirm accuracy. But don’t worry - we always make sure we have your consent beforehand (check out our HIPAA policy before you hit submit).",
    },
  ];

  const checkYourEmailFAQInfo = [
    {
      title: "I didn’t get an email.",
      body: "If you didn’t get an email with your access code, click the “I didn’t get an email” link above to take the appropriate steps.",
    },
    {
      title: "My code didn’t work.",
      body: "If your 7-digit code didn’t work, please reply to the email to let us know. We’ll be on it ASAP! In the meantime, don’t close the browser.",
    },
  ];

  return (
    <div
      className={`account-creation-sign-up flex-wrapper-space-between ${
        invoker === "sign up" ? " account-creation-margin-top" : ""
      }`}
    >
      <div
        className={`${
          invoker === "sign up" ? "account-creation-main-content" : ""
        }`}
      >
        {currentPage === "confirmation" ? (
          EmailConfirmation()
        ) : currentPage === "forgot password" ? (
          history.push("/forgot_password")
        ) : (
          <>
            <h5 className="fw-200 account-creation-header">
              {invoker === "onboarding"
                ? "First things first: create an account"
                : "Let’s Handl this. Create your account"}
            </h5>
            <p
              className={`account-creation-helper-description ${
                smallScreen ? "text-description" : "body-1"
              }`}
            >
              {invoker === "onboarding" &&
                "By making an account, you’ll be able to track the progress of your bill’s review and submit future bills quicker."}
            </p>

            {Object.entries(userInformation).map((el) => (
              <Input
                key={el[0]}
                placeholder={
                  el[0] === "passwordConfirmation"
                    ? "Re-enter password"
                    : camelCaseToCapitalized(el[0])
                }
                name={el[0]}
                type={
                  el[0].includes("password")
                    ? "password"
                    : el[0].includes("date")
                    ? "date"
                    : el[0].includes("email")
                    ? "email"
                    : el[0].includes("phone")
                    ? "tel"
                    : null
                }
                label={
                  el[0] === "passwordConfirmation"
                    ? "Re-enter password*"
                    : el[0] === "phoneNumber"
                    ? camelCaseToCapitalized(el[0])
                    : camelCaseToCapitalized(el[0]) + "*"
                }
                value={el[1]}
                onChange={(e) => {
                  setUserInformation({
                    ...userInformation,
                    [e.target.name]: e.target.value,
                  });
                  e.target.name in validity &&
                    setValidity({
                      ...validity,
                      [e.target.name]: null,
                    });
                  e.target.name === "email" && setUserExists(false);
                }}
                onBlur={(e) =>
                  el[0].includes("password")
                    ? setValidity({
                        ...validity,
                        password: validatePassword(),
                        passwordConfirmation:
                          userInformation.passwordConfirmation !== ""
                            ? userInformation.password ===
                              userInformation.passwordConfirmation
                            : null,
                      })
                    : el[0] === "phoneNumber"
                    ? setValidity({
                        ...validity,
                        phoneNumber: validatePhoneNumber(),
                      })
                    : el[0] === "email"
                    ? setValidity({ ...validity, email: validateEmail() })
                    : el[0] === "passwordConfirmation"
                    ? setValidity({
                        ...validity,
                        passwordConfirmation:
                          userInformation.password ===
                          userInformation.passwordConfirmation,
                      })
                    : null
                }
                errorMessage={
                  el[0].includes("email")
                    ? emailErrorMessage
                    : el[0] === "password"
                    ? "Please use at least 12 characters, including 1 uppercase letter and 1 symbol."
                    : el[0] === "phoneNumber"
                    ? "Please enter a valid phone number."
                    : el[0] === "passwordConfirmation"
                    ? "Passwords do not match. Please re-enter."
                    : null
                }
                showErrorMessage={
                  (el[0] === "email" && userExists === true) ||
                  (userInformation?.[el?.[0]] !== "" &&
                    validity?.[el?.[0]] === false)
                }
                validMessage="Passwords match."
                showValidMessage={
                  (el?.[0] === "password" ||
                    el?.[0] === "passwordConfirmation") &&
                  validity.password === true &&
                  validity.passwordConfirmation === true
                }
                showLabel={el?.[0] === "dateOfBirth"}
              />
            ))}
            <div className="sign-up-form__consent-input hipaa-form__section">
              <input
                type="checkbox"
                onChange={(e) => {
                  setConsented(!consented);
                  setValidity({ ...validity, email: validateEmail() });
                }}
                checked={consented}
              />
              <p className="consent-text">
                I have read and agree to the Handl Health{" "}
                <a href="/terms" target="_blank" className="sign-up-form__link">
                  Terms and Conditions of Use
                </a>{" "}
                and acknowledge the Handl Health{" "}
                <a
                  href="/privacy"
                  target="_blank"
                  className="sign-up-form__link"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            {ActionButtons()}
          </>
        )}
      </div>
      <div style={smallScreen ? { margin: "0 -16px 0" } : {}}>
        {currentPage !== "confirmation" && (
          <a className="sign-in__privacy-link" href="/privacy" target="_blank">
            Handl Health Privacy Policy
          </a>
        )}
        <FAQAccordian
          accordianInformation={
            currentPage === "confirmation"
              ? checkYourEmailFAQInfo
              : accountCreationFAQInfo
          }
        />
      </div>
    </div>
  );
}
