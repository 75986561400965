import React, { Component, useState, useReducer } from "react";
import "./EstimateFormDesktopMobile.css";
import axios from "axios";
import Geocode from "react-geocode";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useHistory, Link } from "react-router-dom";
import Button from "../elements/Button";
import Form from "react-bootstrap/Form";
import AutocompleteInput from "../elements/inputs/AutocompleteInput";
import TextInput from "../elements/inputs/TextInput";
import DesktopSelect from "../elements/inputs/DesktopSelect";
import Input from "../elements/Input.js";
class EstimateFormDesktop extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      // currentStep: 1, // Default is Step 1

      serviceName: this.props.clickedService,
      service: this.props.service ?? "",
      serviceError: "",
      services: [],
      searchTerm: "",

      zipCode: "",
      locationLoading: true,

      rawInsuranceData: [],
      providerList: [],
      plansList: [],
      insuranceProvider: "",
      insurancePlan: "",
      insurance: "",
      insuranceError: "",

      greatestCost: "",

      deductible: "",
      isDeductibleMet: "",
      deductiblePercentage: 0,

      oopMax: "",
      isMaxOopMet: "",
      oopMaxPercentage: 0,
      lowestOopMaxPercentageValue: 0,
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleIsDeductibleMetChange =
      this.handleIsDeductibleMetChange.bind(this);
    this.calculateOopMaxPercentage = this.calculateOopMaxPercentage.bind(this);

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    this.getLocation = this.getLocation.bind(this);
    this.updateProgressBar = this.updateProgressBar.bind(this);
    this.searchService = this.searchService.bind(this);
    this.searchZipCode = this.searchZipCode.bind(this);
    this.searchInsuranceProvider = this.searchInsuranceProvider.bind(this);
    this.searchInsurancePlan = this.searchInsurancePlan.bind(this);
    this.findPlansForProvider = this.findPlansForProvider.bind(this);
    this.validateCoverage = this.validateCoverage.bind(this);
    this.validateDeductible = this.validateDeductible.bind(this);
    this.startOver = this.startOver.bind(this);
    this.createUserServiceQuery = this.createUserServiceQuery.bind(this);

    this.getESServices = this.getESServices.bind(this);
    this.quoteFormRef = React.createRef();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.clickedService !== prevProps.clickedService &&
      this.props.clickedService !== undefined
    ) {
      this.setState({ serviceName: this.props.clickedService });
    }
  }
  componentDidMount() {
    if ("geolocation" in navigator) {
      this.getLocation();
    }
  }

  // This function uses google geocode to get the user's zipcode if they allow it
  // If it is allowed, their zipcode will be pre-filled in the form
  getLocation() {
    var self = this;
    Geocode.setLanguage("en");
    Geocode.setRegion("us");
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          var postalCode = response.results[0].address_components.find(
            (element) => element.types[0] == "postal_code"
          ).long_name;
          self.setState({
            zipCode: postalCode,
            locationLoading: false,
          });
        },
        (error) => {
          console.error(error);
          self.setState({
            locationLoading: false,
          });
        }
      );
    });
  }

  getESServices(query) {
    const URL = process.env.REACT_APP_API_URL + "services/";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },

        params: {
          query_text: query,
        },
      })
      .then((res) => {
        const services = res.data.data.map((s) => s.attributes);
        this.setState({ services });
      });
  }

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  // Use the submitted data to set the state
  handleServiceChange(event) {
    if (event.target.value.length > 2) {
      this.getESServices(event.target.value);
    } else {
      this.setState({ services: [] });
    }
    this.setState({
      searchTerm: event.target.value,
    });
  }

  handleSelectChange(option, value, name) {
    this.setState({ [name]: value }, () => {
      this.updateProgressBar(name);

      if (name == "isDeductibleMet") {
        this.handleIsDeductibleMetChange(value);
      }

      if (name == "oopMax") {
        this.setState({
          oopMaxPercentage: this.calculateOopMaxPercentage(),
          lowestOopMaxPercentageValue: this.calculateOopMaxPercentage() * 10,
        });
      }
    });
  }

  handleIsDeductibleMetChange(value) {
    if (value == "false") {
      this.setState({
        isMaxOopMet: "false",
      });
    } else {
      this.setState({
        isMaxOopMet: "",
      });
    }
  }

  calculateOopMaxPercentage() {
    const deductible =
      this.state.deductible == "I don't know" ? 2000 : this.state.deductible;
    const oopMax =
      this.state.oopMax == "I don't know" ? 4000 : this.state.oopMax;
    const deductiblePercentage =
      this.state.isDeductibleMet == "true"
        ? 1
        : this.state.deductiblePercentage;

    const deductiblePaid =
      deductiblePercentage == 0 ? 0 : deductible * deductiblePercentage;
    const percentageOfOopPaid =
      deductiblePaid == 0 ? deductiblePaid : deductiblePaid / oopMax;
    const roundedPercentage = Math.round(percentageOfOopPaid * 10) / 10;

    return roundedPercentage;
  }

  _next() {
    let currentStep = this.props.currentStep;
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4 ? 5 : currentStep + 1;
    this.props.handleCurrentSteps(currentStep);
  }

  _prev() {
    let currentStep = this.props.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.props.handleCurrentSteps(currentStep);
  }

  // This function updates the progress bar element in the header based on
  // which step the user is on
  updateProgressBar(name) {
    if (name == "service") {
      this.state.service != ""
        ? this.props.updateProgress(10)
        : this.props.updateProgress(0);
    } else if (name == "zipCode") {
      this.state.zipCode != ""
        ? this.props.updateProgress(20)
        : this.props.updateProgress(10);
    } else if (name == "insuranceProvider") {
      this.state.insuranceProvider != ""
        ? this.props.updateProgress(30)
        : this.props.updateProgress(20);
    } else if (name == "insurancePlan") {
      this.props.updateProgress(40);
    } else if (name == "deductible") {
      this.props.updateProgress(50);
    } else if (name == "isDeductibleMet") {
      this.props.updateProgress(60);
    } else if (name == "deductiblePercentage") {
      this.props.updateProgress(70);
    } else if (name == "oopMax") {
      this.state.isMaxOopMet != ""
        ? this.props.updateProgress(90)
        : this.props.updateProgress(80);
    } else if (name == "isMaxOopMet") {
      this.props.updateProgress(90);
    } else if (name == "oopMaxPercentage") {
      this.props.updateProgress(100);
    }
  }

  // This function handles when a user clicks on a service in the drop down list
  selectService = (service) => {
    this.setState({
      service: service,
    });
    this.setState({
      serviceName: service,
    });
  };

  // This function handles when a user clicks on a service in the drop down list
  selectServiceName = (service) => {
    this.setState({
      serviceName: service,
    });
    this.props.fillServiceInput(service);
    this.createUserServiceQuery(service);
  };
  // This function searches for the service the user entered
  // If it is found, it goes to the next step
  // If its not found the user recieved an error directing them to our service list
  searchService(e) {
    e.stopPropagation();
    e.preventDefault();

    const service_name = this.state.serviceName;
    if (service_name == "") {
    } else {
      let serviceData;
      this.createUserServiceQuery();

      const URL =
        process.env.REACT_APP_API_URL + "services/searchable_service_search";
      axios
        .get(URL, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
          params: {
            search: service_name,
          },
        })
        .then((res) => {
          serviceData = res.data;
          if (serviceData == "Service Not Found") {
            this.setState({ serviceError: serviceData });
          } else {
            this.setState({ service: serviceData.cpt_code });
            this.setState({ serviceName: serviceData.name });
            this.updateProgressBar("service");
            this._next(e);
          }
        });
    }
  }

  createUserServiceQuery(selectedService) {
    if (this.state.service != null) {
      const service_name =
        selectedService ?? this.quoteFormRef.current.state.userInput;
      const suggestions =
        this.quoteFormRef.current.state.filteredSuggestions.map(
          (s) => s.display_name
        );
      const URL = process.env.REACT_APP_API_URL + "user_service_queries";

      axios
        .post(
          URL,
          {
            input: this.state.service,
            is_mobile: false,
            selected_search_result: service_name,
            rejected_search_results: suggestions,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
            },
          }
        )
        .then((res) => {});
    }
  }

  // This function searches for the zipcode the user entered
  // If there are facilities offering the given service in the zipcode, it goes to the next step
  // If there are not, it shows an error screen
  searchZipCode(e) {
    e.stopPropagation();
    e.preventDefault();

    const zipCode = document.querySelector("input[name='zipCode']").value;
    if (zipCode == "") {
      console.log("No input");
    } else {
      let insuranceData;
      const URL =
        process.env.REACT_APP_API_URL +
        "insurance_providers/by_service_and_zip_code";
      axios
        .get(URL, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },

          params: {
            zip_code: zipCode,
            service: this.state.service,
          },
        })
        .then((res) => {
          insuranceData = res.data;
          if (typeof insuranceData === "string") {
            this.props.handleErrorScreenState();
          } else {
            this.setState({ rawInsuranceData: insuranceData });
            var greatestCost = Math.max.apply(
              Math,
              insuranceData.map(function (o) {
                return o.cost;
              })
            );
            this.setState({ greatestCost: greatestCost });

            var provider_data = insuranceData.map((i) => ({
              option: i.insurance_name,
              value: i.insurance_name,
            }));
            var unique_provider_data = [
              ...new Map(
                provider_data.map((item) => [item["option"], item])
              ).values(),
            ];
            this.setState({ providerList: unique_provider_data });
            this.updateProgressBar("zipCode");
            this._next(e);
          }
        });
    }
  }

  // This function searches for the insurance provider the user entered in the raw insurance data set
  // If the provide is found, it is saved, the progress bar is updated, and the plans list is updated
  // If it is not found, an error message appears
  searchInsuranceProvider(name) {
    if (
      this.state.rawInsuranceData.filter((i) => i.insurance_name == name)
        .length > 0 ||
      this.state.rawInsuranceData.filter(
        (i) => i.insurance_plan == name.toLowerCase()
      ).length > 0
    ) {
      this.setState({
        insuranceProvider: name,
        insurancePlan: "",
        insurance: "",
      });
      this.updateProgressBar("insuranceProvider");
      this.findPlansForProvider(name);
    } else {
      this.setState({
        insuranceError:
          "Sorry, we do not have data for that insurance provider.",
      });
    }
  }

  // This function searches for the insurance provider the user entered in the raw insurance data set
  // If the pln is found, it is saved and the progress bar is updated
  // If it is not found, an error message appears
  searchInsurancePlan(name) {
    if (this.state.rawInsuranceData.some((i) => i.issuer_legal_name == name)) {
      this.setState({ insurancePlan: name });
      this.setState({ insurance: name });
      this.updateProgressBar("insurancePlan");
    } else {
      this.setState({
        insuranceError:
          "Sorry, we do not have data for that insurance provider.",
      });
    }
  }

  findPlansForProvider(name) {
    var plans;
    var insurance;
    var unique_plans;

    if (name != "I do not have insurance") {
      if (name == "Medicare" || name == "Medicaid") {
        insurance = this.state.rawInsuranceData.filter(
          (i) => i.insurance_plan == name.toLowerCase()
        );
        plans = insurance.map((i) => ({
          option: i.issuer_legal_name,
          value: i.issuer_legal_name,
        }));
      } else {
        insurance = this.state.rawInsuranceData.filter(
          (i) => i.insurance_name == name
        );
        plans = insurance.map((i) => ({
          option: i.issuer_legal_name,
          value: i.issuer_legal_name,
        }));
      }

      unique_plans = [
        ...new Map(plans.map((item) => [item["option"], item])).values(),
      ];

      this.setState({
        plansList: unique_plans,
      });
    }
  }

  validateCoverage(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.insuranceProvider == "") {
      console.log("No provider input");
    } else {
      if (this.state.insurancePlan == "") {
        console.log("No plan input");
      } else {
        this._next(e);
        this.updateProgressBar("insurancePlan");
      }
    }
  }

  validateDeductible(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.deductible == "") {
      console.log("No deductible input");
    } else if (this.state.deductible == "I don't know") {
      this._next(e);
      this.updateProgressBar("deductiblePercentage");
    } else {
      if (this.state.isDeductibleMet == "") {
        console.log("No is deductible met input");
      } else {
        if (this.state.isDeductibleMet == "true") {
        } else {
        }
        this._next(e);
        this.updateProgressBar("deductiblePercentage");
      }
    }
  }

  startOver() {
    this.setState({
      currentStep: 1,
      service: "",
      zipCode: "",
    });

    this.props.updateProgress(0);
  }

  render() {
    var descriptionText;
    descriptionText = (
      <>
        <h5 className="fw-500">Find your service.</h5>
        <p className="body-1">
          {" "}
          Handl Health’s cost estimator helps you see prices of healthcare
          services in your area.
        </p>
      </>
    );

    return (
      <React.Fragment>
        <div className="estimate-form__step-description">{descriptionText}</div>
        <div className="estimate-form__desktop-container">
          <Service
            currentStep={this.props.currentStep}
            handleChange={this.handleServiceChange}
            service={this.state.service}
            serviceName={this.state.serviceName}
            clickedService={this.props.clickedService}
            services={
              this.state.searchTerm === ""
                ? this.props.commonServices
                : this.state.services
            }
            errorMessage={this.state.serviceError}
            quoteFormRef={this.quoteFormRef}
            selectService={this.selectService}
            selectServiceName={this.selectServiceName}
            back={this.props.onClose}
            searchTerm={this.searchTerm}
            next={this.searchService}
          />

          <ZipCode
            currentStep={this.props.currentStep}
            handleChange={this.handleChange}
            zipCode={this.state.zipCode}
            locationLoading={this.state.locationLoading}
            back={this.props.onClose}
            next={this.searchZipCode}
          />

          <Coverage
            currentStep={this.props.currentStep}
            searchInsurancePlan={this.searchInsurancePlan}
            searchInsuranceProvider={this.searchInsuranceProvider}
            providerList={this.state.providerList}
            plansList={this.state.plansList}
            insuranceProvider={this.state.insuranceProvider}
            plan={this.state.insurancePlan}
            insurance={this.state.insurance}
            service={this.state.service}
            serviceName={this.state.serviceName}
            zipCode={this.state.zipCode}
            back={this._prev}
            next={this.validateCoverage}
            showArrow={true}
            errorMessage={this.state.insuranceError}
          />

          <Deductible
            currentStep={this.props.currentStep}
            handleChange={this.handleChange}
            handleSelectChange={this.handleSelectChange}
            deductible={this.state.deductible}
            isDeductibleMet={this.state.isDeductibleMet}
            deductiblePercentage={this.state.deductiblePercentage}
            handleIsDeductibleMetChange={this.handleIsDeductibleMetChange}
            service={this.state.service}
            serviceName={this.state.serviceName}
            zipCode={this.state.zipCode}
            insuranceProvider={this.state.insuranceProvider}
            insurancePlan={this.state.insurancePlan}
            insurance={this.state.insurance}
            greatestCost={this.state.greatestCost}
            back={this._prev}
            next={this.validateDeductible}
            showArrow={true}
          />

          <OOPMax
            currentStep={this.props.currentStep}
            handleSelectChange={this.handleSelectChange}
            oopMax={this.state.oopMax}
            isMaxOopMet={this.state.isMaxOopMet}
            oopMaxPercentage={this.state.oopMaxPercentage}
            back={this._prev}
            service={this.state.service}
            serviceName={this.state.serviceName}
            zipCode={this.state.zipCode}
            deductible={this.state.deductible}
            isDeductibleMet={this.state.isDeductibleMet}
            deductiblePercentage={this.state.deductiblePercentage}
            insuranceProvider={this.state.insuranceProvider}
            insurancePlan={this.state.insurancePlan}
            insurance={this.state.insurance}
            calculateOopMaxPercentage={this.calculateOopMaxPercentage}
            lowestOopMaxPercentageValue={this.state.lowestOopMaxPercentageValue}
            showArrow={true}
          />

          <NotAvailable
            currentStep={this.props.currentStep}
            startOver={this.startOver}
          />
        </div>
      </React.Fragment>
    );
  }
}

function Service(props) {
  // Validate input before running search
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    props.next(event);
    setValidated(true);
  };

  // If there is no service found
  const goToServiceList = () => {
    document.getElementById("service-offerings").scrollIntoView();
  };

  var errorMessage;
  if (props.errorMessage && props.service != "") {
    errorMessage = (
      <div className="error-message pointer" onClick={goToServiceList}>
        <p className="bold">Service not found.</p>
        <p>Try a different term, or click here to search by category.</p>
      </div>
    );
  } else {
    errorMessage = null;
  }

  if (props.currentStep !== 1) {
    return null;
  } else {
    var submitIsActive =
      (props.service != null && props.service != "") ||
      (props.clickedService != null && props.clickedService != "");

    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="d-flex align-items-start"
        style={{ gap: "32px" }}
      >
        <AutocompleteInput
          onInput={props.handleChange}
          ref={props.quoteFormRef}
          name="service"
          label="Service name"
          value={props.serviceName}
          placeholder="Search for a service"
          formLabelClasses="quote-form-label text-small"
          formGroupClasses="position-relative"
          containerClasses="position-relative w-100"
          clickedService={props.clickedService}
          suggestions={props.services}
          suggestionClasses="suggestions"
          inputClasses="landing-page-input text-medium"
          errorMessage={errorMessage}
          smallScreen={false}
          selectService={props.selectService}
          selectServiceName={props.selectServiceName}
          onBlur={props.onBlur}
          showDropdownArrow={true}
        />
        <Button
          class="outline-btn"
          active={submitIsActive}
          onClick={handleSubmit}
          label="NEXT: LOCATION"
        />
      </Form>
    );
  }
}

function ZipCode(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    props.next(event);
    setValidated(true);
  };

  if (props.currentStep !== 2) {
    return null;
  } else {
    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="d-flex align-items-start"
        style={{ gap: "32px" }}
      >
        <div className="position-relative w-100 ">
          <Input
            placeholder="Zip code"
            type="text"
            name="zipCode"
            label="Zip code"
            value={props.zipCode}
            formLabelClasses="quote-form-label text-small"
            inputClasses="landing-page-input text-medium"
            onChange={props.handleChange}
            pattern="^[0-9]{5}(?:-[0-9]{4})?$"
            autoFocus={true}
          />
        </div>
        <Button
          class="outline-btn"
          active={props.zipCode != null && props.zipCode != ""}
          label="NEXT: COVERAGE"
          onClick={handleSubmit}
        />
      </Form>
    );
  }
}

function Coverage(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (props.insuranceProvider == "I do not have insurance") {
      getFacilities(event);
    } else {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }

      setValidated(true);
      props.next(event);
    }
  };

  const history = useHistory();

  const getFacilities = (e) => {
    e.stopPropagation();
    e.preventDefault();

    history.push({
      pathname: "/facilities",
      from: "/estimates",
      state: {
        service: props.service,
        serviceName: props.serviceName,
        zipCode: props.zipCode,
        insuranceProvider: props.insuranceProvider,
        deductible: 0,
        isDeductibleMet: false,
        deductiblePercentage: 0,
        oopMax: 0,
        isMaxOopMet: false,
        oopMaxPercentage: 0,
        currentStep: 3,
      },
    });
  };

  var noInsurance = props.insuranceProvider == "I do not have insurance";
  var providerInputted =
    props.insuranceProvider != "" && props.insuranceProvider != null;
  var planInputted = props.plan != "" && props.plan != null;

  var hidePlan = providerInputted && !noInsurance ? false : true;
  var nextText = noInsurance ? "NEXT: SEE MY QUOTE" : "NEXT: DEDUCTIBLE";
  var active = noInsurance ? true : providerInputted && planInputted;

  var errorMessage;
  if (props.errorMessage && props.service != "") {
    errorMessage = (
      <div className="error-message pointer">
        <p className="bold">{props.errorMessage}</p>
      </div>
    );
  } else {
    errorMessage = null;
  }

  if (props.currentStep !== 3) {
    return null;
  } else {
    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="d-flex align-items-start"
        style={{ gap: "32px" }}
      >
        <div style={{ gap: "24px" }} className="d-flex w-100">
          <AutocompleteInput
            name="insuranceProvider"
            label="Insurance provider"
            placeholder="Insurance provider"
            formLabelClasses="quote-form-label text-small"
            containerClasses="position-relative w-100"
            suggestions={props.providerList}
            suggestionClasses="suggestions insurance-provider-suggestions"
            inputClasses="landing-page-input text-medium"
            searchCoverage={props.searchInsuranceProvider}
            errorMessage={errorMessage}
            smallScreen={false}
            showDropdownArrow={true}
            value={props.insuranceProvider}
          />
          <AutocompleteInput
            name="insurancePlan"
            label="Plan type"
            placeholder="Plan type"
            formLabelClasses="quote-form-label text-small"
            formGroupClasses="revealed-input position-relative"
            suggestions={props.plansList}
            suggestionClasses="suggestions insurance-plan-suggestions"
            inputClasses="landing-page-input text-medium"
            containerClasses="position-relative w-100"
            searchCoverage={props.searchInsurancePlan}
            errorMessage={errorMessage}
            smallScreen={false}
            hide={hidePlan}
            showDropdownArrow={true}
            value={props.plan}
          />
        </div>
        <Button
          class="outline-btn"
          onClick={handleSubmit}
          active={active}
          label={nextText}
        />
      </Form>
    );
  }
}

function Deductible(props) {
  const [validated, setValidated] = useState(false);
  const history = useHistory();

  // Handle submit button click
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    if (showOOPMax) {
      props.next(event);
    } else {
      getFacilities(event);
    }
  };

  // Go To the facilities page
  const getFacilities = (e) => {
    e.stopPropagation();
    e.preventDefault();

    history.push({
      pathname: "/facilities",
      from: "/estimates",
      state: {
        service: props.service,
        serviceName: props.serviceName,
        zipCode: props.zipCode,
        insuranceProvider: props.insurance,
        deductible:
          props.deductible == "I don't know" ? 2000 : props.deductible,
        isDeductibleMet: props.isDeductibleMet,
        deductiblePercentage:
          props.isDeductibleMet == "true" ? 1 : props.deductiblePercentage,
        oopMax: 0,
        isMaxOopMet: false,
        oopMaxPercentage: 0,
        currentStep: 4,
      },
    });
  };

  // Dropdown Options
  let deductibleOptions = [{ option: "I don't know", value: "I don't know" }];
  for (let i = 0; i <= 6000; i = i + 250) {
    const item = { option: "$" + i, value: i };
    deductibleOptions.push(item);
  }

  const percentageOptions = [];
  for (let i = 0; i <= 10; i = i + 1) {
    const item = {
      option: i == 0 ? "0%" : i + "0%",
      value: i == 10 ? "1" : "0." + i,
    };
    percentageOptions.push(item);
  }

  const isDeductibleMetOptions = [
    { option: "Yes - I have met my deductible", value: "true" },
    { option: "No - I have not met my deductible", value: "false" },
  ];

  // Determine if 'Have you met your deductible?' should show
  var hideIsMet = true;
  if (
    props.deductible != "" &&
    props.deductible != "none" &&
    props.deductible != "I don't know"
  ) {
    hideIsMet = false;
  }

  // Determine if the deductible percentage input should show
  var hidePercentagePaid = true;
  if (props.isDeductibleMet == "false") {
    hidePercentagePaid = false;
  }

  // Determine if the OOP Max inputs should show
  var showOOPMax = true;
  if (props.greatestCost < 2500 || props.deductible > props.greatestCost) {
    showOOPMax = false;
  }

  // Adjust button text depending on if the next page is the oop max options or the facilities list
  var nextText = "NEXT: OUT-OF-POCKET MAX";
  if (showOOPMax) {
    nextText = "NEXT: OUT-OF-POCKET MAX";
  } else {
    nextText = "NEXT: SEE MY QUOTE";
  }

  // Format the deductible percentage value as a percentage to show to the user
  var deductiblePercentageValue = props.deductiblePercentage * 100 + "%";

  // Format the deductible vallue as a dollar amount to show to the user
  var deductibleAmount =
    props.deductible != "" &&
    props.deductible != "none" &&
    props.deductible != "I don't know"
      ? "$" + props.deductible
      : props.deductible;

  // Determine what to show to the user if deductible is met is undefined, true, or false
  var isDeductibleMetValue;
  if (props.isDeductibleMet == "") {
    isDeductibleMetValue = props.isDeductibleMet;
  } else {
    isDeductibleMetValue =
      props.isDeductibleMet == "true"
        ? "Yes - I have met my deductible"
        : "No - I have not met my deductible";
  }

  // I don't know blub
  var iDontKnow;
  if (props.deductible == "I don't know") {
    iDontKnow = (
      <div className="i-dont-know idk-d text-small">
        See our glossary for more information about{" "}
        <Link
          className=""
          to={{
            pathname: "/glossary",
            state: { searchTerm: "deductible" },
          }}
        >
          deductibles
        </Link>
        . For now we have assumed your deductible is $2000 for{" "}
        <Link
          className=""
          to={{
            pathname: "/glossary",
            state: { searchTerm: "in-network" },
          }}
        >
          in-network
        </Link>{" "}
        care, based on the national average. You can update this for a more
        accurate search at any time.
      </div>
    );
  } else {
    iDontKnow = null;
  }

  if (props.currentStep !== 4) {
    return null;
  } else {
    var deductibleFilled = props.deductible != null && props.deductible != "";
    var isDeductibleMetFilled =
      props.isDeductibleMet != null && props.isDeductibleMet != "";
    var active =
      (deductibleFilled && isDeductibleMetFilled) ||
      props.deductible == "I don't know";

    return (
      <div>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="d-flex"
          style={{ gap: "32px" }}
        >
          <div style={{ gap: "24px" }} className="d-flex w-100">
            <DesktopSelect
              name="deductible"
              label="Deductible amount"
              options={deductibleOptions}
              formGroupClasses="select-input"
              formLabelClasses="quote-form-label text-small"
              inputClasses="landing-page-input text-medium"
              placeholder="Deductible amount"
              hide={false}
              onChange={props.handleSelectChange}
              onClick={props.handleSelectChange}
              value={deductibleAmount}
              showArrow={props.showArrow}
              showDropdownArrow={true}
            />

            <DesktopSelect
              name="isDeductibleMet"
              label="Deductible met?"
              options={isDeductibleMetOptions}
              formGroupClasses="select-input revealed-input"
              formLabelClasses="quote-form-label text-small"
              inputClasses="landing-page-input text-medium"
              placeholder="Deductible met?"
              onClick={props.handleSelectChange}
              onChange={props.handleSelectChange}
              hide={hideIsMet}
              value={isDeductibleMetValue}
              showArrow={props.showArrow}
            />

            <DesktopSelect
              name="deductiblePercentage"
              label="Percentage paid"
              options={percentageOptions}
              formGroupClasses="select-input revealed-input"
              formLabelClasses="quote-form-label text-small"
              inputClasses="landing-page-input text-medium"
              placeholder="Percentage paid"
              onClick={props.handleSelectChange}
              onChange={props.handleSelectChange}
              hide={hidePercentagePaid}
              value={deductiblePercentageValue}
              showArrow={props.showArrow}
            />
          </div>
          <Button
            class="outline-btn"
            onClick={handleSubmit}
            active={active}
            label={nextText}
          />
        </Form>
        {iDontKnow}
      </div>
    );
  }
}

function OOPMax(props) {
  const [validated, setValidated] = useState(false);
  const history = useHistory();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    validateOOPMax(event);
    setValidated(true);
  };

  const validateOOPMax = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (props.oopMax == "I don't know") {
      getFacilities(e);
    } else if (props.oopMax == "") {
      console.log("No oopMax input");
    } else {
      if (props.isMaxOopMet == "") {
        console.log("No is oop max met input");
      } else {
        getFacilities(e);
      }
    }
  };

  const getFacilities = (e) => {
    e.stopPropagation();
    e.preventDefault();

    history.push({
      pathname: "/facilities",
      from: "/estimates",
      state: {
        service: props.service,
        serviceName: props.serviceName,
        zipCode: props.zipCode,
        insuranceProvider: props.insurance,
        deductible:
          props.deductible == "I don't know" ? 2000 : props.deductible,
        isDeductibleMet: props.isDeductibleMet,
        deductiblePercentage:
          props.isDeductibleMet == "true" ? 1 : props.deductiblePercentage,
        oopMax: props.oopMax == "I don't know" ? 4000 : props.oopMax,
        isMaxOopMet: props.isMaxOopMet,
        oopMaxPercentage:
          props.isMaxOopMet == "true" ? 1 : props.oopMaxPercentage,
        currentStep: 5,
      },
    });
  };

  if (props.currentStep !== 5) {
    return null;
  } else {
    // Drop down options
    let oopMaxOptions = [{ option: "I don't know", value: "I don't know" }];
    var validDeductibleAmount =
      props.deductible != "" &&
      props.deductible != "none" &&
      props.deductible != "I don't know";
    var lowestOopValue = validDeductibleAmount ? parseInt(props.deductible) : 0;
    for (let i = lowestOopValue; i <= 6000; i = i + 250) {
      const item = { option: "$" + i, value: i };
      oopMaxOptions.push(item);
    }

    const isMaxOopMetOptions = [
      { option: "Yes - I have met my out-of-pocket max", value: "true" },
      { option: "No - I have not met my out-of-pocket max", value: "false" },
    ];

    // Determine whether or not to show 'Is max oop met?' input
    var hideIsMet = true;
    if (
      props.oopMax != "" &&
      props.oopMax != "none" &&
      props.oopMax != "I don't know"
    ) {
      hideIsMet = false;
    }

    // Determine if percentage of opm paid input should be show
    var hidePercentPaid = false;
    var percentageOptions = [];
    var lowestValue = 0;
    if (
      props.isDeductibleMet == "false" ||
      props.isMaxOopMet == "" ||
      props.isMaxOopMet == "true"
    ) {
      hidePercentPaid = true;
    } else {
      hidePercentPaid = false;

      lowestValue = props.lowestOopMaxPercentageValue;

      for (let i = 0; i <= 10; i = i + 1) {
        const item = {
          option: i == 0 ? "0%" : i + "0%",
          value: i == 10 ? "1" : "0." + i,
          disabled: lowestValue > i ? true : false,
        };
        percentageOptions.push(item);
      }
    }

    // Determine display values
    var oopMaxValue;
    if (props.oopMax == "I don't know") {
      oopMaxValue = "I don't know";
    } else {
      oopMaxValue =
        props.oopMax != "" && props.oopMax != "none"
          ? "$" + props.oopMax
          : props.oopMax;
    }

    var isMaxOopMetValue;
    var disabledInput;

    if (props.isDeductibleMet == "false") {
      isMaxOopMetValue = "No - I have not met my out-of-pocket max";
      disabledInput = true;
    } else if (props.isMaxOopMet != "") {
      isMaxOopMetValue =
        props.isMaxOopMet == "true"
          ? "Yes - I have met my out-of-pocket max"
          : "No - I have not met my out-of-pocket max";
      disabledInput = false;
    } else {
      isMaxOopMetValue = props.isMaxOopMet;
    }

    var OOPPercentageValue = props.oopMaxPercentage * 100 + "%";

    var iDontKnow;
    if (props.oopMax == "I don't know") {
      iDontKnow = (
        <div className="i-dont-know idk-o text-small">
          See our glossary for more information about{" "}
          <Link
            className=""
            to={{
              pathname: "/glossary",
              state: { searchTerm: "out of pocket maximum" },
            }}
          >
            out of pocket maximums
          </Link>
          . For now we have assumed your out of pocket max is $4000 for{" "}
          <Link
            className=""
            to={{
              pathname: "/glossary",
              state: { searchTerm: "in-network" },
            }}
          >
            in-network
          </Link>{" "}
          care, based on the national average. You can update this for a more
          accurate search at any time.
        </div>
      );
    } else {
      iDontKnow = null;
    }

    var oopMaxFilled = props.oopMax != null && props.oopMax != "";
    var isMaxOopMetFilled =
      props.isMaxOopMet != null && props.isMaxOopMet != "";
    var active =
      (oopMaxFilled && isMaxOopMetFilled) || props.oopMax == "I don't know";

    return (
      <div>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="d-flex"
          style={{ gap: "32px" }}
        >
          <div style={{ gap: "24px" }} className="d-flex w-100">
            <DesktopSelect
              name="oopMax"
              label="Out-of-pocket max"
              options={oopMaxOptions}
              formGroupClasses="select-input"
              formLabelClasses="quote-form-label text-small"
              inputClasses="landing-page-input text-medium"
              placeholder="Out-of-pocket max"
              hide={false}
              lowestValue={lowestOopValue}
              onClick={props.handleSelectChange}
              onChange={props.handleSelectChange}
              value={oopMaxValue}
              showArrow={props.showArrow}
            />

            <DesktopSelect
              name="isMaxOopMet"
              label="Out-of-pocket max met?"
              options={isMaxOopMetOptions}
              formGroupClasses="select-input revealed-input"
              formLabelClasses="quote-form-label text-small"
              inputClasses="landing-page-input text-medium"
              placeholder="Out-of-pocket max met?"
              onClick={props.handleSelectChange}
              onChange={props.handleSelectChange}
              hide={hideIsMet}
              value={isMaxOopMetValue}
              disabled={disabledInput}
              showArrow={props.showArrow}
            />

            <DesktopSelect
              name="oopMaxPercentage"
              label="Percentage paid"
              options={percentageOptions}
              formGroupClasses="select-input revealed-input"
              formLabelClasses="quote-form-label text-small"
              inputClasses="landing-page-input text-medium"
              placeholder="Percentage paid"
              onClick={props.handleSelectChange}
              onChange={props.handleSelectChange}
              hide={hidePercentPaid}
              value={OOPPercentageValue}
              lowestValue={props.lowestOopMaxPercentageValue}
              showArrow={props.showArrow}
            />
          </div>
          <Button
            class="outline-btn"
            onClick={handleSubmit}
            active={active}
            label="NEXT: SEE MY QUOTE"
          />
        </Form>
        {iDontKnow}
      </div>
    );
  }
}

function NotAvailable(props) {
  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY =
    process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, "\n");

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);

      document.getElementById("not-available").style.display = "none";
      document.getElementById("thank-you-container").style.display = "block";
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + "email_lists";
    axios
      .post(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },

        contact_first_name: name,
        contact_last_name: "",
        contact_email: email_address,
        query_type: "sign up",
      })
      .then((res) => {});
  };

  const handleClick = (e) => {
    const email_address = document.querySelector("input[name='email']").value;
    const name = document.querySelector("input[name='name']").value;
    const today = new Date();

    const newRow = {
      Email: email_address,
      Name: name,
      "Submitted On": today.toLocaleDateString("en-US"),
      "Query Type": "sign up",
    };

    appendSpreadsheet(newRow);
    createContact(email_address, name);
  };

  if (props.currentStep !== 999) {
    return null;
  } else {
    return (
      <div className="not-available">
        <div id="not-available">
          <img src="https://img.icons8.com/emoji/124/000000/eyes-emoji.png" />
          <p className="not-available-heading">
            Darn! We weren’t able to find that service in your area.{" "}
          </p>
          <p className="text-large m-0">
            Handl Health is still growing. Sign up to find out when we’ve
            reached your zip.
          </p>
          <section>
            <TextInput
              type="text"
              name="name"
              inputClasses="not-available-input"
              placeholder="Enter your name"
              formGroupClasses="w-100"
              onChange={props.handleChange}
              autoFocus={true}
            />
            <TextInput
              type="text"
              name="email"
              inputClasses="not-available-input"
              placeholder="Enter your email address"
              formGroupClasses="w-100"
              onChange={props.handleChange}
              autoFocus={true}
            />
          </section>

          <Button
            active={true}
            class="submit-btn with-shadow"
            label="SIGN UP"
            onClick={handleClick}
          />

          <p className="start-over pointer" onClick={props.startOver}>
            Try another service.
          </p>
        </div>
        <div id="thank-you-container">
          <p className="thank-you-text">Thanks! We’ll be in touch.</p>
        </div>
      </div>
    );
  }
}

export default EstimateFormDesktop;
