import React from "react";
import "./ShowMore.css";

export default function ShowMore({
  type,
  toShow,
  totalCount,
  filled,
  showMoreHandler,
}) {
  return (
    <div>
      <p
        style={{
          paddingTop: "28px",
          marginBottom: "12px",
          textAlign: "center",
        }}
        className="showing-count-text"
      >
        Showing {Math.min(toShow, totalCount)} of {totalCount} {type}
      </p>
      <div
        style={{ marginTop: "0px" }}
        className="facility-page__services-count"
      >
        <div
          style={{ marginBottom: "30px" }}
          className="facility-page__services-count-bar"
        >
          <div
            className="facility-page__services-count-bar-filled"
            style={{ width: `${filled}%` }}
          />
        </div>
        <button
          style={{ paddingBottom: "50px" }}
          className={`see-more-estimates ${
            toShow === totalCount ? "all-appts-visible" : ""
          }`}
          onClick={() => {
            showMoreHandler();
          }}
        >
          See more {type}
        </button>
      </div>
    </div>
  );
}
