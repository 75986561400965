import React, { useState, useEffect } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router-dom";
import ForgotPassword from "../SignIn/ForgotPassword";
import PopupModal from "../../components/templates/PopupModal";
import FAQAccordian from "../SignIn/FAQAccordian";
import "./SignIn.css";
import SignInForm from "../../components/modules/SignInForm";

export default function SignIn({ setToken }) {
  const history = useHistory();

  function onSubmitAction() {
    history.push("/billing");
  }

  const accordianInformation = [
    {
      title: "I don’t have an account.",
      body: "Don’t already have a Handl Health account? Click the “Create New Account” button above to set up your profile and get started using Handl Health.",
    },
    {
      title: "I can’t remember my password.",
      body: "If you can’t remember your password, click the “Forgot Password?” link under Log In, above, and follow the directions outlined.",
    },
  ];
  return (
    <div className="account-creation-sign-up account-creation-margin-top flex-wrapper-space-between">
      <div className="account-creation-main-content">
        <h5 className="fw-200 account-creation-header">
          Let’s Handl this. Sign in to your account.
        </h5>
        <SignInForm
          onSubmitAction={onSubmitAction}
          isMainSignIn={true}
          setToken={setToken}
        />
      </div>

      <div className="account-creation-signin-faq">
        <a className="sign-in__privacy-link" href="/privacy" target="_blank">
          Handl Health Privacy Policy
        </a>
        <FAQAccordian accordianInformation={accordianInformation} />
      </div>
    </div>
  );
}
