import React, { useState, useEffect, Fragment } from "react";
import left from "../../assets/icons/left.svg";
import Button from "../../components/elements/Button";
import axios from "axios";
import Input from "../../components/elements/Input";
import "./BillDetails.css";
import S3 from "react-aws-s3";
import cancel from "../../assets/icons/popup-cancel.svg";

export default function BillDetails({
  setCurrentScreen,
  billingRecord,
  updateBillingRecord,
}) {
  const [smallScreen, setSmallScreen] = useState(false);
  const [billAmountNotValid, setBillAmountNotValid] = useState(false);
  const [showMultipleDates, setShowMultipleDates] = useState(false);
  const [dateText, setDateText] = useState("My visit was more than one day.");
  const [providersList, setProvidersList] = useState([]);
  const [contentReady, setContentReady] = useState(false);
  const [billDetails, setBillDetails] = useState({
    insurancePlan:
      !billingRecord?.remaining_tasks?.billing_details.includes(
        "insurance_provider_id"
      ) && billingRecord?.insurance_provider === null
        ? "I don't have insurance"
        : billingRecord?.insurance_provider?.issuer_legal_name ?? "",
    billAmount: billingRecord?.bill_amount ?? "",
    startDateOfService: billingRecord?.service_start_date
      ? billingRecord?.service_start_date
      : "",
    endDateOfService: billingRecord?.service_end_date
      ? billingRecord?.service_end_date
      : "",
    billPDF: billingRecord?.copy_of_bill_path ?? "",
  });

  const fileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(
    billingRecord?.copy_of_bill_path?.split(".com/")?.[1] ?? ""
  );
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getInsuranceProviders();
  }, []);

  useEffect(() => {
    if (contentReady === true) {
      updateBillingRecord({
        insurance_provider_id: billDetails.insuranceID,
        bill_amount: billDetails.billAmount,
        service_start_date:
          billDetails.startDateOfService !== ""
            ? billDetails.startDateOfService
            : null,
        service_end_date:
          billDetails.endDateOfService !== ""
            ? billDetails.endDateOfService
            : billDetails.startDateOfService !== ""
            ? billDetails.startDateOfService
            : null,
        copy_of_bill_path:
          billDetails?.billPDF !== "" ? billDetails.billPDF : null,
      });
    }
  }, [contentReady]);

  // get insurance providers
  function getInsuranceProviders() {
    const URL = process.env.REACT_APP_API_URL + "insurance_providers";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setProvidersList([["None", "I don't have insurance"], ...res.data]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function camelCaseToCapitalized(text) {
    const result = text.replace(/([A-Z])/g, (str) => " " + str.toLowerCase());
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  function processLabels(item) {
    if (item === "insurancePlan" || item === "billAmount") {
      return camelCaseToCapitalized(item);
    } else if (item === "billPDF") {
      return "Photo/PDF of bill (optional)";
    } else if (item === "startDateOfService") {
      return "Date of service";
    }
  }

  function handleMultipleDates() {
    setShowMultipleDates(!showMultipleDates);
    setDateText(
      dateText === "My visit was more than one day."
        ? "My bill only has one date."
        : "My visit was more than one day."
    );
  }

  const uploadPDF = (file) => {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME_USER_BILLS,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS,
      secretAccessKey: process.env.REACT_APP_SECRET,
    };
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, file?.name)
      .then((data) => {
        if (data.status === 204) {
          setBillDetails({
            ...billDetails,
            billPDF: data?.location,
          });
          setContentReady(true);
        } else {
          console.log("fail");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function handleBillUpload() {
    if (selectedFile === "") {
      setContentReady(true);
    } else {
      uploadPDF(selectedFile);
    }
  }

  return (
    <div className="hipaa-form">
      <div
        className={smallScreen === true ? "bill-tracker__top-container" : ""}
      >
        <Button
          iconFirst
          icon={<img style={{ marginRight: "11px" }} src={left} />}
          class="text-btn"
          className={`mobile-search__close 
              width-904 hipaa-form__return-to-bill`}
          label="Return to bill"
          onClick={() => {
            setCurrentScreen("bill tracker");
          }}
          active={true}
        />
        <div className="hipaa-form__text-container width-904">
          <h5
            style={{ marginBottom: "24px" }}
            className={`hipaa-form__text-container-heading fw-600
            `}
          >
            Bill details
          </h5>
          <ul
            style={smallScreen ? { lineHeight: "22px" } : {}}
            className={`poppins ${smallScreen ? "body-2" : "body-1"} ${
              !smallScreen ? "bill-tracker-update__ul" : ""
            }`}
          >
            <li>
              <span style={{ marginBottom: "16px" }}>
                Here’s how this works
              </span>
            </li>
            <li>
              These details will allow us to accurately reconcile your bill
            </li>
            <li>
              {" "}
              Please make sure the information is the same as what’s on the bill
              you received from the doctor
            </li>
          </ul>
        </div>
      </div>
      <section
        style={smallScreen ? { paddingInline: "16px" } : {}}
        className="hipaa-form__section"
      >
        {Object.keys(billDetails)
          .filter((item) => item !== "insuranceID")
          .map((item) => (
            <Fragment key={item}>
              {item === "endDateOfService" ? null : (
                <>
                  <p className="hipaa-form__heading body-1">
                    {processLabels(item)}
                  </p>
                  {item !== "billPDF" ? (
                    <>
                      <Input
                        placeholder={processLabels(item)}
                        showCurrencyPrefix={item === "billAmount"}
                        name={item}
                        label={processLabels(item)}
                        type={item.includes("Date") ? "date" : "text"}
                        value={billDetails[item]}
                        onChange={(e) => {
                          setBillAmountNotValid(false);
                          setBillDetails({
                            ...billDetails,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        onSelect={(val, name, value) => {
                          setBillDetails({
                            ...billDetails,
                            [item]: val,
                            [item === "insurancePlan" && "insuranceID"]: value,
                          });
                        }}
                        inputContainerClasses="sign-up-form__input"
                        showDropdownArrow={item === "insurancePlan"}
                        readOnly={false}
                        options={
                          item === "insurancePlan"
                            ? providersList.map((i) => ({
                                label: i[1],
                                value: i[0],
                              }))
                            : null
                        }
                        forceAutocomplete={item === "insurancePlan"}
                        onBlur={(e) => {
                          if (item === "billAmount") {
                            setBillAmountNotValid(
                              !/^[1-9]\d*(\.\d+)?$/.test(billDetails.billAmount)
                            );
                          }
                        }}
                        showErrorIcon={true}
                        showErrorMessage={
                          item === "billAmount" && billAmountNotValid === true
                        }
                        errorMessage={"Please enter a valid bill amount"}
                      />
                      {item === "startDateOfService" && (
                        <>
                          {showMultipleDates === false && (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleMultipleDates()}
                            >
                              {dateText}
                            </span>
                          )}
                          {showMultipleDates === true && (
                            <>
                              <Input
                                placeholder="End date of service"
                                type="date"
                                name="endDateOfService"
                                label="End date of service"
                                value={billDetails.endDateOfService}
                                onChange={(e) => {
                                  setBillDetails({
                                    ...billDetails,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                                inputContainerClasses="settings__input"
                                showArrow={false}
                                readOnly={false}
                              />
                              <span onClick={() => handleMultipleDates()}>
                                {dateText}
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="upload-section">
                      <Button
                        active={true}
                        label="Upload bill"
                        className="onboarding-steps__next-btn "
                        onClick={() => handleClick()}
                        class="long-btn outline-btn-blue"
                      />
                      <input
                        type="file"
                        ref={fileInput}
                        accept="application/pdf, image/jpeg, image/png"
                        style={{ display: "none" }}
                        onChange={(e) => handleChange(e)}
                      ></input>
                      {selectedFile !== "" && (
                        <div
                          style={{ justifyContent: "space-between" }}
                          className="flex-row"
                        >
                          <p>
                            {typeof selectedFile === "string"
                              ? selectedFile
                              : selectedFile?.name}
                          </p>

                          <img
                            onClick={() => {
                              fileInput.current.value = "";
                              setBillDetails({ ...billDetails, billPDF: null });
                              setSelectedFile("");
                            }}
                            src={cancel}
                          />
                        </div>
                      )}
                      <p className="text-small">
                        Make sure the bill you are uploading matches the visit
                        details you provided. <br />
                        We only accept photos and PDF versions of your bill.
                      </p>
                    </div>
                  )}
                </>
              )}
            </Fragment>
          ))}
      </section>
      <div style={{ paddingBottom: "40px" }}>
        <Button
          active={true}
          label="Save"
          className="onboarding-steps__next-btn "
          onClick={() => {
            handleBillUpload();
          }}
          class="long-btn filled"
        />
      </div>
    </div>
  );
}
