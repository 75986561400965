import ServiceDetails from '../../components/sections/ServiceDetails'
import Mobile from '../../components/templates/Mobile'
import './ServiceSearch.css'
import { useLocation, useHistory } from "react-router-dom";
import { createRef } from 'react';

function ServiceSearch() {
  const location = useLocation();
  const history = useHistory();
  const quoteRef = createRef();


  const service = location.state.service;
  const serviceName = location.state.serviceName;
  const zipCode = location.state.zipCode;
  const insurance_provider = location.state.insurance_provider; 
  const deductible = location.state.deductible;
  const is_deductible_met = location.state.is_deductible_met;
  const deductible_percentage = location.state.deductible_percentage;
  const max_oop = location.state.max_oop;
  const is_max_oop_met = location.state.is_max_oop_met;
  const max_oop_percentage = location.state.max_oop_percentage;
  const currentStep = location.state.currentStep;

  const formData = currentStep != null? { service: service, serviceName: serviceName, zipCode: zipCode, insurance_provider: insurance_provider, deductible: deductible, is_deductible_met: is_deductible_met, deductible_percentage: deductible_percentage, max_oop: max_oop, is_max_oop_met: is_max_oop_met,  max_oop_percentage: max_oop_percentage, currentStep: currentStep } : null
  
  const onClick = () => {
    history.push("/search");
  }

  const getFacilities = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const deductible = quoteRef.current.state.deductible == "I don't know"? 2000: quoteRef.current.state.deductible;
    const is_deductible_met = quoteRef.current.state.isDeductibleMet;
    const deductible_percentage = quoteRef.current.state.isDeductibleMet == 'true'? 1: quoteRef.current.state.deductiblePercentage;

    const max_oop = quoteRef.current.state.oopMax == "I don't know"? 4000: quoteRef.current.state.oopMax;
    const is_max_oop_met = quoteRef.current.state.isMaxOopMet;
    const max_oop_percentage = quoteRef.current.state.isMaxOopMet == 'true'? 1: quoteRef.current.state.oopMaxPercentage;

    const zipCode = quoteRef.current.state.zipCode;
    const insurance_provider = quoteRef.current.state.insurance; 
    const serviceName = quoteRef.current.state.serviceName;

    const currentStep = quoteRef.current.state.currentStep;

    const insurance = {insurance_provider: insurance_provider, deductible: deductible, is_deductible_met: is_deductible_met, deductible_percentage: deductible_percentage, max_oop: max_oop, is_max_oop_met: is_max_oop_met,  max_oop_percentage: max_oop_percentage };

    history.push({
      pathname: '/facilities',
      state: { service: service, serviceName: serviceName, zipCode: zipCode, insurance_provider: insurance_provider, deductible: deductible, is_deductible_met: is_deductible_met, deductible_percentage: deductible_percentage, max_oop: max_oop, is_max_oop_met: is_max_oop_met,  max_oop_percentage: max_oop_percentage, currentStep: currentStep  }
    })
  }

  return (
    <Mobile 
      body={<ServiceDetails 
              cpt_code={service} 
              onSubmit={getFacilities} 
              quoteRef={quoteRef}
              formData={formData}/>}
      back={onClick} 
      lastPage='SEARCH'
      showQuoteNavigation={true} 
      showMobileNav={true} 
      mobileNavColor='white'
    />
  ); 
  
}

export default ServiceSearch;
