import axios from "axios";
import Button from "../../components/elements/Button";
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import PopupModal from "../../components/templates/PopupModal";
import Input from "../../components/elements/Input";
import useToken from "../../hooks/useToken";

function DeletionActions(props) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showAfterDeletionModal, setShowAfterDeletionModal] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const [accountDeletionInfo, setAccountDeletionInfo] = useState({
    password: "",
    confirmedPassword: "",
    date: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    password: "Password is incorrect. Please try again.",
    confirmedPassword: "Passwords do not match. Please try again.",
    date: "The date you entered is incorrect.",
  });

  const [passwordIsCorrect, setPasswordIsCorrect] = useState(null);
  const [passwordsMatch, setPasswordsMatch] = useState(null);
  const [dateIsValid, setDateIsValid] = useState(null);

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { token, removeToken } = useToken();
  function deleteAccount() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL + "users/users/" + props.userId;
    axios
      .delete(URL, {
        params: {
          password: accountDeletionInfo.password,
        },

        headers: {
          "Content-Type": "application/json",
          Authorization: props.token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          removeToken();
          setShowModal(false);
          setShowAfterDeletionModal(true);
          window.scrollTo(0, 0);
        }
      })
      .catch(function (error) {
        // Error
        console.log(error.response);
        if (error.response.status === 401) {
          setPasswordIsCorrect(false);
        }
      });
  }

  const DeleteAccountElement = () => {
    function validateDate(e) {
      return (
        new Date(e).toLocaleDateString() === new Date().toLocaleDateString()
      );
    }

    function handleAccountDeletion() {
      setDateIsValid(validateDate(accountDeletionInfo.date));
      if (
        accountDeletionInfo.password ===
          accountDeletionInfo.confirmedPassword &&
        validateDate(accountDeletionInfo.date) === true
      ) {
        deleteAccount();
      }
    }

    const ConfirmAccountDeletionModal = () => {
      function closeModal() {
        setShowModal(false);
        setDateIsValid(null);
        setPasswordIsCorrect(null);
        setPasswordsMatch(null);
        setAccountDeletionInfo({
          password: "",
          confirmedPassword: "",
          date: "",
        });
      }
      return (
        <PopupModal
          noPadding
          show={showModal}
          close={closeModal}
          size="lg"
          dialogClassName="settings__deletion-modal"
          position="centered"
          body={
            <div className="sign-up__modal-body">
              <p className="sign-up__modal-header">
                Are you sure you want to delete your account?
              </p>
              <p className={`${smallScreen ? "body-2" : "body-1"} color-gray `}>
                All records of your account will be permanently deleted. If you
                are sure you want to delete, please input your account details
                below.
              </p>
              {Object.keys(accountDeletionInfo).map((info) => (
                <Input
                  key={info}
                  type={info === "date" ? "text" : "password"}
                  name={info}
                  label={
                    info === "password"
                      ? "Password"
                      : info === "confirmedPassword"
                      ? "Confirm password"
                      : "Today's date"
                  }
                  placeholder={
                    info === "password"
                      ? "Password"
                      : info === "confirmedPassword"
                      ? "Confirm password"
                      : "MM/DD/YYYY"
                  }
                  value={accountDeletionInfo[info]}
                  showErrorMessage={
                    info === "confirmedPassword"
                      ? accountDeletionInfo.password !== "" &&
                        accountDeletionInfo.confirmedPassword !== "" &&
                        passwordsMatch === false
                      : info === "password"
                      ? passwordIsCorrect === false
                      : info === "date" &&
                        accountDeletionInfo?.date !== "" &&
                        dateIsValid === false
                  }
                  showErrorIcon={true}
                  errorMessage={errorMessages[info]}
                  onBlur={() =>
                    (info === "confirmedPassword" || info === "password") &&
                    setPasswordsMatch(
                      accountDeletionInfo.password ===
                        accountDeletionInfo.confirmedPassword
                    )
                  }
                  inputContainerClasses="deletion-actions__popup-input"
                  onChange={(e) => {
                    setDateIsValid(null);
                    setPasswordsMatch(null);
                    setPasswordIsCorrect(null);
                    setAccountDeletionInfo({
                      ...accountDeletionInfo,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              ))}
              <Button
                label="Delete my account"
                active={Object.values(accountDeletionInfo).every(
                  (item) => item !== ""
                )}
                class="long-btn filled"
                onClick={() => handleAccountDeletion()}
              />
              <Button
                label="Go back"
                active={true}
                class="outline-btn-blue"
                onClick={() => closeModal()}
              />
            </div>
          }
        />
      );
    };
    const AfterDeletionModal = () => {
      function closeModal() {
        setShowAfterDeletionModal(false);
      }
      return (
        <PopupModal
          noPadding
          show={showAfterDeletionModal}
          close={closeModal}
          size="lg"
          dialogClassName="settings__deletion-modal"
          position="centered"
          body={
            <div className="sign-up__modal-body">
              <p className="sign-up__modal-header">
                Your Handl Health account has been permanently deleted.
              </p>
              <p className={`${smallScreen ? "body-2" : "body-1"} color-gray `}>
                You will receive an email confirmation of your account deletion
                within 24 hours.
              </p>
              <Button
                label="Go to home page"
                active={true}
                class="long-btn filled"
                onClick={() => history.push("/")}
              />
            </div>
          }
        />
      );
    };
    return (
      <div className="settings__deletion-section">
        {ConfirmAccountDeletionModal()}
        {AfterDeletionModal()}
        <p className="settings__subtitle">Account deletion</p>
        <p>
          Deleting your account will remove all account history and data. You
          will not be able to restore your account once its been deleted.
        </p>
        <Button
          label="Delete Your Account"
          active={true}
          class="long-btn text-btn"
          onClick={() => setShowModal(true)}
        />
      </div>
    );
  };

  return <div className="settings__deletions">{DeleteAccountElement()}</div>;
}

export default DeletionActions;
