import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    currentUser: {},
  },
  reducers: {
    authenticate: (state, action) => {
      state.currentUser = action.payload;
      state.loggedIn = true;
    },
    deauthenticate: (state) => {
      state.currentUser = {};
      state.loggedIn = false;
    },
  },
});

export const { authenticate, deauthenticate } = userSlice.actions;
export default userSlice.reducer;
