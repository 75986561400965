import "./LandingPage.css";
import search from "../../assets/icons/search.png";

import Button from "../../components/elements/Button";

import Loading from "../../components/modules/Loading";

import HandlCategory from "../../components/sections/HandlCategory";
import WhatWeDo from "../../components/sections/WhatWeDo";
import FirstVisit from "../../components/sections/FirstVisit";

import Desktop from "../../components/templates/Desktop";
import TabSection from "../../components/templates/TabSection";
import Mobile from "../../components/templates/Mobile";
import PopupModal from "../../components/templates/PopupModal";

import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EstimateFormDesktop from "../../components/sections/EstimateFormDesktop";
import EstimatesCatalog from "../../components/sections/EstimatesCatalog";
import ProgressBar from "react-bootstrap/ProgressBar";
import CostEstimatorTools from "../../components/sections/CostEstimatorTools";
import Footer from "../../components/sections/Footer";
import { withRouter } from "react-router-dom";
import NotAvailable from "../../components/sections/NoEstimatesInYourArea";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      smallScreen: "",
      show: false,
      commonlySearchedServices: [],
      loading: true,
      progress: 0,
      clickedService: "",
      serviceCategories: [],
      mobileSearchText: "Search for services & procedures",
      viewPopup: false,
      showErrorScreen: false,
      currentStep: 1, // Default is Step 1
    };

    this.getMostCommonlySearchedServices =
      this.getMostCommonlySearchedServices.bind(this);
    this.handleErrorScreenState = this.handleErrorScreenState.bind(this);
    this.handleCurrentSteps = this.handleCurrentSteps.bind(this);

    this.checkDevice = this.checkDevice.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
    this.fillServiceInput = this.fillServiceInput.bind(this);
  }

  getMostCommonlySearchedServices() {
    const URL =
      process.env.REACT_APP_API_URL + "services/most_searched_services";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        const commonlySearchedServices = res.data.data.map((s) => s.attributes);
        this.setState({ commonlySearchedServices });
        this.setState({ loading: false });
        this.fillServiceInput(this.props?.location?.query?.serviceName);
      })
      .catch(function (error) {
        // Error
        console.log(error.response);
      });
  }

  handleCurrentSteps(updatedCurrentStep) {
    this.setState({
      currentStep: updatedCurrentStep,
    });
  }
  handleErrorScreenState() {
    this.setState({
      showErrorScreen: true,
    });
  }
  checkDevice() {
    const resolution = window.innerWidth;
    const smallScreen = resolution <= 768;
    this.setState({
      smallScreen: smallScreen,
    });

    if (resolution <= 450) {
      this.setState({
        mobileSearchText: "Search for services",
      });
    } else {
      this.setState({
        mobileSearchText: "Search for services and procedures",
      });
    }
  }

  componentDidMount() {
    this.checkDevice();
    this.getMostCommonlySearchedServices();
    window.addEventListener("resize", this.checkDevice.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkDevice.bind(this));

    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  showModal = () => {
    this.setState({ show: !this.state.show });
  };

  closePopup = () => {
    this.setState({ viewPopup: false });
  };

  updateProgress(value) {
    this.setState({
      progress: value,
    });
  }

  fillServiceInput(value) {
    this.setState({
      clickedService: value,
    });
    document.getElementById("main-content")?.scrollIntoView();
    if (value !== undefined && this.state.progress < 10) {
      this.updateProgress(10);
    }
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      let displayCategories = [];
      const firstVisitPopup = (
        <PopupModal
          size="sm"
          position="left"
          show={this.state.viewPopup}
          close={this.closePopup}
          body={<FirstVisit close={this.closePopup} />}
        />
      );

      return (
        <main className="h-100 landing-page-main" id="main-content">
          <ProgressBar
            variant="dark"
            now={this.state.progress}
            id="progress-bar"
          />

          <div className="estimates-main-container flex-wrapper-space-between">
            {firstVisitPopup}
            <div className="estimates-content">
              {this.state.showErrorScreen === true ? (
                <NotAvailable />
              ) : (
                <>
                  <p
                    onClick={() => {
                      if (this.state.currentStep === 1) {
                        this.props.history.push("/");
                      } else {
                        this.updateProgress(
                          this.state.currentStep === 3
                            ? 20
                            : this.state.currentStep === 4
                            ? 40
                            : this.state.currentStep === 5
                            ? 50
                            : this.state.progress - 10
                        );
                        this.handleCurrentSteps(this.state.currentStep - 1);
                      }
                    }}
                    className="body-2 pointer"
                  >
                    <span className="back-arrow" /> BACK
                  </p>
                  <div
                    className="estimates-search-container"
                    id="service-quote-form"
                  >
                    <EstimateFormDesktop
                      show={true}
                      clickedService={this.state.clickedService}
                      ref={this.props.quoteRef}
                      updateProgress={this.updateProgress}
                      handleCurrentSteps={this.handleCurrentSteps}
                      currentStep={this.state.currentStep}
                      services={this.state.services}
                      commonServices={this.state.commonlySearchedServices}
                      handleErrorScreenState={this.handleErrorScreenState}
                      fillServiceInput={this.fillServiceInput}
                    />
                  </div>

                  <EstimatesCatalog
                    onClick={this.fillServiceInput}
                    currentLocation={"/estimates"}
                  />

                  <CostEstimatorTools />
                </>
              )}
            </div>
            <Footer />
          </div>
        </main>
      );
    }
  }
}

export default withRouter(LandingPage);
