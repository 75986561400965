import React, { useState, useEffect, useRef } from "react";
import Button from "../../components/elements/Button";
import Input from "../../components/elements/Input";
import { PDFDocument } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import S3 from "react-aws-s3";
import mobilePDF from "../../assets/icons/mobilePDF.svg";
import { useSelector } from "react-redux";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import left from "../../assets/icons/left.svg";

export default function HipaaForm({
  setCurrentStep,
  userID,
  allPages,
  billInformation,
  progressInterval,
  setBillInformation,
  setProgressPercentage,
  progressPercentage,
  handleBillingInformationSubmission,
  billingRecord,
  setCurrentScreen,
  updateBillingRecord,
  facility,
}) {
  const [smallScreen, setSmallScreen] = useState(false);
  const loggedIn = useSelector((state) => state?.currentUser?.loggedIn);

  const [userData, setUserData] = useState({
    address: "",
    address2: "",
    state: "",
    city: "",
    zipCode: "",
  });
  const [fullName, setFullName] = useState("");
  const [pdfInfo, setPdfInfo] = useState([]);
  const [pdfBlob, setPdfBlob] = useState();
  const sigCanvas = useRef(null);
  const [hasViewedPDF, setHasViewedPDF] = useState(false);
  const [hasTappedPad, setHasTappedPad] = useState(false);
  const [signatureEmpty, setSignatureEmpty] = useState(true);
  const [contentUpdated, setContentUpdated] = useState(false);

  const [userPDF, setUserPDF] = useState();
  const { token, setToken } = useToken();
  const location = useLocation();

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getUserInformation();
    sigCanvas?.current?.off();
  }, []);

  useEffect(() => {
    if (fullName === "") return;
    createAndFillFields();
  }, [fullName]);

  useEffect(() => {
    if (hasViewedPDF === true) {
      sigCanvas?.current?.on();
    }
  }, [hasViewedPDF]);

  useEffect(() => {
    if (!pdfBlob) return;
    uploadPDF();
  }, [pdfBlob]);

  useEffect(() => {
    if (!contentUpdated || billingRecord !== undefined) return;
    handleBillingInformationSubmission();
  }, [contentUpdated]);

  const createAndFillFields = async () => {
    const existingPdfBytes = await fetch(
      "https://handllegaldocuments.s3.us-west-1.amazonaws.com/HIPAA+Authorization.pdf"
    ).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPages()[0];
    // add name
    page.drawText(fullName, {
      x: 70,
      y: 315,
      size: 10,
    });
    // add facility text
    (billInformation?.facilityName ?? billingRecord?.where_was_visit) &&
      page.drawText(
        billInformation?.facilityName ?? billingRecord?.where_was_visit,
        {
          x: 180,
          y: 682.7,
          size: 10,
        }
      );

    // add second facility text
    (billInformation?.facilityName ?? billingRecord?.where_was_visit) &&
      page.drawText(
        billInformation?.facilityName ?? billingRecord?.where_was_visit,
        {
          x: 255,
          y: 475.4,
          size: 10,
        }
      );

    // add facility contact info
    function drawLinesOfText(info) {
      let ycoord = 457.4;
      info.map((item, idx) => {
        ycoord = ycoord - 12;
        page.drawText(item, {
          x: 255,
          y: ycoord,
          size: 10,
        });
      });
    }
    (facility ?? billingRecord?.facility).facility_phone &&
      drawLinesOfText([
        (facility ?? billingRecord?.facility).facility_address,
        (facility ?? billingRecord?.facility).facility_city +
          ", " +
          (facility ?? billingRecord?.facility).facility_state +
          " " +
          (facility ?? billingRecord?.facility).zip_code,
        (facility ?? billingRecord?.facility).facility_phone,
      ]);
    // add date
    page.drawText(new Date().toLocaleDateString(), {
      x: 350,
      y: 315,
      size: 10,
    });

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    setUserPDF(docUrl);
  };

  const addSignature = async () => {
    setContentUpdated(false);
    const existingPdfBytes = await fetch(userPDF).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const page = pdfDoc.getPages()[0];

    // Get the width and height of the first page

    const pngUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    page.drawImage(pngImage, {
      width: 100,
      height: 26,
      x: 70,
      y: 270,
    });
    const pdfBytes = await pdfDoc.save();

    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    setPdfBlob(blob);
    const docUrl = URL.createObjectURL(blob);
    setPdfInfo(docUrl);
  };

  const uploadPDF = () => {
    const file = pdfBlob && pdfBlob;
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS,
      secretAccessKey: process.env.REACT_APP_SECRET,
    };
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(
      file,
      fullName +
        "-" +
        (billInformation?.facilityName ?? billingRecord?.where_was_visit)
    )
      .then((data) => {
        if (data.status === 204) {
          if (billingRecord === undefined) {
            setBillInformation({
              ...billInformation,
              ["signedPDFPath"]: data?.location,
              ["signedDate"]: new Date().toLocaleDateString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              }),
            });
            setContentUpdated(true);
          } else {
            updateBillingRecord({
              signed_hipaa_path: data?.location,
              signed_hipaa_date: new Date().toLocaleDateString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
              }),
            });
          }
        } else {
          console.log("fail");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function clearSignature() {
    sigCanvas.current.clear();
    setHasTappedPad(false);
  }

  const stateList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  function camelCaseToCapitalized(text) {
    const result = text.replace(/([A-Z])/g, (str) => " " + str.toLowerCase());
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  function updateUserZipCode() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/update_zip_code";
    axios
      .patch(
        URL,
        { zip_code: userData.zipCode },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {})
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        const userInformation = res.data.data.attributes;
        setFullName(userInformation.full_name);
        setUserData({
          address: userInformation?.street_address
            ? userInformation?.street_address
            : "",
          address2: userInformation?.street_address_two
            ? userInformation?.street_address_two
            : "",
          state: userInformation?.state ? userInformation?.state : "",
          city: userInformation?.city ? userInformation?.city : "",
          zipCode: userInformation?.zip_code?.zip_codes
            ? userInformation?.zip_code?.zip_codes
            : "",
        });
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  function updateUserInformation(data) {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userID;
    axios
      .patch(
        URL,
        { user_information: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          if (billingRecord !== undefined && signatureEmpty === true) {
            setCurrentScreen("bill tracker");
          }
        } else {
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }
  function handleHIPAAFormSubmission(skipped) {
    if (skipped === false) {
      if (!signatureEmpty) addSignature();
      const data = {
        street_address: userData.address !== "" ? userData.address : null,
        street_address_two: userData.address2,
        city: userData.city !== "" ? userData.city : null,
        state: userData.state !== "" ? userData.state : null,
      };
      updateUserInformation(data);
      if (userData.zipCode !== "") updateUserZipCode();
    }
    if (signatureEmpty === true && billingRecord === undefined)
      setContentUpdated(true);
  }

  return (
    <div className="hipaa-form">
      <div
        className={
          billingRecord !== undefined && smallScreen === true
            ? "bill-tracker__top-container"
            : ""
        }
      >
        <Button
          iconFirst
          icon={<img style={{ marginRight: "11px" }} src={left} />}
          class="text-btn"
          className={`mobile-search__close ${
            billingRecord !== undefined
              ? "width-904 hipaa-form__return-to-bill"
              : "hipaa-form__text-container hipaa-form__back"
          }`}
          label={!billingRecord ? "BACK" : "Return to bill"}
          onClick={() => {
            if (billingRecord !== undefined) {
              setCurrentScreen("bill tracker");
            } else {
              setCurrentStep(
                loggedIn === true
                  ? allPages[allPages.length - 1].inputLabel
                  : "account creation"
              );
              setProgressPercentage(progressPercentage - progressInterval);
            }
          }}
          active={true}
        />
        <div
          className={`hipaa-form__text-container ${
            billingRecord !== undefined ? "width-904" : ""
          }`}
        >
          <h5
            style={{ marginBottom: "24px" }}
            className={`hipaa-form__text-container-heading ${
              billingRecord !== undefined ? "fw-600" : "fw-200"
            }`}
          >
            HIPAA agreement
          </h5>
          <ul
            style={smallScreen ? { lineHeight: "22px" } : {}}
            className={`poppins ${smallScreen ? "body-2" : "body-1"} ${
              billingRecord !== undefined
                ? !smallScreen
                  ? "bill-tracker-update__ul"
                  : ""
                : null
            }`}
          >
            <li>
              <span style={{ marginBottom: "16px" }}>
                Here’s how this works
              </span>
            </li>
            <li>
              We need your permission to access your medical information and
              bills
            </li>
            <li>
              {" "}
              By signing the HIPAA agreement, you’re allowing us to contact your
              healthcare provider on your behalf
            </li>
          </ul>
        </div>
      </div>
      <section className="hipaa-form__section">
        <p className="hipaa-form__heading body-1">Home address</p>
        {Object.entries(userData)
          .filter(
            (item) =>
              !["signedPDFPath", "signedDate", "userName"].includes(item[0])
          )
          .map((el) => (
            <Input
              key={el[0]}
              placeholder={
                el[0] === "address2"
                  ? "Address 2"
                  : camelCaseToCapitalized(el[0])
              }
              name={el[0]}
              label={
                el[0] === "address2"
                  ? "Address 2"
                  : camelCaseToCapitalized(el[0])
              }
              value={el[1]}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  [e.target.name]: e.target.value,
                });
              }}
              onSelect={(val) => {
                setUserData({
                  ...userData,
                  [el[0]]: val,
                });
              }}
              showDropdownArrow={el[0] === "state"}
              forceAutocomplete={el[0] === "state"}
              options={
                el[0] === "state" &&
                stateList.map((i) => ({ label: i, value: i }))
              }
            />
          ))}
      </section>
      <section className="hipaa-form__section" style={{ marginTop: "26.5px" }}>
        <p className="hipaa-form__heading body-1">Sign form</p>
        <div className="flex-row hipaa-form__pdf-container">
          <img src={mobilePDF} alt="PDF icon" />
          <a
            onClick={() => setHasViewedPDF(true)}
            href={
              billingRecord === undefined ||
              billingRecord?.signed_hipaa_path === null
                ? userPDF
                : billingRecord.signed_hipaa_path
            }
            target="_blank"
            className="view-pdf-link"
          >
            View HIPAA form PDF
          </a>
        </div>
        {(billingRecord === undefined ||
          billingRecord?.signed_hipaa_path === null) && (
          <>
            <p className="hipaa-form__sign-form-helper-text">
              HIPAA document must be viewed in order to continue.
            </p>

            <div
              className="hipaa-form__canvas-container"
              style={{ position: "relative" }}
            >
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                onBegin={() => setHasTappedPad(true)}
                onEnd={() => setSignatureEmpty(sigCanvas.current.isEmpty())}
                clearOnResize={false}
                canvasProps={{
                  className: "sigCanvas",
                }}
              />
              {!hasTappedPad && (
                <p className="hipaa-form__add-signature-text">
                  Tap to add signature
                </p>
              )}

              <Button
                active={!signatureEmpty}
                label="Clear"
                class="text-btn"
                onClick={() => clearSignature()}
              />
            </div>
          </>
        )}
        <div
          style={billingRecord !== undefined ? { paddingBottom: "40px" } : {}}
        >
          <Button
            active={true}
            label="Save"
            className="onboarding-steps__next-btn "
            onClick={() => handleHIPAAFormSubmission(false)}
            class="long-btn filled"
          />
        </div>
        {billingRecord === undefined && (
          <Button
            active={true}
            label="Skip"
            onClick={() => handleHIPAAFormSubmission(true)}
            class="outline-btn-black"
            className="hipaa-form__submit-btn"
          />
        )}
      </section>
    </div>
  );
}
