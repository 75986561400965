import './WhatWeDo.css';

function  WhatWeDo() {
  return (
    <div className='what-we-do ' id='what-we-do'>
      <p className="what-we-do-heading">Handl Health’s cost estimator makes shopping for healthcare services quick & easy.</p>
      <div className='what-we-do-content'>
        <div className='what-we-do-section'>
          <p className="bold what-we-do-section-heading">Search for the care you need</p>
          <p>Find the service or procedure you’re looking for with our intuitive search platform.</p>
        </div>

        <div className='what-we-do-section'>
          <p className="bold what-we-do-section-heading">Compare & contrast by price</p>
          <p>Clearly see the prices offered by healthcare providers in your area.</p>
        </div>

        <div className='what-we-do-section'>
          <p className="bold what-we-do-section-heading">Choose the best option for you</p>
          <p>Decide on the winning provider and create an account to save the info all in one place.</p>
        </div>
      </div>
    </div>
  );
}
export default WhatWeDo;
