import './Loading.css'
import Paragraph from '../elements/Paragraph'
import Spinner from 'react-bootstrap/Spinner';

function Loading(props){
  return (
    <div className="loading">
      <p className="text-large handl-logo">Handl Health</p>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loading;
