import "./FirstVisit.css";
import Form from "react-bootstrap/Form";
import TextInput from "../elements/inputs/TextInput";
import Button from "../elements/Button";
import axios from "axios";
import { GoogleSpreadsheet } from "google-spreadsheet";
import ReCAPTCHA from "react-google-recaptcha";
import React, { Component, useEffect } from "react";
import { useState } from "react";

function FirstVisit(props) {
  const SPREADSHEET_ID = process.env.REACT_APP_MAILING_LIST_SPREADSHEET_ID;
  const SHEET_ID = process.env.REACT_APP_MAILING_LIST_SHEET_ID;
  const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_MAILING_LIST_ACCOUNT_EMAIL;
  const PRIVATE_KEY =
    process.env.REACT_APP_GOOGLE_MAILING_LIST_PRIVATE_KEY.replace(/\\n/g, "\n");
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();
      const sheet = doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const createContact = (email_address, name) => {
    const URL = process.env.REACT_APP_API_URL + "email_lists";
    axios
      .post(
        URL,
        {
          contact_first_name: name,
          contact_last_name: "",
          contact_email: email_address,
          query_type: "sign up",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {});
  };

  const [validated, setValidated] = useState(false);

  const recaptchaRef = React.createRef();

  const [toSend, setToSend] = useState({
    from_name: "",
    from_email: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue.length) {
      document.getElementById("captcha-text").style.display = "block";
      return;
    }

    const email_address = toSend.from_email;
    const name = toSend.from_name;
    const today = new Date();
    const newRow = {
      Email: email_address,
      Name: name,
      "Submitted On": today.toLocaleDateString("en-US"),
      "Query Type": "sign up",
    };

    appendSpreadsheet(newRow);
    createContact(email_address, name);
    props.close();
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="first-visit-popup">
      <p className="first-visit-heading">Handl Health is in Beta.</p>
      <p className="first-visit-text">
        We’re currently serving the San Francisco Bay Area, CA and Pittsburgh,
        PA.
      </p>
      <p className="first-visit-text">
        Sign up to be notified when we add new locations, or let us know what
        area we should tackle next!{" "}
      </p>

      <Form
        noValidate
        validated={validated}
        onSubmit={onSubmit}
        className="contact-form"
      >
        <TextInput
          type="text"
          name="from_name"
          value={toSend.from_name}
          inputClasses="contact-input"
          formLabelClasses="contact-input-label text-small"
          label="Name"
          placeholder="First name"
          formGroupClasses="w-100"
          onChange={handleChange}
          autoFocus={true}
        />
        <TextInput
          type="text"
          name="from_email"
          value={toSend.from_email}
          label="Email"
          inputClasses="contact-input"
          formLabelClasses="contact-input-label text-small"
          placeholder="We’ll get back to you at this email address"
          formGroupClasses="w-100"
          onChange={handleChange}
          autoFocus={false}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_INVISIBLE_RECAPTCHA_SITE_KEY}
          size="invisible"
        />
        <p className="captcha-text" id="captcha-text">
          You must not be a robot.
        </p>
        <Button active={true} class="" label="SEND MESSAGE" />
      </Form>
    </div>
  );
}
export default FirstVisit;
