import React from "react";
import { useHistory } from "react-router-dom";
import "./Legalese.css";

export default function Privacy() {
  const history = useHistory();

  return (
    <main className="legalese privacy-policy">
      <div className="legalese-container">
        <p
          onClick={() => {
            history.goBack();
          }}
          className="body-2 pointer"
        >
          <span className="back-arrow" /> BACK
        </p>
        <section>
          <h1>Handl Health, INC Privacy Policy</h1>
          <br />
          <p>
            Effective date and last modified: [10/13/2022]
            <br />
            <br />
            Handl Health, INC. (“Handl,” “we,” “our,” or “us”) provides this
            Privacy Policy (“Policy”) to describe the personal information we
            collect, how we use it, with whom we share it, and the choices
            available regarding our use of the information. We also describe the
            measures we take to protect the security of the information and how
            to contact us about our privacy practices. This Privacy Policy
            applies to personal information the Company collects from you,
            through our interactions with you, through websites under the
            control of the Company that link to this Privacy Policy, including
            www.gohandlhealth.com and www.handlhealth.com, and any other website
            or mobile application under the control of Handl that links to this
            Policy (collectively, the “Services”), as well as how we use and
            protect personal information.
            <br />
            <br />
            BY PROVIDING PERSONAL INFORMATION TO US OR BY USING THE SERVICES,
            YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE WITH THIS
            POLICY. IF YOU DO NOT AGREE WITH ANY PART OF THIS POLICY, THEN
            PLEASE DO NOT USE THE SERVICES.
            <br />
            PLEASE BE AWARE THAT HANDL AND ALL ASSOCIATED SERVICES AND SYSTEMS
            ARE HOUSED ON SERVERS IN THE UNITED STATES. IF YOU ARE LOCATED
            OUTSIDE OF THE UNITED STATES, INFORMATION WE COLLECT (INCLUDING
            COOKIES) ARE PROCESSED AND STORED IN THE UNITED STATES, WHICH MAY
            NOT OFFER THE SAME LEVEL OF PRIVACY PROTECTION AS THE COUNTRY WHERE
            YOU RESIDE OR ARE A CITIZEN. BY USING THE SERVICES AND PROVIDING
            INFORMATION TO US, YOU CONSENT TO THE TRANSFER TO AND PROCESSING OF
            THE INFORMATION IN THE UNITED STATES.
          </p>
        </section>
        <article>
          <h2>PERSONAL INFORMATION WE COLLECT</h2>
          <p>
            As described in more detail below, we may collect certain
            information from you through the Services, including personal
            information may collect the following personal information (i.e.,
            individual information that may be used to personally identify or
            contact you).
          </p>
          <p>We may collect the following information:</p>
          <ul>
            <li>
              <b>Identifiers and Contact information</b>, including your first
              and last name, physical address, email address, and phone number.
            </li>
            <li>
              <b>Personal Information Categories</b>, including your Social
              Security number, or other government-issued identification number,
              health information (e.g., health insurance coverage, medical
              records, medical history, treatment or diagnosis).
            </li>
            <li>
              <b>Commercial Information and Related Transaction Information</b>,
              including your designated payment method (bank account number,
              debit or credit card number, or other financial information) and
              related contact information.
            </li>
            <li>
              <b>Contact preference information</b>, including your preferred
              method of receiving marketing communications (email, text, or
              physical mail).
            </li>
            <li>
              <b>
                Protected Classification Characteristics and Account Profile
                Information
              </b>
              , including age, gender, gender identity, sexual orientation,
              birthdate, account credentials, communication preferences, and
              other information about your profile or account.
            </li>
            <li>
              <b>
                Sensory Data and Information You Share During Communications and
                Interactions With Us
              </b>
              , which may include the content of email messages, chat sessions,
              text messages, and phone calls.
            </li>
            <li>
              <b>Location or Geolocation Data</b>, including device ID and
              device location information (if your device settings permit us to
              collect this information).
            </li>
            <li>
              <b>
                Internet or Other Similar Network, Browsing, and Device Activity
              </b>
              , including information about your browsing activity, IP address,
              unique device identifiers, cookie identifiers, device and browser
              settings and information, and Internet service provider
              information. We may also collect information about when and how
              you access and use our Services, such as the date and time of your
              visit or use, the websites visited before and after visiting our
              Services, how you navigate and what you search for on the
              Services, the pages visited on the Services, and items you view
              and interact with.
            </li>
            <li>
              <b>Inferences</b>, including information derived from any of the
              information above to create a profile about you and identify your
              preferences, characteristics, interests, behavior, or other
              matters.
            </li>
          </ul>
        </article>
        <article>
          <h2>HOW WE COLLECT PERSONAL INFORMATION</h2>
          <p>
            We collect personal information in various ways, both directly and
            indirectly. For example, when you send us postal mail, email, call
            us, contact us on our Services, or register an account on our
            Services, we may collect information directly from you. We may also
            collect personal information indirectly through automated means, for
            example, when you interact with our Services, we may use
            technologies to collect information about how you use the Services.
          </p>
          <p>
            <u>We may collect personal information when you provide it.</u>
          </p>
          <p>
            We may collect personal information directly from you or your device
            as follows:
          </p>
          <ul>
            <li>
              <b>When you register for an account</b>, it is necessary for you
              to provide personal information that includes your name and email
              address. You may choose to provide us with additional personal
              information, including physical addresses, phone number, and email
              address. You will also create a username and password, information
              that on subsequent visits helps us confirm your identity and makes
              it easier for you to navigate the Services.
            </li>
            <li>
              <b>When you interact with us</b>, including when you contact us by
              phone, text, email, or chat.
            </li>
          </ul>

          <p>
            <u>
              We may collect personal information using automated technologies.
            </u>
          </p>

          <p>
            Personal information may be collected through automated technologies
            and shared with us when you visit our Services or open emails from
            us.
            <br />
            <br />
            We may automatically create website and mobile application log
            files, which are created when you access our Services. In addition,
            automated data collection technologies – such as cookies, beacons,
            tags, software development kits (SDK), and scripts – are used by us
            to analyze trends, administer the Services, and track movements
            around the Services. We, and our third-party service providers, also
            use these technologies to gather demographic information about
            individuals accessing the Services as individuals and in the
            aggregate.
            <br />
            <br />A “cookie” is a small text file that is stored on an
            individual’s computer and downloaded to the computer or mobile
            device when they visit a website. Cookies then send information back
            to the originating website on each subsequent visit, or to another
            website that recognizes that same cookie. We use the following types
            of cookies:
          </p>

          <ul>
            <li>
              Essential Cookies are required to make our Services function
              properly and cannot be turned off in our systems. Depending on how
              you access the Services, you may not be able to disable these
              cookies. However, if you block these cookies, portions of our
              Services may not function properly.
            </li>
            <li>
              Performance and Functional Cookies allow us to count visits and
              sources of traffic on our Services so we can measure and improve
              performance and functionality. They help us know which pages of
              our Services are most and least popular, which features of the
              Services are used most, and how visitors move around the Services.
            </li>
            <li>
              Advertising Cookies may be set through our Websites by our
              advertising partners. They may be used by advertising companies to
              build a profile of your interests and show you relevant
              advertisements on other websites.
            </li>
          </ul>
          <p>
            We may combine information collected automatically (as described
            above) from your interactions with our Services and your activity on
            third-party sites with other data (including personal information).
            If you wish to opt out of allowing certain cookies, you may do so by
            changing your browser settings so that cookies from our Services
            cannot be set.
            <br />
            <br />
            Find out how to manage cookies on popular browsers:
          </p>
          <ul>
            <li>
              <a
                href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Chrome
              </a>
            </li>
            <li>
              <a
                href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Microsoft Edge
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mozilla Firefox
              </a>
            </li>
            <li>
              <a
                href="https://www.opera.com/help/tutorials/security/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Opera
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Apple Safari
              </a>
            </li>
          </ul>
        </article>
        <article>
          <h2>HOW WE USE THE INFORMATION WE COLLECT</h2>
          <br />

          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>
              <b>to provide our products and Services and contract with you</b>,
              including provision of the Services and helping you identify
              Services of use to you;
            </li>

            <li>
              <b>
                to administer your account and manage our relationship with you
              </b>
              , including management of information, activity, and feedback;
            </li>

            <li>
              <b>
                to personalize and improve our products and Services and your
                overall customer experience
              </b>
              , in particular by improving our existing technologies and
              developing new products and Services;
            </li>

            <li>
              <b>to conduct business analytics</b>, including consumer and
              operations research, to assess the effectiveness of our Services,
              marketing, and advertising, and to analyze market trends and
              (future) customer demand;
            </li>

            <li>
              <b>to ensure the security of our Services and technology</b>,
              including the detection, prevention, and investigation of attacks;
            </li>

            <li>
              <b>
                to protect against, identify, and prevent fraud and other crime,
                claims and other liabilities;
              </b>
            </li>

            <li>
              <b>
                to protect the rights, safety, health, and security of our
                employees and to protect our property;
              </b>
            </li>

            <li>
              <b>
                to anonymize information for further internal and external use
              </b>{" "}
              in a manner that does not identify you;
            </li>

            <li>
              <b>to comply with legal obligations</b> ; and
            </li>

            <li>
              <b>to establish, exercise or defend a legal claim.</b>
            </li>
          </ul>

          <p>
            We may use the information we collect about you in other ways, which
            we will tell you about at the time we collect it or before we begin
            using it for those other purposes.
            <br />
            When you provide us with personal information about dependents and
            family members, we will only use this information for the specific
            reason for which it is provided.
          </p>
        </article>
        <article>
          <h2>WHEN WE SHARE PERSONAL INFORMATION</h2>

          <p>
            We may share personal information by disclosing it to a third-party
            for a business purpose, including the following:
          </p>

          <ul>
            <li>
              To audit our interactions with you to ensure compliance with
              applicable law and to measure the effectiveness of our products
              and Services;
            </li>
            <li>
              To detect, prevent, and prosecute harmful, fraudulent, or illegal
              activity;
            </li>
            <li>To identify issues and repair our Services;</li>
            <li>
              To provide Services, such as customer service, order fulfillment,
              and payment processing, which we either conduct or engage service
              providers to conduct on our behalf;
            </li>
            <li>For research and development;</li>
            <li>
              To further our business goals, including to advertise our products
              and Services; and
            </li>
            <li>For quality assurance.</li>
          </ul>
          <p>
            In the preceding twelve (12) months, we may have disclosed personal
            information for a business purpose to the categories of
            third-parties indicated in the chart below.
            <br />
            We do not sell personal information, and in the preceding twelve
            (12) months, we have not sold personal information.
          </p>

          <table>
            <thead>
              <tr>
                <th rowSpan="2">Personal Information Category</th>
                <th colSpan="2">Category of Third-Party Recipients</th>
              </tr>
              <tr>
                <th>Business Purpose Disclosures</th>
                <th>Sales</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A: Identifiers.</td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>
                  B: California Customer Records personal information
                  categories.
                </td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>
                  C: Protected classification characteristics under California
                  or federal law.
                </td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>D: Commercial information.</td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>E: Biometric information.</td>
                <td>None</td>
                <td>None</td>
              </tr>

              <tr>
                <td>F: Internet or other similar network activity.</td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>G: Geolocation data.</td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>H: Sensory data.</td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>

              <tr>
                <td>I: Professional or employment-related information.</td>
                <td>None</td>
                <td>None</td>
              </tr>

              <tr>
                <td>J: Non-public education information.</td>
                <td>None</td>
                <td>None</td>
              </tr>

              <tr>
                <td>K: Inferences drawn from other personal information.</td>
                <td>
                  Affiliates, partners, parent/subsidiary organizations, service
                  providers, and government entities
                </td>
                <td>None</td>
              </tr>
            </tbody>
          </table>
        </article>
        <article>
          <br />

          <h2>
            CHOICES REGARDING THE USE OF PERSONAL INFORMATION; DO NOT TRACK; AND
            APPLICABLE RIGHTS
          </h2>

          <p>
            You may “opt-out” of receiving communications from us, except to the
            extent such information is necessary to complete any services or
            transactions you have entered into, by emailing us at
            support@handlhealth.com.
            <br />
            You may also opt out of receiving certain promotional communications
            from us by clicking the "unsubscribe" link in certain
            communications. Please note that you may not be able to opt out of
            receiving all communications from us, such as administrative
            messages, service announcements, messages regarding the terms and
            conditions of an account, or other messages that may contain
            important information regarding an account.
            <br />
            <br />
            If you would prefer that we not share personal information with our
            business partners or vendors for marketing purposes, you may opt-out
            of this by sending a request to support@handlhealth.com.
            <br />
            <br />
            Do Not Track: Some web browsers (including Safari, Internet
            Explorer, Firefox and Chrome) incorporate a “Do Not Track” (“DNT”)
            or similar feature that signals to websites that a user does not
            want to have his or her online activity and behavior tracked. If a
            website that responds to a particular DNT signal receives the DNT
            signal, the browser can block that website from collecting certain
            information about the browser’s user. Not all browsers offer a DNT
            option and DNT signals are not yet uniform. For this reason, many
            website operators, including [CLIENT], do not respond to DNT
            signals.
            <br />
            <br />
            <u>
              <b>Applicable Rights</b>
            </u>
            <br />
            You have the right to request to view and correct personal
            information from the Services. Such requests may be submitted by
            sending a request to support@handlhealth.com.
            <br />
            <br />
            If personal information about you changes, you have the right to
            update or request deletion of information collected on the Services,
            or if you believe a third party has provided us with personal
            information about you and you would like to request that it be
            edited or removed from our database, please send a request to
            support@handlhealth.com.
            <br />
            <br />
            If you are a California resident, California law may provide you
            with additional rights regarding our collection and use of personal
            information. To learn more about your California privacy rights,
            please refer to Section 10 of this Policy.
            <br />
          </p>
        </article>
        <article>
          <h2>LINKS TO OTHER WEBSITES AND SOCIAL MEDIA</h2>

          <p>
            This Policy discloses the privacy practices for our Services;
            however, for your convenience, we may provide links to other
            websites. Please be aware that this Policy does not apply to the
            other websites. We encourage you to review the privacy statements of
            each website before providing any information.
          </p>
        </article>
        <article>
          <h2>INFORMATION SECURITY</h2>

          <p>
            While no website or electronic data can ever be completely secure,
            we are committed to maintaining up-to-date and appropriate security
            measures and safeguards. We maintain a variety of physical,
            electronic, and procedural safeguards to help prevent unauthorized
            access to and improper use of personal information. For example, our
            employees’ computers are password protected, we use a secured
            messaging service when we send personal information electronically,
            and we provide regular education to our employees regarding our
            security and privacy practices. We want you to feel confident in our
            security when using the Services, but we cannot guarantee the
            security of any information from or about you 100% of the time.
            Please refer to the U.S. Federal Trade Commission’s website for
            information on how to protect yourself from identity theft.
          </p>
        </article>
        <article>
          <h2>RETENTION</h2>

          <p>
            We retain personal information in identifiable form only for as long
            as necessary to fulfill the purposes for which the personal
            information was provided to Handl or, if longer, to comply with
            legal obligations, to resolve disputes, to enforce agreements and
            similar essential purposes.
          </p>
        </article>
        <article>
          <h2>
            ADDITIONAL STATE RIGHTS AND DISCLOSURES FOR CALIFORNIA RESIDENTS
          </h2>
          <br />
          <p>
            <u>
              <b>Data Collection</b>
            </u>
            <br />
            Our Services collect information that identifies, relates to,
            describes, references, is capable of being associated with, or could
            reasonably be linked, directly or indirectly, with a particular
            consumer or device (<b>“personal information”</b>). In particular,
            the Services collect or may have collected in the last twelve (12)
            months the categories of personal information as described in
            Section 1 above.
            <br />
            <br />
            <u>
              <b>Use of Personal Information</b>
            </u>
            <br />
            We may use or disclose the personal information we collect for one
            or more of the business purposes indicated in Section 3 above.
            <br />
            We will not collect additional categories of personal information or
            use the personal information we collected for materially different,
            unrelated, or incompatible purposes without providing you notice.
            <br />
            <br />
            <u>
              <b>Sharing Personal Information</b>
            </u>
            <br />
            We may disclose personal information to a third party for a business
            purpose. When we disclose personal information for a business
            purpose, we enter a contract that describes the purpose and requires
            the recipient to both keep that personal information confidential
            and not use it for any purpose except performing the contract. We
            share personal information with the categories of third parties
            listed in Section 4 above.
            <br />
            In the preceding twelve (12) months, we have disclosed the following
            categories of personal information for a business purpose:
          </p>
          <ul>
            <li>Identifiers and Contact information</li>
            <li>Personal Information Categories</li>
            <li>Commercial Information and Related Transaction Information</li>
            <li>Contact preference information</li>
            <li>
              Protected Classification Characteristics and Account Profile
              Information
            </li>
            <li>
              Sensory Data and Information You Share During Communications and
              Interactions With Us
            </li>
            <li>Location or Geolocation Data</li>
            <li>
              Internet or Other Similar Network, Browsing, and Device Activity
            </li>
            <li>Inferences</li>
          </ul>
          We do not sell personal information. In the event that we do sell any
          personal information, we will update this Privacy Policy to list the
          categories of consumers’ personal information sold.
          <br />
          <br />
          <u>
            <b>Your Rights and Choices</b>
          </u>
          <br />
          Certain state laws, including the California Consumer Privacy Act
          (CCPA), may provide residents with specific rights regarding their
          personal information. This section describes the rights you may have
          and explains how to exercise those rights.
          <br />
          <br />
          <em>
            Right to Access Specific Information and Data Portability Right
          </em>
          <br />
          You have the right to request that we disclose certain information to
          you about our collection and use of personal information over the past
          twelve (12) months.
          <br />
          <br />
          Once we receive and confirm your verifiable consumer request, we will
          disclose to you:
          <ul>
            <li>
              The categories of personal information we collected about you.
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you.
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              personal information.
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information.
            </li>
            <li>
              The specific pieces of personal information we collected about you
              (also called a data portability request).
            </li>
            <li>
              If we disclosed personal information for a business purpose, the
              business purpose for which personal information was disclosed, and
              the personal information categories that each category of
              recipient obtained.
            </li>
          </ul>
          <em>Right to Delete</em>
          <br />
          <br />
          You have the right to request that we delete any personal information
          about you that we collected from you and retained, subject to certain
          exceptions.
          <br />
          Once we receive and confirm your verifiable consumer request, we will
          delete (and direct our service providers to delete) the personal
          information from our records, unless an exception applies.
          <br />
          <br />
          <em>Exercising Your Rights</em>
          <br />
          <br />
          To exercise the access, data portability and deletion rights described
          above, please complete and email us a copy of the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://handllegaldocuments.s3.us-west-1.amazonaws.com/B2B_Handl+-+Verifiable+Consumer+Access+and+Deletion+Request+Form.docx.pdf"
          >
            Verifiable Consumer Request Form
          </a>{" "}
          to support@handlhealth.com.
          <br />
          Only you, or a person registered with the California Secretary of
          State that you authorize to act on your behalf, may make a verifiable
          consumer request related to personal information about you. You may
          also make a verifiable consumer request on behalf of your minor child.
          <br />
          <br />
          You may only make such a request for access or data portability twice
          within a 12-month period. The verifiable consumer request must provide
          sufficient information that allows us to reasonably verify you are the
          person about whom we collected personal information or an authorized
          representative, and describe your request with sufficient detail that
          allows us to properly understand, evaluate and respond to it.
          <br />
          <br />
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We will only use personal information provided in a
          verifiable consumer request to verify the requestor’s identity or
          authority to make the request.
          <br />
          <br />
          We endeavor to respond to a verifiable consumer request within
          forty-five (45) days of its receipt. If we require more time (up to 90
          days), we will inform you of the reason and extension period in
          writing. We will deliver our written response electronically. Any
          disclosures we provide will only cover the 12-month period preceding
          the receipt of the verifiable consumer request. The response we
          provide will also explain the reasons we cannot comply with a request,
          if applicable. For data portability requests, we will select a format
          to provide the personal information that is readily useable and should
          allow you to transmit the information from one entity to another
          entity without hindrance.
          <br />
          <br />
          We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
          <br />
          <br />
          <u>Non-Discrimination</u>
          <br />
          We will not discriminate against you for exercising any of your
          rights.
          <br />
          <br />
        </article>
        <article>
          <h2>CHANGES TO OUR PRIVACY POLICY</h2>
          <p>
            We are continually updating our Service features to better serve
            you. Accordingly, we reserve the right to modify this Policy from
            time to time for any reason. We will notify you of any changes to
            our Policy by updating the effective date at the top of this page,
            and your use after such change signifies your acceptance of the
            changed terms. Unless otherwise indicated, any change to this Policy
            will become effective immediately when the revised Policy is
            available. You should consult this Policy whenever you access the
            Services or otherwise interact with us to stay informed about our
            information practices.
          </p>
        </article>
        <article>
          <h2>HOW TO CONTACT US</h2>
          <p>
            We welcome any feedback or questions you may have concerning the
            collection and processing of personal information. If you have any
            questions about this Policy or our privacy practices, please contact
            us by any of the following methods:
            <br />
            <br />
            You may email us at support@handlhealth.com, or contact us by
            telephone at 562-392-2053 or post at our corporate address, located
            at:
            <br />
            <br />
            Handl Health, INC.
            <br />
            [251 LITTLE FALLS DRIVE, WILMINGTON DE 19808]
            <br />
          </p>
        </article>
      </div>
    </main>
  );
}
