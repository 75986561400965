import React from "react";
import billDetails from "../../../assets/icons/Billing/billDetails.svg";
import billInformation from "../../../assets/icons/Billing/billInformation.svg";
import billOutcome from "../../../assets/icons/Billing/billOutcome.svg";
import "./BillSidebar.css";

export default function BillSidebar() {
  const content = [
    {
      img: billDetails,
      text: "Provide us your bill details",
    },
    {
      img: billInformation,
      text: "We process the information and get back to you",
    },
    {
      img: billOutcome,
      text: "You receive a bill outcome ",
    },
  ];
  return (
    <div className="bill-sidebar">
      <p className="heading-poppins">
        Here’s how we Handl your health bills with our bill review tool
      </p>
      {content.map((element) => (
        <div
          style={{ gap: "8px" }}
          className="flex-col flex-center"
          key={element.text}
        >
          <img src={element.img} alt="billing icon" />
          <p className="text-description fw-400">{element.text}</p>
        </div>
      ))}
    </div>
  );
}
