import "./EstimateForm.css";
import { useEffect, useState } from "react";
import Button from "../elements/Button";
import Input from "../elements/Input";
import { useHistory } from "react-router-dom";
import axios from "axios";

function EstimateForm(props) {
  const history = useHistory();
  const [showForm, setShowForm] = useState(props.showForm);

  const [serviceName, setServiceName] = useState(props.serviceName);
  const [insuranceProvider, setInsuranceProvider] = useState(
    props.insuranceProvider
  );
  const [insurancePlan, setInsurancePlan] = useState(props.insurancePlan);

  const [service, setService] = useState(props.service);
  const [facility, setFacility] = useState(props.facility);
  const [insurance, setInsurance] = useState(props.insurance);
  const [deductible, setDeductible] = useState({
    display: props.deductibleDisplay,
    value: props.deductible,
  });
  const [deductiblePaid, setDeductiblePaid] = useState({
    display: props.deductiblePaidDisplay,
    value: props.deductiblePaid,
  });
  const [oopMax, setOopMax] = useState({
    display: props.oopMaxDisplay,
    value: props.oopMax,
  });
  const [oopMaxPaid, setOopMaxPaid] = useState({
    display: props.oopMaxPaidDisplay,
    value: props.oopMaxPaid,
  });

  const [serviceList, setServiceList] = useState(props.serviceList);
  const [rawInsuranceData, setRawInsuranceData] = useState(
    props.rawInsuranceData
  );
  const [providerList, setProviderList] = useState([]);
  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    setShowForm(props.showForm);
  }, [props.showForm]);
  useEffect(() => {
    setServiceName(props.serviceName);
  }, [props.serviceName]);
  useEffect(() => {
    setService(props.service);
  }, [props.service]);

  useEffect(() => {
    async function getInsuranceOptions() {
      if (facility) {
        const URL =
          process.env.REACT_APP_API_URL +
          "insurance_providers/by_service_and_facility";
        const request = await axios.get(URL, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
          params: {
            facility: facility.id,
            service: (service || {}).id,
          },
        });
        const insuranceData = request.data;
        setRawInsuranceData(insuranceData);

        var provider_data = insuranceData.map((i) => ({
          label: i.insurance_name,
          value: i.insurance_name,
        }));
        var unique_provider_data = [
          ...new Map(
            provider_data.map((item) => [item["label"], item])
          ).values(),
        ];
        setProviderList(unique_provider_data);
      }
    }
    getInsuranceOptions();
  }, [service]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
  };

  var ServiceInput = () => {
    const selectService = (val) => {
      var selectedService = serviceList.find((service) => {
        return service.name === val;
      });

      createUserServiceQuery(serviceName, selectedService);

      setServiceName(val);
      setService(selectedService);
    };

    function createUserServiceQuery(userInput, selectedService) {
      if (userInput != null) {
        const suggestions = serviceList
          .filter(
            (service) =>
              service.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
          )
          .map((s) => s.name);
        const URL = process.env.REACT_APP_API_URL + "user_service_queries";

        axios
          .post(
            URL,
            {
              input: userInput,
              is_mobile: props.smallScreen == true,
              selected_search_result: (selectedService || {}).name,
              rejected_search_results: suggestions,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
              },
            }
          )
          .then((res) => {});
      }
    }

    return (
      <div className="estimate-form__input-container">
        <Input
          options={serviceList.map((service) => ({
            label: service.name,
            cpt_code: service.cpt_code,
            name: service.name,
          }))}
          optionType="service"
          placeholder="Search by service or keyword"
          name="service"
          label="Service"
          value={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
          onSelect={(val) => selectService(val)}
          inputContainerClasses=""
          showArrow={false}
          smallScreen={false}
        />
      </div>
    );
  };

  var FacilityInput = () => {
    return (
      <div className="estimate-form__input-container">
        <Input
          placeholder="Facility"
          name="facility"
          label="Facility"
          value={facility.name}
          inputContainerClasses=""
          showArrow={false}
          readOnly={true}
          smallScreen={props.smallScreen}
        />
      </div>
    );
  };

  var InsuranceProviderInput = () => {
    const selectInsuranceProvider = (val) => {
      setInsuranceProvider(val);

      if (val == "I do not have insurance") {
        setInsurance(val);
      } else {
        setInsurance(null);
        var plans;
        var insurance;

        if (val == "Medicare" || val == "Medicaid") {
          insurance = rawInsuranceData.filter(
            (i) => i.insurance_plan == val.toLowerCase()
          );
          plans = insurance.map((i) => ({
            label: i.issuer_legal_name,
            value: i.issuer_legal_name,
          }));
        } else {
          insurance = rawInsuranceData.filter((i) => i.insurance_name == val);
          plans = insurance.map((i) => ({
            label: i.issuer_legal_name,
            value: i.issuer_legal_name,
          }));
        }

        const unique_plans = [
          ...new Map(plans.map((item) => [item["label"], item])).values(),
        ];

        setPlanList(unique_plans);
      }
    };

    return (
      <div className="estimate-form__input-container">
        <Input
          options={providerList}
          placeholder="Insurance provider"
          label="Insurance provider"
          name="insuranceProvider"
          value={insuranceProvider}
          onChange={(e) => setInsuranceProvider(e.target.value)}
          onSelect={(val) => selectInsuranceProvider(val)}
          inputContainerClasses=""
          showArrow={true}
          smallScreen={false}
        />
      </div>
    );
  };

  var InsurancePlanInput = () => {
    const selectInsurancePlan = (val) => {
      setInsurancePlan(val);

      var selectedInsurance = rawInsuranceData.find((plan) => {
        return plan.issuer_legal_name === val;
      });

      setInsurance(selectedInsurance);
    };

    return (
      <div className="estimate-form__input-container">
        <Input
          options={planList}
          placeholder="Insurance plan"
          label="Insurance plan"
          name="insurancePlan"
          value={insurancePlan}
          onChange={(e) => setInsurancePlan(e.target.value)}
          onSelect={(val) => selectInsurancePlan(val)}
          inputContainerClasses=""
          showArrow={true}
          smallScreen={false}
        />
      </div>
    );
  };

  var deductibleAmountInput = () => {
    const noInsurance =
      insurance == "I do not have insurance" &&
      insuranceProvider == "I do not have insurance";

    const selectDeductible = (val) => {
      if (val == "I don't know") {
        setDeductible({ display: val, value: val });
        setOopMax({ display: val, value: val });
      } else {
        const amount = parseInt(val.substring(1));
        setDeductible({ display: val, value: amount });
      }
    };

    let deductibleAmountOptions = [
      { label: "I don't know", value: "I don't know" },
    ];
    for (let i = 0; i <= 6000; i = i + 250) {
      const item = { label: "$" + i, value: i };
      deductibleAmountOptions.push(item);
    }

    return (
      <div className="estimate-form__input-container">
        <Input
          options={deductibleAmountOptions}
          showAllOptions={true}
          placeholder="Plan deductible"
          label="Plan deductible"
          name="deductible"
          value={deductible.display}
          onChange={(e) =>
            setDeductible({ display: e.target.value, value: deductible.value })
          }
          onSelect={(val) => selectDeductible(val)}
          inputContainerClasses=""
          showArrow={true}
          smallScreen={false}
          readOnly={noInsurance}
        />
      </div>
    );
  };

  var deductiblePaidInput = () => {
    const noInsurance =
      insurance == "I do not have insurance" &&
      insuranceProvider == "I do not have insurance";

    const selectDeductiblePaid = (val) => {
      let percentage = val.split("%")[0];
      let decimal = percentage / 100;
      setDeductiblePaid({ display: val, value: decimal });
    };

    const deductiblePaidOptions = [
      { label: "0% ($0)", value: 0.0 },
      { label: "10% ($" + deductible.value * 0.1 + ")", value: 0.1 },
      { label: "20% ($" + deductible.value * 0.2 + ")", value: 0.2 },
      { label: "30% ($" + deductible.value * 0.3 + ")", value: 0.3 },
      { label: "40% ($" + deductible.value * 0.4 + ")", value: 0.4 },
      { label: "50% ($" + deductible.value * 0.5 + ")", value: 0.5 },
      { label: "60% ($" + deductible.value * 0.6 + ")", value: 0.6 },
      { label: "70% ($" + deductible.value * 0.7 + ")", value: 0.7 },
      { label: "80% ($" + deductible.value * 0.8 + ")", value: 0.8 },
      { label: "90% ($" + deductible.value * 0.9 + ")", value: 0.9 },
      { label: "100% ($" + deductible.value * 1 + ")", value: 1.0 },
    ];

    return (
      <div className="estimate-form__input-container">
        <Input
          options={deductiblePaidOptions}
          showAllOptions={true}
          placeholder="Amount of deductible paid"
          label="Deductible paid"
          name="deductiblePaid"
          value={deductiblePaid.display}
          onChange={(e) =>
            setDeductiblePaid({
              display: e.target.value,
              value: deductiblePaid.value,
            })
          }
          onSelect={(val) => selectDeductiblePaid(val)}
          inputContainerClasses=""
          showArrow={true}
          smallScreen={false}
          readOnly={noInsurance}
        />
      </div>
    );
  };

  var oopMaxInput = () => {
    const noInsurance =
      insurance == "I do not have insurance" &&
      insuranceProvider == "I do not have insurance";

    const selectOopMax = (val) => {
      if (val == "I don't know") {
        setOopMax({ display: val, value: val });
      } else {
        const amount = parseInt(val.substring(1));
        setOopMax({ display: val, value: amount });
      }
    };

    let oopMaxOptions = [{ label: "I don't know", value: "I don't know" }];

    if (deductible.value != "I don't know") {
      let lowestOption = deductible.value != null ? deductible.value : 0;

      for (let i = lowestOption; i <= 6000; i = i + 250) {
        const item = { label: "$" + i, value: i };
        oopMaxOptions.push(item);
      }
    }

    return (
      <div className="estimate-form__input-container">
        <Input
          options={oopMaxOptions}
          showAllOptions={true}
          placeholder="Out-of-pocket maximum"
          label="Out-of-pocket maximum"
          name="oopMax"
          value={oopMax.display}
          onChange={(e) =>
            setOopMax({ display: e.target.value, value: deductible.value })
          }
          onSelect={(val) => selectOopMax(val)}
          inputContainerClasses=""
          showArrow={true}
          smallScreen={false}
          readOnly={noInsurance}
        />
      </div>
    );
  };

  var oopMaxPaidInput = () => {
    const selectOopMaxPaid = (val) => {
      let percentage = val.split("%")[0];
      let decimal = percentage / 100;
      setOopMaxPaid({ display: val, value: decimal });
    };

    const deductibleAmount =
      deductible.value == "I don't know" ? 2000 : deductible.value;
    const amountPaid = deductibleAmount * deductiblePaid.value;
    const percentageOfOopPaid =
      amountPaid == 0 ? amountPaid : amountPaid / oopMax.value;
    const lowestPercentage = Math.round(percentageOfOopPaid * 10);

    var oopMaxPaidOptions = [];

    for (let i = lowestPercentage; i <= 10; i = i + 1) {
      const item = {
        label:
          i == 0 ? "0% ($0)" : i + "0%" + " ($" + (oopMax.value * i) / 10 + ")",
        value: i == 10 ? "1" : "0." + i,
      };
      oopMaxPaidOptions.push(item);
    }

    return (
      <div className="estimate-form__input-container">
        <Input
          options={oopMaxPaidOptions}
          showAllOptions={true}
          placeholder="Amount of out-of-pocket maximum paid"
          label="Out-of-pocket maximum paid"
          name="oopMaxPaid"
          value={oopMaxPaid.display}
          onChange={(e) =>
            setOopMaxPaid({ display: e.target.value, value: oopMaxPaid.value })
          }
          onSelect={(val) => selectOopMaxPaid(val)}
          inputContainerClasses=""
          showArrow={true}
          smallScreen={false}
        />
      </div>
    );
  };

  var EstimateFormSection = () => {
    const noInsurance =
      insurance == "I do not have insurance" &&
      insuranceProvider == "I do not have insurance";
    const hasInsurance =
      insurance != null && insurance != "I do not have insurance";
    const knowsDeductible =
      deductible.value != null && deductible.value != "I don't know";
    const knowsOopMax = oopMax.value != null && oopMax.value != "I don't know";

    const validService = service != null;
    const validFacility = facility != null;
    const validInsurance =
      noInsurance ||
      (rawInsuranceData != null &&
        insurancePlan != null &&
        rawInsuranceData.find(
          (item) => item.issuer_legal_name === insurancePlan
        ) != null);
    const active = validService && validFacility && validInsurance;

    const FormInputs = () => {
      return (
        <form>
          {ServiceInput()}
          {FacilityInput()}
          {InsuranceProviderInput()}
          {!noInsurance ? InsurancePlanInput() : null}
          {deductibleAmountInput()}
          {knowsDeductible ? deductiblePaidInput() : null}
          {oopMaxInput()}
          {knowsOopMax && deductiblePaid.value == 1 ? oopMaxPaidInput() : null}

          <Button
            label="GET MY ESTIMATE"
            class="estimate-form-section__button submit-button"
            active={active}
            onClick={(e) => submitEstimateForm(e)}
          />
        </form>
      );
    };

    function submitEstimateForm(e) {
      e.preventDefault();

      history.push({
        pathname: "/estimate",
        state: {
          data: {
            service: service.cpt_code,
            serviceName: service.name,
            zipCode: facility.zip_code,
            insuranceProvider: insurance,
            deductible:
              deductible.value != "I don't know" && deductible.value != null
                ? deductible.value
                : 2000,
            isDeductibleMet: deductiblePaid.value == 1,
            deductiblePercentage:
              deductiblePaid.value != null ? deductiblePaid.value : 0,
            oopMax:
              oopMax.value != "I don't know" && oopMax.value != null
                ? oopMax.value
                : 4000,
            isMaxOopMet: oopMaxPaid.value == 1,
            oopMaxPercentage: oopMaxPaid.value != null ? oopMaxPaid.value : 0,
            facility: facility.name,
          },
        },
      });
    }

    return (
      <div className="estimate-form-section" key={props.showForm}>
        <h1 className="estimate-form-section__title">
          Looking for a price estimate?
        </h1>
        <p className="estimate-form-section__description">
          Input your insurance details to get a personalized quote.
        </p>
        {showForm ? (
          FormInputs()
        ) : (
          <Button
            label="GET MY ESTIMATE"
            class="estimate-form-section__button"
            active={true}
            onClick={(e) => setShowForm(true)}
          />
        )}
      </div>
    );
  };

  return <div>{EstimateFormSection()}</div>;
}

export default EstimateForm;
