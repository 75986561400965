import React from "react";
import { useState, useEffect } from "react";
import Desktop from "../../components/templates/Desktop";
import Mobile from "../../components/templates/Mobile";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PressReleases.css";

function PressReleases(props) {
  const [pressReleases, setPressReleases] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    async function fetchArticleData() {
      const url = process.env.REACT_APP_API_URL + "press_releases";
      const request = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      });
      const prs = request.data.data.map((pr) => pr.attributes);
      setPressReleases(prs);
      setLoaded(true);
    }
    fetchArticleData();
  }, [props]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  });

  var PressReleaseList = () => {
    const prs = pressReleases.map((x, i) => PressRelease(x));

    return (
      <div className="press-release__body">
        <h1 className="generic-heading">Press Releases</h1>
        <ul className="press-release-list">{prs}</ul>
      </div>
    );
  };

  var PressRelease = (pr) => {
    return (
      <li key={pr} className="press-release">
        <div className="press-release__press-release-image-wrap">
          <img
            className="press-release__press-release-image"
            src={pr.pr_image}
          />
        </div>
        <div className="press-release__press-release-information">
          <p className="press-release__press-release-title">{pr.title}</p>
          <p className="body-1">{pr.summary}</p>
          <a className="press-release__press-release-link" href={pr.url}>
            READ MORE
          </a>
        </div>
      </li>
    );
  };

  if (smallScreen) {
    return (
      <Mobile
        body={<div>{PressReleaseList()}</div>}
        showQuoteNavigation={false}
        showMobileNav={true}
        showFooter={true}
        mobileNavColor="white"
        genericContainer={true}
      />
    );
  } else {
    return <Desktop genericContainer={true} body={PressReleaseList()} />;
  }
}

export default PressReleases;
