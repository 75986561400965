import Form from "react-bootstrap/Form";
import cancel from "../../../assets/icons/cancel.svg";
import React, { Component } from "react";
import "./DesktopSelect.css";
import down from "../../../assets/icons/down.svg";

class DesktopSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      activeSuggestion: 0,
      selectedOption: "",
    };

    this.toggleOptions = this.toggleOptions.bind(this);
    this.hideOptions = this.hideOptions.bind(this);
    this.showOptions = this.showOptions.bind(this);
    this.setActiveSuggestionToLowestValue =
      this.setActiveSuggestionToLowestValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.lowestValue != prevProps.lowestValue) {
      this.setActiveSuggestionToLowestValue();
    }

    const { activeSuggestion } = this.state;
    if (document.querySelector("li[name='" + activeSuggestion + "']")) {
      var myElement = document.querySelector(
        "li[name='" + activeSuggestion + "']"
      );
      var topPos = myElement.offsetTop;

      document.querySelector(".select-input-options").scrollTop = topPos;
    }
  }

  setActiveSuggestionToLowestValue() {
    this.setState({
      activeSuggestion: this.props.lowestValue,
    });
  }

  onClick = (e) => {
    this.toggleOptions();
  };

  onOptionClick = (e) => {
    const { id, innerText } = e.target;

    this.props.onClick(innerText, id, this.props.name);
    this.setState({
      showOptions: false,
    });
  };

  toggleOptions = () => {
    if (this.state.showOptions == false) {
      if (this.props.name == "deductible") {
        if (document.querySelector("ul#isDeductibleMet")) {
          document.querySelector("ul#isDeductibleMet").style.display = "none";
        }
        if (document.querySelector("ul#deductiblePercentage")) {
          document.querySelector("ul#deductiblePercentage").style.display =
            "none";
        }
      } else if (this.props.name == "isDeductibleMet") {
        if (document.querySelector("ul#deductible")) {
          document.querySelector("ul#deductible").style.display = "none";
        }
        if (document.querySelector("ul#deductiblePercentage")) {
          document.querySelector("ul#deductiblePercentage").style.display =
            "none";
        }
      } else if (this.props.name == "deductiblePercentage") {
        if (document.querySelector("ul#deductible")) {
          document.querySelector("ul#deductible").style.display = "none";
        }
        if (document.querySelector("ul#isDeductibleMet")) {
          document.querySelector("ul#isDeductibleMet").style.display = "none";
        }
      } else if (this.props.name == "oopMax") {
        if (document.querySelector("ul#isMaxOopMet")) {
          document.querySelector("ul#isMaxOopMet").style.display = "none";
        }
        if (document.querySelector("ul#oopMaxPercentage")) {
          document.querySelector("ul#oopMaxPercentage").style.display = "none";
        }
      } else if (this.props.name == "isMaxOopMet") {
        if (document.querySelector("ul#oopMax")) {
          document.querySelector("ul#oopMax").style.display = "none";
        }
        if (document.querySelector("ul#oopMaxPercentage")) {
          document.querySelector("ul#oopMaxPercentage").style.display = "none";
        }
      } else if (this.props.name == "oopMaxPercentage") {
        if (document.querySelector("ul#oopMax")) {
          document.querySelector("ul#oopMax").style.display = "none";
        }
        if (document.querySelector("ul#isMaxOopMet")) {
          document.querySelector("ul#isMaxOopMet").style.display = "none";
        }
      }
    }
    this.setState({
      showOptions: !this.state.showOptions,
    });
  };

  hideOptions = () => {
    this.setState({
      showOptions: false,
    });
  };

  showOptions = () => {
    this.setState({
      showOptions: true,
    });
  };

  onKeyDown = (e) => {
    const { activeSuggestion, showOptions } = this.state;
    const SPACEBAR_KEY_CODE = 32;
    const ENTER_KEY_CODE = 13;
    const DOWN_ARROW_KEY_CODE = 40;
    const UP_ARROW_KEY_CODE = 38;
    const ESCAPE_KEY_CODE = 27;
    const lowestValue = this.props.lowestValue;

    switch (e.keyCode) {
      case ENTER_KEY_CODE:
        if (showOptions == false) {
          this.setState({
            showOptions: true,
          });
        } else {
          if (document.querySelector("li.suggestion-active")) {
            const option = document.querySelector(
              "li.suggestion-active"
            ).innerText;
            const value = document.querySelector("li.suggestion-active").id;
            const name = this.props.name;

            this.props.onClick(option, value, name);
            this.hideOptions();
          }
        }
        return;

      case DOWN_ARROW_KEY_CODE:
        if (activeSuggestion - 1 === this.props.options.length) {
          return;
        }
        this.showOptions();
        if (document.querySelector("li[name='" + activeSuggestion + "']")) {
          var myElement = document.querySelector(
            "li[name='" + activeSuggestion + "']"
          );
          var topPos = myElement.offsetTop - 100;

          document.querySelector(".select-input-options").scrollTop = topPos;
        }

        this.setState({ activeSuggestion: activeSuggestion + 1 });
        return;

      case UP_ARROW_KEY_CODE:
        if (activeSuggestion === 0) {
          return;
        }
        this.showOptions();
        if (
          document.querySelector("li[name='" + (activeSuggestion - 1) + "']")
        ) {
          var myElement = document.querySelector(
            "li[name='" + (activeSuggestion - 1) + "']"
          );
          var topPos = myElement.offsetTop;

          document.querySelector(".select-input-options").scrollTop = topPos;
        }

        this.setState({ activeSuggestion: activeSuggestion - 1 });
        return;

      case ESCAPE_KEY_CODE:
        this.hideOptions();
        return;

      case SPACEBAR_KEY_CODE:
        this.toggleOptions();
        return;

      case 48: // 0
        this.showOptions();
        if (document.querySelector("[id='0']")) {
          var myElement = document.querySelector("[id='0']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);
          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          document.querySelector("[name='0']") &&
          (lowestValue == null || lowestValue == 0)
        ) {
          var myElement = document.querySelector("[name='0']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);
          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 49: // 1
        this.showOptions();
        if (document.querySelector("[id='1000']")) {
          var myElement = document.querySelector("[id='1000']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);
          document.querySelector(".select-input-options").p = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          (document.querySelector("[name='1']") && lowestValue == null) ||
          lowestValue <= 1
        ) {
          var myElement = document.querySelector("[name='1']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);
          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 50: // 2
        this.showOptions();
        if (document.querySelector("[id='2000']")) {
          var myElement = document.querySelector("[id='2000']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);
          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          document.querySelector("[name='2']") &&
          (lowestValue == null || lowestValue <= 2)
        ) {
          var myElement = document.querySelector("[name='2']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);
          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 51: // 3
        this.showOptions();
        if (document.querySelector("[id='3000']")) {
          var myElement = document.querySelector("[id='3000']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          document.querySelector("[name='3']") &&
          (lowestValue == null || lowestValue <= 3)
        ) {
          var myElement = document.querySelector("[name='3']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 52: // 4
        this.showOptions();
        if (document.querySelector("[id='4000']")) {
          var myElement = document.querySelector("[id='4000']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          document.querySelector("[name='4']") &&
          (lowestValue == null || lowestValue <= 4)
        ) {
          var myElement = document.querySelector("[name='4']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 53: // 5
        this.showOptions();
        if (document.querySelector("[id='5000']")) {
          var myElement = document.querySelector("[id='5000']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          document.querySelector("[name='5']") &&
          (lowestValue == null || lowestValue <= 5)
        ) {
          var myElement = document.querySelector("[name='5']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 54: // 6
        this.showOptions();
        if (document.querySelector("[id='6000']")) {
          var myElement = document.querySelector("[id='6000']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        } else if (
          document.querySelector("[name='6']") &&
          (lowestValue == null || lowestValue <= 6)
        ) {
          var myElement = document.querySelector("[name='6']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 55: // 7
        this.showOptions();
        if (
          document.querySelector("[name='7']") &&
          (lowestValue == null || lowestValue <= 7)
        ) {
          var myElement = document.querySelector("[name='7']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 56: // 8
        this.showOptions();
        if (
          document.querySelector("[name='8']") &&
          (lowestValue == null || lowestValue <= 8)
        ) {
          var myElement = document.querySelector("[name='8']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 57: // 9
        this.showOptions();
        if (
          document.querySelector("[name='9']") &&
          (lowestValue == null || lowestValue <= 9)
        ) {
          var myElement = document.querySelector("[name='9']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;
      case 73: // i
        this.showOptions();
        if (document.querySelector("[name='0']")) {
          var myElement = document.querySelector("[name='0']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 78: // n
        this.showOptions();
        if (document.querySelector("[id='false']")) {
          var myElement = document.querySelector("[id='false']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      case 89: // n
        this.showOptions();
        if (document.querySelector("[id='true']")) {
          var myElement = document.querySelector("[id='true']");
          var topPos = myElement.offsetTop;
          var index = parseInt(myElement.attributes.name.value);

          document.querySelector(".select-input-options").scrollTop = topPos;
          this.setState({ activeSuggestion: index });
        }
        return;

      default:
        return;
    }
  };

  render() {
    const classes = "position-relative " + this.props.formGroupClasses;

    var icon;
    if (this.props.showArrow) {
      icon = <img src={down} className="form-input-icon" />;
    } else {
      if (this.props.value == "") {
        icon = (
          <img src={cancel} width="24" height="24" className="cancel-icon" />
        );
      } else {
        icon = null;
      }
    }

    var options = null;
    if (this.state.showOptions) {
      options = (
        <ul className="suggestions select-input-options" id={this.props.name}>
          {this.props.options.map((item, index) => {
            let className =
              index === this.state.activeSuggestion
                ? "suggestion-active " + this.props.name
                : this.props.name;
            if (item.disabled) {
              className = className + " disabled";
            }
            return (
              <li
                key={item.value}
                id={item.value}
                name={index}
                className={className}
                onClick={item.disabled ? null : this.onOptionClick}
              >
                {item.option}
              </li>
            );
          })}
        </ul>
      );
    }

    if (this.props.hide == true) {
      return null;
    } else {
      return (
        <div className="position-relative w-100">
          <Form.Group
            controlId={this.props.name}
            className={classes}
            onClick={this.onClick}
          >
            <Form.Label className={this.props.formLabelClasses}>
              {this.props.label}
            </Form.Label>
            <Form.Control
              name={this.props.name}
              type="text"
              className={this.props.inputClasses}
              placeholder={this.props.placeholder}
              value={this.props.value}
              onInput={this.onInput}
              onChange={this.props.onChange}
              onKeyDown={this.onKeyDown}
              autoComplete="off"
              disabled={this.props.disabled}
              required
              autoFocus
            />
            {icon}
            {options}
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      );
    }
  }
}

export default DesktopSelect;
