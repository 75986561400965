import React, { useState, useEffect } from "react";
import Button from "../../components/elements/Button";
import OnboardingSteps from "./OnboardingSteps";
import axios from "axios";
import "./Onboarding.css";
import AccountCreation from "./AccountCreation";
import ProgressBar from "react-bootstrap/ProgressBar";
import HipaaForm from "./HipaaForm";
import FAQAccordian from "../SignIn/FAQAccordian";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BillReviewOnboarding from "./BillReviewOnboarding";

export default function Onboarding() {
  const [currentStep, setCurrentStep] = useState("let's Handl this");
  const [billInformation, setBillInformation] = useState({
    visitType: "",
    facilityName: "",
    insurancePlan: "",
    billAmount: "",
    startDateOfService: "",
    endDateOfService: "",
    signedPDFPath: "",
    signedDate: "",
  });

  const history = useHistory();
  const [userID, setUserID] = useState(
    useSelector((state) =>
      typeof state?.currentUser?.loggedIn === true
        ? state?.currentUser?.currentUser?.id
        : null
    ) ?? ""
  );
  const loggedIn = useSelector((state) => state?.currentUser?.loggedIn);
  const [multipleDatesOfService, setMultipleDatesOfService] = useState(false);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [facility, setFacility] = useState({});
  const progressInterval = loggedIn ? 100 / 6 : 100 / 7;
  const [progressPercentage, setProgressPercentage] = useState(0);
  const visitTypeOptions = ["Medical", "Hospital", "Lab Visit"];
  const pages = [
    {
      title: "What was your visit for?",
      inputLabel: "Visit type",
      name: "visitType",
      skippable: false,
      options: visitTypeOptions,
      FAQInfo: [
        {
          title: "What is a visit type?",
          body: "A visit type is the category that the care you received falls under. They’re all billed differently and are covered by insurance in different ways.",
        },
        {
          title: "I don’t see the visit type I need.",
          body: [
            "We currently only review hospital bills. If your bill falls under the medical or diagnostic categories, ",
            <a
              className="faq-link"
              href="/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              sign up
            </a>,
            " to be notified when we expand our service. If you need help immediately, ",
            <a
              className="faq-link"
              href="/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              let us know
            </a>,
            " and we’ll see if there’s anything we can do. Not sure about which visit type yours falls under? ",
            <a
              className="faq-link"
              href="/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ask us
            </a>,
            "!",
          ],
        },
      ],
    },
    {
      title: "Where was your visit?",
      inputLabel: "Facility name",
      name: "facilityName",
      skippable: false,
      options: facilitiesList,
      FAQInfo: [
        {
          title: "I don’t know where I had my visit.",
          body: "If you don't see the name of the facility you went to, we probably don't serve that facility yet. Feel free to shoot us a note at hello@handlhealth.com and we'll do our best to give you a hand.",
        },
        {
          title: "The facility has multiple names.",
          body: "If the facility you went to has multiple names, select the closest match on the list. You can always add the other names in the notes section before you submit your bill for review.",
        },
      ],
    },
    {
      title: "What is your health insurance plan?",
      inputLabel: "Insurance plan",
      name: "insurancePlan",
      skippable: true,
      options: providersList,
      FAQInfo: [
        {
          title: "My insurance plan isn’t listed.",
          body: "If your insurance plan isn’t listed, you can skip this question for now and add your insurance plan details in the notes section before submitting your bill for review.",
        },
        {
          title: "I don’t have insurance.",
          body: "If you don’t have insurance, you can choose the “I don’t have insurance” option or skip this question.",
        },
      ],
    },
    {
      title: "What was your bill amount?",
      inputLabel: "Bill amount",
      name: "billAmount",
      skippable: true,
      FAQInfo: [
        {
          title: "How do I know the bill amount?",
          body: "The bill amount is on the bill that you are submitting for review. It should be the portion that you, the patient, owe. If you aren’t sure what the bill amount is, you can skip this question for now and come back to it before submitting your bill.",
        },
        {
          title: "Why do you need the bill amount?",
          body: "This helps us get the most accurate review of your bill. If you aren’t sure what the bill amount is at this time, you can skip this question for now and come back to it before you submit the bill for review.",
        },
      ],
    },
    {
      title: "When did you have this visit?",
      inputLabel: "Date of service",
      name: "startDateOfService",
      skippable: true,
      FAQInfo: [
        {
          title: "I can’t remember when I had this visit.",
          body: "The date that you had this visit should be on the bill that you are submitting for review. If you can’t find it and still aren’t sure what the visit date is, you can skip this question for now and come back to it before submitting your bill for review.",
        },
        {
          title: "Why do I have to put the visit date?",
          body: "Including the visit date helps us get the most accurate review of your bill.",
        },
      ],
    },
  ];

  function handleBillingInformationSubmission() {
    const URL = process.env.REACT_APP_API_URL + "billing_records";
    axios
      .post(
        URL,
        {
          billing_record: {
            user_id: userID,
            facility_id: billInformation.facilityID,
            insurance_provider_id: billInformation.insuranceID,
            bill_amount: billInformation.billAmount,
            service_start_date:
              billInformation.startDateOfService !== ""
                ? billInformation.startDateOfService
                : null,
            service_end_date:
              billInformation.endDateOfService !== ""
                ? billInformation.endDateOfService
                : billInformation.startDateOfService !== ""
                ? billInformation.startDateOfService
                : null,
            status: "incomplete",
            signed_hipaa_path:
              billInformation.signedPDFPath !== ""
                ? billInformation.signedPDFPath
                : null,
            signed_hipaa_date:
              billInformation.signedDate !== ""
                ? billInformation.signedDate
                : null,
            what_was_visit_for: billInformation.visitType,
            where_was_visit: billInformation.facilityName,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((res) => {
        history.push("/billing");
      });
  }
  useEffect(() => {
    getFacilityList();
    getInsuranceProviders();
  }, []);

  function getFacilityList() {
    const URL = process.env.REACT_APP_API_URL + "facilities";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setFacilitiesList(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getFacilityInformation(id) {
    const URL = process.env.REACT_APP_API_URL + "facilities/" + id;
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setFacility(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // get insurance providers
  function getInsuranceProviders() {
    const URL = process.env.REACT_APP_API_URL + "insurance_providers";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((res) => {
        setProvidersList([["None", "I don't have insurance"], ...res.data]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const hipaaFAQInfo = [
    {
      title: "Why do I need to sign this?",
      body: "Privacy is important, and it’s always good to know your rights as a patient. By signing the form, you are giving us permission to access your medical information and bills as they pertain to the bill you are submitting. If we can’t do this, it makes it really hard to complete an accurate bill review.",
    },
    {
      title: "Does Handl contact my insurer?",
      body: "We probably won’t contact your insurer, but if we need to, we’ll ask for your consent first. The consent form above gives us permission to contact the facility you went to, so we’ll send you a separate form with more information if we need to contact your insurer for any reason.",
    },
  ];

  return (
    <div className="flex-wrapper-space-between billing-onboarding">
      <div>
        <ProgressBar
          variant="dark"
          now={progressPercentage}
          id="progress-bar"
        />
        <div className="billing-onboarding__container">
          {currentStep === "let's Handl this" && (
            <BillReviewOnboarding
              setCurrentStep={setCurrentStep}
              progressInterval={progressInterval}
              progressPercentage={progressPercentage}
              setProgressPercentage={setProgressPercentage}
            />
          )}
          {pages.map(
            (page, idx) =>
              currentStep === page?.inputLabel && (
                <OnboardingSteps
                  key={page?.inputLabel}
                  billInformation={billInformation}
                  setBillInformation={setBillInformation}
                  page={page}
                  progressInterval={progressInterval}
                  buttonText="Next"
                  allPages={pages}
                  progressPercentage={progressPercentage}
                  setProgressPercentage={setProgressPercentage}
                  idx={idx}
                  setCurrentStep={setCurrentStep}
                  multipleDatesOfService={multipleDatesOfService}
                  setMultipleDatesOfService={setMultipleDatesOfService}
                  setUserID={setUserID}
                  getFacilityInformation={getFacilityInformation}
                />
              )
          )}
          {currentStep === "account creation" && (
            <AccountCreation
              allPages={pages}
              setCurrentStep={setCurrentStep}
              progressInterval={progressInterval}
              setProgressPercentage={setProgressPercentage}
              progressPercentage={progressPercentage}
              setUserID={setUserID}
              userID={userID}
              currentStep={currentStep}
            />
          )}
          {currentStep === "hipaa form" && (
            <HipaaForm
              allPages={pages}
              setBillInformation={setBillInformation}
              progressInterval={progressInterval}
              setProgressPercentage={setProgressPercentage}
              handleBillingInformationSubmission={
                handleBillingInformationSubmission
              }
              progressPercentage={progressPercentage}
              billInformation={billInformation}
              setCurrentStep={setCurrentStep}
              userID={userID}
              facility={facility}
            />
          )}
        </div>
      </div>
      {(pages?.find((step) => step?.inputLabel === currentStep)?.FAQInfo ||
        currentStep === "hipaa form") && (
        <FAQAccordian
          accordianInformation={
            pages?.find((step) => step?.inputLabel === currentStep)?.FAQInfo ??
            (currentStep === "hipaa form" && hipaaFAQInfo)
          }
        />
      )}
    </div>
  );
}
