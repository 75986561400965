import React, { useState, useEffect } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import axios from "axios";
import useToken from "../../hooks/useToken";
import { useHistory } from "react-router-dom";
import ForgotPassword from "../SignIn/ForgotPassword";
import PopupModal from "../../components/templates/PopupModal";
import FAQAccordian from "../SignIn/FAQAccordian";
import SignInForm from "../../components/modules/SignInForm";
import SignUp from "../SignUp/SignUp";

export default function AccountCreation({
  setCurrentStep,
  allPages,
  progressPercentage,
  progressInterval,
  setProgressPercentage,
  setUserID,
  userID,
  currentStep,
}) {
  const [currentPage, setCurrentPage] = useState("account creation");
  const [smallScreen, setSmallScreen] = useState(false);
  const [renderingConfirmationPage, setRenderingConfirmationPage] =
    useState(false);
  const { token, setToken } = useToken();

  // This function determines whethr the user is view the page on a small screen on load and anytime the screen resizes
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const signInAccordianInformation = [
    {
      title: "I don’t have an account.",
      body: "Don’t already have a Handl Health account? Click the “Create New Account” button above to set up your profile and get started using Handl Health.",
    },
    {
      title: "I can’t remember my password.",
      body: "If you can’t remember your password, click the “Forgot Password?” link under Log In, above, and follow the directions outlined.",
    },
  ];

  function onSubmitAction(id) {
    setUserID(id);
    setProgressPercentage(progressPercentage + progressInterval);
    setCurrentStep("hipaa form");
  }

  function displaySignInForm() {
    setCurrentPage("sign in");
  }
  return (
    <div
      className={`billing-onboarding__account-creation flex-wrapper-space-between`}
    >
      <div>
        <>
          {renderingConfirmationPage === false && (
            <p
              onClick={() => {
                if (currentPage === "sign in") {
                  setCurrentPage("account creation");
                } else {
                  setProgressPercentage(progressPercentage - progressInterval);
                  setCurrentStep(allPages[allPages.length - 1].inputLabel);
                }
              }}
              className="body-2 pointer"
            >
              <span className="back-arrow" /> BACK
            </p>
          )}
          {currentPage === "sign in" ? (
            <>
              <h5 className="fw-200 account-creation-header">
                Let’s Handl this. Sign in to your account.
              </h5>
              <SignInForm
                setToken={setToken}
                onSubmitAction={onSubmitAction}
                isMainSignIn={false}
                setCurrentPage={setCurrentPage}
              />
            </>
          ) : (
            <SignUp
              displaySignInForm={displaySignInForm}
              invoker="onboarding"
              renderingConfirmationPage={renderingConfirmationPage}
              setRenderingConfirmationPage={setRenderingConfirmationPage}
            />
          )}
        </>
      </div>
      {currentPage === "sign in" && (
        <div className="account-creation-signin-faq">
          <a className="sign-in__privacy-link" href="/privacy" target="_blank">
            Handl Health Privacy Policy
          </a>
          <FAQAccordian accordianInformation={signInAccordianInformation} />
        </div>
      )}
    </div>
  );
}
