import {
  MainNavigation,
  MemoizedUserNavigation,
} from "../sections//Navigation";
import Footer from "../sections/Footer";
import ProgressBar from "react-bootstrap/ProgressBar";

function Desktop(props) {
  var progressBar;

  if (props.showProgress) {
    progressBar = (
      <ProgressBar variant="dark" now={props.progress} id="progress-bar" />
    );
  }

  return (
    <main className="h-100" id="main-content">
      {progressBar}
      <div
        className={`h-100 flex-wrapper-space-between ${
          props.genericContainer === true ? "generic-container" : ""
        }`}
      >
        {props.body}
        <Footer />
      </div>
    </main>
  );
}

export default Desktop;
