import SidebarLayout from "../../components/templates/SidebarLayout.js";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import SignInSideBar from "./SignInSideBar";
import EditPassword from "../Settings/EditPassword";
import SubmitAccessCode from "./SubmitAccessCode.js";
import "./ForgotPassword.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import FAQAccordian from "./FAQAccordian";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState(
    "Please enter a valid email address."
  );
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeIsValid, setAccessCodeIsValid] = useState(null);
  const [currentStep, setCurrentStep] = useState("submit email");
  const [userDoesNotExist, setUserDoesNotExist] = useState(false);

  // reset password state
  const [password, setPassword] = useState("");
  const [passwordVisibiliy, setPasswordVisibility] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConfirmationIsValid, setPasswordConfirmationIsValid] =
    useState(null);
  const history = useHistory();

  function onSubmit(e) {
    e.preventDefault();

    if (currentStep == "submit email") {
      submitEmail();
    } else if (currentStep == "submit code") {
      setCurrentStep("reset password");
    }
  }

  function goBack() {
    setCurrentStep("submit email");
    setEmail("");
    setEmailIsValid(null);
    setEmailErrorMessage("Please enter a valid email address.");
    setAccessCode("");
    setAccessCodeIsValid(null);
    setUserDoesNotExist(false);
  }

  const submitAccessCodePage = () => {
    const header = (
      <>
        <h5 className="margin-bottom-24">Check your email</h5>
        <p className="body-1">
          Enter the code sent to your email. It expires in 1 hour.
        </p>
      </>
    );

    return (
      <SubmitAccessCode
        header={header}
        accessCode={accessCode}
        onChange={(e) => setAccessCode(e.target.value)}
        onSubmit={(e) => onSubmit(e)}
        accessCodeIsValid={accessCodeIsValid}
      />
    );
  };

  const submitEmail = () => {
    const URL = process.env.REACT_APP_DEVISE_API_URL + "password";
    axios
      .post(URL, {
        user: {
          email: email,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setCurrentStep("submit code");
        }
      })
      .catch(function (error) {
        const errors = error.response.data.errors;
        const noEmailFound = errors.some((e) => e.detail === "Email not found");
        if (noEmailFound == true) {
          const noEmailError = (
            <span>
              Oops! Looks like there isn’t an account associated with that
              email.
              <a className="forgot-password__error-span" href="/sign_up">
                <u>Sign up here.</u>
              </a>
            </span>
          );
          setEmailErrorMessage(noEmailError);
          setUserDoesNotExist(true);
        } else {
          console.log(error.response);
        }
      });
  };

  function validateEmail() {
    const validEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (email == "") {
      return null;
    } else {
      return validEmail != undefined;
    }
  }

  function handleEmailChange(val) {
    setEmail(val);
    setUserDoesNotExist(false);

    if (val == "") {
      setEmailIsValid(null);
      setEmailErrorMessage("Please enter a valid email address.");
    }
  }

  const ForgotPasswordForm = () => {
    return (
      <div>
        <h5 className="margin-bottom-24">Password reset link</h5>
        <p className="body-1">
          Reset links can only be sent to the email used to create this account.
        </p>
        <form
          className="forgot-password-form"
          onSubmit={(e) => onSubmit(e)}
          noValidate
        >
          <Input
            placeholder="Email"
            name="email"
            label="Email"
            value={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            onBlur={(e) => setEmailIsValid(validateEmail())}
            inputContainerClasses="sign-in-form__input"
            showArrow={false}
            readOnly={false}
            errorMessage={emailErrorMessage}
            showErrorMessage={
              (email != "" && emailIsValid == false) || userDoesNotExist
            }
          />

          <Button
            label="Send Reset Link"
            active={emailIsValid == true && !userDoesNotExist}
            class="long-btn filled"
          />
        </form>
      </div>
    );
  };

  const ResetPassword = () => {
    function togglePasswordVisibility() {
      setPasswordVisibility(!passwordVisibiliy);
    }

    function updatePassword() {
      const updateRequest = {
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: accessCode,
      };

      const URL = process.env.REACT_APP_DEVISE_API_URL + "password";
      axios
        .put(URL, { user: updateRequest }, {})
        .then((res) => {
          if (res.status == 200) {
            history.push({
              pathname: "/sign_in",
            });
          }
        })
        .catch(function (error) {
          // Error
          console.log(error.response);
        });
    }

    function validatePassword() {
      if (password == "") {
        return null;
      } else {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{12,}/;
        const passwordLength = password.length;

        const uppercasePassword = uppercaseRegExp.test(password);
        const lowercasePassword = lowercaseRegExp.test(password);
        const digitsPassword = digitsRegExp.test(password);
        const specialCharPassword = specialCharRegExp.test(password);
        const minLengthPassword = minLengthRegExp.test(password);

        return uppercasePassword && specialCharPassword && minLengthPassword;
      }
    }
    function validatePasswordConfirmation() {
      return (
        password != "" &&
        passwordConfirmation != "" &&
        password == passwordConfirmation
      );
    }

    function resetPasswordHandler(e) {
      e.preventDefault();
      if (passwordIsValid && passwordConfirmationIsValid) {
        updatePassword();
      }
    }
    function onChangePasswordHandler(e) {
      setPassword(e.target.value);
      setPasswordIsValid(null);
    }
    function onChangePasswordConfirmHandler(e) {
      setPasswordConfirmation(e.target.value);
      setPasswordConfirmationIsValid(null);
    }
    const inputType = passwordVisibiliy == true ? "text" : "password";

    return (
      <div>
        <h5 className="margin-bottom-24">Reset your password</h5>
        <form
          className="forgot-password-form"
          onSubmit={(e) => resetPasswordHandler(e)}
          noValidate
        >
          <Input
            placeholder="Password"
            type={inputType}
            name="password"
            label="Password"
            value={password}
            onChange={(e) => onChangePasswordHandler(e)}
            onBlur={(e) => setPasswordIsValid(validatePassword(e))}
            inputContainerClasses="forgot-password-form__input"
            showArrow={false}
            readOnly={false}
            errorMessage="Please use at least 12 characters, including 1 uppercase letter and 1 symbol."
            validMessage="Passwords match."
            showErrorMessage={password !== "" && passwordIsValid == false}
            showValidMessage={
              passwordIsValid == true && passwordConfirmationIsValid == true
            }
            showErrorIcon={true}
            showVisibilityToggle={true}
            iconOnClick={() => togglePasswordVisibility()}
          />

          <Input
            placeholder="Re-enter Password"
            type={inputType}
            name="password-confirmation"
            label="Re-enter Password"
            value={passwordConfirmation}
            onChange={(e) => onChangePasswordConfirmHandler(e)}
            // onFocus={(e) => setPasswordConfirmationIsValid(validatePasswordConfirmation())}
            onBlur={(e) =>
              setPasswordConfirmationIsValid(validatePasswordConfirmation())
            }
            inputContainerClasses="forgot-password-form__input"
            showArrow={false}
            readOnly={false}
            errorMessage="Passwords do not match. Please re-enter."
            validMessage="Passwords match."
            showErrorMessage={
              passwordConfirmation != "" &&
              passwordConfirmationIsValid == false &&
              passwordConfirmationIsValid !== null
            }
            showValidMessage={
              passwordIsValid == true && passwordConfirmationIsValid == true
            }
            showErrorIcon={true}
            showVisibilityToggle={true}
            iconOnClick={() => togglePasswordVisibility()}
          />

          <Button
            type="submit"
            label="Reset Your Password"
            active={password != "" && passwordConfirmation != ""}
            class="long-btn filled"
          >
            Reset My Password
          </Button>
        </form>
      </div>
    );
  };
  const accordianInformation = [
    {
      title: "I forgot my email.",
      body: "I forgot my email.",
    },
    {
      title: "How do I contact Handl support?",
      body: "How do I contact Handl support?",
    },
  ];
  const accordianInformationSecondary = [
    {
      title: "I didn’t get a code.",
      body: "I didn’t get a code.",
    },
    {
      title: "How do I contact Handl support?",
      body: "How do I contact Handl support?",
    },
  ];
  return (
    <>
      <SidebarLayout
        signIn
        content={
          currentStep == "submit email"
            ? ForgotPasswordForm()
            : currentStep == "reset password"
            ? ResetPassword()
            : submitAccessCodePage()
        }
        contentClasses="sign-in__content"
        userStatus="logged-out"
        showFooter={false}
      />
      <div>
        <a className="sign-in__privacy-link" href="/privacy" target="_blank">
          Handl Health Privacy Policy
        </a>
        <FAQAccordian
          accordianInformation={
            currentStep == "submit code"
              ? accordianInformationSecondary
              : accordianInformation
          }
        />
      </div>
    </>
  );
}

export default ForgotPassword;
