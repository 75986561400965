import SettingsSideBar from "./SettingsSideBar";
import SidebarLayout from "../../components/templates/SidebarLayout.js";
import Input from "../../components/elements/Input";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import useToken from "../../hooks/useToken";
import EditPassword from "./EditPassword";
import DeletionActions from "./DeletionActions";

function MyProfile(props) {
  const { token } = useToken();
  const location = useLocation();
  const history = useHistory();
  const [userId, setUserId] = useState(
    location.state ? location.state.userId : null
  );
  const [isPhoneValid, setIsPhoneValid] = useState(null);
  const [contentUpdated, setContentUpdated] = useState(false);
  const [userInformation, setUserInformation] = useState({
    name: "",
    email: "",
    employee_id: "10011000",
    birthdate: "01/20/1990",
    personal_email: "",
    phone_number: "",
    dateOfBirth: "",
    next_to_complete: [],
  });

  const [inputStates, setInputStates] = useState({
    birthdate: true,
    personalEmail: true,
    phoneNumber: true,
  });

  const phoneRef = useRef(null);

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    getUserInformation();
  }, [userId]);

  useEffect(() => {
    getUserInformation();
  }, [contentUpdated]);

  function getUserId() {
    if (userId == null) {
      const URL = process.env.REACT_APP_DEVISE_API_URL + "users/users";
      axios
        .get(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setUserId(res.data.data.id);
            getUserInformation();
          } else {
            history.push({
              pathname: "/sign_in",
            });
          }
        })
        .catch(function (error) {
          history.push({
            pathname: "/sign_in",
          });
        });
    } else {
      getUserInformation();
    }
  }

  function getUserInformation() {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userId;
    axios
      .get(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        var user_information = res.data.data.attributes;
        setUserInformation({
          ...userInformation,
          name: user_information.full_name ? user_information.full_name : "",
          email: user_information.email ? user_information.email : "",
          dateOfBirth: user_information.formatted_birthdate
            ? user_information.formatted_birthdate
            : "",
          personal_email: user_information.secondary_email
            ? user_information.secondary_email
            : "",
          phone_number: user_information.phone_number
            ? user_information.phone_number
            : "",
          zip_code: user_information.preferred_zip_code
            ? user_information.preferred_zip_code
            : "",
          next_to_complete: user_information.next_to_complete,
        });
        setContentUpdated(false);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function updateUserInformation(data) {
    const URL =
      process.env.REACT_APP_DEVISE_API_URL +
      "users/user_informations/" +
      userId;
    axios
      .patch(
        URL,
        { user_information: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
        } else {
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  }

  function toggleReadOnly(input, value) {
    setInputStates({
      ...inputStates,
      [input]: !value,
    });
  }

  const PhoneNumberInput = () => {
    function validatePhoneNumber(input) {
      const regex = new RegExp("^[\\d]{10}$");
      return regex.test(input);
    }

    function handleClick() {
      phoneRef.current.focus();
      var isPhoneValid = validatePhoneNumber(userInformation.phone_number);
      setIsPhoneValid(isPhoneValid);
      if (
        isPhoneValid == true ||
        inputStates.phoneNumber == true ||
        (inputStates.phoneNumber == false && userInformation.phone_number == "")
      ) {
        toggleReadOnly("phoneNumber", inputStates.phoneNumber);

        if (inputStates.phoneNumber == false) {
          var updateRequest = { phone_number: userInformation.phone_number };

          updateUserInformation(updateRequest);
        }
      } else {
        console.log("error!");
      }
    }

    return (
      <>
        <p className="heading-poppins">Phone number</p>
        <Input
          innerref={phoneRef}
          placeholder="Phone number"
          name="Phone-number"
          label="Phone number"
          value={userInformation.phone_number}
          onChange={(e) => {
            setIsPhoneValid(null);
            setUserInformation({
              ...userInformation,
              phone_number: e.target.value,
            });
          }}
          inputContainerClasses="settings__input"
          errorMessage="Please enter a valid phone number with 3-digit area code."
          showErrorMessage={
            userInformation.phone_number != "" && isPhoneValid == false
          }
          showArrow={false}
          readOnly={inputStates.phoneNumber}
          showInputActions={true}
          iconOnClick={() => handleClick()}
        />
      </>
    );
  };

  const ContactInformation = () => {
    return (
      <div className="settings__page-section">
        <p className="settings__subtitle margin-bottom-16">
          Contact Information
        </p>
        <div className="settings__noneditable-data">
          <p className="body-2">
            <span className="fw-500">Name:</span> {userInformation.name}
          </p>
          <p className="body-2">
            <span className="fw-500">Email:</span> {userInformation.email}
          </p>
          <p className="body-2">
            <span className="fw-500">DOB:</span> {userInformation.dateOfBirth}
          </p>
        </div>
        {PhoneNumberInput()}
      </div>
    );
  };

  const MyProfilePage = () => {
    return (
      <div className="settings__my-profile settings-page">
        <div className="width-704">
          <Link
            to={{ pathname: "/settings" }}
            className="settings__back-button"
          >
            <p className="body-2 pointer">
              <span className="back-arrow" /> SETTINGS
            </p>
          </Link>
        </div>
        <div className="width-576">
          {ContactInformation()}
          <EditPassword token={token} userId={userId} />
          <DeletionActions token={token} userId={userId} />
        </div>
      </div>
    );
  };

  return (
    <SidebarLayout
      noSidebar={true}
      sidebar={<SettingsSideBar token={token} />}
      sidebarClasses="settings__side-bar"
      content={MyProfilePage()}
      contentClasses="settings__content"
      userStatus="logged-in"
      showFooter={true}
    />
  );
}

export default MyProfile;
