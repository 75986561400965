import React from "react";
import search from "../../assets/Home/icons/search.svg";
import compare from "../../assets/Home/icons/compare.svg";
import choose from "../../assets/Home/icons/choose.svg";
import "./CostEstimatorTools.css";
export default function CostEstimatorTools() {
  const content = [
    {
      img: search,
      subheading: "Search for the care you need",
      description:
        "Find the service or procedure you’re looking for with our intuitive search platform.",
    },
    {
      img: compare,
      subheading: "Compare & contrast by price",
      description:
        "Clearly see the prices offered by healthcare providers in your area.",
    },
    {
      img: choose,
      subheading: "Choose the best option for you",
      description:
        "Decide on the winning provider and create an account to save the info all in one place.",
    },
  ];

  return (
    <div className="cost-estimator-tools">
      <h5 className="margin-bottom-24">
        Our cost estimator makes shopping for healthcare services quick & easy.
      </h5>
      <div className="cost-estimator-flex-section">
        {content.map((item) => (
          <div className="flex-col" key={item.subheading}>
            <img src={item.img} />
            <p className="color-default-blue fw-600 text-subheading">
              {item.subheading}
            </p>
            <p className="body-2">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
