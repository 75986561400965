import React from "react";
import { useState, useEffect } from "react";
import FirstArticle from "./FirstArticle.js";
import Button from "../../components/elements/Button";
import axios from "axios";
import "./Articles.css";
import search from "../../assets/icons/articleSearch.svg";
import ContactSection from "../../components/sections/ContactSection";
import ArticleCard from "../../components/modules/ArticleCard";
import Input from "../../components/elements/Input";
import Loading from "../../components/modules/Loading";
import { Link } from "react-router-dom";

function ArticlesIndex(props) {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeButton, setActiveButton] = useState("latest");
  const [filterValue, setFilterValue] = useState("");
  const [articlesToShow, setArticlesToShow] = useState(5);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (isMounted) {
      async function fetchArticleData() {
        let env;
        if (
          process.env.REACT_APP_API_URL == "https://localhost:3000/api/v1/" ||
          process.env.REACT_APP_API_URL == "http://localhost:3000/api/v1/"
        ) {
          env = "local";
        } else if (
          process.env.REACT_APP_API_URL ==
            "https://handl-backend-staging.herokuapp.com/api/v1/" ||
          process.env.REACT_APP_API_URL ==
            "http://handl-backend-staging.herokuapp.com/api/v1/"
        ) {
          env = "staging";
        } else {
          env = "production";
        }
        const url = process.env.REACT_APP_API_URL + "articles";
        const request = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
          params: {
            env: env,
          },
        });
        var article_data = request.data.data.map(
          (article) => article.attributes
        );
        setArticles(article_data);
        setFilteredArticles(article_data);
        setLoading(false);
        window.scrollTo(0, 0);
      }
      fetchArticleData();
    }
  }, [props]);

  useEffect(() => {
    return () => {
      setArticles(null);
      setFilteredArticles(null);
      setLoading(true);
      setIsMounted(false);
    };
  }, []);

  function sortBy(type) {
    if (type == "latest") {
      articles.sort((a, b) => new Date(b.date) - new Date(a.date));
      setActiveButton("latest");
    } else if (type == "top") {
      articles.sort((a, b) => b.times_read - a.times_read);
      setActiveButton("top");
    } else if (type == "az") {
      articles.sort((a, b) => a.title.localeCompare(b.title));
      setActiveButton("az");
    }
  }

  function filterbyKeyword(keyword) {
    const keywords = [
      ...new Set(articles.map((article) => article.article_keywords).flat()),
    ];

    if (keywords.includes(keyword)) {
      setFilteredArticles(
        articles.filter((article) => article.article_keywords.includes(keyword))
      );
    } else {
      setFilteredArticles(articles);
    }

    setFilterValue(keyword);
  }

  function onFilterChange(e) {
    const keyword = e.target.value;

    filterbyKeyword(keyword);
  }

  var ArticleSort = () => {
    const sortTypes = ["latest", "top", "az"];
    return (
      <div className="article-sort">
        {sortTypes.map((type) => (
          <div
            key={type}
            className={
              activeButton == type ? "filter-option-active" : "filter-option"
            }
            onClick={() => sortBy(type)}
          >
            {type === "top"
              ? "Top reads"
              : type === "az"
              ? "A-Z"
              : type.charAt(0).toUpperCase() + type.slice(1)}
          </div>
        ))}
      </div>
    );
  };

  var ArticleFilter = () => {
    const keywords = [
      ...new Set(articles.map((article) => article.article_keywords).flat()),
    ];

    const formattedKeywords = keywords.map((keyword) => ({ label: keyword }));

    return (
      <div className="article-filter">
        <img src={search} alt="search icon" />
        <Input
          options={formattedKeywords}
          placeholder="Search by keyword"
          value={filterValue}
          onChange={onFilterChange}
          onSelect={filterbyKeyword}
          name="articles-filter-input"
        />
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="articles-container">
        <div className="article-list__body">
          <h1 className="article-list__page-title">Articles</h1>
          <p className="article-list__subtitle">
            Quick reads about all things healthcare.
          </p>

          <div className="article-list__filter-and-sort">
            {ArticleSort()}
            {ArticleFilter()}
          </div>

          <ul className="article-list__list">
            <FirstArticle
              article={filteredArticles[0]}
              key={filteredArticles[0].url}
            />
            <div className="articles-grid-container">
              {filteredArticles.slice(1, articlesToShow).map((article, idx) => (
                <ArticleCard key={idx} article={article} />
              ))}
            </div>
          </ul>

          <Button
            active={true}
            class="text-btn"
            label="see more Articles"
            onClick={() => setArticlesToShow(articlesToShow + 3)}
            hide={articlesToShow >= filteredArticles.length}
          />

          <ContactSection />
        </div>
      </div>
    );
  }
}

export default ArticlesIndex;
