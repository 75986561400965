import React, { useState, useEffect } from "react";
import Button from "../../components/elements/Button";
import Input from "../../components/elements/Input";
import useToken from "../../hooks/useToken";
import { useSelector } from "react-redux";

export default function OnboardingSteps({
  billInformation,
  setBillInformation,
  page,
  buttonText,
  setUserID,
  progressPercentage,
  setProgressPercentage,
  progressInterval,
  FAQData,
  allPages,
  setCurrentStep,
  multipleDatesOfService,
  setMultipleDatesOfService,
  idx,
  getFacilityInformation,
}) {
  const userID = useSelector((state) =>
    typeof state?.currentUser?.currentUser?.id === "string"
      ? state?.currentUser?.currentUser?.id
      : null
  );

  const loggedIn = useSelector((state) => state?.currentUser?.loggedIn);
  const [billAmountNotValid, setBillAmountNotValid] = useState(false);
  const ActionButtons = () => {
    return (
      <>
        <Button
          label={buttonText}
          className="onboarding-steps__next-btn"
          active={
            page.name === "billAmount"
              ? billInformation[page?.name] !== "" &&
                billAmountNotValid === false
              : billInformation[page?.name] !== ""
          }
          onClick={() => {
            setProgressPercentage(progressPercentage + progressInterval);
            setCurrentStep(
              idx <= allPages.length - 2
                ? allPages[idx + 1].inputLabel
                : loggedIn === true
                ? "hipaa form"
                : "account creation"
            );
            loggedIn === true && setUserID(userID);
          }}
          class="long-btn filled"
        />
        {page?.skippable === true && (
          <Button
            label="Skip"
            active={true}
            class="outline-btn-black"
            className="onboarding-steps-skip-btn"
            onClick={() => {
              setProgressPercentage(progressPercentage + progressInterval);
              setBillInformation({
                ...billInformation,
                [page?.name === "insurancePlan" ? "insuranceID" : page?.name]:
                  "",
              });
              setCurrentStep(
                idx <= allPages.length - 2
                  ? allPages[idx + 1].inputLabel
                  : loggedIn === true
                  ? "hipaa form"
                  : "account creation"
              );
            }}
          />
        )}
      </>
    );
  };
  return (
    <div>
      <p
        onClick={() => {
          if (idx > 0) {
            setProgressPercentage(progressPercentage - progressInterval);
            setCurrentStep(allPages[idx - 1].inputLabel);
          } else {
            setCurrentStep("let's Handl this");
            setProgressPercentage(0);
          }
        }}
        className="body-2 pointer"
      >
        <span className="back-arrow" /> BACK
      </p>
      {page?.inputLabel !== "Date of service" ||
      multipleDatesOfService === false ? (
        <div>
          <h5 className="fw-200">{page.title}</h5>
          <Input
            placeholder={page?.inputLabel}
            showCurrencyPrefix={page?.name === "billAmount"}
            name={page?.name}
            label={page?.inputLabel}
            type={page?.inputLabel === "Date of service" ? "date" : "text"}
            value={billInformation[page?.name]}
            onChange={(e) => {
              setBillAmountNotValid(false);
              setBillInformation({
                ...billInformation,
                [e.target.name]: e.target.value,
              });
            }}
            onSelect={(val, name, value) => {
              setBillInformation({
                ...billInformation,
                [page?.name]: val,
                [page?.name === "facilityName" && "facilityID"]: value,
                [page?.name === "insurancePlan" && "insuranceID"]: value,
              });
              if (page?.name === "facilityName") {
                getFacilityInformation(value);
              }
            }}
            inputContainerClasses="sign-up-form__input"
            showDropdownArrow={[
              "Visit type",
              "Facility name",
              "Insurance plan",
            ].includes(page?.inputLabel)}
            selectOnly={["Visit type"].includes(page?.inputLabel)}
            readOnly={false}
            options={
              page?.options
                ? page?.inputLabel === "Facility name" ||
                  page?.inputLabel === "Insurance plan"
                  ? page?.options.map((i) => ({ label: i?.[1], value: i?.[0] }))
                  : page?.options.map((i) => ({ label: i, value: i }))
                : null
            }
            forceAutocomplete={true}
            onBlur={(e) => {
              if (page?.inputLabel === "Bill amount") {
                setBillAmountNotValid(
                  !/^[1-9]\d*(\.\d+)?$/.test(billInformation.billAmount)
                );
              }
            }}
            showErrorIcon={true}
            showErrorMessage={
              page?.inputLabel === "Bill amount" && billAmountNotValid === true
            }
            errorMessage={"Please enter a valid bill amount"}
          />
          {page?.inputLabel === "Date of service" && (
            <p
              className="dates-of-service-text text-small fw-400"
              onClick={() => setMultipleDatesOfService(!multipleDatesOfService)}
            >
              {multipleDatesOfService === true
                ? "My bill only has one date."
                : "My visit was more than one day."}
            </p>
          )}
          {ActionButtons()}
        </div>
      ) : (
        page.inputLabel === "Date of service" &&
        multipleDatesOfService === true && (
          <div>
            <h5 className="fw-200">{page.title}</h5>
            <Input
              specialDatePlaceholder
              placeholder="Start date of service"
              name="startDateOfService"
              label="Start date of service"
              type="date"
              value={billInformation.startDateOfService}
              onChange={(e) => {
                setBillInformation({
                  ...billInformation,
                  startDateOfService: e.target.value,
                });
              }}
              onSelect={(val, name) => {
                setBillInformation({
                  ...billInformation,
                  startDateOfService: val,
                });
              }}
              inputContainerClasses="sign-up-form__input"
              readOnly={false}
            />
            <Input
              specialDatePlaceholder
              placeholder="End date of service"
              name="DateOfService"
              label="End date of service"
              type="date"
              value={billInformation.endDateOfService}
              onChange={(e) => {
                setBillInformation({
                  ...billInformation,
                  endDateOfService: e.target.value,
                });
              }}
              onSelect={(val, name) => {
                setBillInformation({
                  ...billInformation,
                  endDateOfService: val,
                });
              }}
              inputContainerClasses="sign-up-form__input"
              showArrow={true}
              readOnly={false}
            />
            <p
              className="dates-of-service-text text-small"
              onClick={() => setMultipleDatesOfService(!multipleDatesOfService)}
            >
              {multipleDatesOfService === true
                ? "My bill only has one date."
                : "My visit was more than one day."}
            </p>
            {ActionButtons()}
          </div>
        )
      )}
    </div>
  );
}
