import React from 'react'
import SidebarLayout from '../../components/templates/SidebarLayout.js';
import SignInSideBar from './SignInSideBar';
import SubmitAccessCode from './SubmitAccessCode';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';


function UnlockAccount () {

  const [accessCode, setAccessCode] = useState('');
  const [accessCodeIsValid, setAccessCodeIsValid] = useState(null);

  const history = useHistory()

  const header = (
    <>
        <h1>Enter your code</h1>
        <p className="body-1">Enter the code sent to your work email. It expires in 1 hour.</p>
    </>
  )
  function onSubmit(e) {
      e.preventDefault()
      console.log(accessCode)
      const URL = process.env.REACT_APP_DEVISE_API_URL + 'unlock';
    axios.get(URL, {
            params: {
                unlock_token: accessCode
              }
      }).then(res => {
        if (res.status == 200) {
            history.push({
                pathname: '/forgot_password'
              })
        }
      }).catch(function(error) {
        setAccessCodeIsValid(false)
      });
  }
    return (
        <SidebarLayout 
          signIn
          sidebar={<SignInSideBar/>}
          sidebarClasses='sign-in__sidebar'
          content={<SubmitAccessCode header={header} onChange={(e)=>setAccessCode(e.target.value)} onSubmit={(e)=> onSubmit(e)} accessCode={accessCode} accessCodeIsValid={accessCodeIsValid}/>}
          contentClasses='sign-in__content'
          userStatus='logged-out'
          showFooter={false}
        />
      )
}

export default UnlockAccount