import React, { useEffect, useState } from "react";
import MobileFilterPanel from "./MobileFilterPanel";
import MobileSortFilter from "./MobileSortFilter";

export default function SortFilter({
  filterLabel,
  sortLabel,
  setFilteredContent,
  contentManipulationOptions,
  originalContent,
  content,
}) {
  function filterResults(records) {
    if (filterLabel.length === 0) return records;
    return records?.filter((record) => filterLabel.includes(record?.status));
  }

  function sortResults(records) {
    if (sortLabel === "") return records;
    else if (sortLabel === "Date created: newest to oldest") {
      return records.sort(function (a, b) {
        return new Date(b?.created_at) - new Date(a?.created_at);
      });
    } else if (sortLabel === "Date created: oldest to newest") {
      return records.sort(function (a, b) {
        return new Date(a?.created_at) - new Date(b?.created_at);
      });
    } else if (sortLabel === "Visit date: newest to oldest") {
      return records.sort(function (a, b) {
        return (
          new Date(b?.service_start_date) - new Date(a?.service_start_date)
        );
      });
    } else if (sortLabel === "Visit date: oldest to newest") {
      return records.sort(function (a, b) {
        return (
          new Date(a?.service_start_date) - new Date(b?.service_start_date)
        );
      });
    } else if (sortLabel === "Default") {
      return originalContent.filter((bill) =>
        records.some((record) => record.id === bill.id)
      );
    }
  }

  useEffect(() => {
    let result = filterResults(content);
    let sortedResult = sortResults(result);
    setFilteredContent(sortedResult);
  }, [filterLabel, sortLabel]);

  return (
    <div>
      {contentManipulationOptions?.map((option) =>
        option.type === "filter" ? (
          <MobileFilterPanel
            key={option.type}
            showSortModal={option.showSortModal}
            setShowSortModal={option.setShowSortModal}
            filterLabel={option.filterLabel}
            setFilterLabel={option.setFilterLabel}
            sortOptions={option.sortOptions}
          />
        ) : (
          <MobileSortFilter
            key={option.type}
            showSortModal={option.showSortModal}
            setShowSortModal={option.setShowSortModal}
            filterLabel={option.filterLabel}
            setFilterLabel={option.setFilterLabel}
            sortOptions={option.sortOptions}
          />
        )
      )}
    </div>
  );
}
