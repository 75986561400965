import React, { Component } from "react";
import Button from "../elements/Button";
import EstimateFormMobile from "../sections/EstimateFormMobile";
import Loading from "../modules/Loading";
import axios from "axios";
import clock from "../../assets/icons/clock.png";
import "./ServiceDetails.css";

class ServiceDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceName: "",
      cptCode: this.props.cpt_code,
      time: "",
      serviceDescription: "",
      rationale: "",
      overline: "",
      serviceGroup: "",
      handlCategory: "",
      loading: true,
      show: false,
    };

    this.searchService = this.searchService.bind(this);
    this.showModal = this.showModal.bind(this);
    this.buildServiceDetails = this.buildServiceDetails.bind(this);
  }

  searchService() {
    const URL = process.env.REACT_APP_API_URL + "services/searchable_service";
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
        params: {
          cpt_code: this.props.cpt_code,
        },
      })
      .then((res) => {
        const service = res.data.data.attributes;
        this.setState({ serviceName: service.name });
        this.setState({ cptCode: service.cpt_code });
        this.setState({ time: service.additional_detail });
        this.setState({ serviceDescription: service.description });
        this.setState({ rationale: service.rationale });
        this.setState({ overline: service.overline });
        this.setState({ serviceGroup: service.service_group_name });
        this.setState({ handlCategory: service.handl_category_name });
        this.setState({ loading: false });
      });
  }

  showModal = () => {
    this.setState({ show: !this.state.show });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.searchService();
  }

  buildServiceDetails() {
    const time = this.state.time;
    if (time != null) {
      return (
        <ul className="service-details-list pb-2 pl-0">
          <li className="small my-3">
            <img src={clock} width="18" height="18" className="mr-3" /> {time}{" "}
            minutes single session
          </li>
        </ul>
      );
    }
  }

  render() {
    var serviceDetailsList = this.buildServiceDetails();
    var secondTitle = "Uses of " + this.state.serviceName;

    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <div className="service-details">
          <p className="text-xsmall">
            {this.state.handlCategory.toUpperCase()}
          </p>
          <h3 className="bold service-name">{this.state.serviceName} </h3>
          <p>{this.state.serviceDescription}</p>
          {serviceDetailsList}
          <Button
            class="mobile-estimate-form-btn long-btn with-shadow"
            active={true}
            label="SELECT SERVICE"
            onClick={this.showModal}
          />

          <h4 className="service-title">{secondTitle}</h4>
          <p>{this.state.rationale}</p>
        </div>

        <EstimateFormMobile
          onClose={this.showModal}
          show={this.state.show}
          serviceName={this.state.serviceName}
          service={this.state.cptCode}
          ref={this.props.quoteRef}
          onSubmit={this.props.onSubmit}
          smallScreen={true}
          formData={this.props.formData}
        />
      </div>
    );
  }
}

export default ServiceDetails;
